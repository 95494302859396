import React, {createContext, ReactElement, useContext, useEffect, useState} from 'react';
import {AuthenticatedUser} from "../types";
import {Auth} from "firebase/auth";
import axios from "axios";
import {ToastContext} from "./ToastContext";
import {stringToEnumPermissionMap} from "../utils/utils";
import {getMe} from "../API";
import {mapErrors} from "../utils/errorMapping";


export interface AuthContextType {
    authenticatedUser: AuthenticatedUser | null;
    signOut: () => void;
    validateAuthentication: () => boolean;
    loading: boolean;
    firebaseAuth: Auth;
}

export const AuthContext = createContext<AuthContextType>({
    authenticatedUser: null,
    signOut: () => {},
    validateAuthentication: () => false,
    loading: false,
    firebaseAuth: {} as Auth,
});

export const useAuth = () => useContext(AuthContext);

interface Props {
    children: ReactElement;
    firebaseAuth: Auth;
}

export default function AuthProvider({children, firebaseAuth}: Props): ReactElement {
    const { addToast } = useContext(ToastContext);

    const [loading, setLoading] = useState(true);
    const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser | null>(null);

    useEffect(() => {
        firebaseAuth.onIdTokenChanged(function(firebaseUser: any) {
            if (firebaseUser) {
                getMe()
                    .then((me) => {
                        const authorities = me.authorities.map(item => stringToEnumPermissionMap[item.authority]);
                        setAuthenticatedUser({
                            id: firebaseUser.uid,
                            firstName: firebaseUser.displayName ? firebaseUser.displayName : 'kasutaja',
                            lastName: '',
                            email: firebaseUser.email ?? '',
                            permissions: authorities,
                            roles: me.roles,
                            driverId: me.driverId,
                            regions: me.regions,
                            regionalFilteringApplies: me.regionalFilteringApplies,
                        });
                    })
                    .catch((error) => {
                        addToast({type: 'error', text: mapErrors(error) ?? 'Kasutaja info pärimisel ilmnes viga'});
                        setAuthenticatedUser(null);
                    })
            } else {
                setAuthenticatedUser(null);
            }
        });
        axios.get('/actuator/health').finally(() => setLoading(false));
    }, []);

    const validateAuthentication = () => {
        return authenticatedUser !== null;
    };

    const signOut = () => {
        if (firebaseAuth.currentUser) {
            setLoading(true);
            firebaseAuth.signOut().then(() => {
                window.location.reload();
            });
        }
    };

    return (
        <AuthContext.Provider value={{authenticatedUser: authenticatedUser, signOut, validateAuthentication, firebaseAuth, loading}}>
            {children}
        </AuthContext.Provider>
    );
}
