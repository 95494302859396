import React, {useState} from 'react';
import {useEffect} from "react";
import {getAvlAssignments} from "../../../API/avlAssignments/api";
import ListView from "../../../layouts/ListViewWrapper";
import DateNavigator from "../../../components/DateNavigator";
import {Box, Typography, useTheme} from "@mui/material";
import {useDayNavigation} from "../../../store/viewSlice";
import {GridColDef} from "@mui/x-data-grid";
import DataGrid from "../../../components/DataGrid";
import {AvlAssignmentRow} from "./types";
import {getAvlSyncStatusTranslation} from "../../../utils/enumTranslations";
import {getDisplayDate, getDisplayNullableDateTime, getTimeString} from "../../../utils/dateUtils";
import Button from "../../../components/Buttons/Button";
import dayjs, {Dayjs} from "dayjs";
import {Refresh} from "@mui/icons-material";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../../../layouts/SearchLayoutWrapper";
import {filterByValue} from "../../../utils/utils";

export default function AvlAssignments() {

    const theme = useTheme();
    const [date, handleChangeDate] = useDayNavigation();
    const [rows, setRows] = useState<AvlAssignmentRow[]>([]);
    const [refreshTime, setRefreshTime] = useState<Dayjs | null>(null);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();

    function fetchAssignments() {
        getAvlAssignments(date).then((data) => {
            const rows = data.map(assignment => {
                return {
                    id: `${assignment.tripId}-${assignment.date}`,
                    ...assignment,
                    date: getDisplayDate(assignment.date),
                    status: getAvlSyncStatusTranslation(assignment.status),
                    lastSyncTime: getDisplayNullableDateTime(assignment.lastSyncTime),
                };
            });
            setRows(rows);
            setRefreshTime(dayjs())
        });
    }

    useEffect(() => {
        fetchAssignments();
    }, [date]);

    const columns: GridColDef[] = [
        {
            field: 'tripCode',
            headerName: 'Reisi kood',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'date',
            headerName: 'Kuupäev',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'status',
            headerName: 'Staatus',
            sortable: true,
            filterable: false,
            width: 120,
        },
        {
            field: 'lastSyncTime',
            headerName: 'Viimati saadetud',
            sortable: true,
            filterable: true,
            width: 150,
        },
        {
            field: 'lastMessage',
            headerName: 'Viimane tulemus',
            sortable: true,
            filterable: false,
            flex: 1
        }
    ]

    return (
        <ListView headerProps={{
            title: 'Töökäsud',
            searchProps: {input: searchInput, setInput: setSearchInput},
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                mb: 1.5,
                width: 'fit-content',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column-reverse',
                    width: '100%',
                    alignItems: 'flex-start',
                },
            }}>
                <DateNavigator date={date} handleSelectedDateChange={handleChangeDate} unit={'day'} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    pl: 1,
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        pl: 0,
                        pb: 1,
                        flexDirection: 'row',
                    },
                }}>
                    <Button onClick={fetchAssignments}
                            size="small"
                            color="secondary"
                            styles={{ height: '26px', lineHeight: 'normal', width: 'fit-content', minWidth: 'fit-content' }}
                            startIcon={<Refresh />}
                            variant="outlined"
                            text={'värskenda'}
                    />
                    {refreshTime &&
                        <Box sx={{ ml: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                {refreshTime && `Värskendatud ${getTimeString(refreshTime)}`}
                            </Typography>
                        </Box>
                    }
                </Box>
            </Box>
            <DataGrid
                sorting={{
                    sortModel: [{ field: 'lastSyncTime', sort: 'desc' }],
                }}
                rows={filterByValue(rows, searchInput)}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </ListView>
    );
}