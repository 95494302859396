import React, {createContext, ReactElement, useEffect} from 'react';
import {useMediaQuery} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {useLocalStorage} from "usehooks-ts";


interface SidebarContextType {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
    expandedSidebarMenuItems: string[];
    toggleExpandedSidebarMenuItem: (name: string) => void;
}

interface Props {
    children: ReactElement;
}

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);

export const SidebarProvider = ({ children }: Props) => {
    const [isSidebarOpen, setIsSidebarOpen] = useLocalStorage('isSidebarOpen', false);
    const [expandedSidebarMenuItems, setExpandedSidebarMenuItems] = useLocalStorage<string[]>('expandedSidebarMenuItems', []);

    const toggleSidebar = () => setIsSidebarOpen(isOpen => !isOpen);
    const toggleExpandedSidebarMenuItem = (name: string) => {
        setExpandedSidebarMenuItems(prevState =>
            prevState.includes(name) ? prevState.filter(itemName => itemName !== name) : [...prevState, name]
        );
    };

    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'), {
        defaultMatches: true,
        noSsr: false,
    });

    useEffect(() => {
        if (isSidebarOpen) setIsSidebarOpen(lgUp);
    }, [lgUp]);

    return (
        <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, expandedSidebarMenuItems, toggleExpandedSidebarMenuItem: toggleExpandedSidebarMenuItem }}>
            {children}
        </SidebarContext.Provider>
    );
};
