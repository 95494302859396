import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import React from "react";
import TextField from "../../../../../../../components/Form/TextField";
import Button from "../../../../../../../components/Buttons/Button";
import {useAppDispatch} from "../../../../../../../hooks";
import {updateWorkSheetComment} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import {Box} from "@mui/material";
import {isEqual} from "lodash";

interface FormData {
    comment: string;
}

interface CommentFormProps {
    workSheetId: number;
    driverComment: string;
    handleClose: () => void;
}

const CommentForm = ({workSheetId, driverComment, handleClose}: CommentFormProps) => {
    const dispatch = useAppDispatch();

    const initialValues: FormData = {
        comment: driverComment,
    };

    const handleSubmit = (values: FormData, helpers: FormikHelpers<FormData>) => {
        dispatch(updateWorkSheetComment({
            id: workSheetId,
            comment: values.comment,
        }));
        helpers.setSubmitting(false);
        handleClose();
    };

    const isSubmitDisabled = (formikProps: FormikProps<FormData>) => {
        return isEqual(initialValues, formikProps.values) || formikProps.isSubmitting;
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(formikProps: FormikProps<FormData>) =>
                <Form>
                    <TextField name="comment" label="Kommentaar" multiline fullWidth minRows={3} maxRows={5}/>
                    <Box sx={{textAlign: 'right'}}>
                        <Button text="Salvesta" type="submit" disabled={isSubmitDisabled(formikProps)} />
                    </Box>
                </Form>
            }
        </Formik>
    );
};

export default CommentForm;
