import React from "react";
import {Typography} from "@mui/material";
import StaticIcon, {StaticIconProps} from "../StaticIcon";

interface StaticIconWithTextProps extends StaticIconProps {
    text: string;
    mobileViewText?: string;
    variant?: 'body1' | 'body2';
    color?: 'text.primary' | 'text.secondary';
}

const StaticIconWithText = ({text, mobileViewText, variant = 'body1', color = 'text.secondary', ...staticIconProps}: StaticIconWithTextProps) => {
    return (
        <Typography variant={variant} color={color} sx={{display: 'flex', alignItems: 'flex-start', mt: -0.5}}>
            <StaticIcon {...staticIconProps} />
            <span style={{paddingTop: '5px', paddingBottom: '2px'}}>{mobileViewText ? `${mobileViewText} ${text}` : text}</span>
        </Typography>
    );
};

export default StaticIconWithText;
