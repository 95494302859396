import {Box, MenuItem, TextField, Typography, useTheme} from "@mui/material";
import React, {ChangeEventHandler} from "react";
import {ResourceType} from "../../../../API/workGroup/types";
import {BusOption, DriverOption} from "../../../../hooks";
import {PriorityIconText} from "../../defects/detailsView/components/DefectFields";
import {getHighestPriorityDefect} from "../listView/BusesWithDefects";


interface SelectProps {
    value: number | null;
    onChange: ChangeEventHandler<HTMLInputElement>;
    options: BusOption[] | DriverOption[];
    resourceType: ResourceType;
}

export default function Select({value, onChange, options, resourceType}: SelectProps) {
    const theme = useTheme();

    const DisplayOption = ({option}: {option: BusOption | DriverOption}) => {
        const highestPriorityDefect = resourceType === ResourceType.VEHICLE && 'defects' in option && option.defects?.length > 0
            ? getHighestPriorityDefect(option.defects) : undefined;

        return (
            <Box sx={{display: 'flex', flexDirection: 'row', wrap: 'no-wrap', gap: 0.5}}>
                <Typography variant="body2">{option.name}</Typography>
                {highestPriorityDefect &&
					<PriorityIconText key={highestPriorityDefect.id} priority={highestPriorityDefect.priority} priorityDisplayStr="" showCompactView />
                }
            </Box>
        );
    };

    return (
        <TextField
            select
            size="small"
            value={value ?? ''}
            fullWidth
            inputProps={{
                sx: {fontSize: theme.typography.fontSize, px: 1, py: .5}
            }}
            onChange={onChange}
            sx={{
                '.MuiInputBase-root': {
                    '& fieldset': {borderColor: 'transparent'},
                    '& .MuiSvgIcon-root': { visibility: 'hidden' },
                    '&:hover, &.Mui-focused': {
                        '.MuiSvgIcon-root': {visibility: 'visible'},
                    },
                },
            }}
        >
            <MenuItem key="null" value="" sx={{fontSize: theme.typography.fontSize}}> - </MenuItem>
            {options.map(option =>
                <MenuItem key={option.id} value={option.id} sx={{fontSize: theme.typography.fontSize}}>
                    <DisplayOption option={option} />
                </MenuItem>
            )}
        </TextField>
    );
}