import { Box } from "@mui/material";
import React, {ReactNode} from "react";
import PageHeader, {PageHeaderProps} from "../../components/PageHeader";
import LoaderWithChildren from "../../components/LoaderWithChildren";

interface ListViewProps {
    headerProps: PageHeaderProps;
    isLoading?: boolean;
    children?: ReactNode;
}

const ListView = ({headerProps, isLoading, children}: ListViewProps) => {
    return (
        <Box sx={{height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            <PageHeader {...headerProps} />
            <LoaderWithChildren loading={isLoading ?? false}>
                {children}
            </LoaderWithChildren>
        </Box>
    );
};

export default ListView;
