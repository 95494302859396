import React, {useState} from 'react';
import {DefectWithSearchFields} from "../../../../../../../store/defectsSlice";
import {Box, Collapse, Typography} from "@mui/material";
import CardBase from "../../../components/CardBase";
import StaticIcon from "../../../../../../../components/Icons/StaticIcon";
import DefectFormWrapper from "../../../../../defects/detailsView/components/DefectFormWrapper";
import DefectComments from "../../../../../defects/detailsView/components/DefectComments";
import {useAuth} from "../../../../../../../contexts/AuthContext";
import {DefectStatus} from "../../../../../../../API/defects/types";
import {
    CommentsSummaryIconText,
    CreatorIconText,
    DescriptionIconText,
    EditorIconText,
    PriorityIconText,
    RepairDateIconText,
    StatusIconText
} from "../../../../../defects/detailsView/components/DefectFields";


interface DefectsCardProps {
    defect: DefectWithSearchFields;
}

const DefectsCard = ({defect}: DefectsCardProps) =>  {
    const { authenticatedUser } = useAuth();
    const [isCardOpen, setIsCardOpen] = useState(false);

    const handleCardClick = () => setIsCardOpen(prevState => !prevState);

    return (
        <CardBase key={defect.id} onClick={handleCardClick} sx={{pb: 2}}>
            <Typography variant="body2" component="div" sx={{display: 'flex', justifyContent: 'space-between', p: 1}}>
                    <Box>
                        <StatusIconText status={defect.status} statusDisplayStr={defect.statusDisplayStr} />
                    </Box>
                <Box sx={{display: 'flex', alignItems: 'center', mt: -0.5}}>
                    <Box pr={1}>
                        <PriorityIconText priority={defect.priority} priorityDisplayStr={defect.priorityDisplayStr} />
                    </Box>
                    <StaticIcon
                        type={defect.creatorUserId === authenticatedUser?.id && defect.status === DefectStatus.NEW ? 'EDIT' : 'VIEW'}
                        color="text.secondary"
                        disabled={false}
                    />
                </Box>
            </Typography>
            <CreatorIconText defect={defect} isDriverView />
            <Collapse in={isCardOpen}>
                <EditorIconText defect={defect} isDriverView />
                <Typography variant="body2"  color="text.secondary" component="div" sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <RepairDateIconText repairDateDisplayStr={defect.repairDateDisplayStr} repairedAt={defect.repairedAt} expectedRepairDateTime={defect.expectedRepairDateTime} isDriverView />
                </Typography>
            </Collapse>
            <Collapse in={!isCardOpen || defect.status !== DefectStatus.NEW}>
                <DescriptionIconText description={defect.description} />
            </Collapse>
            <Collapse in={!isCardOpen}>
                {defect.commentIds.length > 0 && <CommentsSummaryIconText commentIds={defect.commentIds} />}
            </Collapse>
            <Collapse in={isCardOpen && defect.status === DefectStatus.NEW} onClick={(event: React.MouseEvent) => event.stopPropagation()}>
                <Box pt={1}>
                    <DefectFormWrapper defect={defect} isDriverView={true} handleCancel={handleCardClick} />
                </Box>
            </Collapse>
            <Collapse in={isCardOpen} onClick={(event: React.MouseEvent) => event.stopPropagation()}>
                <Box px={1}>
                    <DefectComments defectId={defect.id} />
                </Box>
            </Collapse>
        </CardBase>
    )
}

export default DefectsCard;
