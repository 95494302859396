import React, {useMemo} from "react";
import {PayType} from "../../../../API/payslip/types";
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@mui/material";
import {getPayTypeTranslation} from "../../../../utils/enumTranslations";
import {numberFormat, payTypeIsCountedForTotal} from "../types";


const payTypesWithoutHours = [PayType.PARCEL_DELIVERY_FEE, PayType.EXTRA_FEE];

export interface PayslipBlockData {
    name?: string;
    lines: Map<PayType, {hours: number, amount: number}>;
}

const PayslipBlock = ({data}: {data: PayslipBlockData}) => {
    const theme = useTheme();
    const {totalHours, totalAmount} = useMemo(() => {
        let totalHours = 0;
        let totalAmount = 0;

        data.lines.forEach(({hours, amount}, payType) => {
            totalHours += payTypeIsCountedForTotal(payType) ? hours : 0;
            totalAmount += amount;
        });

        return {totalHours, totalAmount};
    }, [data]);

    return (
        <Box  sx={{
            mt: 3,
            border: `1px solid ${theme.palette.divider}`,
            padding: 1,
            borderRadius: '10px',
            minWidth: 300,
            '@media print': { breakInside: 'avoid' },
        }}>
            {data.name && <Typography sx={{ml: 2, mt: 1}}>{data.name}</Typography>}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>tundi</TableCell>
                        <TableCell>summa</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(PayType).map(payType =>
                        <TableRow key={payType}>
                            <TableCell>{getPayTypeTranslation(payType)}</TableCell>
                            <TableCell>
                                {payTypesWithoutHours.includes(payType)
                                    ? ''
                                    : data.lines.get(payType)?.hours.toLocaleString('et-EE', numberFormat)
                                }
                            </TableCell>
                            <TableCell>{data.lines.get(payType)?.amount.toLocaleString('et-EE', numberFormat)}</TableCell>
                        </TableRow>
                    )}
                    <TableRow sx={{'.MuiTableCell-root': {borderBottom: 'none'}}}>
                        <TableCell sx={{fontWeight: 'bold'}}>Kokku</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{totalHours.toLocaleString('et-EE', numberFormat)}</TableCell>
                        <TableCell sx={{fontWeight: 'bold'}}>{totalAmount.toLocaleString('et-EE', numberFormat)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

export default PayslipBlock;
