import React from 'react';
import {CalendarEntryType, ScheduleSetting, RepetitionRules} from "../../API/types";
import {Box, SvgIconTypeMap, Tooltip, Typography} from "@mui/material";
import theme from "../../theme";
import {Flag, SportsKabaddi, WbSunny} from "@mui/icons-material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {getCalendarEntryTypeTranslation, getScheduleSettingTranslation} from "../../utils/enumTranslations";


interface RepetitionRulesProps {
    repetition: RepetitionRules;
    fontSize?: 'small' | 'default';
}

const Repetition = ({ repetition, fontSize = 'default' }: RepetitionRulesProps) => {
    const renderRepetitionDay = (isActive: boolean, day: string) =>
        <Box mx={0.1} color={isActive ? 'inherit' : theme.palette.text.disabled}>{day}</Box>;

    const renderRepetitionIcon = (
        title: CalendarEntryType, setting: ScheduleSetting | undefined, Icon: OverridableComponent<SvgIconTypeMap>
    ) => {
        return (setting !== undefined && setting !== ScheduleSetting.NoEffect ?
            <Tooltip arrow placement="top" title={
                <>
                    <Typography fontSize="12px" color="common.white" align="center">
                        {getCalendarEntryTypeTranslation(title)}:
                    </Typography>
                    <Typography fontSize="12px" color="common.white" align="center">
                        {getScheduleSettingTranslation(setting)}
                    </Typography>
                </>
            }>
                <Icon sx={{mx: '0.1rem', fontSize: fontSize === 'small' ? '0.8rem' : '0.95rem', color: 'inherit'}} />
            </Tooltip>
            :
            null
        );
    };

    return (
        <Box display="flex" alignItems="center" fontSize={fontSize === 'small' ? '12px' : '14px'}>
            {renderRepetitionDay(repetition.mon, 'E')}
            {renderRepetitionDay(repetition.tue, 'T')}
            {renderRepetitionDay(repetition.wed, 'K')}
            {renderRepetitionDay(repetition.thu, 'N')}
            {renderRepetitionDay(repetition.fri, 'R')}
            {renderRepetitionDay(repetition.sat, 'L')}
            {renderRepetitionDay(repetition.sun, 'P')}
            {renderRepetitionIcon(CalendarEntryType.SCHOOL_HOLIDAY, repetition.scheduleSettings.SCHOOL_HOLIDAY, SportsKabaddi)}
            {renderRepetitionIcon(CalendarEntryType.PUBLIC_HOLIDAY, repetition.scheduleSettings.PUBLIC_HOLIDAY, Flag)}
            {renderRepetitionIcon(CalendarEntryType.SUMMER_HOLIDAY, repetition.scheduleSettings.SUMMER_HOLIDAY, WbSunny)}
        </Box>
    )
};

export default Repetition;
