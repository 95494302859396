import {CharterTripStatus} from "../../../../../API/charterTrip/types";
import {Box, CircularProgress, Tooltip, Typography} from "@mui/material";
import React from "react";
import {getCharterTripStatusTranslation} from "../../../../../utils/enumTranslations";


const StatusWithProgress = ({status, showCompactVersion, customSize, tooltipPlacement = 'top'}: {
    status: CharterTripStatus;
    showCompactVersion?: boolean;
    customSize?: number;
    tooltipPlacement?: 'right' | 'top';
}) => {
    const getStatusWithProgress = (size: number, color = 'primary.light') => {
        const wheelSize = customSize ?? 18;
        const displayStatus = getCharterTripStatusTranslation(status);

        return (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Tooltip
                    placement={tooltipPlacement}
                    arrow
                    title={showCompactVersion ? displayStatus : undefined}
                    PopperProps={tooltipPlacement === 'top' ? {} : {
                        modifiers: [
                            {name: 'offset', options: {offset: [0, -5]}}
                        ],
                    }}
                >
                    <Box sx={{position: 'relative', height: `${wheelSize}px`, mr: 0.5}}>
                        <CircularProgress
                            variant="determinate"
                            size={wheelSize}
                            thickness={8}
                            value={size}
                            sx={{position: 'absolute', color: color}}
                        />
                        <CircularProgress
                            variant="determinate"
                            size={wheelSize}
                            thickness={8}
                            value={100}
                            sx={{color: 'divider'}}
                        />
                    </Box>
                </Tooltip>
                {!showCompactVersion && <Typography variant="body2">{displayStatus}</Typography>}
            </Box>
        );
    };

    switch (status) {
        case CharterTripStatus.CONFIRMED:
            return getStatusWithProgress(33);
        case CharterTripStatus.PLANNED:
            return getStatusWithProgress(66);
        case CharterTripStatus.DONE:
            return getStatusWithProgress(100);
        case CharterTripStatus.CANCELLED:
            return getStatusWithProgress(33, 'error.light');
    }
};

export default StatusWithProgress;
