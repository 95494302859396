import {
    CalendarEntry,
    CalendarEntryType,
    DriverContract as ApiDriverContract,
    WorkGroupItemType,
    WorkScheduleItemType,
    WorkSheetWorkItem
} from "../../../API/types";
import {isOnWeekend} from "../../../utils/utils";
import dayjs, {Dayjs} from "dayjs";
import {WorkScheduleActivityTypes, WorkScheduleRequestTypes} from "./types";
import minMax from "dayjs/plugin/minMax";
import {
    MIN_DRIVER_REST_TIME_HOURS,
    MONTHLY_NOMINAL_HOURS,
    WEEKLY_NOMINAL_HOURS
} from "../../../constants";
import {WorkScheduleItemWithWorkGroup} from "./store/selectors";
import {DriverContract} from "../../../store/driverContractSlice";

dayjs.extend(minMax);

interface Day {
    day: Dayjs;
    isWorkDay: boolean;
}

export interface CalculatorDriverContract {
    contractStartDate: Dayjs;
    contractEndDate: Dayjs | null;
    nominalWeeklyWorkingHours: number;
}

export interface CalculatorScheduledItem {
    type: WorkScheduleItemType;
    startDate: Dayjs;
    endDate: Dayjs;
    startTime: Dayjs | string;
    endTime: Dayjs | string;
    hours: number | null;
    nonWorkHours: number | null;
    isReserveItem: boolean;
}

export const getStatisticsCalculators = function (month: Dayjs, calendarEntries: CalendarEntry[]) {

    const startOfMonth = month.startOf('month');
    const endOfMonth = month.endOf('month');

    const days: Day[] = [...Array(endOfMonth.diff(startOfMonth, 'day') + 1)]
        .map((v, i) => {
            const day = startOfMonth.add(i, 'days');

            return {day: day, isWorkDay: !isOnWeekend(day) && !isOnPublicHoliday(day, calendarEntries)};
        });

    const getNumberOfWorkingDaysInRange = (start: Dayjs, end: Dayjs) => days
        .filter(({day, isWorkDay}) => isWorkDay && day >= start && day <= end)
        .length;

    const numberOfWorkingDaysInMonth = days.filter(day => day.isWorkDay).length;

    const getNominalHours = (driver: CalculatorDriverContract, items: CalculatorScheduledItem[]) => {
        const numberOfNonWorkingDays = getNumberOfNonWorkingDays(items);
        const driverCoefficient = driver.nominalWeeklyWorkingHours / WEEKLY_NOMINAL_HOURS;
        const contractDurationInDays = getContractWorkingDays(driver);

        return MONTHLY_NOMINAL_HOURS * driverCoefficient * (contractDurationInDays - numberOfNonWorkingDays) / numberOfWorkingDaysInMonth;
    };

    const getContractWorkingDays = (driver: CalculatorDriverContract) => {
        const start = dayjs.max(startOfMonth, driver.contractStartDate.startOf('day')) ?? startOfMonth;
        const end = driver.contractEndDate
            ? dayjs.min(endOfMonth, driver.contractEndDate.endOf('day')) ?? endOfMonth
            : endOfMonth;

        return getNumberOfWorkingDaysInRange(start, end);
    };

    const getNumberOfNonWorkingDays = (items: CalculatorScheduledItem[]) => {
        return items
            .filter(item => isMultiDateType(item.type) && item.endDate >= startOfMonth)
            .reduce((total, item) => {
                const start = dayjs.max(startOfMonth, item.startDate.startOf('day')) ?? startOfMonth;
                const end = dayjs.min(endOfMonth, item.endDate.endOf('day')) ?? endOfMonth;
                return total + getNumberOfWorkingDaysInRange(start, end);
            }, 0) ?? 0;
    };

    const getTotalHours = (items: CalculatorScheduledItem[]): number =>
        items.filter(item => item.startDate >= startOfMonth)
            .reduce((total, item) => total + (item.hours ?? 0), 0);


    const getNonWorkHours = (items: CalculatorScheduledItem[]): number =>
        items.filter(item => item.startDate >= startOfMonth)
            .reduce((total, item) => total + (item.nonWorkHours ?? 0), 0);

    const getWorkingDaysOnWeekend = (items: CalculatorScheduledItem[]) =>
        items.reduce(
            (total, item) =>
                total + (item.type === WorkScheduleItemType.WORK_GROUP && (isOnWeekend(item.startDate) || isOnPublicHoliday(item.startDate, calendarEntries)) ? 1 : 0),
            0
        );

    const getAverageDayHours = (items: CalculatorScheduledItem[]) =>
        getNumberOfDaysWorked(items) > 0 ? getTotalHours(items) / getNumberOfDaysWorked(items): 0;

    return {getNominalHours, getTotalHours, getWorkingDaysOnWeekend, getAverageDayHours, getNonWorkHours};
};

export const getNumberOfDaysWorked = (items: CalculatorScheduledItem[]) => {
    return items.reduce((total, item) =>
        total + (item.type === WorkScheduleItemType.WORK_GROUP ? 1 : 0), 0);
};

export const isOnPublicHoliday = (day: Dayjs, calendarEntries: CalendarEntry[]) => calendarEntries.some(
    entry => entry.type === CalendarEntryType.PUBLIC_HOLIDAY && day.isSame(entry.validFrom, 'day')
);

export const isOverOrUnderTargetHours = (plannedHours: number, nominalHours: number) => {
    const ratio = plannedHours / nominalHours;

    return ratio > 1.05 || ratio < 0.9;
};

export const isMultiDateType = (type: string): boolean => (
    type === WorkScheduleItemType.VACATION.toString()
    || type === WorkScheduleItemType.SICK_LEAVE.toString()
    || type === WorkScheduleItemType.UNPAID_LEAVE.toString()
);

export const isActivityType = (type: WorkScheduleItemType): boolean =>
    WorkScheduleActivityTypes.findIndex(t => t === type) > -1;

export const isRequestType = (type: WorkScheduleItemType): boolean =>
    WorkScheduleRequestTypes.findIndex(t => t === type) > -1;


export const isReserveItem = (items: {type: WorkGroupItemType}[]) => {
    if(items.length === 0) {
        return false;
    }

    return items.some(activity => activity.type === WorkGroupItemType.RESERVE)
        && !items.some(activity => activity.type === WorkGroupItemType.TRIP_DEFINITION);
};

export const getNumberOfReserveDays = (items: CalculatorScheduledItem[]): number =>
    items.reduce(
        (total, item) => total + (item.isReserveItem ? 1 : 0), 0
    );

export const isActiveOnDay = (driver: CalculatorDriverContract, day: Dayjs): boolean => {
    if (driver.contractStartDate.isAfter(day, 'day')) {
        return false;
    }
    return !(driver.contractEndDate !== null && driver.contractEndDate.isBefore(day, 'day'));
};

export const contractIsActiveOnMonth = (contract: ApiDriverContract | DriverContract, month: Dayjs | string): boolean => {
    const monthYear = dayjs(month);
    const start = dayjs(contract.startDate);
    const end = contract.endDate ? dayjs(contract.endDate) : null;

    return !start.isAfter(monthYear.endOf('month')) && !end?.isBefore(monthYear.startOf('month'));
};

export interface CollectionOfWork {
    startDateTime: string;
    endDateTime: string;
}

export const restTimeLessThan9Hours = (item?: CollectionOfWork, previousItem?: CollectionOfWork) => {
    if (item && previousItem) {
        const itemStartDate = dayjs(item.startDateTime);
        const previousItemEndDate = dayjs(previousItem.endDateTime);
        if (itemStartDate && previousItemEndDate) {
            const minuteDiff = itemStartDate.diff(previousItemEndDate, 'm');
            return minuteDiff < MIN_DRIVER_REST_TIME_HOURS * 60;
        }
    }

    return false;
};

const NOT_COUNTED_ACTIVITIES = [
    WorkGroupItemType.DISRUPTION,
    WorkGroupItemType.LUNCH_BREAK,
    WorkGroupItemType.TIME_BETWEEN_SHIFTS
];

const NON_WORK_ACTIVITIES = [
    WorkGroupItemType.DISRUPTION,
    WorkGroupItemType.LUNCH_BREAK,
];

export interface WorkItem {
    type: WorkGroupItemType;
    startTime: Dayjs;
    endTime: Dayjs;
    distance: number;
}

export interface CalculatedWork {
    startDateTime: string;
    endDateTime: string;
    hours: number;
    nonWorkHours: number;
    distance: number;
}

export const calculateHoursFromWorkSheetWorkItems = (items: WorkSheetWorkItem[]) => {
    return calculateHours(items.map(item => ({
        type: item.type,
        startTime: dayjs(item.startDateTime),
        endTime: dayjs(item.endDateTime),
        distance: item.distance ?? 0,
    })));
};

export const calculateHours = (items: WorkItem[]): CalculatedWork => {
    const countedItems = items.filter(activity => {
        return !NOT_COUNTED_ACTIVITIES.includes(activity.type)

    });
    const minMax = countedItems
        .reduce(({min, max}: { min: Dayjs | null, max: Dayjs | null }, {startTime, endTime}) => ({
            min: min === null ? startTime : dayjs.min(min, startTime),
            max: max === null ? endTime : dayjs.max(max, endTime),
        }), {min: null, max: null});

    const notCountedHours = items
        .filter(activity => NOT_COUNTED_ACTIVITIES.includes(activity.type))
        .reduce((total, item) =>
                total + item.endTime.diff(item.startTime, 'hours', true)
            , 0);

    const duration = minMax.min && minMax.max
        ? minMax.max.diff(minMax.min, 'hours', true)
        : null;

    const distance = countedItems.reduce((total: number, item: WorkItem) => total + (item.distance ?? 0), 0);

    const nonWorkHours = items.filter(activity => NON_WORK_ACTIVITIES.includes(activity.type))
        .reduce((total, item) =>
                total + item.endTime.diff(item.startTime, 'hours', true)
            , 0);

    return {
        startDateTime: minMax.min?.format('YYYY-MM-DD HH:mm') ?? '',
        endDateTime: minMax.max?.format('YYYY-MM-DD HH:mm') ?? '',
        hours: duration ? duration - notCountedHours : 0,
        nonWorkHours: nonWorkHours,
        distance: distance,
    };
};

export const calculateWorkScheduleItemHours = (workScheduleItem: WorkScheduleItemWithWorkGroup): CalculatedWork => {
    if (workScheduleItem.items && workScheduleItem.items.length > 0) {
        return calculateHours(workScheduleItem.items.map(item => ({
            type: item.type,
            startTime: dayjs(item.startDateTime),
            endTime: dayjs(item.endDateTime),
            distance: item.distance ?? 0,
        })));
    }

    if (workScheduleItem.workGroup?.items) {
        return calculateHours(workScheduleItem.workGroup.items.map(item => ({
            type: item.type,
            startTime: dayjs(`${workScheduleItem.startDate} ${item?.startTime}`).add(item?.startTimeIsOnNextDay ? 1 : 0, 'days'),
            endTime: dayjs(`${workScheduleItem.startDate} ${item?.endTime}`).add(item?.endTimeIsOnNextDay ? 1 : 0, 'days'),
            distance: item.distance ?? 0,
        })));
    }

    return {startDateTime: '', endDateTime: '', hours: 0, distance: 0, nonWorkHours: 0};
};
export const formatName = (names: {firstName: string, lastName: string}, format?: 'lastNameFirst' | 'firstNameWithInitials'): string => {
    switch (format) {
        case 'lastNameFirst':
            return `${names.lastName.toUpperCase()}, ${names.firstName}`;
        case 'firstNameWithInitials':
            return `${names.firstName.charAt(0)}. ${names.lastName}`;
        default:
            return `${names.firstName} ${names.lastName}`;
    }
};

export const sortNames = (names: {firstName: string, lastName: string}[]) =>
    names.sort((a, b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName, 'et-ee'));
