import React from 'react';
import {WorkGroupItemType} from "../../../../../API/workGroup/types";
import {Box, Typography} from "@mui/material";
import {selectOppositeGroupMarkerColorMap, setSelectedOppositeGroupId} from "../../../../../store/plannerSlice";
import {markerColors} from "../../../utils/colorUtils";
import {green} from "@mui/material/colors";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {EntityId} from "@reduxjs/toolkit";
import {openAddOppositeGroupDialog} from "../../../store/actions";
import {canHaveOppositeResource} from "../../../../../API/workSheets/types";


interface OppositeGroupMarkerProps {
    itemId: number;
    type: WorkGroupItemType;
    isRowDisabled: boolean;
    groupOfOppositeTypeId?: number;
    currentGroupId: EntityId;
}

const OppositeGroupMarker = ({
    itemId,
    type,
    isRowDisabled,
    groupOfOppositeTypeId,
    currentGroupId,
}: OppositeGroupMarkerProps) => {
    const oppositeGroupMarkerColorMap = useAppSelector(selectOppositeGroupMarkerColorMap);
    const dispatch = useAppDispatch();

    if (isRowDisabled) {
        return null;
    }

    if (!canHaveOppositeResource(type)) {
        return null;
    }

    const renderOppositeGroupMarker = () => {
        return groupOfOppositeTypeId && oppositeGroupMarkerColorMap ?
            <Box
                onClick={() => {
                    dispatch(setSelectedOppositeGroupId(groupOfOppositeTypeId));
                }}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '8px',
                    width: '8px',
                    borderRadius: '20px',
                    backgroundColor: markerColors[oppositeGroupMarkerColorMap[groupOfOppositeTypeId]],
                    border: '2px solid white',
                    cursor: 'pointer'
                }}
            />
            :
            <></>;
    };

    const renderAddOppositeGroupMarker = () => {
        if (currentGroupId === 0) {
            return null;
        }

        return (
            <Box
                onClick={() => dispatch(openAddOppositeGroupDialog(currentGroupId, itemId, type))}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    height: '10px',
                    width: '10px',
                    borderRadius: '20px',
                    backgroundColor: 'common.white',
                    border: '1px solid white',
                    cursor: isRowDisabled ? '' : 'pointer',
                }}
            >
                <Typography sx={{
                    fontSize: '14px',
                    lineHeight: '8px',
                    position: 'absolute',
                    top: '0px',
                    right: '1px',
                    color: green[500],
                    fontWeight: 600
                }}>
                    +
                </Typography>
            </Box>
        );
    };

    if (groupOfOppositeTypeId) {
        return renderOppositeGroupMarker();
    }

    return renderAddOppositeGroupMarker();
};

export default OppositeGroupMarker;