import {Box} from '@mui/material';
import React from 'react';
import TimelineBackground from "../../../Timeline/BackgroundRow";
import TripCardWrapper from "../../../ItemCards/Wrappers/Trip/CardWrapper";
import {useAppSelector} from "../../../../../../hooks";
import {selectPlannerType} from "../../../../../../store/plannerSlice";
import {PlannerType} from "../../../../types";
import TripWorkItemCardWrapper from '../../../ItemCards/Wrappers/TripWorkItem/CardWrapper';
import CharterTripCardWrapper from "../../../ItemCards/Wrappers/CharterTrip/CardWrapper";

type tripType = 'line' | 'charter';

export interface UnassignedTripRowProps {
    unassignedTripIds: number[];
    type?: tripType;
}

const UnassignedTripRow = ({ unassignedTripIds, type = 'line' }: UnassignedTripRowProps) => {
    const plannerType = useAppSelector(selectPlannerType);

    return (
        <Box>
            <TimelineBackground />
            {plannerType === PlannerType.WORK_GROUP && unassignedTripIds.map(tripId =>
                <TripCardWrapper key={tripId} tripSegmentId={tripId} />
            )}
            {plannerType === PlannerType.WORK_SHEET && unassignedTripIds.map(tripId => {
                if (type === 'line') {
                    return <TripWorkItemCardWrapper key={tripId} workItemId={tripId} />
                }
                if (type === 'charter') {
                    return <CharterTripCardWrapper key={tripId} id={tripId} />
                }
            })}
        </Box>
    );
};

export default UnassignedTripRow;
