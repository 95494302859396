import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography, useMediaQuery} from "@mui/material";
import {AddCalendarEntryRequest, CalendarEntry, CalendarEntryType} from "../../../API/types";
import {ToastContext} from "../../../contexts/ToastContext";
import DataGrid from "../../../components/DataGrid";
import {GridColDef} from "@mui/x-data-grid";
import theme from "../../../theme";
import {Permission} from "../../../types";
import {getCalendarEntryTypeTranslation} from "../../../utils/enumTranslations";
import CalendarDialog from "./components/CalendarDialog";
import {CalendarEntryForm} from "./types";
import {FormikHelpers} from "formik";
import {getEnumFromStr} from "../../../utils/formUtils";
import {createCalendarEntry, deleteCalendarEntry, loadCalendarEntries} from "../../../API";
import dayjs from "dayjs";
import {getDateString} from "../../../utils/dateUtils";
import ActionIcon from "../../../components/Icons/ActionIcon";
import ListView from "../../../layouts/ListViewWrapper";


export default function Calendar() {
    const { addToast } = useContext(ToastContext);
    const [rows, setRows] = useState<CalendarEntry[] | undefined>(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        loadCalendarEntryRows();
    }, []);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const sortRowsByValidFrom = (rows: CalendarEntry[]) =>
        rows.sort((a: CalendarEntry, b: CalendarEntry) => b.validFrom.localeCompare(a.validFrom));

    const loadCalendarEntryRows = () => {
        loadCalendarEntries()
            .then(result => setRows(sortRowsByValidFrom(result)))
            .catch(() => {
                setRows([]);
                addToast({type: 'error', text: 'Kalendrikirjete pärimisel ilmnes viga'});
            });
    };

    const handleFormSubmit = (form: CalendarEntryForm, formHelpers: FormikHelpers<CalendarEntryForm>) => {
        formHelpers.setSubmitting(true);
        const calendarEntryType = getEnumFromStr(form.type, CalendarEntryType);

        if (rows && form.validFrom && calendarEntryType) {
            const request: AddCalendarEntryRequest = {
                validFrom: getDateString(form.validFrom),
                validTo: getDateString(form.validTo ?? form.validFrom),
                type: calendarEntryType,
                comment: form.comment,
            };

            createCalendarEntry(request)
                .then(result => {
                    setRows(sortRowsByValidFrom([...rows, result]));
                    addToast({type: 'success', text: 'Uus kalendrikirje edukalt loodud'});
                    handleCloseDialog();
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Kalendrikirje lisamisel ilmnes viga'});
                })
                .finally(() => formHelpers.setSubmitting(false));
        }
    };

    const handleDeleteClick = (id: number) => {
        if (rows && window.confirm('Kas oled kindel, et soovid kalendrikirje kustutada?')) {
            deleteCalendarEntry(id)
                .then(result => {
                    if (result.status == 200) {
                        addToast({type: 'success', text: 'Kalendrikirje edukalt kustutatud'});
                        setRows([...rows.filter(row => row.id !== id)])
                    } else {
                        addToast({type: 'error', text: 'Kalendrikirje kustutamisel ilmnes viga'});
                    }
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Kalendrikirje kustutamisel ilmnes viga'});
                });
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tüüp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 200 : 320,
            renderCell: (params) => {
                if (params.value) {
                    return (
                        <Typography variant="body2" sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {getCalendarEntryTypeTranslation(params.value as CalendarEntryType)}
                        </Typography>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            field: 'validFrom',
            headerName: 'Kuupäev',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 110,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{dayjs(params.value).format('DD.MM.YYYY')}</Box> : <></>
        },
        {
            field: 'validTo',
            headerName: 'Kehtivuse lõppkuupäev',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 130 : 200,
            renderCell: (params) => params.value && !dayjs(params.row.validFrom).isSame(params.value, 'day') ?
                <Box style={{minWidth: 'fit-content'}}>{dayjs(params.value).format('DD.MM.YYYY')}</Box> : <></>
        },
        {
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: false,
            filterable: false,
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            width: 50,
            renderCell: (params) =>
                <ActionIcon type="DELETE" id={params.row.id} handleClick={handleDeleteClick} navPermission={Permission.UpdateCalendarEntries} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Kalender',
                buttonProps: {
                    title: 'Lisa kalendrikirje',
                    onClick: handleOpenDialog,
                    permission: Permission.UpdateCalendarEntries
                }
            }}
            isLoading={!rows}
        >
            <DataGrid rows={rows ?? []} columns={columns} />
            <CalendarDialog open={openDialog} handleSubmit={handleFormSubmit} handleCloseDialog={handleCloseDialog} />
        </ListView>
    );
}
