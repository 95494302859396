import React, {useState} from "react";
import CardBase from "../../../components/CardBase";
import {blue} from "@mui/material/colors";
import {Box, Collapse, Typography} from "@mui/material";
import BusLabel from "../../../components/BusLabel";
import {ArrowRightAlt, Person} from "@mui/icons-material";
import {BusHandover} from "../../../types";
import {useAppSelector} from "../../../../../../../hooks";
import {
    selectCurrentDriverBusById,
} from "../../../../../../../store/currentDriverWorkSheetsSlice";

interface BusHandoverCardProps {
    busHandover: BusHandover;
}

const BusHandoverCard = ({busHandover}: BusHandoverCardProps) => {
    const [cardOpen, setCardOpen] = useState(false);
    const bus = useAppSelector(state => selectCurrentDriverBusById(state, busHandover.busId));
    if (!bus || (!busHandover.nextDriverName && !busHandover.previousDriverName)) return null;

    const index = busHandover.previousDriverName ? 0 : 1;

    const handleClick = () => {
        setCardOpen(prevState => !prevState);
    };

    return (
        <CardBase
            key={'bus-handover-' + index} sx={{backgroundColor: blue[50]}}
            onClick={handleClick}
        >
            <Box sx={{display: 'flex', justifyContent: 'space-between', pb: 0.5}}>
                <Typography component="div" variant="body2" sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        { busHandover.previousDriverName && <>
                            <Person sx={{fontSize: '16px', color: 'text.secondary'}} />
                            <ArrowRightAlt sx={{fontSize: '16px', px: 0.5, color: 'text.secondary'}} />
                        </> }
                        <BusLabel licencePlateNumbers={[bus.licencePlateNumber]} />
                        { busHandover.nextDriverName && <>
                            <ArrowRightAlt sx={{fontSize: '16px', px: 0.5, color: 'text.secondary'}} />
                            <Person sx={{fontSize: '16px', color: 'text.secondary'}} />
                        </> }
                    </Box>
                </Typography>
            </Box>
            <Collapse in={cardOpen} >
                { busHandover.nextDriverName &&
                    <Typography component="div" variant="body2" sx={{pb: 0.5}}>
                        { `${ bus.licencePlateNumber } võtab vastu ${ busHandover.nextDriverName }` }
                    </Typography>
                }
                { busHandover.previousDriverName &&
                    <Typography component="div" variant="body2">
                        { `${ bus.licencePlateNumber } annab üle ${ busHandover.previousDriverName }` }
                    </Typography>
                }
            </Collapse>
        </CardBase>
    );
};

export default BusHandoverCard;
