import {createAsyncThunk, EntityId} from "@reduxjs/toolkit";
import {ApiError, DriverPosition, Region, ResourceType} from "../../../../API/types";
import {AppDispatch, RootState} from "../../../../store";
import {
    changeDriversOrder,
    confirmBusesWorkSchedule,
    confirmDriversWorkSchedule,
    createWorkScheduleItem,
    deleteWorkScheduleItem,
    updateWorkScheduleItem
} from "../../../../API";
import dayjs from "dayjs";
import {selectMonth, selectSortedActiveDriverIds} from "./selectors";
import {selectConfirmedIds, WorkScheduleItem} from "../../../../store/workScheduleItemSlice";
import {setToast} from "../../../../store/toastSlice";
import {WorkScheduleItemStatus} from "../../../../API/workSchedule/types";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {mapErrors} from "../../../../utils/errorMapping";

export  const updateDriversOrder = createAsyncThunk<
    DriverPosition[],
    {
        id: EntityId,
        up: boolean,
        selectedRegion: Region,
    },
    {
        state: RootState,
    }
>(
    'workSchedule/drivers/changeOrder',
    async ({id, up, selectedRegion}, {dispatch, getState}): Promise<DriverPosition[]> => {
        const state = getState();
        const sortedActiveDriverIds = selectSortedActiveDriverIds(state);

        const driverId = parseInt(id.toString());

        const sortedPositions = sortedActiveDriverIds
            .map((item, index) => ({
                driverId: parseInt(item.toString()),
                position: index + 1
            }));

        const changedDriver = sortedPositions.find(
            item => item.driverId === driverId
        );
        if (!changedDriver) {
            return sortedPositions;
        }
        const positionChange = up ? -1 : 1;
        let newPosition = changedDriver.position + positionChange;
        if (newPosition < 1) {
            newPosition = 1;
        }

        const result: DriverPosition[] = sortedPositions.map(item => {
            let position = item.position;
            // All contract rows with same driver ID move to new position
            if (item.driverId === driverId) {
                position = newPosition
            }
            // All other rows on that position move the opposite direction
            if (item.driverId !== driverId && item.position === newPosition) {
                position = newPosition - positionChange;
            }

            return {
                driverId: item.driverId,
                position: position,
            }
        });

        await changeDriversOrder(
            selectedRegion.id,
            result,
        ).catch((error: ApiError) => {
            dispatch(setToast({
                type: 'error',
                text: mapErrors(error) ?? 'Juhtide järjekorra muutmisel tekkis tõrge',
            }));
        });

        return result;
    }
);

interface ConfirmWorkSchedule {
    removedIds: EntityId[];
    items: WorkScheduleItem[];
}

export const confirmWorkSchedule = createAsyncThunk<
    ConfirmWorkSchedule,
    Region,
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'workSchedule/confirm',
    async (selectedRegion, {dispatch, getState}): Promise<ConfirmWorkSchedule> => {
        const state = getState();
        const month = selectMonth(state);
        const date = dayjs(month);
        const resourceType = selectToggledResourceType(state);

        const response = await (
            resourceType === ResourceType.DRIVER
                ? confirmDriversWorkSchedule(selectedRegion.id, date)
                : confirmBusesWorkSchedule(selectedRegion.id, date)
        ).catch((error: ApiError) => {
            dispatch(setToast({
                type: 'error',
                text: mapErrors(error) ?? 'Tööajakava kinnitamisel tekkis tõrge',
            }));
            throw error;
        });

        return {
            removedIds: selectConfirmedIds(getState()),
            items: response.workScheduleItems,
        }
    }
);

export const removeWorkScheduleItem = createAsyncThunk<
    WorkScheduleItem,
    WorkScheduleItem,
    {
        dispatch: AppDispatch,
    }
>(
    'workSchedule/removeItem',
    async (item: WorkScheduleItem, {dispatch}): Promise<WorkScheduleItem> => {
        if (!item.id) {
            dispatch(setToast({type: 'error', text: 'Tööajakava tegevuse kustutamisel tekkis tõrge'}));
            throw new Error();
        }

        await deleteWorkScheduleItem(item.id).catch((error: ApiError) => {
            dispatch(setToast({
                type: 'error',
                text: mapErrors(error) ?? 'Tööajakava tegevuse kustutamisel tekkis tõrge',
            }));
            throw error;
        });

        return item;
    }
);

export const addWorkScheduleItem = createAsyncThunk<
    WorkScheduleItem,
    WorkScheduleItem
>(
    'workSchedule/addItem',
    async (item: WorkScheduleItem, { dispatch }): Promise<WorkScheduleItem> => {
        const response = await createWorkScheduleItem({
            busId: item.resourceType === ResourceType.VEHICLE ? item.resourceId : undefined,
            comment: item.comment,
            driverContractId: item.resourceType === ResourceType.DRIVER ? item.resourceId : undefined,
            endDate: item.endDate,
            startDate: item.startDate,
            type: item.type,
            workGroupId: item.workGroupId ?? null,
            status: WorkScheduleItemStatus.SCHEDULED,
        }).catch(error => {
            dispatch(setToast({
                type: 'error',
                text: mapErrors(error) ?? 'Tööajakava tegevuse salvestamisel tekkis viga',
            }));
            throw error;
        });

        return {
            ...item,
            id: response.id,
        };
    }
);

export interface ChangeWorkScheduleItem {
    entityId: EntityId;
    workScheduleItem: WorkScheduleItem;
}

export const changeWorkScheduleItem = createAsyncThunk<
    ChangeWorkScheduleItem,
    ChangeWorkScheduleItem
>(
    'workSchedule/changeItem',
    async (changeWorkScheduleItem: ChangeWorkScheduleItem, {dispatch}): Promise<ChangeWorkScheduleItem> => {
        const item = changeWorkScheduleItem.workScheduleItem;
        if (!item.id) {
            throw new Error();
        }

        await updateWorkScheduleItem(item.id,{
            busId: item.resourceType === ResourceType.VEHICLE ? item.resourceId : undefined,
            comment: item.comment,
            driverContractId: item.resourceType === ResourceType.DRIVER ? item.resourceId : undefined,
            endDate: item.endDate,
            startDate: item.startDate,
            type: item.type,
            workGroupId: item.workGroupId ?? null,
            status: item.status,
        }).catch((error: ApiError) => {
            dispatch(setToast({
                type: 'error',
                text: mapErrors(error) ?? 'Tööajakava tegevuse salvestamisel tekkis viga',
            }));
            throw error;
        });

        return changeWorkScheduleItem;
    }
);
