import React from 'react';
import {useField} from "formik";
import {Typography, useTheme} from "@mui/material";
import {MenuItem, TextField as MuiTextField} from '@mui/material';
import codes, {ICountryCodeItem} from 'country-calling-code';


interface PhonePrefixProps {
    name: string;
    disabled?: boolean;
}

const PhonePrefix = ({name, disabled}: PhonePrefixProps) => {
    const theme = useTheme();
    const [field, meta, helpers] = useField(name);
    const phonePrefixes: ICountryCodeItem[] = codes
        .filter(code => code.countryCodes.length === 1)
        .filter(code => !code.countryCodes[0].includes('-'))
        .map((code: ICountryCodeItem) => {
            return {...code, countryCodes:code.countryCodes.map(code => '+' + code)}
        })

    return (
        <MuiTextField
            value={field.value}
            onChange={(event) => helpers.setValue(event.target.value)}
            select
            size="small"
            label="Suunakood"
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            SelectProps={{
                renderValue: option => option as string,
                MenuProps: {
                    PaperProps: {
                        style: {
                            maxHeight: '300px',
                        }
                    }
                }
            }}
            sx={{my: 1, minWidth: '90px', maxWidth: '90px', mr: 1}}
            disabled={disabled}
        >
            {phonePrefixes.map(code =>
                <MenuItem key={code.isoCode3} value={code.countryCodes[0]} sx={{width: '200px', whiteSpace: 'normal'}}>
                    <Typography>
                        {code.country} <span style={{color: theme.palette.text.secondary}}>{code.countryCodes[0]}</span>
                    </Typography>
                </MenuItem>
            )}
        </MuiTextField>
    )
}

export default PhonePrefix;
