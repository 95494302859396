import React, {useEffect, useState} from "react";
import {Region} from "../../../../../../API/types";
import {Formik, FormikHelpers, FormikProps} from "formik";
import {WorkItemForm} from "./types";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {getUnplannedWorkItems} from "../../../../../../API";
import {selectDate, selectToggledResourceType} from "../../../../../../store/viewSlice";
import {setToast} from "../../../../../../store/toastSlice";
import {
    CharterTripWorkItem as WorkScheduleCharterTripWorkItem,
    selectAllCharterTripWorkItemIds,
    selectAllTripDefinitionWorkItemIds,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem
} from "../../../../../../store/workScheduleItemSlice";
import * as Yup from "yup";
import {validationSchema} from "../../../../../../utils/formValidation";
import OtherRegionTripForm from "../Form";
import {workItemToCharterTripWorkItem, workItemToTripWorkItem} from "../../../../utils/mappers";


const tripValidationSchema = Yup.object().shape({
    workItem: validationSchema('Reis').fields.objectRequired,
});

export interface OtherRegionWorkSheetWorkItemFormProps {
    selectedOtherRegion: Region | undefined;
    onSubmit: (form: WorkItemForm, formikHelpers: FormikHelpers<WorkItemForm>) => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OtherRegionWorkSheetWorkItemForm({
    selectedOtherRegion, onSubmit, isLoading, setIsLoading
}: OtherRegionWorkSheetWorkItemFormProps) {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(selectDate);
    const currentTripWorkItemIds = useAppSelector(selectAllTripDefinitionWorkItemIds);
    const currentCharterTripWorkItemIds = useAppSelector(selectAllCharterTripWorkItemIds);
    const resourceType = useAppSelector(selectToggledResourceType);

    const [workItems, setWorkItems] = useState<(WorkScheduleTripDefinitionWorkItem | WorkScheduleCharterTripWorkItem)[] | undefined>(undefined);
    const initialValues: WorkItemForm = {workItem: null};

    useEffect(() => {
        if (selectedOtherRegion && selectedDate) {
            getUnplannedWorkItems(selectedOtherRegion.id, selectedDate, resourceType)
                .then(result => {
                    const filteredOtherRegionWorkItems: WorkScheduleTripDefinitionWorkItem[] = result
                        .filter(wi => wi.tripSegmentId && !(currentTripWorkItemIds.includes(wi.tripSegmentId)))
                        .sort((a,b) => a.trip?.code && b.trip?.code && a.trip.code > b.trip.code ? 1 : -1)
                        .map(workItemToTripWorkItem(selectedOtherRegion.id, resourceType));
                    const filteredOtherRegionCharterTripWorkItems: WorkScheduleCharterTripWorkItem[] = result
                        .filter(wi => wi.charterTrip && !(currentCharterTripWorkItemIds.includes(wi.charterTrip.id)))
                        .map(workItemToCharterTripWorkItem(selectedOtherRegion.id, resourceType));
                    setWorkItems([...filteredOtherRegionWorkItems, ...filteredOtherRegionCharterTripWorkItems]);
                })
                .catch(() => {
                    setWorkItems([]);
                    dispatch(setToast({type: 'error', text: 'Reiside pärimisel ilmnes viga'}));
                });
        }
    }, [selectedOtherRegion]);

    useEffect(() => {
        if (workItems) setIsLoading(false);
    }, [workItems]);

    return (
        <Formik initialValues={initialValues} validationSchema={tripValidationSchema} onSubmit={onSubmit}>
            {(formikProps: FormikProps<WorkItemForm>) => {
                useEffect(() => {
                    formikProps.setFieldValue('workItem', null).then(() => {
                        setIsLoading(false);
                    });
                }, [workItems]);

                return <OtherRegionTripForm options={workItems} isLoading={isLoading} name="workItem" />
            }}
        </Formik>
    );
}
