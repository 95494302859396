import React from 'react';
import {useField} from "formik";
import {Box, MenuItem, TextField as MuiTextField, Typography} from '@mui/material';
import {SelectOptionWithIdAndPermission} from "../../../types";
import {doesUserHaveAllRegionsAccess} from "../../Visible";
import {useAuth} from "../../../contexts/AuthContext";


interface SelectProps {
    name: string;
    label: string;
    options: SelectOptionWithIdAndPermission[];
    onChange?: (value: any) => void;
    disabled?: boolean;
    noOptionsText?: string;
}

const SelectWithId = ({name, label, options, onChange, disabled, noOptionsText}: SelectProps) => {
    const { authenticatedUser } = useAuth();
    const [field, meta, helpers] = useField<number | null>(name);

    return (
        <Box sx={{my: 1}}>
            <MuiTextField
                value={field.value && options.find(option => option.id === field.value) ? field.value.toString() : ''}
                onChange={(event) => {
                    helpers.setValue(Number(event.target.value));
                    if (onChange) {
                        onChange(event.target.value);
                    }
                }}
                select
                size="small"
                label={label}
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
                disabled={disabled}
                sx={{width: '100%'}}
            >
                {options
                    .filter(option => doesUserHaveAllRegionsAccess(authenticatedUser, option.requireAllRegions))
                    .map(option =>
                        <MenuItem key={option.id} value={option.id} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            {option.name}
                            {option.description && <Typography variant="body2" color="text.secondary" mt={-0.5}>{option.description}</Typography>}
                        </MenuItem>
                    )
                }
                {(noOptionsText !== undefined && options.length === 0) &&
                    <Typography variant="body2" color="text.secondary" ml={1.5}>{noOptionsText}</Typography>
                }
            </MuiTextField>
        </Box>
    );
};

export default SelectWithId;
