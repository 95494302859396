import React, {useEffect, useState} from 'react';
import {Bus, DefectStatus} from '../../../../../API/types';
import {DefectFormData} from '../types';
import Loader from "../../../../../components/Loader";
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {validationSchema} from "../../../../../utils/formValidation";
import routes from '../../../../../routes';
import {fetchBuses} from "../../../../../store/busSlice";
import {useAppDispatch} from "../../../../../hooks";
import DefectForm, {defaultDefectFormValues, showCommentsInput} from '../components/DefectForm';
import {getDate} from "../../../../../utils/dateUtils";
import dayjs from "dayjs";
import {createDefect, updateDefect} from "../../../../../store/defectsSlice";
import {Defect} from "../../../../../store/defectsSlice";


const defectValidationSchema = Yup.object().shape({
    bus: validationSchema('Buss').fields.objectRequired,
    description: validationSchema('Kirjeldus').fields.longTextFieldRequired,
    expectedRepairDateTime: validationSchema('Remondi algusaeg').fields.date,
    expectedRepairFinishedDateTime: Yup.date()
        .typeError('Vigane kuupäev')
        .notRequired()
        .when('expectedRepairDateTime', (_, schema) => schema.test({
            test: function () {
                const expectedRepairDateTime = this.parent.expectedRepairDateTime;
                const expectedRepairFinishedDateTime = this.parent.expectedRepairFinishedDateTime;

                if (expectedRepairDateTime) {
                    const startTimeDayjs = dayjs(expectedRepairDateTime);
                    const endTimeDayjs = dayjs(expectedRepairFinishedDateTime);

                    if (!expectedRepairFinishedDateTime) {
                        return this.createError({
                            message: 'Remondi algusaja olemasolul on remondiaja lõpp kohustuslik väli',
                        })
                    }
                    if (endTimeDayjs < startTimeDayjs) {
                        return this.createError({
                            message: 'Remondi lõpp ei tohi olla enne algust',
                        })
                    }
                }

                return true;
            },
        })),
});

interface DefectFormWrapperProps {
    defect?: Defect;
    defaultBus?: Bus;
    isDriverView?: boolean;
    handleSuccess?: () => void;
    handleCancel?: () => void;
}

const DefectFormWrapper = ({defect, defaultBus, isDriverView = false, handleSuccess, handleCancel}: DefectFormWrapperProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState<DefectFormData | undefined>(undefined);

    useEffect(() => {
        dispatch(fetchBuses());
    }, []);

    useEffect(() => {
        if (defect) {
            setInitialValues({
                ...defect,
                bus: {id: defect.bus.id, name: defect.bus.licencePlateNumber},
                expectedRepairDateTime: defect.expectedRepairDateTime ? getDate(defect.expectedRepairDateTime) : null,
                expectedRepairFinishedDateTime: defect.expectedRepairFinishedDateTime ? getDate(defect.expectedRepairFinishedDateTime) : null,
                status: defect.status,
                comment: ''
            });
        } else {
            setInitialValues(defaultDefectFormValues(defaultBus));
        }
    }, [defect]);

    const onSubmit = (form: DefectFormData, formHelpers: FormikHelpers<DefectFormData>) => {
        if (form.bus) {
            formHelpers.setSubmitting(true);
            if (defect?.id) {
                if (
                    form.status === DefectStatus.SOLVED && defect.status !== DefectStatus.SOLVED
                    && form.expectedRepairDateTime && dayjs(form.expectedRepairDateTime).isAfter(dayjs())
                    && !window.confirm('Eeldatav remondiaeg on tulevikus. Kas oled kindel, et tahad rikke lahendatuks märkida?')
                ) {
                    formHelpers.setSubmitting(false);
                    return;
                }

                dispatch(updateDefect({form, formHelpers, id: defect.id, handleSuccess}));
            } else {
                dispatch(createDefect({form, formHelpers, handleSuccess: isDriverView ? handleSuccess : navigateToDetailViewWithId}));
            }
        }
    };

    const navigateToDetailViewWithId = (id: number) => navigate(routes.AUTHENTICATED.DEFECTS.EDIT.replace(':id', id.toString()));

    return (initialValues ?
        <Formik enableReinitialize initialValues={initialValues} validationSchema={defectValidationSchema} onSubmit={onSubmit}>
            {(formikProps: FormikProps<DefectFormData>) => {
                useEffect(() => {
                    if (formikProps.values.expectedRepairDateTime && !formikProps.values.expectedRepairFinishedDateTime) {
                        const updatedExpectedRepairFinishedDateTime = dayjs(formikProps.values.expectedRepairDateTime).add(1, 'h');
                        if (updatedExpectedRepairFinishedDateTime.isValid()) {
                            void formikProps.setFieldValue('expectedRepairFinishedDateTime', updatedExpectedRepairFinishedDateTime.toDate());
                        }
                    }
                }, [formikProps.values.expectedRepairDateTime]);

                useEffect(() => {
                    if (formikProps.values.comment !== '' && !showCommentsInput(formikProps, defaultDefectFormValues(defaultBus), defect?.id)) {
                        void formikProps.setFieldValue('comment', '');
                    }
                }, [formikProps.values]);

                return <DefectForm defect={defect} isDriverView={isDriverView} handleCancel={handleCancel} defaultBus={defaultBus} />
            }}
        </Formik>
        :
        <Loader />
    );
}

export default DefectFormWrapper;
