import {Alert, Box} from "@mui/material";
import {Comment} from "@mui/icons-material";
import React from "react";
import {WorkSheetDetails} from "../../../../../../API/types";


interface DriverCommentProps {
    workSheet: WorkSheetDetails;
}

const DriverComment = ({workSheet}: DriverCommentProps) => {
    return (workSheet.driverComment && workSheet.driverComment.length > 0 &&
        <Alert
            severity="info"
            variant="outlined"
            icon={<Comment fontSize="small" />}
            sx={{mb: 1, '&:not(:last-of-type)': {mr: {sm: 1}}}}
        >
            <Box component="span" style={{fontWeight: 'bold'}}>Juhi kommentaar</Box>: {workSheet.driverComment}
        </Alert>
    );
};

export default DriverComment;