import {Box, SxProps} from "@mui/material";
import StaticIconWithText from "../../../../../components/Icons/StaticIconWithText";
import React from "react";
import {DefectPriority, DefectStatus} from "../../../../../API/defects/types";
import {Defect} from "../../../../../store/defectsSlice";
import {getDisplayDateTime} from "../../../../../utils/dateUtils";
import {OdometerReading} from "../../../../../API/bus/types";


export const RepairDateIconText = ({repairDateDisplayStr, repairedAt, expectedRepairDateTime, isDriverView}: {
    repairDateDisplayStr: string;
    repairedAt?: string;
    expectedRepairDateTime?: string;
    isDriverView?: boolean;
}) => {
    const text = repairedAt ? 'Lahendatud' : 'Eeldatav remondikuupäev';

    return repairedAt || expectedRepairDateTime ?
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <StaticIconWithText
                variant={isDriverView ? 'body1' : 'body2'}
                color={isDriverView ? 'text.secondary' : 'text.primary'}
                text={repairDateDisplayStr}
                mobileViewText={isDriverView ? text : ''}
                type={repairedAt ? 'CHECK' : 'IN_PROGRESS'}
                tooltipTitle={text}
            />
        </Box>
        :
        <></>;
};

export const getPriorityColor = (priority: DefectPriority) => priority === DefectPriority.CRITICAL
    ? 'error.light' : priority === DefectPriority.IMPORTANT
        ? 'warning.light' : 'info.light';

export const PriorityIcon = ({priority, sx}: {priority: DefectPriority, sx?: SxProps}) => (
    <Box sx={{
        borderRadius: '10px',
        minHeight: '12px',
        maxHeight: '12px',
        minWidth: '12px',
        maxWidth: '12px',
        backgroundColor: getPriorityColor(priority),
        my: 0.5,
        ...sx
    }}/>
);

export const PriorityIconText = ({priority, priorityDisplayStr, hideTextInMobileView = false, showCompactView = false}: {
    priority: DefectPriority;
    priorityDisplayStr: string;
    hideTextInMobileView?: boolean;
    showCompactView?: boolean;
}) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <PriorityIcon priority={priority} sx={{mr: 1}} />
            <Box sx={{display: showCompactView ? 'none' : {xs: hideTextInMobileView ? 'none' : 'flex', sm: 'flex'}}}>
                {priorityDisplayStr}
            </Box>
        </Box>
    );
};

export const StatusIconText = ({status, statusDisplayStr}: {
    status: DefectStatus;
    statusDisplayStr: string;
}) => {
    const color = status === DefectStatus.NEW
        ? 'error.light' : status === DefectStatus.IN_PROGRESS
            ? 'warning.light' : 'secondary.main';

    return (
        <Box sx={{border: '2px solid', borderColor: color, px: 0.5, borderRadius: '5px', my: '-2px'}}>
            {statusDisplayStr}
        </Box>
    );
};

export const CreatorIconText = ({defect, isDriverView}: {
    defect?: Defect,
    isDriverView?: boolean
}) => {
    return defect?.creatorUserName && defect.createdAt ?
        <StaticIconWithText
            variant={isDriverView ? 'body1' : 'body2'}
            text={`${defect.creatorUserName} (${getDisplayDateTime(defect.createdAt)})`}
            mobileViewText={isDriverView ? 'Esitas'  : ''}
            type="PERSON"
            tooltipTitle="Esitaja"
        />
        :
        <></>;
};

export const EditorIconText = ({defect, isDriverView}: {
    defect?: Defect;
    isDriverView?: boolean;
}) => {
    return defect?.lastEditorUserName && defect.lastEditedAt ?
        <StaticIconWithText
            variant={isDriverView ? 'body1' : 'body2'}
            text={`${defect.lastEditorUserName} (${getDisplayDateTime(defect.lastEditedAt)})`}
            mobileViewText={isDriverView ? 'Viimati muutis'  : ''}
            type="HISTORY_CHANGED"
            tooltipTitle="Viimane muutja"
        />
        :
        <></>;
};

export const DescriptionIconText = ({description}: {description: string}) => {
    return(
        <StaticIconWithText
            text={description}
            type="DESCRIPTION"
            tooltipTitle="Kirjeldus"
        />
    );
};

export const CommentsSummaryIconText = ({commentIds}: {commentIds: number[]}) => {
    return(
        <StaticIconWithText
            text={commentIds.length.toString()}
            mobileViewText={'Kommentaare'}
            type="COMMENT"
            tooltipTitle="Kommentaarid"
        />
    );
};

export const OdometerReadingIconText = ({odometerReading}: {odometerReading: OdometerReading}) => {
    return(
        <StaticIconWithText
            text={`${odometerReading.reading.toString()} km`}
            type="ODOMETER_READING"
            tooltipTitle="Odomeetrinäit"
            variant="body2"
        />
    );
};

export const NumberOfCommentsIconText = ({numberOfComments}: {numberOfComments: number}) => {
    return (
        <StaticIconWithText
            text={numberOfComments.toString()}
            type="COMMENT"
            tooltipTitle={`Kommentaare ${numberOfComments}`}
            color="text.primary"
            variant="body2"
        />
    );
};
