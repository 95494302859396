import {Form, Formik, FormikProps} from "formik";
import {Box} from "@mui/material";
import PhoneInput from "../../../../components/Form/PhoneInput";
import Button from "../../../../components/Buttons/Button";
import {Clear} from "@mui/icons-material";
import React from "react";
import * as Yup from "yup";
import {validationSchema} from "../../../../utils/formValidation";
import {FormikHelpers} from "formik/dist/types";


export interface SendConfirmationCodeFormData {
    phonePrefix: string;
    phoneNumber: string;
}

const sendConfirmationFormValidation = Yup.object().shape({
    phoneNumber: validationSchema('Telefoninumber').fields.textFieldRequired,
});

const SendConfirmationForm = ({handleSubmit, handleCancel}: {
    handleSubmit: (values: SendConfirmationCodeFormData, formikHelpers: FormikHelpers<SendConfirmationCodeFormData>) => void;
    handleCancel: () => void;
}) => {
    return (
        <Formik
            initialValues={{
                phonePrefix: '+372',
                phoneNumber: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={sendConfirmationFormValidation}
            validateOnBlur={false}
            validateOnChange={false}
        >{(formikProps: FormikProps<SendConfirmationCodeFormData>) => <Form>
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center'}}>
                <PhoneInput name="phoneNumber" label="Telefoninumber" prefixName="phonePrefix" />
            </Box>
            <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', mt: .5}}>
                <Button color="secondary" startIcon={<Clear/>} text="Loobu" onClick={handleCancel} style={{width:'100%'}} />
                <Button type="submit" color="primary" disabled={formikProps.isSubmitting} text="Saada" style={{width:'100%'}} />
            </Box>
        </Form>
        }</Formik>
    );
};

export default SendConfirmationForm;