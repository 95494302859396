import React, {useEffect} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import TextField from "../../../../../components/Form/TextField";
import PhoneInput from "../../../../../components/Form/PhoneInput";
import {Driver} from "../../../../../API/types";
import Checkbox from "../../../../../components/Form/Checkbox";
import {Form, useFormikContext} from "formik";
import {DriverForm} from "../types";
import DetailViewButtons from "../../../../../components/Buttons/DetailViewButtons";
import routes from "../../../../../routes";
import MultiSelect from "../../../../../components/Form/MultiSelect";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions} from "../../../../../store/regionSlice";
import SelectWithId from "../../../../../components/Form/SelectWithId";
import {formatName} from "../../../workSchedule/utils";
import {updateAccountingRegionOnRegionsChange} from "../../../../../utils/formUtils";
import {useAuth} from "../../../../../contexts/AuthContext";
import {getRegionChoicesForUser} from "../../../../../utils/utils";

interface DriverDetailsEditFormProps {
    driver: Driver | undefined;
    id: string | undefined;
    defaultFormValues: DriverForm;
    canEdit: boolean;
}

export default function DriverDetailsEditForm({ driver, id, defaultFormValues, canEdit }: DriverDetailsEditFormProps) {
    const theme = useTheme();
    const formikProps = useFormikContext<DriverForm>();
    const regions = useAppSelector(selectAllRegions);
    const { authenticatedUser } = useAuth();

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    useEffect(() => {
        updateAccountingRegionOnRegionsChange(formikProps);
    }, [formikProps.values.regions]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.DRIVERS.ROOT}
            />
        );
    };

    const [regionChoices, accountingRegionChoices] = getRegionChoicesForUser(regions, authenticatedUser, driver);

    return <Form>
        <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
            <Box>
                <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                    {driver ? formatName(driver) : 'Uus bussijuht'}
                </Typography>
                {driver?.personalId &&
                    <Typography variant="body2" color="text.secondary">{driver.personalId}</Typography>
                }
            </Box>
            <Box sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}>
                {renderDetailViewButtons()}
            </Box>
        </Box>
        <Box sx={{
            display: 'flex', flexDirection: 'column', width: '400px', maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {width: '100%'}
        }}>
            <TextField name="firstName" label="Eesnimi*" disabled={!canEdit} />
            <TextField name="lastName" label="Perekonnanimi*" disabled={!canEdit} />
            <TextField name="personalId" label="Isikukood*" disabled={!canEdit} />
            <TextField name="externalId" label="Ridango kood" disabled={!canEdit} />
            <PhoneInput name="phone" label="Telefon*" prefixName="phonePrefix" disabled={!canEdit} />
            <TextField name="email" label="E-post" disabled={!canEdit} />
            <MultiSelect name="regions" label="Planeerimispiirkond*" options={regionChoices} disabled={!canEdit} />
            <SelectWithId
                name="accountingRegionId"
                label="Arvelduspiirkond*"
                options={accountingRegionChoices}
                disabled={!canEdit}
            />
            <Checkbox name="active" label="Aktiivne" disabled={!canEdit} />
        </Box>
        <Box sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
            {renderDetailViewButtons()}
        </Box>
    </Form>
}