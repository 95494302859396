import React from 'react';
import {EntityId} from "@reduxjs/toolkit";
import {blue} from "@mui/material/colors";
import {DisplayCharterTripWorkItem, DisplayWorkItem} from "../../../../../types";
import {WorkGroupItemType} from "../../../../../../../API/workGroup/types";
import {useAppDispatch} from "../../../../../../../hooks";
import {updateCharterTripWorkItem} from "../../../../../../../store/plannerSlice";
import CharterTripTooltipContent from "../../../components/Tooltip/CharterTripTooltipContent";
import OppositeGroupMarker from "../../../components/OppositeGroupMarker";
import ItemCard from "../../../index";
import AddOppositeGroupButton from "../../../components/Tooltip/AddOppositeGroupButton";
import {openAddOppositeGroupDialog} from "../../../../../store/actions";
import {Box} from "@mui/material";


interface CharterTripCardProps {
    currentGroupId: EntityId;
    charterTrip: DisplayWorkItem | DisplayCharterTripWorkItem;
    isRowDisabled: boolean;
    isFromOtherRegion: boolean;
    groupOfOppositeTypeId?: number;
}

const CharterTripCard = ({
    currentGroupId,
    charterTrip,
    isRowDisabled,
    isFromOtherRegion,
    groupOfOppositeTypeId,
}: CharterTripCardProps) => {
    const dispatch = useAppDispatch();

    const onDrop = (itemId: number, targetGroupId: number) => {
        dispatch(updateCharterTripWorkItem({
            movedCharterTripId: itemId,
            originWorkSheetId: currentGroupId,
            targetWorkSheetId: targetGroupId,
        }));
    };

    const handleAddOpposite = () => dispatch(openAddOppositeGroupDialog(currentGroupId, charterTrip.id, WorkGroupItemType.TRIP_DEFINITION));

    const color = isFromOtherRegion ? `repeating-linear-gradient(
        -45deg,
        ${blue[600]},
        ${blue[600]} 10px,
        ${blue[700]} 10px,
        ${blue[700]} 15px
    )` : blue[600];


    return (charterTrip &&
            <ItemCard
                isRowDisabled={isRowDisabled}
                tooltipContent={
                    <CharterTripTooltipContent
                        id={charterTrip.charterTripId?.toString() ?? ''}
                        code={charterTrip.code ?? ''}
                        route={typeof charterTrip.route === 'string' ? charterTrip.route : ''}
                        startTime={charterTrip.startTime}
                        startTimeIsOnNextDay={charterTrip.startTimeIsOnNextDay}
                        endTime={charterTrip.endTime}
                        endTimeIsOnNextDay={charterTrip.endTimeIsOnNextDay}
                        distance={charterTrip.distance}
                        workSheetsOfOppositeType={charterTrip.workSheetsOfOppositeType}
                    />
                }
                tooltipActions={
                    <>
                        <AddOppositeGroupButton
                            currentGroupId={currentGroupId}
                            oppositeGroupId={groupOfOppositeTypeId}
                            onClick={handleAddOpposite}
                        />
                    </>
                }
                color={color}
                title={charterTrip.code ?? ''}
                subtitle={typeof charterTrip.route === 'string' &&
                    <Box sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {charterTrip.route.toUpperCase()}
                    </Box>
                }
                currentGroupId={currentGroupId}
                item={{
                    id: charterTrip.id,
                    type: WorkGroupItemType.TRIP_DEFINITION,
                    xPos: charterTrip.xPos,
                    width: charterTrip.width,
                    startTime: charterTrip.startTime,
                    startTimeIsOnNextDay: charterTrip.startTimeIsOnNextDay,
                    endTime: charterTrip.endTime,
                    endTimeIsOnNextDay: charterTrip.endTimeIsOnNextDay,
                }}
                oppositeGroupMarker={charterTrip.width > 10 &&
                    <OppositeGroupMarker itemId={charterTrip.id}
                        type={WorkGroupItemType.TRIP_DEFINITION}
                        isRowDisabled={isRowDisabled}
                        currentGroupId={currentGroupId}
                        groupOfOppositeTypeId={groupOfOppositeTypeId}
                    />
                }
                onDrop={onDrop}
            />
    );
};

export default CharterTripCard;