export interface AvlAssignment {
    tripId: number;
    tripCode: string;
    date: string;
    status: AvlSyncStatus;
    lastSyncTime: string | null;
    lastMessage: string | null;
    retryCount: number | null;
}

export enum AvlSyncStatus {
    PENDING = 'PENDING',
    OK = 'OK',
    CANCELLED = 'CANCELLED',
    ERROR = 'ERROR'
}