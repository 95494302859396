import React, {useEffect, useState} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps} from '@mui/x-date-pickers';
import {useField} from 'formik';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/et';
import {useTheme} from '@mui/material';


dayjs.locale('et');

interface DateTimePickerProps extends Omit<MuiDateTimePickerProps<Date>, 'name' | 'value' | 'onChange' | 'views' | 'minDate' | 'maxDate'> {
	name: string;
	label: string;
	fullWidth?: boolean;
	disabled?: boolean;
	handleBlur?: () => void;
}

const DateTimePicker = ({ name, label, fullWidth, disabled, handleBlur }: DateTimePickerProps) => {
	const [field, meta, helpers] = useField<Date | null>(name);
	const [value, setValue] = useState<Dayjs | null>(field.value ? dayjs(field.value) : null);
	const theme = useTheme();

	useEffect(() => {
		const newValue = dayjs(field.value)
		if (newValue.isValid()) setValue(newValue);
	}, [field.value]);

	const handleChange = (date: Dayjs | null) => {
		setValue(date);
		void helpers.setValue(date ? date.toDate() : null);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
			<MuiDateTimePicker
				label={label}
				value={value}
				onChange={(newValue: Dayjs | null) => handleChange(newValue)}
				disabled={disabled}
				onError={(error) => meta.touched && !!meta.error ? error : null}
				slotProps={{
					textField: {
						size: 'small',
						error: meta.touched && !!meta.error,
						helperText: meta.touched && meta.error,
						disabled: disabled,
						onBlur: () => {
							void helpers.setTouched(true);
							if (handleBlur) handleBlur();
						},
						inputProps: {
							sx: {
								color: value?.isValid() ? '' : theme.palette.text.secondary,
							},
						},
						FormHelperTextProps: {
							sx: {
								color: theme.palette.error.main,
							},
						},
						fullWidth: fullWidth,
					},
					popper: {
						sx: {
							'& .MuiPickersDay-root': {'&.Mui-selected': {backgroundColor: theme.palette.primary.main}},
							'& .MuiPickersYear-yearButton': {'&.Mui-selected': {backgroundColor: theme.palette.primary.main}},
							'& .MuiPickersPopper-paper': {pr: 1},
						}
					}
				}}
				sx={{my: 1, '& .MuiInputLabel-root': {
					color: meta.touched && !!meta.error ? theme.palette.error.main : disabled ? theme.palette.text.disabled : theme.palette.text.secondary
				}}}
			/>
		</LocalizationProvider>
	);
};

export default DateTimePicker;
