import {Box} from "@mui/material";
import theme from "../../theme";
import React from "react";
import FilterChip, {FilterChipProps} from "./components/FilterChip";
import InputSearch, {InputSearchProps} from "./components/InputSearch";
import RegionsSearch, {RegionSearchProps} from "./components/RegionsSearch";


interface FilterToolbarProps {
    inputSearch?: InputSearchProps,
    regionsSearch?: RegionSearchProps,
    filterChips?: FilterChipProps[];
}

const FilterToolbar = ({inputSearch, regionsSearch, filterChips}: FilterToolbarProps) => {
    return (
        <Box sx={{
            border: '1px solid',
            borderBottom: 'none',
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.action.hover,
            px: 1,
            py: 1.5,
            borderRadius: '5px 5px 0 0',
            mb: '-5px',
            display: 'flex',
            alignItems: {xs: 'flex-start', md: 'center'},
            flexDirection: {xs: 'column', md: 'row'},
            gap: 1,
        }}>
            <Box sx={{
                boxSizing: 'border-box',
                width: {xs: '100%', md: 'fit-content'},
                display: 'flex',
                flexDirection: {xs: 'column', sm: 'row'},
                gap: {xs: 2, sm: 1},
                pr: {xs: 0, sm: 1, md: 0}
            }}>
                {inputSearch && <InputSearch {...inputSearch} />}
                {regionsSearch && <RegionsSearch {...regionsSearch} />}
            </Box>
            {filterChips &&
                <Box>
                    {filterChips.map((filterChip, index) => <FilterChip key={index} {...filterChip} />)}
                </Box>
            }
        </Box>
    );
};

export default FilterToolbar;
