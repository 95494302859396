import {Contract} from "../driver/types";

export interface Payslip {
    id: number;
    driverId: number;
    regionId: number;
    createdAt: string;
    startDate: string;
    endDate: string;
    driverContractType?: Contract;
    body: PayslipBody;
}

export interface PayslipBody {
    finalPayslip: boolean;
    periods: PayslipPeriod[];
}

export interface PayslipPeriod {
    yearMonth: string;
    numberOfWorkDays: number;
    contracts: PayslipContract[];
    lines: PayslipLine[];
}

export interface PayslipContract {
    id: number;
    contractId: string;
    profitCenter: string;
    monthlyPay: number;
    coefficient: number;
    averageCoefficientOfSixMonths: number;
}

export interface PayslipLine {
    type: PayType;
    contractId: number | null;
    hours: number;
    amount: number;
}

export enum PayType {
    LINE_TIME = 'LINE_TIME',
    EXTRA_TIME = 'EXTRA_TIME',
    RESERVE = 'RESERVE',
    MAINTENANCE = 'MAINTENANCE',
    OVERTIME = 'OVERTIME',
    NIGHT_TIME = 'NIGHT_TIME',
    HOLIDAY = 'HOLIDAY',
    DISRUPTION = 'DISRUPTION',
    UNDERTIME = 'UNDERTIME',
    PARCEL_DELIVERY_FEE = 'PARCEL_DELIVERY_FEE',
    EXTRA_FEE = 'EXTRA_FEE',
}

export interface CoefficientsReportRequest {
    regionId: number;
    selectors: Selector[];
    yearMonthFrom: string;
    yearMonthTo: string;
}

export enum Selector {
    Driver = 'Driver',
    YearMonth = 'YearMonth',
    TransportContract = 'TransportContract',
    ProfitCenter = 'ProfitCenter',
}

export interface CoefficientsReportLine {
    group: string[];
    coefficient: number;
}

export interface PayslipExtra {
    id?: number;
    yearMonth: string;
    parcelDeliveryFee: number;
    extraFee: number;
    transportContractId: number;
    driverContract: DriverContract;
    payslipGenerated: boolean;
}

export interface SavePayslipExtra {
    parcelDeliveryFee: number;
    extraFee: number;
    transportContractId: number;
}

export interface PayslipHours {
    id?: number;
    yearMonth: string;
    workingHours: number;
    nationalHolidayHours: number;
    nightTimeHours: number;
    transportContractId: number;
    driverContract: DriverContract;
    payslipGenerated: boolean;
}

export interface SavePayslipHours {
    workingHours: number;
    nationalHolidayHours: number;
    nightTimeHours: number;
    transportContractId: number;
}

export interface DriverContract {
    id: number;
    number: string;
    type: Contract;
    startDate: string;
    endDate?: string;
    driver: Driver;
}

export interface Driver {
    id: number;
    firstName: string;
    lastName: string;
    personalId: string;
}
