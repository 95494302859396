import React from 'react';
import {useAppSelector} from "../../../../../../../../../hooks";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import PlannerItemGroupCard from "../../../Card";
import {
    selectResourceNameByTypeNullable,
    selectWorkScheduleItemWorkGroupCode,
} from "../../../../../../../../../scenes/authenticated/workSheets/store/selectors";
import {selectAllItemTimesInPlannerItemGroupMemoized} from "../../../../../../../store/selectors";
import {ResourceType, WorkScheduleItemStatus} from "../../../../../../../../../API/types";
import {isDriverWorkDurationOverLimit} from "../../../../../../../utils/utils";
import {getWorkScheduleItemId, selectWorkScheduleItemById} from "../../../../../../../../../store/workScheduleItemSlice";
import {selectRegion} from "../../../../../../../../../store/regionSlice";
import {RootState} from "../../../../../../../../../store";


interface WorkSheetWrapperProps {
    groupId: EntityId;
    index: number;
}

function selectOtherRegion(state: RootState, groupId: number | string) {
    const workSheet = selectWorkScheduleItemById(state, groupId)
    const selectedRegionId = state.region.selected;
    if (workSheet?.regionId && workSheet?.regionId !== selectedRegionId) {
        return selectRegion(state, workSheet?.regionId);
    } else {
        return undefined;
    }
}

const WorkSheetCardWrapper = ({groupId, index}: WorkSheetWrapperProps) => {
    const workSheet = useAppSelector(state => selectWorkScheduleItemById(state, groupId), shallowEqual);
    const workGroupCode = useAppSelector(state => selectWorkScheduleItemWorkGroupCode(state, groupId), shallowEqual) ?? 'TG';
    const otherRegion = useAppSelector(state => selectOtherRegion(state, groupId));
    const resourceName = useAppSelector(state => selectResourceNameByTypeNullable(state, workSheet?.resourceId, workSheet?.resourceType))
    const driverItems = useAppSelector(state => selectAllItemTimesInPlannerItemGroupMemoized(state, workSheet?.resourceType === ResourceType.DRIVER ? groupId : 0));
    if (!workSheet) return <></>;

    const isRowDisabled = workSheet.status !== WorkScheduleItemStatus.GENERATED;
    const workSheetId = isRowDisabled ? undefined : getWorkScheduleItemId(groupId);
    const isDurationOverDriverLimit = driverItems && driverItems.length > 0
        ? isDriverWorkDurationOverLimit(workSheet.resourceType, driverItems[0].startHh, driverItems[driverItems.length - 1].endHh, driverItems)
        : undefined;


    return (
        <PlannerItemGroupCard
            groupId={groupId}
            index={index}
            workGroupCode={workGroupCode}
            workGroupId={workSheet.workGroupId}
            workSheetId={workSheetId}
            resourceName={resourceName}
            isDurationOverLimit={isDurationOverDriverLimit}
            otherRegion={otherRegion}
            isRowDisabled={isRowDisabled}
        />
    )
};

export default WorkSheetCardWrapper;
