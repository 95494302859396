import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import React from "react";
import TextField from "../../Form/TextField";
import Button from "../../Buttons/Button";
import {Box} from "@mui/material";
import {isEqual} from "lodash";
import {FormComment, CommentFormData} from "../types";


interface CommentFormProps {
    comment?: FormComment;
    handleSubmit: (commentForm: CommentFormData, formHelpers: FormikHelpers<CommentFormData>, id?: number) => void;
}

const CommentForm = ({comment, handleSubmit}: CommentFormProps) => {
    const initialValues: CommentFormData = {
        comment: comment?.text ?? '',
    };

    const onSubmit = (values: CommentFormData, helpers: FormikHelpers<CommentFormData>) => {
        handleSubmit(values, helpers, comment?.id);
    };

    const isSubmitDisabled = (formikProps: FormikProps<CommentFormData>) => {
        return isEqual(initialValues, formikProps.values) || formikProps.isSubmitting;
    };

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formikProps: FormikProps<CommentFormData>) =>
                <Form>
                    <TextField name="comment" label="Kommentaar" multiline fullWidth minRows={3} maxRows={5} />
                    <Box sx={{textAlign: 'right'}}>
                        <Button text="Salvesta" type="submit" disabled={isSubmitDisabled(formikProps)} />
                    </Box>
                </Form>
            }
        </Formik>
    );
};

export default CommentForm;
