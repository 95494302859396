import {Box} from "@mui/material";
import CustomCell from "./CustomCell";
import React, {useMemo} from "react";
import dayjs, {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector, useWorkScheduleSelectedDay} from "../../../../../hooks";
import {
    selectNumberOfUnscheduledWorkGroupsOnDay,
    selectUnscheduledWorkGroups,
    selectUnscheduledWorkGroupsOnDay
} from "../../store/selectors";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {ResourceType} from "../../../../../API/types";
import Button from "../../../../../components/Buttons/Button";
import {Add} from "@mui/icons-material";
import {openAddDriverDialog} from "../../../../../store/workScheduleSlice";
import {DEFAULT_CELL_WIDTH, disabledBackground} from "../index";
import {roundToDecimals} from "../../../../../utils/utils";

const UnscheduledWorkGroupsCellTooltip = ({day}: {day: Dayjs}) => {
    const workGroups = useAppSelector(state => selectUnscheduledWorkGroupsOnDay(state, day.format('YYYY-MM-DD')));
    return (workGroups.length > 0 &&
        <>{workGroups.map(item =>
            <Box key={item.id}>
                {item.code}
                {item.workScheduleItemStartDateTime && item.workScheduleItemEndDateTime && item.hours ?
                    ' (' + dayjs(item.workScheduleItemStartDateTime).format('HH:mm')
                    + ' - ' + dayjs(item.workScheduleItemEndDateTime).format('HH:mm') + ')'
                    + ' ' + roundToDecimals(item.hours, 1) + 'h'
                    : null}
            </Box>
        )}</>
    );
};

const UnscheduledWorkGroupsCell = ({day}: {day: Dayjs}) => {
    const numberOfItems = useAppSelector(state => selectNumberOfUnscheduledWorkGroupsOnDay(state, day.format('YYYY-MM-DD')));
    const { selectedDay, handleSelectDay } = useWorkScheduleSelectedDay();

    const content = numberOfItems > 0 ? numberOfItems : '';

    const tooltip = useMemo(() => {
        if (numberOfItems > 50 || numberOfItems === 0) return undefined;

        return <UnscheduledWorkGroupsCellTooltip day={day} />
    }, [numberOfItems, day]);

    return (
        <CustomCell
            key={day.toString()}
            sx={{
                minWidth: DEFAULT_CELL_WIDTH,
                borderTopWidth: 1,
                backgroundColor: selectedDay && selectedDay !== day.format('YYYY-MM-DD') ? disabledBackground : 'common.white'
            }}
            content={content}
            tooltip={tooltip}
            onClick={handleSelectDay(day)}
        />
    )
};

interface UnscheduledWorkGroupsProps {
    currentMonthDays: Dayjs[];
    labelColumnWidth: number;
}

const UnscheduledWorkGroupsRow = ({currentMonthDays, labelColumnWidth}: UnscheduledWorkGroupsProps) => {
    const unscheduledWorkGroups = useAppSelector(selectUnscheduledWorkGroups);
    const resourceType = useAppSelector(selectToggledResourceType);
    const dispatch = useAppDispatch();

    const handleOpenAddDriverDialog = () => {
        dispatch(openAddDriverDialog());
    };

    const unscheduledWorkGroupCells = useMemo(() => {
        return currentMonthDays.map((day) => <UnscheduledWorkGroupsCell day={day} key={day.toString()} />)
    }, [currentMonthDays]);

    return (unscheduledWorkGroups.length > 0
        ?
        <Box sx={{
            displayPrint: 'none',
            zIndex: 1,
            marginTop: '-1px',
            display: 'flex',
        }} role="row">
            <CustomCell
                sx={{
                    textAlign: 'right',
                    paddingRight: 1,
                    minWidth: labelColumnWidth,
                    maxWidth: labelColumnWidth,
                    flexGrow: 0,
                    borderTopWidth: 1,
                    backgroundColor: 'common.white',
                }}
                content={
                    resourceType === ResourceType.DRIVER ?
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button text="Lisa juht" startIcon={<Add />} variant="text" onClick={handleOpenAddDriverDialog} />
                        <Box sx={{lineHeight: 1.4}}>Planeerimata<br/> töögrupid</Box>
                    </Box>
                    : <Box sx={{lineHeight: 1.4}}>Planeerimata<br/> töögrupid</Box>
                }
            />
            {unscheduledWorkGroupCells}
        </Box>
        : null
    );
};

export default React.memo(UnscheduledWorkGroupsRow);