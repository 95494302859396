import React, {useContext, useEffect, useState} from "react";
import {WorkGroup} from "../../../../API/types";
import {ToastContext} from "../../../../contexts/ToastContext";
import {Box, useMediaQuery} from "@mui/material";
import {
    Permission,
    resourceGroupToggleButtonTypeValuesWithAll,
    ResourceGroupToggleButtonTypeWithAll
} from "../../../../types";
import DataGrid from "../../../../components/DataGrid";
import {filterByValue} from "../../../../utils/utils";
import {GridColDef} from "@mui/x-data-grid";
import theme from "../../../../theme";
import Visible from "../../../../components/Visible";
import {getResourceTypeTranslation} from "../../../../utils/enumTranslations";
import routes from "../../../../routes";
import {useNavigate, useOutletContext} from "react-router-dom";
import Repetition from "../../../../components/RepetitionRules";
import NavigationButton from "../../../../components/Buttons/NavigationButton";
import {loadWorkGroups} from "../../../../API";
import {useAppSelector, useWorkGroupsPlanningView} from "../../../../hooks";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {useResourceTypeToggle} from "../../../../store/viewSlice";
import {getDisplayDate, getDisplayDateWithoutYear} from "../../../../utils/dateUtils";
import {filterWorkGroupsByResourceType} from "../utils";
import NavLink from "../../../../components/NavLink";
import NavIcon from "../../../../components/Icons/NavIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import ActionIcon from "../../../../components/Icons/ActionIcon";
import NewWorkGroupVersionDialog, {NewWorkGroupVersionDialogData} from "./components/NewVersionDialog";


export default function WorkGroupsListView() {
    const { addToast } = useContext(ToastContext);
    const navigate = useNavigate();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [rows, setRows] = useState<WorkGroup[] | undefined>(undefined);
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonTypeWithAll>(true);
    const [newWorkGroupVersionDialogData, setNewWorkGroupVersionDialogData] = useState<NewWorkGroupVersionDialogData | undefined>(undefined);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));

    useWorkGroupsPlanningView(false);

    useEffect(() => {
        loadRows();
    }, [selectedRegion]);

    const loadRows = () => {
        if (selectedRegion) {
            loadWorkGroups(selectedRegion.id)
                .then(result => {
                    setRows(result);
                })
                .catch(() => {
                    setRows([]);
                    addToast({type: 'error', text: 'Töögruppide pärimisel ilmnes viga'});
                });
        }
    };

    const getFilteredRows = (rows: WorkGroup[]) => {
        return filterWorkGroupsByResourceType(filterByValue(rows, searchInput), resourceTypeToggleButtonValue);
    }

    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: 'Kood',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.code} route={routes.AUTHENTICATED.WORK_GROUPS.EDIT} navPermission={Permission.UpdateWorkGroups} />
        },
        {
            field: 'type',
            headerName: 'Tüüp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box>{getResourceTypeTranslation(params.value)}</Box> : <></>
        },
        {
            field: 'validFrom',
            headerName: 'Algus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'validTo',
            headerName: 'Lõpp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'startDate',
            headerName: 'Perioodi algus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDateWithoutYear(params.value)}</Box> : <></>
        },
        {
            field: 'endDate',
            headerName: 'Perioodi lõpp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDateWithoutYear(params.value)}</Box> : <></>
        },
        {
            field: 'repetition',
            headerName: 'Korduvusreeglid',
            sortable: false,
            filterable: false,
            minWidth: 150,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}><Repetition repetition={params.value} /></Box> : <></>
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            minWidth: 70,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Visible permission={Permission.UpdateWorkGroups}>
                        <Box>
                            <ActionIcon type="START_NEW" id={params.row.id} handleClick={() => setNewWorkGroupVersionDialogData({originalWorkGroup: params.row})} />
                            <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.WORK_GROUPS.EDIT} />
                        </Box>
                    </Visible>
                );
            }
        }
    ];

    const handleCloseNewWorkGroupVersionDialog = () => setNewWorkGroupVersionDialogData(undefined);

    return (
        <ListView
            headerProps={{
                title: 'Töögrupid',
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {
                    title: 'Lisa töögrupp',
                    onClick: () => navigate(routes.AUTHENTICATED.WORK_GROUPS.ADD),
                    permission: Permission.UpdateWorkGroups
                },
                secondaryButton: <NavigationButton text="Planeerija" route={routes.AUTHENTICATED.WORK_GROUPS.PLANNER} />,
                toggleProps: {
                    options: [...resourceGroupToggleButtonTypeValuesWithAll],
                        value: resourceTypeToggleButtonValue,
                        handleChange: handleResourceTypeChange,
                },
                showRegionSelect: true,
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            {newWorkGroupVersionDialogData &&
                <NewWorkGroupVersionDialog
                    dialogData={newWorkGroupVersionDialogData}
                    handleSuccess={handleCloseNewWorkGroupVersionDialog}
                    handleCloseDialog={handleCloseNewWorkGroupVersionDialog}
                />
            }
        </ListView>
    );
}
