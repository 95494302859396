import {Bus, OdometerReading} from "../bus/types";
import {Comment} from "../../components/Comments/types";

export interface Defect {
    id: number;
    bus: Bus;
    comments: Comment[];
    status: DefectStatus;
    creatorUserId: string;
    createdAt: string;
    creatorUserName?: string;
    lastEditorUserId?: string;
    lastEditedAt?: string;
    lastEditorUserName?: string;
    markedInProgressByUserId?: string;
    markedInProgressAt?: string;
    markedInProgressByUserName?: string;
    description: string;
    priority: DefectPriority;
    expectedRepairDateTime?: string;
    expectedRepairFinishedDateTime?: string;
    markedAsRepairedByUserId?: string;
    repairedAt?: string;
    markedAsRepairedByUserName?: string;
    odometerReading?: OdometerReading;
}

export interface SaveDefectRequest {
    busId: number;
    status: DefectStatus;
    description: string;
    priority: DefectPriority;
    expectedRepairDateTime?: string;
    expectedRepairFinishedDateTime?: string;
    comment?: string;
}

export interface SaveDefectComment {
    defectId: number;
    text: string;
}

export enum DefectPriority {
    CRITICAL = 'CRITICAL',
    IMPORTANT = 'IMPORTANT',
    LOW = 'LOW'
}

export enum DefectStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    SOLVED = 'SOLVED'
}

export interface DefectSimple {
    id: number;
    busId: number;
    status: DefectStatus;
    priority: DefectPriority;
    description: string;
    from: string;
    to?: string;
}

export const PRIORITY_ORDER: Record<DefectPriority, number> = {
    'LOW': 1,
    'IMPORTANT': 2,
    'CRITICAL': 3,
};
