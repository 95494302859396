import React from 'react';
import {PLANNER_SIDEBAR_WIDTH} from "../../../../constants";
import {Box, IconButton, TextField, Tooltip} from "@mui/material";
import {PAGE_HEADER_ITEM_HEIGHT} from "../../../../../PageHeader";
import {AddLocationOutlined, Sync} from "@mui/icons-material";
import {
    resetUnassignedTripsState,
    selectUnassignedTripsFilterInput,
    setDialogData,
    setUnassignedTripsFilterInput
} from "../../../../../../store/plannerSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";


const UnassignedItemsContainerToolbox = () => {
    const dispatch = useAppDispatch();
    const filterInput = useAppSelector(selectUnassignedTripsFilterInput);

    const reloadUnassignedTrips = () => {
        dispatch(resetUnassignedTripsState());
    };

    const openAddOtherRegionTripDialog = () => {
        dispatch(setDialogData({addOtherRegionTrip: true}));
    }

    return (
        <Box sx={{
            minWidth: `${PLANNER_SIDEBAR_WIDTH}px`,
            maxWidth: `${PLANNER_SIDEBAR_WIDTH}px`,
            minHeight: '100%',
            p: 1,
            boxSizing: 'border-box',
            backgroundColor: 'common.white',
        }}>
            <TextField
                value={filterInput}
                label="Otsing"
                size="small"
                onChange={(event) => dispatch(setUnassignedTripsFilterInput(event.target.value))}
                InputLabelProps={{shrink: true}}
                InputProps={{sx: {height: PAGE_HEADER_ITEM_HEIGHT}}}
                sx={{mt: 1, mb: 0.5, width: '100%'}}
            />
            <Tooltip title="Värskenda planeerimata reiside järjestust" arrow placement="top">
                <IconButton size="small" onClick={() => reloadUnassignedTrips()}>
                    <Sync />
                </IconButton>
            </Tooltip>
            <Tooltip title="Otsi regioonivälist reisi" arrow placement="top">
                <IconButton size="small" onClick={() => openAddOtherRegionTripDialog()}>
                    <AddLocationOutlined />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default UnassignedItemsContainerToolbox;