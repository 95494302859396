import React, {useMemo} from "react";
import {ApiError, EndTripResponse, ResourceType, TripDefinition} from "../../../../../API/types";
import Dialog from "../../../../../components/Dialog";
import {Check, Clear} from "@mui/icons-material";
import {getDisplayDate, getDisplayValidDatesRange} from "../../../../../utils/dateUtils";
import {Formik, FormikHelpers, FormikProps} from "formik";
import DatePicker from "../../../../../components/Form/DatePicker";
import * as Yup from "yup";
import {Box, Typography} from "@mui/material";
import WorkGroupCard from "../../detailsView/components/WorkGroupCard";
import {countByRegion} from "../../detailsView/components/ConfirmDialog";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import PlannedChangesSection, {ChangeSection} from "../../components/PlannedChangesSection";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import dayjs from "dayjs";


export interface EndTripDialogData {
    trip: TripDefinition;
    date: Date | null;
    plannedChanges?: EndTripResponse;
}

interface EndTripForm {
    date: Date | null;
}

const getEndTripFormValidationSchema = (trip: TripDefinition) => {
    let dateValidationSchema = Yup.date().required("Lõpukuupäev on kohustuslik väli");
    if (trip.validTo) {
        dateValidationSchema = dateValidationSchema.max(
            dayjs(trip.validTo).subtract(1, 'day').toDate(),
            `Lõpukuupäev peab olema enne reisi lõpukuupäeva ${getDisplayDate(trip.validTo)}`,
        );
    }

    if (trip.validFrom) {
        dateValidationSchema = dateValidationSchema.min(
            trip.validFrom,
            `Lõpukuupäev peab olema peale reisi alguskuupäeva ${getDisplayDate(trip.validFrom)}`,
        );
    }

    return Yup.object().shape({
        date: dateValidationSchema,
    });
};

interface EndTripDialogProps {
    dialogData: EndTripDialogData;
    handleCloseDialog: () => void;
    handleSave: (trip: TripDefinition, date: Date) => Promise<void>;
    handleConfirm: (trip: TripDefinition, date: Date) => Promise<void>;
}

const EndTripDialog = ({dialogData, handleCloseDialog, handleSave, handleConfirm}: EndTripDialogProps) => {
    const initialValues: EndTripForm = { date: null };
    const {trip, date, plannedChanges} = dialogData;
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const dispatch = useAppDispatch();

    const handleSubmit = (values: EndTripForm, helpers: FormikHelpers<EndTripForm>) => {
        if (values.date) {
            handleSave(trip, values.date)
                .catch((error: ApiError) => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? 'Reisi lõpetamisel tekkis viga'
                    }));
                })
                .finally(() => {
                    helpers.setSubmitting(false);
                });
        }
    };

    const handleConfirmClick = () => date ? void handleConfirm(trip, date) : null;

    const changes: ChangeSection[] = useMemo(() => {
        if (!plannedChanges) {
            return [];
        }

        const changes: ChangeSection[] = [];

        const newDriverWorkGroupVersions = plannedChanges.newWorkGroupVersions.filter(wg => wg.type === ResourceType.DRIVER);
        if (newDriverWorkGroupVersions.length > 0) {
            let text = `Uus versioon luuakse ${newDriverWorkGroupVersions.length} juhi töögrupist`;
            if (newDriverWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(newDriverWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: newDriverWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />)
            });
        }

        const newBusWorkGroupVersions = plannedChanges.newWorkGroupVersions.filter(wg => wg.type === ResourceType.VEHICLE);
        if (newBusWorkGroupVersions.length > 0) {
            let text = `Uus versioon luuakse ${newBusWorkGroupVersions.length} bussi töögrupist`;
            if (newBusWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(newBusWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: newBusWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />),
            });
        }

        const removedFromDriverWorkGroupVersions = plannedChanges.removedFromWorkGroupVersions.filter(wg => wg.type === ResourceType.DRIVER);
        if (removedFromDriverWorkGroupVersions.length > 0) {
            let text = `Reis eemaldatakse ${removedFromDriverWorkGroupVersions.length} juhi töögrupist`;
            if (removedFromDriverWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(removedFromDriverWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: removedFromDriverWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />)
            });
        }

        const removedFromBusWorkGroupVersions = plannedChanges.removedFromWorkGroupVersions.filter(wg => wg.type === ResourceType.VEHICLE);
        if (removedFromBusWorkGroupVersions.length > 0) {
            let text = `Reis eemaldatakse ${removedFromBusWorkGroupVersions.length} bussi töögrupist`;
            if (removedFromBusWorkGroupVersions.some(wg => wg.region.id !== selectedRegion?.id)) {
                text += ` (${countByRegion(removedFromBusWorkGroupVersions).map(([regionName, count]) => `${count} ${regionName}`).join(', ')})`;
            }
            changes.push({
                summary: text,
                details: removedFromBusWorkGroupVersions.map(wgv => <WorkGroupCard key={wgv.code+wgv.validTo+wgv.region.id} workGroup={wgv} />)
            });
        }


        return changes;
    }, [plannedChanges, selectedRegion]);

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={getEndTripFormValidationSchema(trip)}>
            {(formikProps: FormikProps<EndTripForm>) =>
                <Dialog
                    open={true}
                    buttons={[
                        {text: 'Loobu', onClick: handleCloseDialog, color: 'secondary', startIcon: <Clear/>, disabled: formikProps.isSubmitting},
                        plannedChanges
                            ? {text: 'Kinnita', type: 'button', startIcon: <Check/>, disabled: formikProps.isSubmitting, onClick: handleConfirmClick}
                            : {text: 'Salvesta', type: 'submit', startIcon: <Check/>, disabled: formikProps.isSubmitting},
                    ]}
                    title="Lõpeta reis"
                    subtitle={`${trip.code} ${trip.validFrom || trip.validTo ? getDisplayValidDatesRange({
                        validFrom: trip.validFrom,
                        validTo: trip.validTo,
                        useEllipsisFallback: true
                    }) : ''}`}
                    hasFormikForm={true}
                    onClose={handleCloseDialog}
                >
                    {date && <Typography sx={{mb: 2}}>Reisi lõpukuupäevaks määratakse {getDisplayDate(date)}</Typography>}
                    {plannedChanges
                        ? changes.map(({summary, details}, index) =>
                            <PlannedChangesSection key={index} summary={summary} details={details} />
                        )
                        :
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <DatePicker name="date" label="Lõpukuupäev*" />
                        </Box>
                    }
                </Dialog>
            }
        </Formik>
    );
};

export default EndTripDialog;