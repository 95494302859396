import React from 'react';
import {TextField} from "@mui/material";
import theme from "../../../theme";
import Autocomplete from "@mui/material/Autocomplete";
import {useAppSelector} from "../../../hooks";
import {selectAllRegions} from "../../../store/regionSlice";
import {Region} from "../../../API/region/types";

export interface RegionSearchProps {
    values: Region[];
    updateValues: (regions: Region[]) => void;
}

const RegionsSearch = ({values, updateValues}: RegionSearchProps) => {
    const regions = useAppSelector(selectAllRegions);

    return (
        <Autocomplete
            multiple
            options={regions}
            size="small"
            getOptionLabel={(option) => option.name}
            value={values}
            onChange={(_, newValue) => {
                updateValues([...newValue]);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Piirkond"
                    InputLabelProps={{shrink: true}}
                />
            )}
            sx={{minWidth: {xs: '100%', sm: '60%', md: 300}, maxWidth: '100%', backgroundColor: theme.palette.background.paper}}
        />
    );
};

export default RegionsSearch;
