import {createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState} from "@reduxjs/toolkit";
import {Driver} from "../API/types";
import {loadDrivers} from "../API";
import {RootState} from "../store";
import {selectSelectedRegion} from "./regionSlice";

export const driverAdapter = createEntityAdapter<Driver>();

export const fetchDrivers = createAsyncThunk(
    'drivers/getAll',
    () => {
        return loadDrivers();
    }
);

const initialState: EntityState<Driver> = driverAdapter.getInitialState();

export const driverSlice = createSlice({
    name: 'drivers',
    initialState: initialState,
    reducers: {
        setDrivers: driverAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDrivers.fulfilled, (state, action) => {
            if (action.payload) {
                driverAdapter.setAll(state, action.payload);
            }
        });
    }
});

export const {
    setDrivers,
} = driverSlice.actions;

export const {
    selectAll: selectAllDrivers,
    selectIds: selectDriverIds,
    selectById: selectDriverById,
} = driverAdapter.getSelectors<RootState>(state => state.drivers);

export const selectDriversInSelectedRegion = createSelector(
    selectAllDrivers,
    selectSelectedRegion,
    (drivers, selectedRegion) => {
        if (!selectedRegion) {
            return drivers;
        }

        return drivers.filter(driver => driver.regionIds.includes(selectedRegion.id));
    }
);

export const selectDriversWithSelectedRegionAsAccountingRegion = createSelector(
    selectAllDrivers,
    selectSelectedRegion,
    (drivers, selectedRegion) => {
        if (!selectedRegion) {
            return drivers;
        }

        return drivers.filter(driver => driver.accountingRegionId === selectedRegion.id);
    }
);

export default driverSlice.reducer;