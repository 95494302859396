import {OdometerReading, OdometerReadingWithBusId} from "../../../../../API/bus/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {fetchDrivers, selectAllDrivers} from "../../../../../store/driverSlice";
import React, {useEffect} from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {getFuelTranslation} from "../../../../../utils/enumTranslations";
import DataGrid from "../../../../../components/DataGrid";
import {formatName} from "../../../workSchedule/utils";
import {IconButton, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";

interface BusOdometerReadingsProps {
    odometerReadings: OdometerReading[];
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}

const BusOdometerReadings = ({
    odometerReadings,
    handleEditOdometerReading,
    handleDeleteOdometerReading
}: BusOdometerReadingsProps) => {
    const sorted = [...odometerReadings].sort(
        (a, b) => b.dateTime.localeCompare(a.dateTime)
    );
    const drivers = useAppSelector(selectAllDrivers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchDrivers());
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'dateTime',
            headerName: 'Kuupäev/aeg',
            sortable: true,
            disableColumnMenu: true,
            width: 150,
            valueFormatter: (params) => dayjs(params.value).format('DD.MM.YYYY HH:mm')
        },
        {
            field: 'reading',
            headerName: 'Näit',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            valueFormatter: (params) => `${Number(params.value).toLocaleString('et-EE')} km`,
        },
        {
            field: 'driverId',
            headerName: 'Juht',
            sortable: false,
            filterable: true,
            width: 150,
            valueGetter: (params) => drivers.find(driver => driver.id === params.value),
            valueFormatter: (params) => {
                return params.value ? formatName(params.value, 'lastNameFirst') : '';
            },
        },
        {
            field: 'fuelType',
            headerName: 'Kütuse liik',
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            valueFormatter: (params) => getFuelTranslation(params.value)
        },
        {
            field: 'fuelAmount',
            headerName: 'Tangitud kogus',
            sortable: false,
            disableColumnMenu: true,
            width: 130,
            valueFormatter: (params) => `${params.value ? Number(params.value).toLocaleString('et-EE') : ''}`,
        },
        {
            field: 'menu',
            headerName: '',
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            minWidth: 80,
            flex: 1,
            renderCell: (params: GridRenderCellParams) =>
                <>
                    <Tooltip title="Muuda" arrow placement="top">
                        <IconButton size="small" onClick={() => handleEditOdometerReading(params.row)}>
                            <Edit sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Kustuta" arrow placement="top">
                        <IconButton size="small" onClick={() => handleDeleteOdometerReading(params.row)}>
                            <Delete sx={{height: '20px', width: '20px'}}/>
                        </IconButton>
                    </Tooltip>
                </>
        },
    ];

    return <DataGrid rows={sorted} columns={columns} />
};

export default BusOdometerReadings;
