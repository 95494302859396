const routes = {
    UNAUTHENTICATED: {
        ROOT: '/login',
    },
    AUTHENTICATED: {
        DASHBOARD:  '/',
        AGENDA: {
            ROOT: '/minu-soidulehed',
            WORK_SHEET: '/minu-soidulehed/:id',
            BUS: '/minu-soidulehed/buss/:licencePlateNumber',
        },
        BUSES: {
            ROOT: '/bussid',
            ADD: '/bussid/uus',
            EDIT: '/bussid/:id',
        },
        DRIVERS: {
            ROOT: '/bussijuhid',
            ADD: '/bussijuhid/uus',
            EDIT: '/bussijuhid/:id'
        },
        CALENDAR: '/kalender',
        USERS: '/kasutajad',
        TRANSPORT_CONTRACTS: {
            ROOT: '/veolepingud',
            ADD: '/veolepingud/uus',
            EDIT: '/veolepingud/:id'
        },
        TRIPS: {
            ROOT: '/reisid',
            ADD: '/reisid/uus',
            EDIT: '/reisid/:id'
        },
        CHARTER_TRIPS: {
            ROOT: '/tellimusveod',
            ADD: '/tellimusveod/uus',
            EDIT: '/tellimusveod/:id'
        },
        WORK_GROUPS: {
            ROOT: '/toogrupid',
            LIST: '/toogrupid/nimekiri',
            PLANNER: '/toogrupid/planeerija',
            ADD: '/toogrupid/uus',
            EDIT: '/toogrupid/:id'
        },
        WORK_SCHEDULE: {
            ROOT: '/tooajakava',
        },
        WORK_SHEETS: {
            ROOT: '/paevaplaan',
            PLANNER: '/paevaplaan/planeerija',
            DETAILS: '/paevaplaan/soidulehed/:id',
        },
        REPORTS: '/aruanded',
        PAYROLL: {
            ROOT: '/palgalehed',
        },
        DEFECTS: {
            ROOT: '/rikked',
            ADD: '/rikked/uus',
            EDIT: '/rikked/:id',
        },
        AVL_ASSIGNMENTS: '/tookasud',
    }
};

export default routes;
