import React from "react";
import Distance from "./components/Distance";
import {WorkGroup, WorkGroupItemType} from "../../../../../../API/types";
import StartAndEndTime from "./components/StartAndEndTime";
import TooltipRow from "./components/TooltipRow";
import OppositeWorkSheet from "./components/OppositeWorkSheet";
import {getWorkGroupItemTypeTranslation} from "../../../../../../utils/enumTranslations";
import OppositeWorkGroup from "./components/OppositeWorkGroup";

interface ActivityTooltipContentProps {
    activity: {
        type: WorkGroupItemType;
        startTime: string;
        startTimeIsOnNextDay: boolean;
        endTime: string;
        endTimeIsOnNextDay: boolean;
        distance: number | null;
        workSheetsOfOppositeType?: number[];
        workGroupsOfOppositeType?: WorkGroup[];
    };
}

const ActivityTooltipContent = ({activity}: ActivityTooltipContentProps) => {
    const oppositeWorkGroup = activity.workGroupsOfOppositeType && activity.workGroupsOfOppositeType.at(0);

    return (
        <>
            <TooltipRow>{getWorkGroupItemTypeTranslation(activity.type)}</TooltipRow>
            <StartAndEndTime {...activity} />
            <Distance distance={activity.distance} />
            <OppositeWorkSheet workSheetIds={activity.workSheetsOfOppositeType ?? []} />
            <OppositeWorkGroup workGroup={oppositeWorkGroup} />
        </>
    )
};


export default ActivityTooltipContent;