import React, {useEffect, useMemo, useState} from "react";
import Button from "../../../../../../components/Buttons/Button";
import Dialog from "@mui/material/Dialog";
import {Box, Typography} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Clear} from "@mui/icons-material";
import SubmitErrorListener from "../../../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {
    ApiError,
    ResourceType,
    WorkGroupItemType,
    WorkSheetDetails,
    WorkSheetWorkItem
} from "../../../../../../API/types";
import Select from "../../../../../../components/Form/Select";
import {getWorkGroupItemTypeTranslationFromStr} from "../../../../../../utils/enumTranslations";
import ModifierStartAndEndTime from "../../../../../../components/Form/ModifierStartAndEndTime";
import NumberTextField from "../../../../../../components/Form/NumberTextField";
import {workGroupActivityValidationSchema} from "../../../../../../utils/formValidation";
import dayjs from "dayjs";
import {useAppDispatch} from "../../../../../../hooks";
import {SelectOptionWithIdAndTripComment, WorkItemForm} from "../../../types";
import Autocomplete from "../../../../../../components/Form/Autocomplete";
import {SelectOptionWithId, SelectOptionWithIdAndRegion} from "../../../../../../types";
import Route from "../../../../../../components/Form/Route";
import {getCharterTripLabel, getTripLabel} from "../../../utils";
import TextField from "../../../../../../components/Form/TextField";
import {
    canHaveOppositeResource,
    ResourceWorkSheet,
    WorkGroupItemTypeCharterTrip,
    workGroupItemTypesWithComment,
    workGroupItemTypesWithDistance,
    workGroupItemTypesWithOppositeResource,
    WorkGroupItemTypeWithCharterTrip
} from "../../../../../../API/workSheets/types";
import {getUnplannedWorkItems, getWorkSheetsWithResourceOnDate} from "../../../../../../API";
import {setToast} from "../../../../../../store/toastSlice";
import {
    getBusWorkSheetOption,
    getBusWorkSheetOptions,
    getDriverWorkSheetOption,
    getDriverWorkSheetOptions
} from "../../utils";

const emptyValues: WorkItemForm = {
    type: WorkGroupItemType.TRIP_DEFINITION,
    trip: null,
    charterTrip: null,
    startTime: null,
    startTimeIsOnNextDay: false,
    endTime: null,
    endTimeIsOnNextDay: false,
    route: [],
    distance: '',
    comment: '',
    oppositeResource: null,
};

export interface WorkItemDialogData {
    workSheet: WorkSheetDetails;
    workItem?: WorkSheetWorkItem;
}

interface WorkItemDialogProps {
    dialogData: WorkItemDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: WorkItemForm, workItem?: WorkSheetWorkItem) => void;
}

export default function WorkItemDialog({dialogData, handleCloseDialog, handleSave}: WorkItemDialogProps) {
    const dispatch = useAppDispatch();
    const [selectedWorkSheetItem, setSelectedWorkSheetItem] = useState<WorkSheetWorkItem | undefined>(undefined);
    const [workSheetsWithResources, setWorkSheetsWithResources] = useState<ResourceWorkSheet[]>([]);
    const [unplannedWorkItems, setUnplannedWorkItems] = useState<WorkSheetWorkItem[]>([]);

    useEffect(() => {
        getWorkSheetsWithResourceOnDate(dialogData.workItem?.date ?? dialogData.workSheet.startDate)
            .then(setWorkSheetsWithResources)
            .catch(handleError('Sõidulehe valiku leidmisel tekkis viga'));
    }, []);
    useEffect(() => {
        if (dialogData.workSheet.region?.id && dialogData.workItem === undefined) {
            getUnplannedWorkItems(dialogData.workSheet.region.id, dayjs(dialogData.workSheet.startDate), dialogData.workSheet.resourceType)
                .then(setUnplannedWorkItems)
                .catch(handleError('Reiside valiku leidmisel tekkis viga'));
        }
    }, [dialogData]);

    const { workSheet, workItem} = dialogData;

    const date = useMemo(() => dayjs(workSheet.startDate), [workSheet]);

    const handleError = (defaultMessage: string) => (error?: ApiError) => {
        dispatch(setToast({type: 'error', text: error?.message ?? defaultMessage}));
        handleCloseDialog();
    };

    const getOppositeResourceOption = (workItem: WorkSheetWorkItem, resourceType: ResourceType): SelectOptionWithId | null => {
        if (!canHaveOppositeResource(workItem.type)) {
            return null;
        }

        if (resourceType === ResourceType.DRIVER) {
            return getBusWorkSheetOption(workItem);
        } else {
            return getDriverWorkSheetOption(workItem);
        }
    };

    const getTripOption = (workItem?: WorkSheetWorkItem): SelectOptionWithId | null => {
        if (!workItem || !workItem.tripSegmentId) {
            return null;
        }

        return {
            id: workItem.tripSegmentId,
            name: getTripLabel(workItem),
        }
    };

    const getCharterTripOption = (workItem?: WorkSheetWorkItem): SelectOptionWithId | null => {
        if (!workItem || !workItem.charterTrip?.id) {
            return null;
        }

        return {
            id: workItem.charterTrip.id,
            name: getCharterTripLabel(workItem),
        }
    };

    const initialValues: WorkItemForm = workItem ? {
        type: workItem.charterTrip ? WorkGroupItemTypeCharterTrip.CHARTER_TRIP : workItem.type,
        trip: getTripOption(workItem),
        charterTrip: getCharterTripOption(workItem),
        startTime: dayjs(workItem.startDateTime),
        startTimeIsOnNextDay: dayjs(workItem.startDateTime).isAfter(date, 'day'),
        endTime: dayjs(workItem.endDateTime),
        endTimeIsOnNextDay: dayjs(workItem.endDateTime).isAfter(date, 'day'),
        route: workItem.route?.map(point => ({
            geoPoint: point,
            stopName: point.stopName ?? '',
            requestStop: point.requestStop,
            time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
            timeIsOnNextDay: point.timeIsOnNextDay ?? false,
        })) ?? [],
        distance: workItem.distance?.toString() ?? '',
        comment: workItem.comment ?? '',
        oppositeResource: getOppositeResourceOption(workItem, workSheet.resourceType),
    } : emptyValues;

    const handleSubmit = (form: WorkItemForm, formHelpers: FormikHelpers<WorkItemForm>) => {
        formHelpers.setSubmitting(true);
        if (selectedWorkSheetItem) {
            handleSave(form, selectedWorkSheetItem);
        } else {
            handleSave(form, workItem);
        }
        handleCloseDialog();
    };

    const getFilteredTypeOptions = (): WorkGroupItemTypeWithCharterTrip[] => {
        const workGroupActivityTypes = [...Object.values(WorkGroupItemType), ...Object.values(WorkGroupItemTypeCharterTrip)];
        const worksheetIncludesLunchOrDisruption = workSheet.workItems.some(activity =>
            activity.type === WorkGroupItemType.LUNCH_BREAK || activity.type === WorkGroupItemType.DISRUPTION
        );
        return worksheetIncludesLunchOrDisruption && !workItem
            ? workGroupActivityTypes.filter(type => type !== WorkGroupItemType.LUNCH_BREAK && type !== WorkGroupItemType.DISRUPTION)
            : workGroupActivityTypes;
    };

    const tripOptions: SelectOptionWithIdAndTripComment[] = unplannedWorkItems
        .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.trip)
        .map(item => {
            return {
                id: item.tripSegmentId as number,
                name: getTripLabel(item),
                tripComment: item.trip?.comment,
            }
        });

    const charterTripOptions: SelectOptionWithId[] = unplannedWorkItems
        .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.charterTrip)
        .map(item => {
            return {
                id: item.charterTrip?.id as number,
                name: getCharterTripLabel(item),
            }
        });


    if (workItem) {
        tripOptions.push({
            id: workItem.tripSegmentId as number,
            name: getTripLabel(workItem),
            tripComment: workItem.trip?.comment,
        });
    }

    const handleChangeTrip = (formikProps: FormikProps<WorkItemForm>) => (value: SelectOptionWithId | null) => {
        const workSheetItem = unplannedWorkItems.find(item => item.tripSegmentId === value?.id);
        if (workSheetItem && workSheetItem.tripSegmentId) {
            void formikProps.setValues({
                ...formikProps.values,
                trip: getTripOption(workSheetItem),
                route: workSheetItem.route?.map(point => ({
                    geoPoint: point,
                    stopName: point.stopName ?? '',
                    requestStop: point.requestStop,
                    time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
                    timeIsOnNextDay: point.timeIsOnNextDay ?? false,
                })) ?? [],
                startTime: dayjs(workSheetItem.startDateTime),
                startTimeIsOnNextDay: !dayjs(workSheetItem.startDateTime).isSame(date, 'day'),
                endTime: dayjs(workSheetItem.endDateTime),
                endTimeIsOnNextDay: !dayjs(workSheetItem.endDateTime).isSame(date, 'day'),
                distance: workSheetItem.distance?.toString() ?? '',
                comment: '',
                oppositeResource: getOppositeResourceOption(workSheetItem, workSheet.resourceType),
            });
        }
        if (workSheetItem && workSheetItem.id) {
            setSelectedWorkSheetItem(workSheetItem);
        }
    };

    const handleChangeCharterTrip = (formikProps: FormikProps<WorkItemForm>) => (value: SelectOptionWithId | null) => {
        const workSheetItem = unplannedWorkItems.find(item => item.charterTrip?.id === value?.id);
        if (workSheetItem && workSheetItem.charterTrip) {
            void formikProps.setValues({
                ...formikProps.values,
                charterTrip: getCharterTripOption(workSheetItem),
                startTime: dayjs(workSheetItem.startDateTime),
                startTimeIsOnNextDay: !dayjs(workSheetItem.startDateTime).isSame(date, 'day'),
                endTime: dayjs(workSheetItem.endDateTime),
                endTimeIsOnNextDay: !dayjs(workSheetItem.endDateTime).isSame(date, 'day'),
                distance: workSheetItem.distance?.toString() ?? '',
                comment: '',
                oppositeResource: getOppositeResourceOption(workSheetItem, workSheet.resourceType),
            });
        }
        if (workSheetItem && workSheetItem.id) {
            setSelectedWorkSheetItem(workSheetItem);
        }
    };

    const handleChangeType = (formikProps: FormikProps<WorkItemForm>) => (value: WorkGroupItemTypeWithCharterTrip) => {
        void formikProps.setValues({
            ...formikProps.values,
            type: value,
            trip: value === WorkGroupItemType.TRIP_DEFINITION ? (formikProps.values.trip ?? null) : null,
            charterTrip: value === WorkGroupItemTypeCharterTrip.CHARTER_TRIP ? (formikProps.values.charterTrip ?? null) : null,
            route: value === WorkGroupItemType.TRIP_DEFINITION ? formikProps.values.route : [],
            distance: workGroupItemTypesWithDistance.includes(value) ? formikProps.values.distance : '',
            comment: workGroupItemTypesWithComment.includes(value) ? formikProps.values.comment : '',
        });
    };
    const getSecondaryResourceOptions = (workItem: WorkSheetWorkItem | undefined): SelectOptionWithIdAndRegion[] => {
        const preferredRegionName = workSheet.region?.name;
        if (workSheet.resourceType === ResourceType.DRIVER) {
            return getBusWorkSheetOptions(workSheetsWithResources, getBusWorkSheetOption(workItem), preferredRegionName);
        } else {
            return getDriverWorkSheetOptions(workSheetsWithResources, getDriverWorkSheetOption(workItem), preferredRegionName);
        }
    };

    const renderTripComment = (formikProps: FormikProps<WorkItemForm>) => {
        const tripComment = tripOptions.find(option => option.id === formikProps.values.trip?.id)?.tripComment;

        return tripComment
            ? <Typography variant="body2" color="text.secondary" pb={0.5}>Reisi kommentaar: {tripComment}</Typography>
            : <></>;
    };

    const groupByFunc = (option: SelectOptionWithIdAndRegion) => option.regionName;

    return (
        <Dialog open={true} onClose={handleCloseDialog}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">{workItem ? 'Tegevuse muutmine' : 'Uus tegevus'}</Typography>
                </DialogTitle>
                <Formik initialValues={initialValues}
                        validationSchema={workGroupActivityValidationSchema}
                        onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<WorkItemForm>) =>
                        <Form>
                            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                <Select name="type"
                                        label="Tegevuse tüüp"
                                        options={getFilteredTypeOptions()}
                                        translationFunction={getWorkGroupItemTypeTranslationFromStr}
                                        translationEnumType={WorkGroupItemType}
                                        onChange={handleChangeType(formikProps)}
                                        disabled={!!workItem}
                                />
                                {formikProps.values.type === WorkGroupItemType.TRIP_DEFINITION &&
                                    <>
                                        <Autocomplete
                                            name="trip"
                                            label="Reis"
                                            options={tripOptions}
                                            onChange={handleChangeTrip(formikProps)}
                                            getOptionLabel={(option) => option.name}
                                            disabled={!!workItem}
                                        />
                                        {renderTripComment(formikProps)}
                                        <Route
                                            name="route"
                                            values={formikProps.values.route}
                                        />
                                    </>
                                }
                                {formikProps.values.type === WorkGroupItemTypeCharterTrip.CHARTER_TRIP &&
                                    <Autocomplete
                                        name="charterTrip"
                                        label="Tellimusvedu"
                                        options={charterTripOptions}
                                        onChange={handleChangeCharterTrip(formikProps)}
                                        getOptionLabel={(option) => option.name}
                                        disabled={!!workItem}
                                    />
                                }
                                <ModifierStartAndEndTime />
                                {workGroupItemTypesWithDistance.includes(formikProps.values.type) &&
                                    <NumberTextField name="distance" label="Pikkus (km)" decimals={3} />
                                }
                                {workGroupItemTypesWithOppositeResource.includes(formikProps.values.type) &&
                                    <Autocomplete
                                        name="oppositeResource"
                                        label={workSheet.resourceType === ResourceType.DRIVER ? 'Buss' :'Juht'}
                                        options={getSecondaryResourceOptions(workItem)}
                                        groupBy={groupByFunc}
                                        getOptionLabel={option => option.name}
                                    />
                                }
                                {workGroupItemTypesWithComment.includes(formikProps.values.type) &&
                                    <TextField
                                        name="comment"
                                        label="Kommentaar"
                                        multiline
                                        minRows={2}
                                        maxRows={5}
                                        required={false}
                                    />
                                }
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        text="Loobu"
                                        color="secondary"
                                        startIcon={<Clear />}
                                        onClick={handleCloseDialog}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit" />
                                </Box>
                            </DialogActions>
                            <SubmitErrorListener
                                isValid={formikProps.isValid}
                                isValidating={formikProps.isValidating}
                                isSubmitting={formikProps.isSubmitting}
                            />
                        </Form>
                    }
                </Formik>
            </Box>
        </Dialog>
    );
}
