import React, {useEffect, useMemo, useState} from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';
import {useField} from 'formik';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/et';
import {useTheme} from '@mui/material';


dayjs.locale('et');

interface DatePickerProps extends Omit<MuiDatePickerProps<Date>, 'name' | 'value' | 'onChange' | 'views' | 'minDate' | 'maxDate'> {
    name: string;
    label: string;
    hideDay?: boolean;
    hideYear?: boolean;
    hasCopiedContent?: boolean;
    minDate?: Dayjs;
    maxDate?: Dayjs;
    disabled?: boolean;
    helperText?: string;
}

const DatePicker = ({ name, label, hideDay, hideYear, hasCopiedContent, minDate, maxDate, disabled, helperText }: DatePickerProps) => {
    const [field, meta, helpers] = useField<Date | null>(name);
    const [value, setValue] = useState<Dayjs | null>(field.value ? dayjs(field.value) : null);
    const theme = useTheme();

    useEffect(() => {
        if (hasCopiedContent && field.value !== value?.toDate()) {
            setValue(field.value ? dayjs(field.value) : null);
        }
    }, [field.value]);

    const color = useMemo(() =>
        disabled ? theme.palette.text.disabled
            : meta.touched && !!meta.error ? theme.palette.error.main
                : theme.palette.text.secondary, [disabled, meta.touched, meta.error]);

    const handleChange = (date: Dayjs | null) => {
        setValue(date);
        helpers.setValue(date ? date.toDate() : null);
    };

    const getHelperText = (): string | undefined => {
        if (meta.touched && meta.error) {
            return meta.error;
        } else {
            return helperText;
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
            <MuiDatePicker
                views={hideDay ? ['month', 'year'] : hideYear ? ['day', 'month'] : undefined}
                label={label}
                value={value}
                onChange={(newValue: Dayjs | null) => handleChange(newValue)}
                format={hideDay ? 'MM.YYYY' : hideYear ? 'DD.MM' : 'DD.MM.YYYY'}
                slotProps={{
                    textField: {
                        size: 'small',
                        error: meta.touched && !!meta.error,
                        helperText: getHelperText(),
                        onBlur: () => helpers.setTouched(true),
                        inputProps: {
                            sx: {
                                color: value?.isValid() ? '' : theme.palette.text.secondary,
                            },
                        },
                        InputLabelProps: {sx: {color: color}},
                        FormHelperTextProps: {sx: {color: color}},
                    },
                    popper: {
                        sx: {
                            '& .MuiPickersDay-root': {'&.Mui-selected': {backgroundColor: theme.palette.primary.main}},
                            '& .MuiPickersYear-yearButton': {'&.Mui-selected': {backgroundColor: theme.palette.primary.main}},
                            '& .MuiPickersPopper-paper': {pr: 1},
                        }
                    }
                }}
                sx={{my: 1, '& .MuiInputLabel-root': {color: color}}}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
            />
        </LocalizationProvider>
    );
};

export default DatePicker;
