import {DEFAULT_PLANNER_DURATION, DEFAULT_PLANNER_START_TIME, MIN_PLANNER_DURATION} from "../constants";
import {HOURS_IN_A_DAY, MINUTES_IN_AN_HOUR} from "../../../constants";
import {PlannerTimeline, StartAndEndTimeWithModifier} from "../types";
import {getTimeInHours} from "./utils";


export const generateDisplayedHourStrings = (startTime: number, duration: number): string[] => {
    const timeStrings: string[] = [];

    for (let i = 0; i < duration; i++) {
        timeStrings.push(`${(Math.floor(startTime + i) % HOURS_IN_A_DAY).toString().padStart(2, '0')}:00`);
    }

    return timeStrings;
};

export const getTimelineMinWidth = (duration: number) => duration * MINUTES_IN_AN_HOUR;

export const getPlannerTimeline = (allItems: StartAndEndTimeWithModifier[]): PlannerTimeline => {
    if (!allItems.length) {
        return {
            startTime: DEFAULT_PLANNER_START_TIME,
            duration: DEFAULT_PLANNER_DURATION,
            displayedHours: generateDisplayedHourStrings(DEFAULT_PLANNER_START_TIME, DEFAULT_PLANNER_DURATION),
            minWidth: getTimelineMinWidth(DEFAULT_PLANNER_DURATION),
        };
    }

    let minStartHh = DEFAULT_PLANNER_DURATION;
    let maxEndHh = 0;
    allItems.forEach(trip => {
        const startHh = getTimeInHours(trip.startTime, trip.startTimeIsOnNextDay);
        const endHh = getTimeInHours(trip.endTime, trip.endTimeIsOnNextDay);

        if (startHh < minStartHh) minStartHh = startHh;
        if (endHh > maxEndHh) maxEndHh = endHh;
    });

    minStartHh = Math.floor(minStartHh);
    maxEndHh = Math.ceil(maxEndHh);

    const durationOfTrips = maxEndHh - minStartHh;
    if (durationOfTrips < MIN_PLANNER_DURATION) {
        const timePadding = Math.round((MIN_PLANNER_DURATION - durationOfTrips) / 2);
        minStartHh -= timePadding;
        maxEndHh += timePadding;
    }

    const duration = maxEndHh - minStartHh;

    return {
        startTime: minStartHh,
        duration: duration,
        displayedHours: generateDisplayedHourStrings(minStartHh, duration),
        minWidth: getTimelineMinWidth(duration),
    };
};
