import React, {useEffect, useMemo, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {Bus} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {filterByActive, filterByRegionsForUser, filterByValue, getRegionsDisplayStr,} from "../../../../utils/utils";
import theme from "../../../../theme";
import {getFuelTranslationsDisplayStr} from '../../../../utils/enumTranslations';
import {ActiveToggleButtonType, activeToggleButtonValues, Permission} from "../../../../types";
import DataGrid from "../../../../components/DataGrid";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import routes from "../../../../routes";
import {useNavigate, useOutletContext} from "react-router-dom";
import {loadBuses} from "../../../../API";
import {setToast} from "../../../../store/toastSlice";
import {selectAllRegions} from "../../../../store/regionSlice";
import {getDisplayDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink";
import NavIcon from "../../../../components/Icons/NavIcon";
import StaticIcon from "../../../../components/Icons/StaticIcon";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import {useAuth} from "../../../../contexts/AuthContext";
import ListView from "../../../../layouts/ListViewWrapper";


interface DisplayBus extends Bus {
    regions: string;
    fuels: string;
}

export default function Buses() {
    const navigate = useNavigate();
    const { authenticatedUser } = useAuth();
    const regions = useAppSelector(selectAllRegions);
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [buses, setBuses] = useState<Bus[] | undefined>(undefined);
    const [toggleButtonValue, setToggleButtonValue] = useState<ActiveToggleButtonType>('AKTIIVSED');
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (authenticatedUser) {
            loadBuses()
                .then(result => {
                    setBuses(filterByRegionsForUser(result, authenticatedUser));
                })
                .catch(() => dispatch(setToast({type: 'error', text: 'Busside pärimisel tekkis viga'})));
        }
    }, [authenticatedUser]);

    const rows: DisplayBus[] | undefined = useMemo(() => buses?.map(bus => ({
        ...bus,
        fuels: getFuelTranslationsDisplayStr(bus.fuelTypes),
        regions: getRegionsDisplayStr(bus.regionIds, regions)
    })), [buses, regions]);

    const handleToggleButtonChange = (value: string) => {
        if (activeToggleButtonValues.includes(value as ActiveToggleButtonType)) setToggleButtonValue(value as ActiveToggleButtonType);
    };

    const getFilteredRows = (rows: Bus[]) => {
        return filterByActive(filterByValue(rows, searchInput), toggleButtonValue);
    };

    const columns: GridColDef[] = [
        {
            field: 'licencePlateNumber',
            headerName: 'Reg-nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.licencePlateNumber} route={routes.AUTHENTICATED.BUSES.EDIT} navPermission={Permission.UpdateBuses} />
        },
        {
            field: 'make',
            headerName: 'Mark',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'model',
            headerName: 'Mudel',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'type',
            headerName: 'Masinmark',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'registrationDate',
            headerName: 'Esmaregistreerimine',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 120 : 180,
            minWidth: 100,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'seatingCapacity',
            headerName: 'Istekohad',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 60 : 100,
        },
        {
            field: 'standingCapacity',
            headerName: 'Seisukohad',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 70 : 120,
        },
        {
            field: 'fuels',
            headerName: 'Kütuseliik',
            sortable: false,
            filterable: false,
            width: isScreenSmall ? 120 : 200,
        },
        {
            field: 'regions',
            headerName: 'Piirkonnad',
            sortable: false,
            filterable: false,
            width: isScreenSmall ? 150 : 200,
            minWidth: 100
        },
        {
            field: 'active',
            headerName: 'Aktiivne',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            flex: 1,
            minWidth: 50,
            renderCell: (params) =>
                <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.BUSES.EDIT} navPermission={Permission.UpdateBuses} />
        }
    ];

    return (
        <ListView
            headerProps={{
                title: "Bussid",
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {
                    title: 'Lisa buss',
                    onClick: () => navigate(routes.AUTHENTICATED.BUSES.ADD),
                    permission: Permission.UpdateBuses
                },
                toggleProps: {
                    options: activeToggleButtonValues.map(value => value.toString()),
                    value: toggleButtonValue,
                    handleChange: handleToggleButtonChange
                },
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
        </ListView>
    );
}
