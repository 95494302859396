import axios from "axios";
import {Defect, DefectSimple, SaveDefectComment, SaveDefectRequest} from "./types";
import {handleError} from "../utils";
import {Comment} from "../../components/Comments/types";
import {Dayjs} from "dayjs";


export const loadDefects = () =>
    axios.get<Defect[]>('/api/defects')
        .then(result => result.data)
        .catch(handleError);

export const getDefect = (id: number | string) =>
    axios.get<Defect>(`/api/defects/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const createDefect = (request: SaveDefectRequest) =>
    axios.post<Defect>(`/api/defects`, request)
        .then(result => result.data)
        .catch(handleError);

export const updateDefect = (id: number | string, request: SaveDefectRequest) =>
    axios.put(`/api/defects/${id}`, request)
        .then(result => result.data)
        .catch(handleError);

export const createDefectComment = (request: SaveDefectComment) =>
    axios.post<Comment>(`/api/defects/comments`, request)
        .then(result => result.data)
        .catch(handleError);

export const updateDefectComment = (id: number | string, request: SaveDefectComment) =>
    axios.put(`/api/defects/comments/${id}`, request)
        .then(result => result.data)
        .catch(handleError);

export const deleteDefectComment = (id: number | string) =>
    axios.delete(`/api/defects/comments/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const getDefectsByRegionAndDateRange = async (regionId: string | number, from: Dayjs, to?: Dayjs) =>
    axios.get<DefectSimple[]>(`/api/regions/${regionId}/defects?from=${from.format('YYYY-MM-DD')}&to=${(to ?? from).format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);
