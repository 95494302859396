import dayjs from "dayjs";
import {CalendarEntry, RepetitionRules, ScheduleSetting} from "../API/types";
import {Dayjsable, isOnDay} from "./dateUtils";


export interface Repeatable {
    validFrom: Dayjsable | null,
    validTo?: Dayjsable | null,
    startDate?: string | null,
    endDate?: string | null,
    repetition: RepetitionRules,
}

export const appliesOnDate = (
    item: Repeatable,
    date: Dayjsable,
    calendarEntries: CalendarEntry[],
): boolean => {
    const day = dayjs(date);

    if (!isOnDay(day, item.validFrom, item.validTo)) {
        return false;
    }

    if (item.startDate && item.endDate) {
        const yearIndependentString = day.format('MM-DD');
        const seasonalStartDateString = dayjs(item.startDate).format('MM-DD');
        const seasonalEndDateString = dayjs(item.endDate).format('MM-DD');
        if (seasonalStartDateString.localeCompare(seasonalEndDateString) > 0) { // If seasonal period includes year change
            // Date is before startDate or after endDate
            if (!(yearIndependentString.localeCompare(seasonalEndDateString) <= 0 || yearIndependentString.localeCompare(seasonalStartDateString) >= 0)) {
                return false;
            }
        } else {
            // Date is after startDate and before endDate
            if (!(yearIndependentString.localeCompare(seasonalStartDateString) >= 0 && yearIndependentString.localeCompare(seasonalEndDateString) <= 0)) {
                return false;
            }
        }
    }

    const applicableCalendarEntries = calendarEntries.filter(entry => isOnDay(day, entry.validFrom, entry.validTo));

    const hasAnyExclusions = Object.entries(item.repetition.scheduleSettings)
        .some(([type, setting]) =>
            applicableCalendarEntries.some(
                calendarEntry => calendarEntry.type === type && setting === ScheduleSetting.Exclude
            )
        );

    if (hasAnyExclusions) return false;

    const hasAnyOnly = Object.values(item.repetition.scheduleSettings)
        .some(setting => setting === ScheduleSetting.Only);
    const hasAnyOnlyOnDay = Object.entries(item.repetition.scheduleSettings)
        .some(([type, setting]) =>
            applicableCalendarEntries.some(
                calendarEntry => calendarEntry.type === type && setting === ScheduleSetting.Only
            )
        );

    if (hasAnyOnly && !hasAnyOnlyOnDay) return false;

    const hasAnyInAdditions = Object.entries(item.repetition.scheduleSettings)
        .some(([type, setting]) =>
            applicableCalendarEntries.some(
                (entry) => entry.type === type && setting === ScheduleSetting.InAddition
            )
        );
    if (hasAnyInAdditions) return true;

    switch (day.day()) {
        case 0: return item.repetition.sun;
        case 1: return item.repetition.mon;
        case 2: return item.repetition.tue;
        case 3: return item.repetition.wed;
        case 4: return item.repetition.thu;
        case 5: return item.repetition.fri;
        case 6: return item.repetition.sat;
    }

    return false;
};