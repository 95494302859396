import {WorkSheetDetails as ApiWorkSheetDetails, WorkSheetWorkItem} from "../../../../../API/workSheets/types";
import {ResourceType} from "../../../../../API/workGroup/types";
import {useAppSelector} from "../../../../../hooks";
import {selectResourceNameByType} from "../../store/selectors";
import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../../../../routes";
import React from "react";


const SecondaryResourceName = ({workItem, secondaryWorkSheets, secondaryResourceType}: {
    workItem: WorkSheetWorkItem,
    secondaryWorkSheets: ApiWorkSheetDetails[],
    secondaryResourceType: ResourceType,
}) => {
    const secondaryWorkSheetId = secondaryResourceType === ResourceType.DRIVER
        ? workItem.driverWorkSheetId
        : workItem.busWorkSheetId;
    const secondaryResourceId = secondaryWorkSheets
        .find(sws => sws.id === secondaryWorkSheetId)?.resourceId ?? 0;
    const resourceName = useAppSelector(
        state => selectResourceNameByType(state, secondaryResourceId, secondaryResourceType)
    );

    if (secondaryWorkSheetId) {
        return <Link
            component={RouterLink}
            to={routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', secondaryWorkSheetId.toString())}>
            {resourceName ? resourceName : ''}
        </Link>;
    }

    return <>{resourceName ? resourceName : ''}</>;
};

export default SecondaryResourceName;