import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState
} from "@reduxjs/toolkit";
import {Bus} from "../API/types";
import {loadBuses} from "../API";
import {RootState} from "../store";
import {AuthenticatedUser} from "../types";
import {filterByRegionsForUser} from "../utils/utils";

export const busAdapter = createEntityAdapter<Bus>({
    sortComparer: (a, b) => a.licencePlateNumber.localeCompare(b.licencePlateNumber),
});

export const fetchBuses = createAsyncThunk(
    'buses/getAll',
    () => {
        return loadBuses();
    }
);

const initialState: EntityState<Bus> = busAdapter.getInitialState();

export const busSlice = createSlice({
    name: 'buses',
    initialState: initialState,
    reducers: {
        setBuses: busAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchBuses.fulfilled, (state, action) => {
            if (action.payload) {
                busAdapter.setAll(state, action.payload);
            }
        });
    }
});
export const {
    setBuses,
} = busSlice.actions;

export const {
    selectIds: selectBusIds,
    selectAll: selectAllBuses,
    selectById: selectBusById,
} = busAdapter.getSelectors<RootState>(state => state.buses);

export const selectActiveBusSelectOptions = createSelector(
    selectAllBuses,
    (_: RootState, user: AuthenticatedUser | null) => user,
    (buses, user) => {
        const userFilteredBuses = user ? filterByRegionsForUser(buses, user) : buses;
        return userFilteredBuses.filter(bus => bus.active).map(bus => ({id: bus.id, name: bus.licencePlateNumber}))
    }
);

export default busSlice.reducer;
