import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/et';
import TimePicker from "../TimePicker";
import {Box, Divider} from "@mui/material";
import {PlusOne} from "@mui/icons-material";
import Checkbox from "../Checkbox";


dayjs.locale('et');

interface TimePickerProps {
    timeName: string;
    modifierName: string;
    label: string;
    disabled?: boolean;
}

const TimePickerWithModifier = ({ timeName, modifierName, label, disabled }: TimePickerProps) => {
    return (
        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'relative', width: '100%', minWidth: 160}}>
            <TimePicker
                name={timeName}
                label={label}
                sx={{width: '100%', '.MuiInputAdornment-positionEnd': {position: 'absolute', right: 76}}}
                disabled={disabled}
            />
            <Box sx={{position: 'absolute', right: -8, top: 8, display: 'flex'}}>
                <Divider orientation="vertical" variant="middle" flexItem sx={{mx: 0.5}} />
                <Checkbox
                    name={modifierName}
                    labelIcon={<PlusOne />}
                    label="Järgmisel päeval"
                    sx={{mr: -1}}
                    disabled={disabled}
                />
            </Box>
        </Box>
    );
};

export default TimePickerWithModifier;