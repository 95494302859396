import {createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState} from "@reduxjs/toolkit";
import {TransportContract} from "../API/types";
import {loadTransportContracts} from "../API";
import {AppDispatch, RootState} from "../store";
import {setToast} from "./toastSlice";
import {mapErrors} from "../utils/errorMapping";
import {AuthenticatedUser} from "../types";
import {filterByRegionNameForUser} from "../utils/utils";


const transportContractAdapter = createEntityAdapter<TransportContract>({
    sortComparer: (a, b) => a.contractId.localeCompare(b.contractId),
});

export const fetchTransportContracts = createAsyncThunk(
    'transportContracts/getAll',
    async (_, { dispatch }) => {
        return loadTransportContracts()
            .then(result => {
                return result;
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Veolepingute laadimisel tekkis viga'}));
                throw Error;
            });
    }
);

export const fetchTransportContractsByUser = createAsyncThunk<
    TransportContract[],
    AuthenticatedUser | null | undefined,
    {dispatch: AppDispatch}
>(
    'transportContracts/getAll',
    async (authenticatedUser, { dispatch }) => {
        if (!authenticatedUser) throw Error;

        return loadTransportContracts()
            .then(result => {
                return filterByRegionNameForUser(result, authenticatedUser);
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Veolepingute laadimisel tekkis viga'}));
                throw Error;
            });
    }
);

const initialState: EntityState<TransportContract> = transportContractAdapter.getInitialState();

export const transportContractSlice = createSlice({
    name: 'transportContracts',
    initialState: initialState,
    reducers: {
        setTransportContract: (state, action) => {
            transportContractAdapter.upsertOne(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransportContracts.fulfilled, (state, action) => {
            transportContractAdapter.setAll(state, action.payload);
        });
    },
});

export const {
    setTransportContract,
} = transportContractSlice.actions;

export const {
    selectAll: selectAllTransportContracts,
    selectById: selectTransportContractById,
} = transportContractAdapter.getSelectors<RootState>(state => state.transportContracts);

export const selectProfitCenterOptions = createSelector(
    selectAllTransportContracts,
    (transportContracts) => {
        const profitCenterOptions: string[] = [];

        transportContracts.forEach(transportContract => {
            if (!profitCenterOptions.includes(transportContract.profitCenter)) {
                profitCenterOptions.push(transportContract.profitCenter);
            }
        })

        return profitCenterOptions.sort((a,b) => a.localeCompare(b));
    }
);

export default transportContractSlice.reducer;
