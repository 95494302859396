import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction
} from "@reduxjs/toolkit";
import {Region} from "../API/types";
import {loadRegions} from "../API";
import {RootState} from "../store";
import {setToast} from "./toastSlice";

const regionsAdapter = createEntityAdapter<Region>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const fetchAllRegions = createAsyncThunk(
    'regions/fetchAll',
    async (_, { dispatch }) => {
        try {
            const regions = (await loadRegions()).sort((a, b) => a.name.localeCompare(b.name));
            const storedRegionName: string | null = localStorage.getItem('region');
            const selected = storedRegionName ? regions.find(region => region.name === storedRegionName) ?? regions[0] : regions[0];

            return {
                regions,
                selected,
            }
        } catch(e) {
            dispatch(setToast({
                type: 'error',
                text: 'Piirkondade pärimisel ilmnes viga',
            }));

            throw e;
        }
    }
);

interface RegionState {
    selected: number;
    regions: EntityState<Region>;
}

const initialState: RegionState  = {
    selected: 0,
    regions: regionsAdapter.getInitialState(),
};

export const regionSlice = createSlice({
    name: 'regions',
    initialState: initialState,
    reducers: {
        setSelectedRegion: (state, action: PayloadAction<Region>) => {
            localStorage.setItem('region', action.payload.name);
            state.selected = action.payload.id
        },
        setRegions: (state, action) => {
            if (action.payload) {
                regionsAdapter.setAll(state.regions, action.payload);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllRegions.fulfilled, (state, action) => {
            regionsAdapter.setAll(state.regions, action.payload.regions);
            state.selected = action.payload.selected.id;
        });
    },
});
export const {
    setRegions,
    setSelectedRegion,
} = regionSlice.actions;

export const {
    selectAll: selectAllRegions,
    selectById: selectRegionById,
} = regionsAdapter.getSelectors<RootState>(state => state.region.regions);

export const selectSelectedRegion = createSelector(
    selectAllRegions,
    (state: RootState) => state.region.selected,
    (regions, selectedId) => regions.find(region => region.id === selectedId),
);

export const selectRegion = createSelector(
    selectAllRegions,
    (_: RootState, regionId?: number) => regionId,
    (regions, regionId) => regionId ? regions.find(region => region.id === regionId) : undefined,
);

export default regionSlice.reducer;
