import React, {useMemo} from 'react';
import {getActivityCardColor} from "../../../../../utils/colorUtils";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks";
import {EntityId} from "@reduxjs/toolkit";
import {
    deleteWorkSheetActivity,
    removeActivityFromWorkGroup,
    selectPlannerType,
    setDialogData,
    updateActivityWorkSheet
} from "../../../../../../../store/plannerSlice";
import {DisplayWorkGroupActivity, DisplayWorkItem, PlannerType} from '../../../../../types';
import {getWorkGroupItemTypeTranslation,} from "../../../../../../../utils/enumTranslations";
import ActivityTooltipContent from "../../../components/Tooltip/ActivityTooltipContent";
import ItemCard from "../../../index";
import OppositeGroupMarker from "../../../components/OppositeGroupMarker";
import ActionIcon from "../../../../../../Icons/ActionIcon";
import AddOppositeGroupButton from "../../../components/Tooltip/AddOppositeGroupButton";
import {openAddOppositeGroupDialog} from "../../../../../store/actions";
import {canHaveOppositeResource} from "../../../../../../../API/workSheets/types";


interface ActivityCardProps {
    activity: DisplayWorkGroupActivity | DisplayWorkItem;
    currentGroupId: EntityId;
    isRowDisabled?: boolean;
    groupOfOppositeTypeId?: number;
}

const ActivityCard = ({activity, currentGroupId, isRowDisabled = false, groupOfOppositeTypeId}: ActivityCardProps) => {
    const dispatch = useAppDispatch();
    const plannerType = useAppSelector(selectPlannerType);

    const onDrop = useMemo(() => {
        if (plannerType === PlannerType.WORK_SHEET) {
            return (itemId: number, targetGroupId: number) => {
                dispatch(updateActivityWorkSheet({
                    movedActivityId: itemId,
                    originWorkSheetId: currentGroupId,
                    targetWorkSheetId: targetGroupId
                }));
            }
        }
        return undefined;
    }, [plannerType, activity, currentGroupId]);

    const handleAddOpposite = () => {
        dispatch(openAddOppositeGroupDialog(currentGroupId, activity.id, activity.type));
    };

    const handleEdit = () => {
        dispatch(setDialogData({
            editActivity: {
                groupId: currentGroupId,
                activityId: activity.id,
                activity: {
                    type: activity.type,
                    startTime: activity.startTime,
                    startTimeIsOnNextDay: activity.startTimeIsOnNextDay,
                    endTime: activity.endTime,
                    endTimeIsOnNextDay: activity.endTimeIsOnNextDay,
                    distance: activity.distance?.toString() ?? '',
                    comment: activity.comment,
                }
            }
        }))
    };

    const handleDelete = () => {
        if (plannerType === PlannerType.WORK_GROUP) {
            dispatch(removeActivityFromWorkGroup({activityId: activity.id, groupId: currentGroupId}));
        }
        if (plannerType === PlannerType.WORK_SHEET) {
            dispatch(deleteWorkSheetActivity({activityId: activity.id, groupId: currentGroupId}));
        }
    };

    return (
        <ItemCard
            onDrop={onDrop}
            isRowDisabled={isRowDisabled}
            tooltipContent={<ActivityTooltipContent activity={activity} />}
            tooltipActions={
                <>
                    {canHaveOppositeResource(activity.type) &&
                        <AddOppositeGroupButton
                            currentGroupId={currentGroupId}
                            oppositeGroupId={groupOfOppositeTypeId}
                            onClick={handleAddOpposite}
                        />
                    }
                    <ActionIcon type="EDIT" id={activity.id} handleClick={handleEdit} color="common.white" />
                    <ActionIcon type="DELETE" id={activity.id} handleClick={handleDelete} color="common.white" />
                </>
            }
            color={getActivityCardColor(activity.type)}
            title={getWorkGroupItemTypeTranslation(activity.type)}
            currentGroupId={currentGroupId}
            groupOfOppositeTypeId={groupOfOppositeTypeId}
            item={{...activity}}
            oppositeGroupMarker={activity.width > 10 &&
                <OppositeGroupMarker
                    itemId={activity.id}
                    type={activity.type}
                    isRowDisabled={isRowDisabled}
                    currentGroupId={currentGroupId}
                    groupOfOppositeTypeId={groupOfOppositeTypeId}
                />
            }
        />
    );
};

export default ActivityCard;
