export { Fuel } from './bus/types';
export type { Bus, BusDetails, UpdateBusRequest, FuelCard, OdometerReading, OdometerReadingRequest, OdometerReadingWithBusId} from './bus/types';

export { Contract } from './driver/types';
export type { Driver, DriverContract, UpdateDriverContractRequest, UpdateDriverRequest } from './driver/types';

export { CalendarEntryType } from './calendar/types';
export type { CalendarEntry, AddCalendarEntryRequest } from './calendar/types';

export type {Region} from './region/types';

export type {
    TripDefinition,
    SaveTripDefinitionRequest,
    CopyTripDefinitionRequest,
    CopyTripDefinitionResponse,
    ChangedWorkGroupVersion,
    ActivityChanges,
    EndTripRequest,
    EndTripResponse,
} from './tripDefinition/types';
export type { CharterTrip, SaveCharterTrip, CharterOrder } from './charterTrip/types';
export { CharterTripStatus } from './charterTrip/types';

export { WorkScheduleItemType, WorkScheduleItemStatus } from './workSchedule/types';
export type {
    UnscheduledWorkGroup,
    SaveWorkScheduleItem,
    DriverPosition,
} from './workSchedule/types';

export { ResourceType, WorkGroupItemType, WORK_GROUP_ITEM_TYPES } from './workGroup/types';
export type {
    WorkGroup,
    WorkGroupVersion,
    WorkGroupActivity,
    WorkGroupTripDefinition,
    DisplayWorkGroup,
    MoveWorkGroupItem,
    CopyOppositeWorkGroupRequest,
} from './workGroup/types';

export type {
    WorkSheet,
    SaveWorkSheet,
    GenerateWorkSheetRequest,
    WorkSheetWorkItem,
    WorkSheetDetails,
    SaveWorkItem,
    DriverWorkSheetRecord,
} from './workSheets/types';

export { DefectPriority, DefectStatus } from './defects/types';
export type { Defect } from './defects/types';

export type { User, UserPermissions } from "./users/types"

export type { TransportContract, UpdateTransportContractRequest} from './transportContracts/types';

export interface GeoPoint {
    id?: number;
    name: string;
    stopCode?: string;
    stopArea?: string;
    stopDescription?: string;
    authority?: string;
    archived?: string;
}

export interface RoutePoint {
    id: number;
    name: string;
    stopCode?: string;
    stopArea?: string;
    stopDescription?: string;
    authority?: string;
    order: number;
    stopName: string | null;
    requestStop: boolean;
    time?: string;
    timeIsOnNextDay?: boolean;
}

export interface RepetitionRules {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
    scheduleSettings: {
        SCHOOL_HOLIDAY?: ScheduleSetting;
        PUBLIC_HOLIDAY?: ScheduleSetting;
        SUMMER_HOLIDAY?: ScheduleSetting;
    },
}

export type {BusReport, BusStopUsagesReport, OverUnderTimeReport, TripReport} from "././reports/types";

export enum ScheduleSetting {
    NoEffect = 'NoEffect',
    Exclude = 'Exclude',
    Only = 'Only',
    InAddition = 'InAddition',
}

export interface TripRoutePointRequest extends Omit<RoutePointRequest, 'order'> {}

export interface RoutePointRequest extends Omit<RoutePoint, 'id'> {
    id?: number;
}

export class ApiError {
    errorCode?: string;
    message?: string;
    context?: { [key in ErrorContextKey]: string };
}

enum ErrorContextKey {
    WorkGroupCode = 'WorkGroupCode',
    WorkGroupItemCode = 'WorkGroupItemCode',
    TripDefinitionCode = 'TripDefinitionCode',
    WorkScheduleItemId = 'WorkScheduleItemId',
    Date = 'Date',
    Id = 'Id',
    Name = 'Name',
}
