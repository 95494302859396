import React, {ReactNode} from "react";
import {Alert, AlertColor} from "@mui/material";

interface ErrorWrapperProps {
    error?: string;
    severity?: AlertColor;
    children: ReactNode;
}

const ErrorWrapper = ({error, severity, children}: ErrorWrapperProps) => {
    if (error) {
        return <Alert severity={severity ?? 'error'}>{error}</Alert>
    }

    return children;
};

export default ErrorWrapper;