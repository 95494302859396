import {useNavigate} from "react-router-dom";
import React from "react";
import {Box, Link} from "@mui/material";
import {Permission} from "../../types";
import Visible from "../Visible";

interface NavigationProps {
    id: string;
    value: string;
    displayValue?: string;
    route: string;
    navPermission?: Permission;
    openInNewWindow?: boolean;
    color?: string;
    textDecoration?: boolean;
}

const NavLink = ({id, value, displayValue, route, navPermission, openInNewWindow, color, textDecoration = false}: NavigationProps) => {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (openInNewWindow || event.ctrlKey || event.metaKey) {
            window.open(route.replace(':id', id), '_blank');
        } else {
            navigate(route.replace(':id', id));
        }
    };

    return (
        <Visible permission={navPermission} fallback={<Box>{displayValue ?? value}</Box>}>
            <Link onClick={handleClick} sx={{cursor: 'pointer', textDecoration: textDecoration ? '' : 'none', color: color ?? ''}}>
                {displayValue ?? value}
            </Link>
        </Visible>
    );
};

export default NavLink;
