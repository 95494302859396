import React, {ReactNode} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";


export interface ChangeSection {
    summary: ReactNode;
    details: ReactNode[];
}

const PlannedChangesSection = ({summary, details}: ChangeSection) => {
    return (
        <Accordion sx={{
            border: '1px solid #ebebeb',
            boxShadow: 'none',
            '.Mui-expanded': {m: 0},
            '&.Mui-expanded': {m: 0},
            '&::before': {display: 'none'}
        }}>
            <AccordionSummary sx={{
                '&.Mui-expanded': {minHeight: '48px'},
                '.Mui-expanded': {m: 0}}
            } expandIcon={<ExpandMore />}>
                <Typography>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{pt: 0, pb: 1}}>
                {details}
            </AccordionDetails>
        </Accordion>
    );
};

export default PlannedChangesSection;