import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {GeoPoint} from "../API/types";
import {loadGeoPoints} from "../API";
import {AppDispatch, RootState} from "../store";
import {setToast} from "./toastSlice";

export const geoPointEntityAdapter = createEntityAdapter<GeoPoint>({
    selectId: (geoPoint: GeoPoint) => geoPoint.id ?? geoPoint.name,
});

export const ensureGeoPoints = createAsyncThunk<
    void,
    void,
    {
        state: RootState,
        dispatch: AppDispatch
    }
>(
    'geoPoints/ensureAll',
    (_, { dispatch, getState }) => {
        if (selectTotalGeoPoints(getState()) === 0) {
            dispatch(fetchGeoPoints());
        }
    }
);

export const fetchGeoPoints = createAsyncThunk(
    'geoPoints/getAll',
    (_, { dispatch }) => {
        try {
            return loadGeoPoints();
        } catch (e) {
            dispatch(setToast({type: 'error', text: 'Reisi punktide pärimisel ilmnes viga'}));
            throw e;
        }
    }
);

const initialState: EntityState<GeoPoint> = geoPointEntityAdapter.getInitialState();

export const geoPointSlice = createSlice({
    name: 'geoPoints',
    initialState: initialState,
    reducers: {
        addGeoPoint: (state, action) => {
            geoPointEntityAdapter.upsertOne(state, action);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGeoPoints.fulfilled, (state, action) => {
            geoPointEntityAdapter.setAll(state, action.payload);
        });
    }
});

export const {
    selectAll: selectAllGeoPoints,
    selectTotal: selectTotalGeoPoints,
} = geoPointEntityAdapter.getSelectors<RootState>((state) => state.geoPoints);

export const {
    addGeoPoint,
} = geoPointSlice.actions;

export default geoPointSlice.reducer;