import React, {useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import routes from './routes';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './scenes/unauthenticated/login';
import {useAuth} from "./contexts/AuthContext";
import Dashboard from "./scenes/authenticated/dashboard";
import Users from "./scenes/authenticated/users";
import Buses from "./scenes/authenticated/buses/listView";
import Drivers from "./scenes/authenticated/drivers/listView";
import AuthLayout from "./layouts/BaseLayout";
import Loader from "./components/Loader";
import {Permission} from "./types";
import {doesUserHavePermission} from "./components/Visible";
import DriverDetails from "./scenes/authenticated/drivers/detailsView";
import Calendar from "./scenes/authenticated/calendar";
import TransportContracts from "./scenes/authenticated/transportContracts/listView";
import TransportContractDetails from "./scenes/authenticated/transportContracts/detailsView";
import Trips from "./scenes/authenticated/trips/listView";
import TripDetails from "./scenes/authenticated/trips/detailsView";
import WorkGroupsListView from "./scenes/authenticated/workGroups/listView";
import WorkGroupsPlanningView from "./scenes/authenticated/workGroups/planningView";
import WorkGroupDetails from "./scenes/authenticated/workGroups/detailsView";
import WorkSchedule from "./scenes/authenticated/workSchedule";
import WorkSheetsPlanningView from "./scenes/authenticated/workSheets/planningView";
import WorkSheetsListView from "./scenes/authenticated/workSheets/listView";
import WorkSheetDetailsView from "./scenes/authenticated/workSheets/detailsView";
import BusDetails from "./scenes/authenticated/buses/detailsView";
import DriverDashboard from "./scenes/authenticated/dashboard/DriverDashboard";
import Reports from "./scenes/authenticated/reports";
import Payslips from "./scenes/authenticated/payslips";
import Defects from "./scenes/authenticated/defects/listView";
import DefectDetails from "./scenes/authenticated/defects/detailsView";
import ProtectedLayoutWrapper from "./layouts/ProtectedLayoutWrapper";
import SearchLayoutWrapper from "./layouts/SearchLayoutWrapper";
import CharterTrips from "./scenes/authenticated/charterTrips/listView";
import CharterTripDetails from "./scenes/authenticated/charterTrips/detailsView";
import WorkGroups from './scenes/authenticated/workGroups';
import AvlAssignments from "./scenes/authenticated/avlAssignments";


export default function Router() {
    const { authenticatedUser, loading } = useAuth();
    const [routerLoading, setRouterLoading] = useState(true);

    useEffect(() => {
        if (!loading) triggerRouterLoadingEnd();
    }, []);

    useEffect(() => {
        if (!loading) triggerRouterLoadingEnd();
    }, [loading]);

    const triggerRouterLoadingEnd = () => {
        const timer = setTimeout(() => {
            setRouterLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    };

    const getPermissionGuardedPage = (permission: Permission, children: React.ReactNode) => {
        return doesUserHavePermission(authenticatedUser, permission) ? children : <Navigate to={routes.AUTHENTICATED.DASHBOARD} />
    };

    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <ProtectedLayoutWrapper isLoading={loading || routerLoading}>
                        <DashboardLayout />
                    </ProtectedLayoutWrapper>
                }>
                    <Route index element={<Dashboard />} />
                    <Route path={routes.AUTHENTICATED.AGENDA.ROOT+'/*'} element={<DriverDashboard />} />
                    <Route
                        path={routes.AUTHENTICATED.BUSES.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.BUSES.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadBuses, <Buses />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.BUSES.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateBuses, <BusDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.BUSES.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateBuses, <BusDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.DRIVERS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.DRIVERS.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadDrivers, <Drivers />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.DRIVERS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateDrivers, <DriverDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.DRIVERS.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateDrivers, <DriverDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.CALENDAR}
                        element={getPermissionGuardedPage(Permission.ReadCalendarEntries, <Calendar />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadTransportContracts, <TransportContracts />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateTransportContracts, <TransportContractDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.TRANSPORT_CONTRACTS.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateTransportContracts, <TransportContractDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.TRIPS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.TRIPS.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadTripDefinitions, <Trips />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.TRIPS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateTripDefinitions, <TripDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.TRIPS.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateTripDefinitions, <TripDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.CHARTER_TRIPS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.CHARTER_TRIPS.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadCharterTrips, <CharterTrips />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.CHARTER_TRIPS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateCharterTrips, <CharterTripDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.CHARTER_TRIPS.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateCharterTrips, <CharterTripDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.WORK_GROUPS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.WORK_GROUPS.ROOT} />}
                    >
                        <Route
                            index
                            element={<WorkGroups />}
                        />
                        <Route
                            path={routes.AUTHENTICATED.WORK_GROUPS.LIST}
                            element={getPermissionGuardedPage(Permission.ReadWorkGroups, <WorkGroupsListView />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.WORK_GROUPS.PLANNER}
                            element={getPermissionGuardedPage(Permission.ReadWorkGroups, <WorkGroupsPlanningView />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.WORK_GROUPS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateWorkGroups, <WorkGroupDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.WORK_GROUPS.EDIT}
                            element={getPermissionGuardedPage(Permission.UpdateWorkGroups, <WorkGroupDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.USERS}
                        element={getPermissionGuardedPage(Permission.ReadUsers, <Users />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.WORK_SCHEDULE.ROOT}
                        element={getPermissionGuardedPage(Permission.UpdateWorkSchedules, <WorkSchedule />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.WORK_SHEETS.ROOT}
                        element={getPermissionGuardedPage(Permission.UpdateWorkSchedules, <WorkSheetsListView />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.WORK_SHEETS.PLANNER}
                        element={getPermissionGuardedPage(Permission.UpdateWorkSchedules, <WorkSheetsPlanningView />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
                        element={getPermissionGuardedPage(Permission.UpdateWorkSchedules, <WorkSheetDetailsView />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.REPORTS}
                        element={getPermissionGuardedPage(Permission.ReadWorkSchedules, <Reports />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.PAYROLL.ROOT}
                        element={getPermissionGuardedPage(Permission.ReadPayroll, <Payslips />)}
                    />
                    <Route
                        path={routes.AUTHENTICATED.DEFECTS.ROOT}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.DEFECTS.ROOT} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ListDefects, <Defects />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.DEFECTS.ADD}
                            element={getPermissionGuardedPage(Permission.UpdateDefects, <DefectDetails />)}
                        />
                        <Route
                            path={routes.AUTHENTICATED.DEFECTS.EDIT}
                            element={getPermissionGuardedPage(Permission.ReadDefects, <DefectDetails />)}
                        />
                    </Route>
                    <Route
                        path={routes.AUTHENTICATED.AVL_ASSIGNMENTS}
                        element={<SearchLayoutWrapper path={routes.AUTHENTICATED.AVL_ASSIGNMENTS} />}
                    >
                        <Route
                            index
                            element={getPermissionGuardedPage(Permission.ReadAvlAssignments, <AvlAssignments />)}
                        />
                    </Route>
                    <Route path={'*'} element={<Navigate to={routes.AUTHENTICATED.DASHBOARD} />} />
                </Route>
                <Route path={routes.UNAUTHENTICATED.ROOT} element={(loading || routerLoading) ? <Loader /> : <AuthLayout />}>
                    <Route index element={<Login />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
