import {WorkGroupItemType, ResourceType, WorkSheetWorkItem, RoutePoint, RepetitionRules} from "../types";
import {DefectSimple} from "../defects/types";

export interface UnscheduledWorkGroup {
    id: number;
    code: string;
    date: string;
    workScheduleItemStartDateTime: string | null;
    workScheduleItemEndDateTime: string | null;
    hours: number | null;
    regionId?: number;
    workScheduleItemId?: number;
}

export enum WorkScheduleItemType {
    WORK_GROUP = 'WORK_GROUP',
    VACATION = 'VACATION',
    UNPAID_LEAVE = 'UNPAID_LEAVE',
    SICK_LEAVE = 'SICK_LEAVE',
    TRAINING = 'TRAINING',
    FREE_DAY_REQUEST = 'FREE_DAY_REQUEST',
    WORK_REQUEST = 'WORK_REQUEST',
    SPECIAL_REQUEST = 'SPECIAL_REQUEST',
    MAINTENANCE = 'MAINTENANCE',
    REPAIR = 'REPAIR',
    AWAY = 'AWAY',
}

export interface WorkScheduleItem {
    id?: number;
    type: WorkScheduleItemType;
    workGroupId?: number;
    resourceId?: number;
    resourceType: ResourceType;
    startDate: string;
    endDate: string;
    regionId: number;
    status: WorkScheduleItemStatus;
    workItemIds: number[];
    comment: string;
    confirmedAt?: string | null;
}

export interface WorkGroup {
    id: number;
    versionId: number;
    validFrom: string;
    validTo: string;
    code: string;
    type: ResourceType;
    tripDefinitionIds: number[];
    activityIds: number[];
}

export interface WorkGroupItem {
    id: number;
    type: WorkGroupItemType;
    startTime: string;
    startTimeIsOnNextDay: boolean;
    endTime: string;
    endTimeIsOnNextDay: boolean;
    distance: number;
    tripId?: number;
    tripDefinitionRoute?: RoutePoint[] | null;
    tripDefinitionCode?: string | null;
    tripDefinitionRepetition?: RepetitionRules | null;
}

export interface GetWorkScheduleResponse {
    workScheduleItems: WorkScheduleItem[];
    workItems: WorkSheetWorkItem[];
    workGroups: WorkGroup[];
    activities: WorkGroupItem[];
    tripDefinitions: WorkGroupItem[];
}

export interface SaveWorkScheduleItem {
    driverContractId?: number;
    busId?: number;
    type: WorkScheduleItemType;
    workGroupId: number | null;
    startDate: string;
    endDate: string;
    comment: string;
    status: WorkScheduleItemStatus;
    regionId?: number;
    resourceType?: ResourceType;
}

export interface DriverPosition {
    driverId: number;
    position: number;
}

export enum WorkScheduleItemStatus {
    SCHEDULED = 'SCHEDULED',
    GENERATED = 'GENERATED',
    CONFIRMED = 'CONFIRMED',
}

export interface DriverWorkSheet {
    id: number;
    workGroupId?: number;
    resourceType?: ResourceType;
    resourceId?: number;
    startDate: string;
    driverComment?: string;
    workItemIds: number[];
    busUsages: BusUsage[];
    defectsOnDay: DefectSimple[];
}

export interface BusUsage {
    busId: number;
    startDateTime: string;
    endDateTime: string;
    previousDriverFirstName?: string;
    previousDriverLastName?: string;
    nextDriverFirstName?: string;
    nextDriverLastName?: string;
}