import React from "react";
import TooltipRow from "./TooltipRow";
import {getDisplayValidDatesRangeEllipsis} from "../../../../../../../utils/dateUtils";

const Validity = (item: { validFrom?: string | null, validTo?: string | null }) => {
    if (!item.validFrom || !item.validTo) return null;

    return (
        <TooltipRow>
            {getDisplayValidDatesRangeEllipsis(item)}
        </TooltipRow>
    )
};

export default Validity;
