export interface CalendarEntry {
    id: number;
    validFrom: string;
    validTo: string;
    type: CalendarEntryType;
    comment: string | null;
}

export interface AddCalendarEntryRequest {
    validFrom: string;
    validTo: string;
    type: CalendarEntryType;
    comment: string | null;
}

export enum CalendarEntryType {
    PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
    SCHOOL_HOLIDAY = 'SCHOOL_HOLIDAY',
    SUMMER_HOLIDAY = 'SUMMER_HOLIDAY',
}
