import React, {useEffect, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import {Box} from "@mui/material";
import WorkGroupEditFormWrapper from "../../../../../scenes/authenticated/workGroups/detailsView/components/WorkGroupEditFromWrapper";
import {WorkGroup} from '../../../../../API/types';
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    addOrReplaceWorkGroup,
    clearDialogData,
    removeWorkGroup,
    selectDialogData,
    updateWorkGroup
} from "../../../../../store/plannerSlice";
import {selectDate, selectToggledResourceType} from "../../../../../store/viewSlice";
import {fetchCalendarEntries, selectAllCalendarEntries} from "../../../../../store/calendarEntriesSlice";
import {appliesOnDate} from "../../../../../utils/repetitionRulesUtils";
import {loadWorkGroupDetails} from "../../../../../API";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import Loader from "../../../../Loader";


export default function WorkGroupDetailsDialog() {
    const dispatch = useAppDispatch();
    const selectedDate = useAppSelector(selectDate).toDate();
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const addOrEditWorkGroupDetailsDialogData = useAppSelector(selectDialogData).addOrEditWorkGroupDetails;
    const sourceWorkGroupVersion = addOrEditWorkGroupDetailsDialogData?.workGroup;
    const [workGroup, setWorkGroup] = useState<WorkGroup | null | undefined>();
    const calendarEntries = useAppSelector(selectAllCalendarEntries);

    useEffect(() => {
        if (addOrEditWorkGroupDetailsDialogData) {
            if (sourceWorkGroupVersion?.id) {
                loadWorkGroupDetails(sourceWorkGroupVersion.id)
                    .then(result => {
                        setWorkGroup(result)
                    })
                    .catch((apiError) =>
                        dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? `Töögrupi leidmisel esines tõrge`}))
                    );
            } else {
                setWorkGroup(null);
            }
            dispatch(fetchCalendarEntries());
        }
    }, []);

    if (!addOrEditWorkGroupDetailsDialogData) return <></>;

    const handleCloseDialog = () => dispatch(clearDialogData());

    const handleSuccessfulCreate = (workGroup?: WorkGroup, oldVersionWorkGroupId?: number) => {
        if (workGroup && workGroup.type === toggledResourceType && appliesOnDate(workGroup, selectedDate, calendarEntries)) {
            dispatch(addOrReplaceWorkGroup({newWorkGroup: workGroup, oldVersionWorkGroupId: oldVersionWorkGroupId}));
        }
        handleCloseDialog();
    };

    const handleSuccessfulEdit = (updatedWorkGroup: WorkGroup) => {
        if (updatedWorkGroup.type === toggledResourceType && appliesOnDate(updatedWorkGroup, selectedDate, calendarEntries)) {
            if (workGroup?.versions && sourceWorkGroupVersion && workGroup.versions.length > 1) {
                // if source version is the first version overwrite start date, if source version is the last version overwrite last date
                dispatch(updateWorkGroup([{
                    ...updatedWorkGroup,
                    validFrom: workGroup.validFrom === sourceWorkGroupVersion.validFrom ? updatedWorkGroup.validFrom : sourceWorkGroupVersion.validFrom,
                    validTo: workGroup.validTo === sourceWorkGroupVersion.validTo ? updatedWorkGroup.validTo : sourceWorkGroupVersion.validTo,
                }]));
            } else {
                dispatch(updateWorkGroup([updatedWorkGroup]));
            }
        } else {
            dispatch(removeWorkGroup(updatedWorkGroup.id));
        }
        handleCloseDialog();
    };

    return (
        <Dialog open={!!addOrEditWorkGroupDetailsDialogData} onClose={handleCloseDialog}>
            <Box sx={{width: '450px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                {workGroup !== undefined ?
                    <WorkGroupEditFormWrapper
                        workGroup={workGroup}
                        handleSuccessfulEdit={handleSuccessfulEdit}
                        handleSuccessfulCreate={handleSuccessfulCreate}
                        useDialogVariant
                        handleCancelButtonPress={handleCloseDialog}
                        selectedDate={selectedDate}
                        selectedVersion={undefined}
                        setSelectedVersion={() => {}}
                    />
                    :
                    <Loader />
                }
            </Box>
        </Dialog>
    );
}
