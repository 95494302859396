import {Box} from "@mui/material";
import {getWorkScheduleItemTypeTranslation} from "../../../../../utils/enumTranslations";
import React from "react";
import {roundToDecimals} from "../../../../../utils/utils";
import {WorkScheduleItemType} from "../../../../../API/workSchedule/types";
import {ReportProblem} from "@mui/icons-material";
import {WorkScheduleItem, WorkScheduleItemWithHours} from "../../../../../store/workScheduleItemSlice";
import {useAppSelector} from "../../../../../hooks";
import {selectAllRegions, selectSelectedRegion} from "../../../../../store/regionSlice";
import dayjs from "dayjs";
import {DefectSimple} from "../../../../../API/defects/types";
import {DefectsTooltipContent} from "../../../workSheets/listView/BusesWithDefects";


export const ItemCellTooltip = ({activity, request, warning, defects}: {
    activity?: WorkScheduleItemWithHours;
    request?: WorkScheduleItem;
    warning: boolean;
    defects?: DefectSimple[];
}) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const regions = useAppSelector(selectAllRegions);

    const regionLabel = activity?.type === WorkScheduleItemType.WORK_GROUP && activity?.regionId !== selectedRegion?.id
        ? ' ' + regions?.find(region => region.id === activity?.regionId)?.name
        : null;

    return (
        <Box sx={{textAlign: 'left'}}>
            {activity &&
                <>
                    {regionLabel ? <Box>{regionLabel}</Box> : null}
                    <Box sx={{mb: request ? 0.5 : undefined}}>
                        {getWorkScheduleItemTypeTranslation(activity.type)}
                        {activity.workGroupCode ? ' ' + activity.workGroupCode : null}
                        {activity.startDateTime && activity.endDateTime ?
                            ' (' + dayjs(activity.startDateTime).format('HH:mm')
                            + ' - ' + dayjs(activity.endDateTime).format('HH:mm') + ')'
                            : null}
                        {activity.workGroupCode && activity.hours !== null ? ' ' + roundToDecimals(activity.hours, 1) + 'h' : null}
                        {activity.comment && activity.comment.length > 0 ? ': ' + activity.comment : null}
                    </Box>
                </>
            }
            {request &&
                <Box>
                    {getWorkScheduleItemTypeTranslation(request.type)}
                    {request.comment && request.comment.length > 0 ? ': ' + request.comment : null}
                </Box>
            }
            {warning &&
                <Box sx={{mt: 0.5, alignItems: 'center', display: 'flex'}}>
                    <ReportProblem sx={{fontSize: '14px', pr: 0.5}} />
                    Kahe tööpäeva vaheline aeg jääb alla 9 tunni.
                </Box>
            }
            {defects && defects.length > 0 &&
                <Box mt={0.5}>
                    Rikked:
                    <DefectsTooltipContent defects={defects} />
                </Box>
            }
        </Box>
    );
};
