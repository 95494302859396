import {DirectionsBus} from "@mui/icons-material";
import React from "react";
import {useNavigate} from "react-router-dom";
import routes from "../../../../../routes";
import {Box, Link, Typography} from "@mui/material";


interface BusLabelProps {
    licencePlateNumbers: string[]
}

const BusLabel = ({licencePlateNumbers}: BusLabelProps) => {
    const navigate = useNavigate();

    const handleBusClick = (licencePlateNumber: string) => (event: React.MouseEvent) => {
        event.stopPropagation();
        navigate(routes.AUTHENTICATED.AGENDA.BUS.replace(':licencePlateNumber', licencePlateNumber));
    };

    return (
        <Typography variant="body2" component="div" sx={{display: 'flex', alignItems: 'center'}}>
            <DirectionsBus sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}}/>
            {licencePlateNumbers.map((licencePlateNumber, index) =>
                <Box key={index}>
                    {index > 0 && ', '}
                    <Link onClick={handleBusClick(licencePlateNumber)} sx={{color: 'inherit', cursor: 'pointer'}}>
                        {licencePlateNumber}
                    </Link>
                </Box>
            )}
        </Typography>
    );
};

export default BusLabel;
