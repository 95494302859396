import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {getFuelTranslationFromStr} from "../../../../../utils/enumTranslations";
import {Fuel} from "../../../../../API/types";
import {useField} from "formik";
import {Box, TextField as MuiTextField} from "@mui/material";


interface MultiAutocompleteProps {
	name: string;
	disabled?: boolean;
}

export default function MultiAutocomplete({name, disabled}: MultiAutocompleteProps) {
	const options: string[] = Object.keys(Fuel).filter(key => isNaN(Number(key)));
	const [field, meta, helpers] = useField(name);
	const [value, setValue] = React.useState<string[]>(field.value);

	return (
		<Box>
			<Autocomplete
				multiple
				size="small"
				options={options}
				getOptionLabel={(option) => getFuelTranslationFromStr(option)}
				value={value}
				onChange={(event, newValue) => {
					setValue([...newValue]);
					helpers.setValue([...newValue]);
				}}
				filterSelectedOptions
				renderInput={(params) => (
					<MuiTextField
						{...params}
						label="Kütuseliik*"
						error={meta.touched && !!meta.error}
						helperText={meta.touched && meta.error}
					/>
				)}
				sx={{my: 1}}
				disabled={disabled}
			/>
		</Box>

	);
}
