import React from "react";
import Select from "../../../../components/Form/Select";
import {WorkScheduleItemType} from "../../../../API/workSchedule/types";
import {getWorkScheduleItemTypeTranslationFromStr} from "../../../../utils/enumTranslations";
import TextField from "../../../../components/Form/TextField";
import {Box, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Delete} from "@mui/icons-material";
import {BusWorkScheduleRequestTypes, DriverWorkScheduleRequestTypes} from "../types";
import {useAppSelector} from "../../../../hooks";
import {selectIsRowActive} from "../store/selectors";
import {WorkScheduleItemDialogData} from "./WorkScheduleItemDialog";
import {ResourceType} from "../../../../API/types";
import {WorkScheduleItem} from "../../../../store/workScheduleItemSlice";
import {selectToggledResourceType} from "../../../../store/viewSlice";

export default function RequestForm({data, request, handleDelete}: {
    data: WorkScheduleItemDialogData,
    request?: WorkScheduleItem,
    handleDelete: (workScheduleItem: WorkScheduleItem) => void,
}) {
    const resourceType = useAppSelector(selectToggledResourceType);
    const isRowActive = useAppSelector(state => selectIsRowActive(state, data.resourceId));

    const handleDeleteClick = () => {
        if (request) {
            handleDelete(request);
        }
    };

    return(
        <Box sx={{display: 'flex', flexDirection: 'column', minWidth: '210px'}}>
            <Box sx={{display: 'flex', alignItems: 'center', verticalAlign: 'baseline', justifyContent: 'space-between'}}>
                <Typography variant="body2" color="text.secondary">Soov</Typography>
                <IconButton size="small" aria-label="Kustuta" onClick={handleDeleteClick}
                            sx={{visibility: !request ? 'hidden' : undefined}}>
                    <Delete />
                </IconButton>
            </Box>
            <Select name="request.type"
                    label="Soovi tüüp"
                    options={resourceType === ResourceType.DRIVER ? DriverWorkScheduleRequestTypes : BusWorkScheduleRequestTypes}
                    translationFunction={getWorkScheduleItemTypeTranslationFromStr}
                    translationEnumType={WorkScheduleItemType}
                    disabled={typeof request?.type !== "undefined" || !isRowActive}
            />
            <TextField name="request.comment" label="Kommentaar" multiline minRows={2} maxRows={5} disabled={!isRowActive} />
        </Box>
    );
}