import {
    amber,
    blueGrey,
    cyan,
    deepOrange,
    deepPurple,
    indigo,
    lightGreen,
    lime,
    pink,
    teal
} from "@mui/material/colors";
import {WorkGroupItemType} from "../../../API/types";


export const markerColors = [
    deepOrange[500], cyan[500], amber[500], lightGreen[500], teal[500],
    deepPurple[500], lime[500], pink[500], indigo[500], blueGrey[500]
];

export const createIdToColorIndexMap = (ids: number[], currentColorIndex = 0): Record<number, number> => {
    const idToColorIndex: Record<number, number> = {};

    ids.forEach((id, index) => {
        idToColorIndex[id] = (currentColorIndex + index) % markerColors.length;
    });

    return idToColorIndex;
};

export const getRandomColorIndex = () => Math.floor(Math.random() * markerColors.length);

export const getActivityCardColor = (type: WorkGroupItemType) => {
    switch (type) {
        case WorkGroupItemType.LUNCH_BREAK:
        case WorkGroupItemType.DISRUPTION:
        case WorkGroupItemType.TIME_BETWEEN_SHIFTS:
            return amber[700];
        case WorkGroupItemType.PREPARATION_TIME:
        case WorkGroupItemType.FINISHING_TIME:
            return teal[400];
        case WorkGroupItemType.RESERVE:
            return cyan[700];
        default:
            return deepPurple[300];
    }
};
