import {Form, FormikProps} from "formik";
import {Box, Typography, useTheme} from "@mui/material";
import TextField from "../../../../../components/Form/TextField";
import DatePicker from "../../../../../components/Form/DatePicker";
import Checkbox from "../../../../../components/Form/Checkbox";
import React, {useEffect} from "react";
import {TripDefinition} from "../../../../../API/types";
import {TripDefinitionForm} from "../types";
import DetailViewButtons from "../../../../../components/Buttons/DetailViewButtons";
import NumberTextField from "../../../../../components/Form/NumberTextField";
import SelectWithId from "../../../../../components/Form/SelectWithId";
import Loader from "../../../../../components/Loader";
import RegionSelect from "../../../../../components/PageHeader/RegionSelect";
import {SelectOptionWithId} from "../../../../../types";
import RepetitionRulesInput from "../../../../../components/Form/RepetiotionRulesInput";
import routes from "../../../../../routes";
import ModifierStartAndEndTime from "../../../../../components/Form/ModifierStartAndEndTime";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import Route from "../../../../../components/Form/Route";
import {getDateWithAddedDays, getDisplayDate} from "../../../../../utils/dateUtils";
import {useAuth} from "../../../../../contexts/AuthContext";


interface TripDefinitionEditFormProps {
    formikProps: FormikProps<TripDefinitionForm>;
    id: string | undefined;
    tripDefinition: TripDefinition | undefined;
    tripDefinitionRegion: string | undefined;
    defaultFormValues: TripDefinitionForm;
    transportContracts: SelectOptionWithId[];
    updateTransportContracts: () => void;
    hasCopiedContent: boolean;
    isNewVersion?: boolean;
}

export default function TripDefinitionEditForm({
    formikProps, id, tripDefinition, tripDefinitionRegion, defaultFormValues, transportContracts,
    updateTransportContracts, hasCopiedContent, isNewVersion
}: TripDefinitionEditFormProps) {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const { authenticatedUser } = useAuth();
    const canEdit = (authenticatedUser?.regionalFilteringApplies && tripDefinitionRegion)
        ? authenticatedUser.regions.some(region => region.name === tripDefinitionRegion)
        : true;

    const theme = useTheme();

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    useEffect(() => {
        if (!id) {
            updateTransportContracts();
            if (transportContracts.find(contract => contract.id === formikProps.values.contractId) === undefined) {
                void formikProps.setFieldValue('contractId', null);
            }
        }
    }, [selectedRegion]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.TRIPS.ROOT}
            />
        );
    };

    return (transportContracts ?
        <Form>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Typography variant="h5" pt={{xs: 1, sm: 0}}>
                        {tripDefinition ? tripDefinition.code : 'Uus reis'}
                    </Typography>
                    {tripDefinitionRegion &&
                        <Typography variant="body2" color="text.secondary">
                            {tripDefinitionRegion}
                        </Typography>
                    }
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    {!id && <RegionSelect styles={{pr: {xs: 0, sm: 1}}} />}
                    <Box sx={{my: 0.5, [theme.breakpoints.down('sm')]: {display: 'none'}}}>
                        {renderDetailViewButtons()}
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '450px', maxWidth: '100%',
                [theme.breakpoints.down('sm')]: {width: '100%'}
            }}>
                <TextField name="code" label="Kood*" disabled={isNewVersion || !canEdit} />
                <SelectWithId name="contractId" label="Veoleping*" options={transportContracts} disabled={isNewVersion || !canEdit} />
                <TextField name="lineNumber" label="Liini number" disabled={!canEdit} />
                <ModifierStartAndEndTime disabled={!canEdit} />
                <Route
                    name="route"
                    values={formikProps.values.route}
                    disabled={!canEdit}
                />
                <NumberTextField name="distance" label="Pikkus (km)" decimals={3} disabled={!canEdit} />
                <DatePicker name="validFrom" label="Algus" hasCopiedContent={hasCopiedContent} disabled={!canEdit} />
                <DatePicker name="validTo" label="Lõpp" hasCopiedContent={hasCopiedContent} disabled={!canEdit} />
                <DatePicker name="startDate" label="Sesonaalse kehtivuse alguskuupäev" hideYear hasCopiedContent={hasCopiedContent} disabled={isNewVersion || !canEdit} />
                <DatePicker name="endDate" label="Sesonaalse kehtivuse lõpukuupäev" hideYear hasCopiedContent={hasCopiedContent} disabled={isNewVersion || !canEdit} />
                <RepetitionRulesInput name="repetition" disabled={isNewVersion || !canEdit} />
                <TextField
                    name="comment"
                    label="Kommentaar"
                    multiline
                    minRows={2}
                    maxRows={5}
                    disabled={!canEdit}
                    description={
                        'Kommentaari kuvatakse juhi sõidulehel reisi juures. Sobilik täiendava informatsiooni lisamiseks ' +
                        'reisi kohta - nt millised peatused on nõudmisel ja millises peatuses tuleb teine buss/rong ära oodata.'
                    }
                />
                <Checkbox name="active" label="Aktiivne" disabled={isNewVersion || !canEdit} />
                {isNewVersion && formikProps.values.validFrom &&
					<Typography variant="body2" color="warning.light">
                        {'Uue versiooni loomisel määratakse algse reisi lõppkuupäevaks ' +
                            getDisplayDate(getDateWithAddedDays(formikProps.values.validFrom, -1))}
					</Typography>
                }
                <Box sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
        </Form>
        :
        <Loader />
    );
}
