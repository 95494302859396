import * as React from 'react';
import {styled, SxProps, ToggleButton as MuiToggleButton, ToggleButtonGroup} from '@mui/material';
import {PAGE_HEADER_ITEM_HEIGHT} from '../../PageHeader';


const StyledToggleButton = styled(MuiToggleButton)(({ theme, color }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: PAGE_HEADER_ITEM_HEIGHT,

    '&.Mui-selected': {
        color: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
        backgroundColor: theme.palette.action.hover,
        borderColor: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
    },
    '&.Mui-selected:not(:first-of-type)': {
        borderLeft: '1px solid !important',
    },
}));

interface ToggleButtonProps {
    options: string[];
    value: string;
    color?: 'primary' | 'secondary';
    handleChange: (value: string) => void;
    sx?: SxProps;
}
export default function ToggleButton({options, value, color = 'primary', handleChange, sx}: ToggleButtonProps) {
    return (
        <ToggleButtonGroup
            value={value}
            size="small"
            exclusive
            onChange={(event, newAlignment) => handleChange(newAlignment)}
            sx={sx}
        >
            {options.map(option =>
                <StyledToggleButton key={option} value={option} color={color}>
                    {option}
                </StyledToggleButton>
            )}
        </ToggleButtonGroup>
    );
}
