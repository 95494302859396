import {WorkGroup} from "../../../API/workGroup/types";
import {
    resourceGroupToggleButtonTypeToResourceGroupTypeMap,
    ResourceGroupToggleButtonTypeWithAll
} from "../../../types";

export const filterWorkGroupsByResourceType = (rows: WorkGroup[], toggleButtonValue: ResourceGroupToggleButtonTypeWithAll): WorkGroup[] => {
    return toggleButtonValue === 'KÕIK'
        ? rows
        : rows.filter(value => value.type === resourceGroupToggleButtonTypeToResourceGroupTypeMap.get(toggleButtonValue));
};