import axios from "axios";
import {TransportContract, UpdateTransportContractRequest} from "./types";
import {handleError} from "../utils";

export const loadTransportContracts = async () => {
    return axios.get<TransportContract[]>('/api/transport-contracts')
        .then(result => result.data)
        .catch(handleError)
};

export const createTransportContract = (request: UpdateTransportContractRequest) =>
    axios.post<TransportContract>(`/api/transport-contracts`, request)
        .then(result => result.data)
        .catch(handleError);


export const updateTransportContract = (id: number | string, request: UpdateTransportContractRequest) =>
    axios.put(`/api/transport-contracts`, {id: id, ...request})
        .then(result => result.data)
        .catch(handleError);
