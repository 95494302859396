import {ArrowBack} from "@mui/icons-material";
import React from "react";
import {IconButton} from "@mui/material";


interface BackButtonProps {
    onClick: () => void;
}

const BackButton = ({onClick}: BackButtonProps) => {
    return (
        <IconButton color="primary" aria-label="Tagasi" onClick={onClick} size="small">
            <ArrowBack sx={{fontSize: '20px'}} />
        </IconButton>
    );
};

export default BackButton;
