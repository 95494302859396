import {WorkScheduleItemForm, WorkScheduleItemRequestForm} from "../types";
import Dialog from "@mui/material/Dialog";
import {Box, Grid, Typography, useTheme} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {Check, ChevronRight, Clear} from "@mui/icons-material";
import React, {useState} from "react";
import dayjs from "dayjs";
import {ResourceType, WorkScheduleItemType} from "../../../../API/types";
import ActivityForm from "./ActivityForm";
import RequestForm from "./RequestForm";
import Button from "../../../../components/Buttons/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import {CollectionOfWork, formatName, isMultiDateType, restTimeLessThan9Hours} from "../utils";
import * as Yup from "yup";
import {isEqual} from "lodash";
import Divider from "@mui/material/Divider";
import {useAppSelector} from "../../../../hooks";
import {
    selectActivityByDayAndId,
    selectIsRowActive,
    selectRequestByDayAndId,
    selectScheduledItemsByResourceId
} from "../store/selectors";
import {shallowEqual} from "react-redux";
import {MIN_DRIVER_REST_TIME_HOURS} from "../../../../constants";
import {selectDisruptiveDefectsByBusId, WorkScheduleItem} from "../../../../store/workScheduleItemSlice";
import {selectDriverContractById} from "../../../../store/driverContractSlice";
import {selectDriverById} from "../../../../store/driverSlice";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {validationSchema} from "../../../../utils/formValidation";
import DefectAlert from "./DefectAlert";

export interface WorkScheduleItemDialogData {
    resourceId: number;
    date: string;
}

interface CombinedForm {
    activity: WorkScheduleItemForm;
    request: WorkScheduleItemRequestForm | null;
}

export default function WorkScheduleItemDialog({open, data, handleCloseDialog, handleSave, handleDelete}: {
    open: boolean,
    data: WorkScheduleItemDialogData,
    handleCloseDialog: () => void,
    handleSave: (resourceId: number, formData: WorkScheduleItemForm, workScheduleItem?: WorkScheduleItem) => void,
    handleDelete: (workScheduleItem: WorkScheduleItem) => void,
}) {
    const theme = useTheme();
    const date = dayjs(data.date);
    const activity = useAppSelector(selectActivityByDayAndId(data.resourceId, date), shallowEqual);
    const previousActivity = useAppSelector(selectActivityByDayAndId(data.resourceId, date.subtract(1, 'day')), shallowEqual);
    const nextActivity = useAppSelector(selectActivityByDayAndId(data.resourceId, date.add(1, 'day')), shallowEqual);
    const request = useAppSelector(selectRequestByDayAndId(data.resourceId, date), shallowEqual);
    const isRowActive = useAppSelector(state => selectIsRowActive(state, data.resourceId));
    const resourceType = useAppSelector(selectToggledResourceType);

    const driverContract = useAppSelector(state => selectDriverContractById(state, data.resourceId));
    const driver = useAppSelector(state => selectDriverById(state, driverContract?.driverId ?? ''));
    const items = useAppSelector((state) => selectScheduledItemsByResourceId(state, data.resourceId));
    const name = driver ? formatName(driver) : '';
    const defects = useAppSelector(state => selectDisruptiveDefectsByBusId(state, resourceType === ResourceType.VEHICLE ? data.resourceId : 0));

    const [requestOpen, setRequestOpen] = useState(!!request);

    const initialValues: CombinedForm = {
        activity: {
            type: activity?.type ?? WorkScheduleItemType.WORK_GROUP,
            startDate: activity?.startDate ? dayjs(activity.startDate) : date,
            endDate: activity?.endDate ? dayjs(activity.endDate) : date,
            workGroup: activity?.workGroupId && activity?.workGroupCode ? {
                id: activity.workGroupId,
                code: activity.workGroupCode,
                date: data.date,
                workScheduleItemStartDateTime: activity.startDateTime ?? null,
                workScheduleItemEndDateTime: activity.endDateTime ?? null,
                hours: activity.hours,
            } : null,
            comment: activity?.comment ?? '',
        },
        request: request ? {
            type: request?.type ?? null,
            comment: request?.comment ?? '',
        } : null,
    };

    const handleFormSubmitClick = (form: CombinedForm, formHelpers: FormikHelpers<CombinedForm>) => {
        const currentCollectionOfWork: CollectionOfWork = {
            startDateTime: form.activity.workGroup?.workScheduleItemStartDateTime ? form.activity.workGroup.workScheduleItemStartDateTime : '',
            endDateTime: form.activity.workGroup?.workScheduleItemEndDateTime ? form.activity.workGroup.workScheduleItemEndDateTime : '',
        };
        if (resourceType === ResourceType.DRIVER) {
            if (restTimeLessThan9Hours(currentCollectionOfWork, previousActivity) || restTimeLessThan9Hours(nextActivity, currentCollectionOfWork)) {
                if (!window.confirm(`Töögrupi lisamisel jääb tööpäevade vahele vähem kui ${MIN_DRIVER_REST_TIME_HOURS} tundi puhkeaega, kas soovid tegevuse siiski lisada?`)) {
                    formHelpers.setSubmitting(false);
                    return;
                }
            }
        }
        if (!isEqual(initialValues.activity, form.activity)) {
            formHelpers.setSubmitting(true);
            handleSave(data.resourceId, {
                type: form.activity.type,
                workGroup: form.activity.type === WorkScheduleItemType.WORK_GROUP.toString() ? form.activity.workGroup : null,
                startDate: isMultiDateType(form.activity.type) ? dayjs(form.activity.startDate) : date,
                endDate: isMultiDateType(form.activity.type) ? dayjs(form.activity.endDate) : date,
                comment: form.activity.comment,
            }, activity);
        }
        if (form.request && !isEqual(initialValues.request, form.request) && form.request.type !== null) {
            formHelpers.setSubmitting(true);
            handleSave(data.resourceId, {
                type: form.request.type,
                workGroup: null,
                startDate: date,
                endDate: date,
                comment: form.request.comment ?? '',
            }, request);
        }
        handleCloseDialog();
    };

    const minDate = dayjs(
        items.filter(item => item.endDate < data.date)
            .reduce((max: string, item) =>
                item.endDate > max ? item.endDate : max,
                date.subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
            )
    );
    const maxDate = dayjs(
        items.filter(item => item.startDate > data.date)
            .reduce((min: string, item) =>
                item.startDate < min ? item.startDate : min,
                date.add(1, 'month').startOf('month').format('YYYY-MM-DD')
            )
    );

    const itemValidationSchema = Yup.object().shape({
        activity: Yup.object().shape({
            workGroup: Yup.object().nullable().test({
                message: 'Töögrupp on kohustuslik väli',
                test: (value, context) => {
                    if (context.parent.type !== 'WORK_GROUP') {
                        return typeof value !== 'undefined';
                    }

                    // Nothing changed, don't validate
                    if (context.parent.comment === initialValues.activity.comment && value === initialValues.activity.workGroup) {
                        return true;
                    }

                    return value !== null;
                }
            }),
            startDate: Yup.date().typeError('Vigane kuupäev')
                .required('Algus on kohustuslik väli')
                .min(minDate, 'Algus kattub olemasoleva tegevusega'),
            endDate: Yup.date().typeError('Vigane kuupäev')
                .required('Lõpp on kohustuslik väli')
                .min(Yup.ref('startDate'), 'Lõpp ei tohi olla enne alguskuupäeva')
                .max(maxDate, 'Lõpp kattub olemasoleva tegevuseg'),
            comment: validationSchema('Kommentaar').fields.varcharTextField,
        }),
    });

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmitClick} validationSchema={itemValidationSchema}>
                {(formikProps: FormikProps<CombinedForm>) =>
                    <Form>
                        <Box sx={{p: {xs: 0, sm: 2, position: 'relative', overflow: 'hidden'}, minWidth: '300px'}}>
                            <DialogTitle>
                                <Typography variant="h5" component="div">{date.format('dd DD.MM')}</Typography>
                                <Typography variant="body2" color="text.secondary">{name}</Typography>
                                {defects && defects.length > 0 && <DefectAlert defects={defects} />}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={12} sm>
                                        <ActivityForm data={data}
                                                      activity={activity}
                                                      request={request}
                                                      handleDelete={handleDelete}
                                                      minDate={minDate}
                                                      maxDate={maxDate} />
                                    </Grid>
                                    <Divider sx={{margin: 2, display: requestOpen ? undefined : 'none'}}
                                             orientation="vertical"
                                             flexItem
                                             variant="middle"
                                    />
                                    <Grid item  xs={12} sm display={requestOpen ? undefined : 'none'}>
                                        <RequestForm data={data} request={request} handleDelete={handleDelete} />
                                    </Grid>
                                </Grid>
                                {activity && !request && isRowActive &&
                                    <Box sx={{
                                        [theme.breakpoints.up('sm')]: {
                                            position: 'absolute',
                                            bottom: '50%',
                                            right: '-34px',
                                            mb: '-32px',
                                            transform: 'translateY(-50%) rotate(270deg)',
                                        },
                                    }}>
                                        <Button
                                            text="Soov"
                                            onClick={() => {
                                                if (!formikProps.values.request) {
                                                    formikProps.setFieldValue('request', {
                                                        type: resourceType === ResourceType.DRIVER ? WorkScheduleItemType.SPECIAL_REQUEST : WorkScheduleItemType.AWAY,
                                                        comment: '',
                                                    });
                                                } else {
                                                    formikProps.setFieldValue('request', null);
                                                }
                                                setRequestOpen(prevState => !prevState);
                                            }}
                                            size="small"
                                            color="secondary"
                                            styles={{[theme.breakpoints.up('sm')]: {
                                                    height: '24px',
                                                },}}
                                            endIcon={<ChevronRight sx={{rotate: requestOpen ? '270deg' : '90deg'}} />}
                                        />
                                    </Box>
                                }
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Tagasi"
                                        variant="outlined"
                                        onClick={handleCloseDialog}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting || isEqual(formikProps.initialValues, formikProps.values)}
                                        text="Salvesta"
                                        type="submit"
                                        startIcon={<Check />}
                                    />
                                </Box>
                            </DialogActions>
                        </Box>
                    </Form>
                }
            </Formik>
        </Dialog>
    );
}