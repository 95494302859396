import {TripReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Cell, Columns, Row, SheetData} from "write-excel-file";
import {dateRangeToArrayOfDays, getStartAndEndPointLabel} from "../../../../utils/utils";
import {ALT_BACKGROUND, BORDER_COLOR, NEGATIVE_COLOR, POSITIVE_COLOR} from "../constants";
import {getDisplayTimeWithModifier} from "../../../../utils/dateUtils";
import {groupByTwoProperties} from "./utils";

const groupByContractAndTrip = (allTripReports: TripReport[]): Map<string, Map<string, TripReport[]>> => {
    return groupByTwoProperties(
        allTripReports,
        (item: TripReport) => item.transportContractName,
        (item: TripReport) => item.tripCode,
    );
};

function getProfitCenter(trips: Map<string, TripReport[]>): string | undefined {
    if (trips.size > 0) {
        const tripReports: TripReport[] = trips.values().next().value;
        if (tripReports.length > 0) {
            const firstTripReport = tripReports[0];
            return firstTripReport.profitCenter;
        }
    }
}

export const formatDailyLineReport = (data: TripReport[], startDate: Dayjs, endDate: Dayjs): [SheetData, Columns] => {
    data.sort((a, b) => a.tripCode > b.tripCode ? 1 : -1)
    const days = dateRangeToArrayOfDays(startDate, endDate);
    const cols = ['plan', 'teg', '+/-'];

    const colHeaders = (colorBackground: boolean): Cell[] => cols.map(col => ({
        value: col,
        fontWeight: 'bold',
        align: 'center',
        bottomBorderColor: BORDER_COLOR,
        backgroundColor: colorBackground ? ALT_BACKGROUND : undefined,
    }));

    const dates: Row = [
        null,
        null,
        null,
        null,
        null,
        ...days.flatMap((day: Dayjs, index): Cell[] => [
            {
                value: day.format('DD.MM.YYYY'),
                fontWeight: 'bold',
                span: 3,
                align: 'center',
                backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined,
            },
            ...Array(cols.length - 1).map(() => null), // Add empty cells for colspan purpose
        ]),
        {
            value: 'Kokku',
            fontWeight: 'bold',
            span: 3,
            align: 'center',
            backgroundColor: days.length % 2 === 0 ? ALT_BACKGROUND : undefined,
        },
        ...Array(cols.length - 1).map(() => null), // Add empty cell for colspan purpose
    ];
    const columnHeaders: Row = [
        {value: 'Reis', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Liini nr', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Reisi nimetus', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Väljub', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Toimumiste arv', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        ...days.flatMap((_, index) => colHeaders(index % 2 === 0)),
        ...colHeaders(days.length % 2 === 0),
    ];
    const grouped = groupByContractAndTrip(data);
    const dataRows: Row[] = [];
    const totals: number[] = [...Array((days.length + 1) * cols.length)].map(() => 0);
    let totalTripOccurrences = 0;

    grouped.forEach(((trips, contractName) => {
        const subTotals = [...Array((days.length + 1) * cols.length)].map(() => 0);
        let subTotalTripOccurrences = 0;

        trips.forEach((tripReports, tripCode) => {
            const totalPlanned = tripReports.reduce((total, trip) => total + trip.plannedDistance, 0);
            const totalReal = tripReports.reduce((total, trip) => total + trip.realDistance, 0);
            const totalDiff = tripReports.reduce((total, trip) => total + trip.realDistance - trip.plannedDistance, 0);

            subTotals[(days.length * cols.length)] += totalPlanned;
            subTotals[(days.length * cols.length) + 1] += totalReal;
            subTotals[(days.length * cols.length) + 2] += totalDiff;

            totals[(days.length * cols.length)] += totalPlanned;
            totals[(days.length * cols.length) + 1] += totalReal;
            totals[(days.length * cols.length) + 2] += totalDiff;

            const nTripOccurrences = tripReports.filter(tripReport => tripReport.realDistance > 0).length;
            subTotalTripOccurrences += nTripOccurrences;
            totalTripOccurrences += nTripOccurrences;

            const lineNumber = tripReports.length > 0 ? tripReports[0].lineNumber : '';
            const startAndEndPoint = tripReports.length > 0 ? getStartAndEndPointLabel(tripReports[0].route) : '';
            const startTime = tripReports.length > 0 && tripReports[0].plannedStartTime && tripReports[0].plannedStartTimeIsOnNextDay !== null
                ? getDisplayTimeWithModifier(tripReports[0].plannedStartTime, tripReports[0].plannedStartTimeIsOnNextDay) : '';

            const row: Row = [
                {
                    value: tripCode,
                    type: String,
                },
                {
                    value: lineNumber,
                    type: String,
                },
                {
                    value: startAndEndPoint,
                    type: String,
                },
                {
                    value: startTime,
                    type: String,
                },
                {
                    value: nTripOccurrences,
                },
                ...days.flatMap((day, index): Cell[] => {
                    const dayData = tripReports.find(item => day.isSame(item.date, 'day'));
                    if (!dayData) {
                        return [
                            {value: '', backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined},
                            {value: '', backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined},
                            {value: '', backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined},
                        ];
                    }

                    const difference = dayData.realDistance - dayData.plannedDistance;

                    subTotals[(index * cols.length)] += dayData.plannedDistance;
                    subTotals[(index * cols.length) + 1] += dayData.realDistance;
                    subTotals[(index * cols.length) + 2] += difference;

                    totals[(index * cols.length)] += dayData.plannedDistance;
                    totals[(index * cols.length) + 1] += dayData.realDistance;
                    totals[(index * cols.length) + 2] += difference;

                    return [
                        {value: dayData.plannedDistance, backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined},
                        {value: dayData.realDistance, backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined},
                        {
                            value: difference,
                            color: difference === 0 ? undefined : difference > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
                            backgroundColor: index % 2 === 0 ? ALT_BACKGROUND : undefined,
                        },
                    ];
                }),
                {
                    value: totalPlanned,
                    fontWeight: 'bold',
                    backgroundColor: days.length % 2 === 0 ? ALT_BACKGROUND : undefined
                },
                {
                    value: totalReal,
                    fontWeight: 'bold',
                    backgroundColor: days.length % 2 === 0 ? ALT_BACKGROUND : undefined
                },
                {
                    value: totalDiff,
                    fontWeight: 'bold',
                    color: totalDiff === 0 ? undefined : totalDiff > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
                    backgroundColor: days.length % 2 === 0 ? ALT_BACKGROUND : undefined,
                },
            ];
            dataRows.push(row);
        });

        const profitCenter = getProfitCenter(trips);
        dataRows.push([
            {
                value: `[Veoleping] ${contractName}${profitCenter ? ` (${profitCenter})` : ''}`,
                fontWeight: 'bold',
                topBorderColor: BORDER_COLOR,
                bottomBorderColor: BORDER_COLOR,
                span: 4,
                align: 'left'
            },
            null,
            null,
            null,
            {
                value: subTotalTripOccurrences,
                fontWeight: 'bold',
                topBorderColor: BORDER_COLOR,
                bottomBorderColor: BORDER_COLOR,
            },
            ...subTotals.map((value, index): Cell => ({
                value: value,
                fontWeight: 'bold',
                topBorderColor: BORDER_COLOR,
                bottomBorderColor: BORDER_COLOR,
                backgroundColor: Math.floor(index / cols.length) % 2 === 0 ? ALT_BACKGROUND : undefined,
                color: ((index % cols.length) !== cols.length - 1 || value === 0) ? undefined : value > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
            })),
        ])
    }));

    const totalsRow: Row = [
        {value: 'Kokku', fontWeight: 'bold', span: 4, align: 'right'},
        null,
        null,
        null,
        {
            value: totalTripOccurrences,
            fontWeight: 'bold',
        },
        ...totals.map((value, index): Cell => ({
            value: value,
            fontWeight: 'bold',
            backgroundColor: Math.floor(index / cols.length) % 2 === 0 ? ALT_BACKGROUND : undefined,
            color: ((index % cols.length) !== cols.length - 1 || value === 0) ? undefined : value > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
        })),
    ];

    return [[
        dates,
        columnHeaders,
        ...dataRows,
        totalsRow,
    ], []];
};