import React from "react";
import {Box, Table, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@mui/material";
import {useAppSelector, useResourceOptions} from "../../../../hooks";
import {selectRows} from "../store/selectors";
import {ResourceType, WorkSheet} from "../../../../API/types";
import WorkSheetListRow from "./WorkSheetListRow";
import {grey} from "@mui/material/colors";
import {selectToggledResourceType} from "../../../../store/viewSlice";

export default function WorkSheetList() {
    const theme = useTheme();
    const rows = useAppSelector(selectRows);
    const resourceType = useAppSelector(selectToggledResourceType);
    const {availableDrivers, availableBuses} = useResourceOptions();

    return (
        <TableContainer sx={{border: `1px solid ${theme.palette.divider}`, borderRadius: '5px'}}>
            <Table size="small" sx={{
                '& .MuiTableHead-root .MuiTableCell-root': {
                    fontWeight: 'bold',
                    paddingY: 1,
                },
                '& .MuiTableBody-root:hover': {
                    backgroundColor: grey[100],
                },
                '& .MuiTableCell-root': {
                    paddingY: 0.25,
                    paddingX: 1,
                },
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth: 24}} />
                        <TableCell sx={{minWidth: 80}}>
                            {resourceType === ResourceType.DRIVER ? 'Juhi TG' : 'Bussi TG'}
                        </TableCell>
                        <TableCell sx={{minWidth: resourceType === ResourceType.DRIVER ? 200 : 100}}>
                            <Box sx={{pl: 1}}>{resourceType === ResourceType.DRIVER ? 'Bussijuht' : 'Buss'}</Box>
                        </TableCell>
                        <TableCell sx={{minWidth: 80}}>
                            {resourceType === ResourceType.DRIVER ? 'Bussi TG' : 'Juhi TG'}
                        </TableCell>
                        <TableCell sx={{minWidth: resourceType === ResourceType.VEHICLE ? 200 : 100}}>
                            <Box sx={{pl: 1}}>{resourceType === ResourceType.DRIVER ? 'Buss' : 'Bussijuht'}</Box>
                        </TableCell>
                        <TableCell sx={{minWidth: 90}}>Algus</TableCell>
                        <TableCell sx={{minWidth: 90}}>Lõpp</TableCell>
                        <TableCell sx={{minWidth: 90}}>Läbisõit</TableCell>
                        <TableCell sx={{minWidth: 70, width: '100%'}} />
                    </TableRow>
                </TableHead>
                {rows.map((row: WorkSheet) => (
                    <WorkSheetListRow
                        key={`${row.id}_${row.workGroupId}`}
                        workSheet={row}
                        availableDrivers={availableDrivers}
                        availableBuses={availableBuses}
                    />
                ))}
            </Table>
        </TableContainer>
    );
}
