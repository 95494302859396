import {combineReducers, configureStore, PreloadedState} from "@reduxjs/toolkit";
import workScheduleReducer from "./store/workScheduleSlice";
import workSheetsReducer from "./scenes/authenticated/workSheets/store/workSheetsSlice";
import plannerReducer from "./store/plannerSlice";
import busesReducer from "./store/busSlice";
import calendarEntriesReducer from "./store/calendarEntriesSlice";
import driversReducer from "./store/driverSlice";
import driverContractsReducer from "./store/driverContractSlice";
import geoPointsReducer from "./store/geoPointSlice";
import regionReducer from "./store/regionSlice";
import toastReducer from "./store/toastSlice";
import workScheduleItemsReducer from './store/workScheduleItemSlice';
import workGroupsReducer from './store/workGroupSlice';
import viewReducer from './store/viewSlice';
import currentDriverWorkSheetsSlice from  './store/currentDriverWorkSheetsSlice'
import defectsSlice from "./store/defectsSlice";
import transportContractSlice from "./store/transportContractsSlice";
import charterTripsReducer from "./store/charterTripsSlice";

const rootReducer = combineReducers({
    buses: busesReducer,
    calendarEntries: calendarEntriesReducer,
    charterTrips: charterTripsReducer,
    drivers: driversReducer,
    driverContracts: driverContractsReducer,
    geoPoints: geoPointsReducer,
    region: regionReducer,
    toast: toastReducer,
    planner: plannerReducer,
    view: viewReducer,
    workSchedule: workScheduleReducer,
    workSheets: workSheetsReducer,
    workScheduleItems: workScheduleItemsReducer,
    workGroups: workGroupsReducer,
    currentDriverWorkSheets: currentDriverWorkSheetsSlice,
    defects: defectsSlice,
    transportContracts: transportContractSlice,
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
