import {AuthErrorCodes} from "@firebase/auth";

export const mapFirebaseAuthError = (errorCode: string) => {
    switch (errorCode) {
        case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
            return 'Liiga palju sisselogimiskatseid. Proovi mõne aja pärast uuesti.';
        case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            return 'Vale e-mail või parool.';
        case AuthErrorCodes.INVALID_CODE:
            return 'Vale kinnituskood.';
        default:
            return 'Sisselogimine ebaõnnestus';
    }
};