import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, DialogActions, Typography} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete from "../../../../components/Form/Autocomplete";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {Form, Formik} from "formik";
import Button from "../../../../components/Buttons/Button";
import {Driver} from "../../../../API/driver/types";
import {selectAllActiveDrivers, selectExtraDriverIds, selectSortedActiveDriverIds} from "../store/selectors";
import {Add, Clear} from "@mui/icons-material";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {addExtraDriver, closeAddDriverDialog} from "../../../../store/workScheduleSlice";
import {formatName} from "../utils";

interface AddDriverForm {
    driver: Driver | null;
}

export function AddDriverDialog() {
    const drivers = useAppSelector(selectAllActiveDrivers);
    const existingDriverIds = useAppSelector(selectSortedActiveDriverIds);
    const extraDriverIds = useAppSelector(selectExtraDriverIds);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const dispatch = useAppDispatch();

    const initialValues: AddDriverForm = {
        driver: null,
    };

    const handleClose = () => {
        dispatch(closeAddDriverDialog());
    };

    const handleSubmit = ({driver}: AddDriverForm) => {
        if (driver && selectedRegion) {
            dispatch(addExtraDriver(driver.id));
        }
        handleClose();
    };

    const options = drivers
        .filter(driver => !existingDriverIds.includes(driver.id) && !extraDriverIds.includes(driver.id))
        .sort((a,b) => (a.lastName + a.firstName).localeCompare(b.lastName + b.firstName));

    return (
        <Dialog open={true} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">Piirkonnaväline juht</Typography>
                </DialogTitle>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form>
                            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                <Autocomplete
                                    options={options}
                                    name="driver"
                                    label="Juht"
                                    getOptionLabel={(driver) => formatName(driver, 'lastNameFirst')}
                                />
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', py: 3}}>
                                <Box maxWidth="50%">
                                    <Button
                                        text="Loobu"
                                        variant="outlined"
                                        onClick={handleClose}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button text="Lisa" type="submit" startIcon={<Add />} />
                                </Box>
                            </DialogActions>
                        </Form>
                    </Formik>
            </Box>
        </Dialog>
    );
}