import React, {ReactNode} from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {
    Check,
    Edit,
    HourglassBottomRounded,
    InfoOutlined,
    ManageHistoryRounded,
    ModeCommentOutlined,
    NotesRounded,
    PersonRounded,
    PublishedWithChanges,
    Speed,
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {ICON_COLOR, ICON_COLOR_DISABLED, ICON_SIZE_SMALL, IconSize} from "../utils";

export const staticIconTypeValues = [
    'CHECK',
    'GENERATE',
    'IN_PROGRESS',
    'PERSON',
    'HISTORY_CHANGED',
    'DESCRIPTION',
    'COMMENT',
    'EDIT',
    'VIEW',
    'HIDE',
    'ODOMETER_READING',
    'INFO',
] as const;
export type StaticIconType = typeof staticIconTypeValues[number];

interface StaticIconData {
    icon: ReactJSXElement;
    tooltipTitle?: string;
}

const getIcon = (type: StaticIconType, size: IconSize, disabled?: boolean, color?: string): StaticIconData => {
    let styles = disabled ? ICON_COLOR_DISABLED : color ? {color: color} : ICON_COLOR;
    if (size === 'small') styles = {...styles, ...ICON_SIZE_SMALL};

    switch (type) {
        case 'CHECK':
            return {icon: <Check sx={styles} />}
        case 'GENERATE':
            return {tooltipTitle: 'Genereeri', icon: <PublishedWithChanges sx={styles} />}
        case 'IN_PROGRESS':
            return {icon: <HourglassBottomRounded sx={styles} />}
        case 'PERSON':
            return {icon: <PersonRounded sx={styles} />}
        case 'HISTORY_CHANGED':
            return {icon: <ManageHistoryRounded sx={styles} />};
        case 'DESCRIPTION':
            return {icon: <NotesRounded sx={styles} />};
        case 'COMMENT':
            return {icon: <ModeCommentOutlined sx={styles} />};
        case 'EDIT':
            return {icon: <Edit sx={styles} />};
        case 'VIEW':
            return {icon: <Visibility sx={styles} />};
        case 'HIDE':
            return {icon: <VisibilityOff sx={styles} />};
        case 'ODOMETER_READING':
            return {icon: <Speed sx={styles} />};
        case 'INFO':
            return {icon: <InfoOutlined sx={styles} />};
    }
};

export interface StaticIconProps {
    type: StaticIconType;
    tooltipTitle?: string;
    disabled?: boolean;
    color?: string;
    size?: IconSize;
    noPadding?: boolean;
    replaceIcon?: ReactNode;
}

const StaticIcon = ({type, tooltipTitle, disabled, color, size = 'small', noPadding = false, replaceIcon}: StaticIconProps) => {
    const iconData = replaceIcon
        ? {icon: replaceIcon, tooltipTitle: tooltipTitle}
        : getIcon(type, size, disabled, color);

    return (
        <Tooltip title={tooltipTitle} arrow placement="top">
            <Box component="span">
                {noPadding ? iconData.icon :
                    <IconButton size={size} disabled>
                        {iconData.icon}
                    </IconButton>
                }
            </Box>
        </Tooltip>
    );
};

export default StaticIcon;
