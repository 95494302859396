import CardBase from "../../../components/CardBase";
import {isToday} from "../../../../../../../utils/utils";
import {Box, Typography} from "@mui/material";
import {Comment, StickyNote2Outlined} from "@mui/icons-material";
import React from "react";
import {WorkSheetCardData} from "../../../types";
import TimeLabel from "../../../components/TimeLabel";
import {useNavigate} from "react-router-dom";
import routes from "../../../../../../../routes";
import BusLabel from "../../../components/BusLabel";
import {getDisplayDayAndWrittenMonth} from "../../../../../../../utils/dateUtils";

interface WorkSheetCardProps {
    card: WorkSheetCardData;
}

const WorkSheetCard = ({card}: WorkSheetCardProps) => {
    const navigate = useNavigate();

    const handleCardClick = ()  => {
        navigate(routes.AUTHENTICATED.AGENDA.WORK_SHEET.replace(':id', String(card.id)));
    };

    return (
        <CardBase onClick={handleCardClick} isActive={isToday(card.date)} sx={{opacity: isToday(card.date) ? 1 : 0.8}}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: 0.5}}>
                <Typography fontWeight="bold" sx={{width: 'fit-content'}}>
                    {getDisplayDayAndWrittenMonth(card.date)}
                </Typography>
                <Typography fontWeight="bold" align="right"
                            sx={{width: 'fit-content', display: 'flex', justifyContent: 'flex-end', pl: 2}}>
                    <TimeLabel startDateTime={card.startDateTime} endDateTime={card.endDateTime} />
                </Typography>
            </Box>
            {card.workGroupCode.length > 0 &&
                <Typography variant="body2" sx={{display: 'flex', alignItems: 'center'}}>
                    <StickyNote2Outlined sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}}/>{card.workGroupCode}
                </Typography>
            }
            {card.workGroupComment.length > 0 &&
                <Typography variant="body2" color="text.secondary" sx={{display: 'flex', alignItems: 'center'}}>
                    <Comment sx={{fontSize: '16px', pr: 0.5, color: 'text.secondary'}}/>{card.workGroupComment}
                </Typography>
            }
            {card.connectedBusLicencePlateNumbers.length > 0 && <BusLabel licencePlateNumbers={card.connectedBusLicencePlateNumbers} />}
        </CardBase>
    );
};

export default WorkSheetCard;