import React, {useEffect} from "react";
import PageHeader from "../../../../components/PageHeader";
import {Box, Typography} from "@mui/material";
import DateNavigator from "../../../../components/DateNavigator";
import NavigationButton from "../../../../components/Buttons/NavigationButton";
import routes from "../../../../routes";
import WorkSheetList from "./WorkSheetList";
import {fetchWorkSheets} from "../store/actions";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {fetchBuses} from "../../../../store/busSlice";
import {fetchDrivers} from "../../../../store/driverSlice";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {setLoading} from "../store/workSheetsSlice";
import {useDayNavigation, useResourceTypeToggle} from "../../../../store/viewSlice";
import {ResourceGroupToggleButtonType, resourceGroupToggleButtonTypeValues} from "../../../../types";
import {useDebounce} from "usehooks-ts";
import {DEFAULT_DEBOUNCE_DELAY} from "../../../../constants";
import ErrorWrapper from "../../../../components/ErrorWrapper";
import {selectBusesWithDefects} from "../../../../store/workScheduleItemSlice";
import {ConstructionRounded} from "@mui/icons-material";
import BusesWithDefects from "./BusesWithDefects";
import AvailableBuses from "./AvailableBuses";
import LoaderWithChildren from "../../../../components/LoaderWithChildren";


export default function WorkSheetsListView() {
    const [date, handleChangeDate] = useDayNavigation();
    const error = useAppSelector(state => state.workSheets.error);
    const debouncedDate = useDebounce(date, DEFAULT_DEBOUNCE_DELAY);
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonType>();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const busesWithDefects = useAppSelector(selectBusesWithDefects);
    const loading = useAppSelector(state => state.workSheets.loading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchBuses());
        dispatch(fetchDrivers());
        dispatch(setLoading(true));
    }, []);

    useEffect(() => {
        dispatch(fetchWorkSheets());
    }, [debouncedDate, selectedRegion]);
    useEffect(() => {
        dispatch(setLoading(true));
    }, [date, selectedRegion]);

    return (
        <Box>
            <PageHeader
                title="Päevaplaan"
                toggleProps={{
                    options: [...resourceGroupToggleButtonTypeValues],
                    value: resourceTypeToggleButtonValue,
                    handleChange: handleResourceTypeChange,
                }}
                secondaryButton={
                    <NavigationButton text="Planeerija" route={routes.AUTHENTICATED.WORK_SHEETS.PLANNER} />
                }
                showRegionSelect
            />
            <ErrorWrapper error={error}>
                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1.5}} displayPrint="none">
                    <DateNavigator date={date} handleSelectedDateChange={handleChangeDate} unit={'day'} />
                    {busesWithDefects.length > 0 &&
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', pl: 1}}>
                            <ConstructionRounded fontSize="small" sx={{color: 'text.secondary'}} />
                            <Typography>{busesWithDefects.length}</Typography>
                        </Box>
                    }
                </Box>
                <Box sx={{mb: 1.5}} display="none" displayPrint="flex" justifyContent="space-between">
                    <Typography>{date.format('DD. MMMM YYYY')}</Typography>
                    <Typography>{selectedRegion?.name}</Typography>
                </Box>
                <LoaderWithChildren loading={loading}>
                    <WorkSheetList />
                    <AvailableBuses />
                    <BusesWithDefects />
                </LoaderWithChildren>
            </ErrorWrapper>
        </Box>
    );
}
