import {Contract, UnscheduledWorkGroup, WorkScheduleItemType} from "../../../API/types";
import {Dayjs} from "dayjs";

export interface WorkScheduleItemForm {
    type: string;
    workGroup: UnscheduledWorkGroup | null;
    comment: string;
    startDate: Dayjs;
    endDate: Dayjs;
}
export interface WorkScheduleItemRequestForm {
    type: string;
    comment: string;
}

export const WorkScheduleRequestTypes = [
    WorkScheduleItemType.FREE_DAY_REQUEST,
    WorkScheduleItemType.WORK_REQUEST,
    WorkScheduleItemType.SPECIAL_REQUEST,
    WorkScheduleItemType.MAINTENANCE,
    WorkScheduleItemType.REPAIR,
    WorkScheduleItemType.AWAY,
];

export const BusWorkScheduleRequestTypes = [
    WorkScheduleItemType.MAINTENANCE,
    WorkScheduleItemType.REPAIR,
    WorkScheduleItemType.AWAY,
];

export const DriverWorkScheduleRequestTypes = [
    WorkScheduleItemType.FREE_DAY_REQUEST,
    WorkScheduleItemType.WORK_REQUEST,
    WorkScheduleItemType.SPECIAL_REQUEST,
];

export const WorkScheduleActivityTypes = [
    WorkScheduleItemType.WORK_GROUP,
    WorkScheduleItemType.VACATION,
    WorkScheduleItemType.UNPAID_LEAVE,
    WorkScheduleItemType.SICK_LEAVE,
    WorkScheduleItemType.TRAINING,
];

export const BusWorkScheduleActivityTypes = [
    WorkScheduleItemType.WORK_GROUP,
];

export const WorkScheduleItemContraction = new Map<WorkScheduleItemType, string>([
    [WorkScheduleItemType.WORK_GROUP, 'TG'],
    [WorkScheduleItemType.VACATION, 'P'],
    [WorkScheduleItemType.UNPAID_LEAVE, 'PP'],
    [WorkScheduleItemType.SICK_LEAVE, 'H'],
    [WorkScheduleItemType.TRAINING, 'K'],
    [WorkScheduleItemType.FREE_DAY_REQUEST, 'SV'],
    [WorkScheduleItemType.WORK_REQUEST, 'ST'],
    [WorkScheduleItemType.SPECIAL_REQUEST, 'E'],
    [WorkScheduleItemType.MAINTENANCE, 'H'],
    [WorkScheduleItemType.REPAIR, 'R'],
    [WorkScheduleItemType.AWAY, 'E'],
]);

export const DriverContractTypeContractions = new Map<Contract, string>([
    [Contract.EMPLOYMENT_CONTRACT, 'TL'],
    [Contract.SERVICE_CONTRACT, 'KL'],
    [Contract.LABOR_LEASING_AGREEMENT, 'RL'],
]);

export interface WorkScheduleRowStats {
    nominalHours: number;
    plannedHours: number;
    difference: number;
    contractType: Contract;
    weeklyHours: number;
    workedDays: number;
    averageDailyHours: number;
    workedWeekendDays: number;
    reserveDays: number;
    nonWorkToWorkRatio: number;
}

export const workScheduleTypes = ['KINNITATUD', 'JOOKSEV'] as const;
export type WorkScheduleTypeToggleButtonType = typeof workScheduleTypes[number];
