import {
    CopyOppositeWorkGroupRequest,
    MoveWorkGroupItem,
    ResourceType,
    SplitWorkGroupRequest,
    WorkGroup,
    WorkGroupActivity,
} from "./types";
import axios from "axios";
import {handleError} from "../utils";
import {Dayjs} from "dayjs";

const cache = new Map<string, WorkGroup[]>([]);

export const loadWorkGroups = (regionId: number) =>
    axios.get<WorkGroup[]>(`/api/regions/${regionId}/work-groups`)
        .then(result => result.data)
        .catch(handleError);

export const loadWorkGroupsOnDate = (regionId: number, type: ResourceType, date: Dayjs) =>
    axios.get<WorkGroup[]>(`/api/regions/${regionId}/work-groups?type=${type}&date=${date.format('YYYY-MM-DD')}`)
        .then(result => result.data)
        .catch(handleError);

export const loadWorkGroupDetails = (id: string | number) =>
    axios.get<WorkGroup>(`/api/work-groups/${id}`)
        .then(result => result.data);

export const updateWorkGroup = (workGroup: WorkGroup) => {
    cache.clear();

    return axios.put(`/api/work-groups`, workGroup)
        .then(result => result.data)
        .catch(handleError);
};

export const createWorkGroup = (workGroup: WorkGroup) => {
    cache.clear();

    return axios.post<WorkGroup>(`/api/work-groups`, workGroup)
        .then(result => result.data)
        .catch(handleError);
};

export const splitWorkGroup = (workGroupId: number | string, date: string, splitWorkGroup: SplitWorkGroupRequest) => {
    return axios.post<WorkGroup[]>(`/api/work-groups/${workGroupId}/split/${date}`, splitWorkGroup)
        .then(result => result.data)
        .catch(handleError);
};

export const moveTripDefinition = (tripDefinitionId: number, moveWorkGroupItem: MoveWorkGroupItem) => {
    cache.clear();

    return axios.post(`/api/work-group-items/TRIP_DEFINITION-${tripDefinitionId}/move`, moveWorkGroupItem)
        .then(result => result.data)
        .catch(handleError);
};

export const createWorkGroupActivity = (workGroupActivity: WorkGroupActivity, workGroupId: number, date: string) => {
    cache.clear();

    return axios.post<WorkGroupActivity>('/api/work-group-activities', {...workGroupActivity, workGroupId: workGroupId, date: date})
        .then(result => result.data)
        .catch(handleError);
};

export const updateWorkGroupActivity = (workGroupActivityId: number | string, workGroupActivity: WorkGroupActivity) => {
    cache.clear();

    return axios.put(`/api/work-group-activities/${workGroupActivityId}`, workGroupActivity)
        .then(result => result.data)
        .catch(handleError);
};

export const addActivityToWorkGroup = (workGroupActivityId: number | string, workGroupId: number | string, date: string) => {
    cache.clear();

    return axios.put(`/api/work-group-activities/${workGroupActivityId}/work-groups/${workGroupId}/${date}`)
        .then(result => result.data)
        .catch(handleError);
};

export const removeActivityFromWorkGroup = (workGroupActivityId: number | string, workGroupId: number | string, date: string) => {
    cache.clear();

    return axios.delete(`/api/work-group-activities/${workGroupActivityId}/work-groups/${workGroupId}/${date}`)
        .then(result => result.data)
        .catch(handleError);
};

export const copyOppositeWorkGroup = (originalWorkGroupId: string | number, data: CopyOppositeWorkGroupRequest) => {
    return axios.post<WorkGroup>(`/api/work-groups/${originalWorkGroupId}/copy-to-opposite`, data)
        .then(result => result.data)
        .catch(handleError);
};

export const divideWorkGroup = (workGroupId: string | number, date: string) => {
    cache.clear();

    return axios.put(`/api/work-groups/${workGroupId}/versions/${date}`)
        .then(result => result.data)
        .catch(handleError);
};
