import {RoutePoint} from "../../../../../../../API/types";
import {getStartAndEndPointLabel} from "../../../../../../../utils/utils";
import React from "react";
import TooltipRow from "./TooltipRow";

const StartAndEndPoint = ({route}: { route: RoutePoint[] | string | null }) => {
    const startAndEndPoint = getStartAndEndPointLabel(route);
    if (!startAndEndPoint) return null;

    return <TooltipRow>{startAndEndPoint}</TooltipRow>;
};

export default StartAndEndPoint;