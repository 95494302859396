import React from "react";
import TooltipRowWithLink from "./components/TooltipRowWithLink";
import routes from "../../../../../../routes";
import {RepetitionRules, RoutePoint, WorkGroup} from "../../../../../../API/types";
import StartAndEndTime from "./components/StartAndEndTime";
import Distance from "./components/Distance";
import StartAndEndPoint from "./components/StartAndEndPoint";
import Repetition from "./components/Repetition";
import OppositeWorkSheet from "./components/OppositeWorkSheet";
import OppositeWorkGroup from "./components/OppositeWorkGroup";
import SeasonalValidity from "./components/SeasonalValidity";
import Validity from "./components/Validity";
import {Box} from "@mui/material";


interface TripTooltipContentProps {
    trip: {
        id: number;
        code: string;
        startTime: string;
        startTimeIsOnNextDay: boolean;
        endTime: string;
        endTimeIsOnNextDay: boolean;
        seasonalStartDate: string | null;
        seasonalEndDate: string | null;
        validFrom: string | null;
        validTo: string | null;
        route: RoutePoint[] | null;
        distance: number | null;
        repetition: RepetitionRules | undefined;
        workSheetsOfOppositeType?: number[];
        workGroupsOfOppositeType?: WorkGroup[];
    };
}

const TripTooltipContent = ({trip}: TripTooltipContentProps) => {
    const oppositeWorkGroup = trip.workGroupsOfOppositeType && trip.workGroupsOfOppositeType.at(0);

    return (
        <>
            <TooltipRowWithLink
                text={trip.code?.toString() ?? ''}
                route={routes.AUTHENTICATED.TRIPS.EDIT.replace(':id', trip.id.toString())}
            />
            <StartAndEndTime {...trip} />
            <StartAndEndPoint route={trip.route ?? null} />
            <Distance distance={trip.distance} />
            <Box sx={{'& :first-of-type': {mt: 0.5}}}>
                <Validity {...trip} />
                <SeasonalValidity {...trip} />
            </Box>
            <Repetition repetition={trip.repetition} />
            <OppositeWorkSheet workSheetIds={trip.workSheetsOfOppositeType ?? []} />
            <OppositeWorkGroup workGroup={oppositeWorkGroup} />
        </>
    )
};


export default TripTooltipContent;
