import {createAsyncThunk, createSlice, EntityId} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../store";
import dayjs, {Dayjs} from "dayjs";
import {ResourceType} from "../API/workGroup/types";
import {loadMonthWorkScheduleForBuses, loadMonthWorkScheduleForDrivers} from "../API";
import {WorkGroup, WorkGroupItem} from "./workGroupSlice";
import {UnscheduledWorkGroup, WorkItem, WorkScheduleItem} from "./workScheduleItemSlice";
import {DriverPosition, WorkScheduleItemStatus, WorkScheduleItemType} from "../API/workSchedule/types";
import {
    addWorkScheduleItem,
    changeWorkScheduleItem,
    confirmWorkSchedule,
    removeWorkScheduleItem,
    updateDriversOrder
} from "../scenes/authenticated/workSchedule/store/actions";
import {WorkScheduleTypeToggleButtonType} from "../scenes/authenticated/workSchedule/types";
import {WorkScheduleItemDialogData} from "../scenes/authenticated/workSchedule/components/WorkScheduleItemDialog";
import {selectToggledResourceType} from "./viewSlice";
import {mapErrorToDisplayMessage} from "../utils/errorMapping";
import {getDefectsByRegionAndDateRange} from "../API/defects/api";
import {DefectSimple} from "../API/defects/types";
import {selectSelectedRegion} from "./regionSlice";


interface WorkScheduleState {
    loading: boolean;
    view: WorkScheduleViewState;
    extraDriverIds: EntityId[];
    error: string | undefined;
}

interface WorkScheduleViewState {
    month: string;
    scheduleType: WorkScheduleTypeToggleButtonType;
    dialogData?: WorkScheduleItemDialogData;
    addDriverDialogOpen: boolean;
    selectedDay?: string;
    editModeData: EditModeData;
    driverSortingPositions: DriverPosition[];
}

interface EditModeData {
    isActive: boolean;
    selectedWorkGroup?: UnscheduledWorkGroup;
}

const currentMonth = dayjs().set('date', 1).add(1, 'month');

interface WorkScheduleData {
    workGroups: WorkGroup[];
    workGroupItems: WorkGroupItem[];
    workScheduleItems: WorkScheduleItem[];
    workItems: WorkItem[];
    driverSortingPositions: DriverPosition[];
    defects: DefectSimple[];
}

export const fetchWorkSchedule = createAsyncThunk<
    any,
    void,
    {
        state: RootState,
        dispatch: AppDispatch,
    }
>(
    'workGroups/fetch',
    async (_, {getState}): Promise<WorkScheduleData> => {
        const state = getState();
        const selectedRegionId = state.region.selected;
        const month = state.workSchedule.view.month;
        const date = dayjs(month);
        const resourceToggleValue = selectToggledResourceType(state);

        const [
            confirmed,
            unconfirmed,
            sortingPositions,
        ] = resourceToggleValue === ResourceType.DRIVER
            ? await loadMonthWorkScheduleForDrivers(selectedRegionId, date)
            : await loadMonthWorkScheduleForBuses(selectedRegionId, date);

        const defects = resourceToggleValue === ResourceType.VEHICLE
            ? await getDefectsByRegionAndDateRange(selectedRegionId, date, date.endOf('month')) : [];

        return {
            workGroups: [
                ...unconfirmed.workGroups.map(workGroup => ({
                    id: workGroup.id,
                    versionId: workGroup.versionId,
                    validFrom: workGroup.validFrom,
                    validTo: workGroup.validTo,
                    code: workGroup.code,
                    type: workGroup.type,
                    workGroupItemIds: [
                        ...workGroup.activityIds.map(id => `activity-${id}`),
                        ...workGroup.tripDefinitionIds.map(id => `trip-definition-${id}`),
                    ]
                })),
                ...confirmed.workGroups.map(workGroup => ({
                    id: workGroup.id,
                    versionId: workGroup.versionId,
                    validFrom: workGroup.validFrom,
                    validTo: workGroup.validTo,
                    code: workGroup.code,
                    type: workGroup.type,
                    workGroupItemIds: [
                        ...workGroup.activityIds.map(id => `activity-${id}`),
                        ...workGroup.tripDefinitionIds.map(id => `trip-definition-${id}`),
                    ]
                })),
            ],
            workGroupItems: [
                ...unconfirmed.activities.map(item => ({
                    id: item.id,
                    type: item.type,
                    startTime: item.startTime,
                    startTimeIsOnNextDay: item.startTimeIsOnNextDay,
                    endTime: item.endTime,
                    endTimeIsOnNextDay: item.endTimeIsOnNextDay,
                    distance: item.distance,
                })),
                ...unconfirmed.tripDefinitions.map(item => ({
                    id: item.id,
                    type: item.type,
                    startTime: item.startTime,
                    startTimeIsOnNextDay: item.startTimeIsOnNextDay,
                    endTime: item.endTime,
                    endTimeIsOnNextDay: item.endTimeIsOnNextDay,
                    distance: item.distance,
                }))
            ],
            workScheduleItems: [
                ...unconfirmed.workScheduleItems.map(item => ({
                    id: item.id,
                    workGroupId: item.workGroupId,
                    type: item.type,
                    resourceId: item.resourceId,
                    resourceType: item.resourceType,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    status: item.status,
                    regionId: item.regionId,
                    comment: item.comment ?? '',
                    confirmedAt: item.confirmedAt,
                    workItemIds: item.workItemIds,
                })),
                ...confirmed.workScheduleItems.map(item => ({
                    id: item.id,
                    workGroupId: item.workGroupId,
                    type: item.type,
                    resourceId: item.resourceId,
                    resourceType: item.resourceType,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    status: item.status,
                    regionId: item.regionId,
                    comment: item.comment ?? '',
                    confirmedAt: item.confirmedAt,
                    workItemIds: [],
                })),
            ],
            workItems: [...unconfirmed.workItems, ...confirmed.workItems].map(item => ({
                id: item.id ?? 0,
                type: item.type,
                startDateTime: item.startDateTime,
                endDateTime: item.endDateTime,
                distance: item.distance ?? 0,
                comment: item.comment,
                driverWorkSheetId: item.driverWorkSheetId,
                busWorkSheetId: item.busWorkSheetId,
            })),
            driverSortingPositions: sortingPositions,
            defects: defects,
        };
    },
);

export const updateWorkScheduleItemOnDay = (id: EntityId, day: Dayjs) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const confirmed = state.workSchedule.view.scheduleType === 'KINNITATUD';

    if (confirmed) return;

    const isEditModeActive = state.workSchedule.view.editModeData.isActive;
    const selectedEditModeWorkGroup = state.workSchedule.view.editModeData.selectedWorkGroup;
    const selectedRegion = selectSelectedRegion(state);
    const resourceType = selectToggledResourceType(state);

    if (isEditModeActive && selectedEditModeWorkGroup) {
        dispatch(addWorkScheduleItem({
            comment: '',
            regionId: selectedRegion?.id ?? 0,
            status: WorkScheduleItemStatus.SCHEDULED,
            type: WorkScheduleItemType.WORK_GROUP,
            workGroupId: selectedEditModeWorkGroup.id,
            workItemIds: [],
            id: undefined,
            resourceType: resourceType,
            resourceId: Number(id),
            startDate: day.format('YYYY-MM-DD'),
            endDate: day.format('YYYY-MM-DD'),
        }));
    } else {
        dispatch(setDialogData({
            resourceId: id,
            date: day.format('YYYY-MM-DD'),
        }));
    }
};

const initialState: WorkScheduleState = {
    loading: true,
    view: {
        month: currentMonth.format('YYYY-MM'),
        scheduleType: 'JOOKSEV',
        dialogData: undefined,
        addDriverDialogOpen: false,
        selectedDay: undefined,
        editModeData: {
            isActive: false,
            selectedWorkGroup: undefined
        },
        driverSortingPositions: [],
    },
    extraDriverIds: [],
    error: undefined,
};

export const workScheduleSlice = createSlice({
    name: 'workSchedule',
    initialState: initialState,
    reducers: {
        setMonth: (state, action) => {
            state.view = {
                ...state.view,
                month: action.payload,
                selectedDay: undefined,
                scheduleType: 'JOOKSEV',
            };
            state.loading = true;
        },
        setScheduleType: (state, action) => {
            state.view.scheduleType = action.payload;
        },
        setDialogData: (state, action) => {
            state.view.dialogData = action.payload;
        },
        setSelectedDay: (state, action) => {
            state.view.selectedDay = action.payload;
        },
        addExtraDriver: (state, action) => {
            state.extraDriverIds.push(action.payload);
        },
        resetExtraDrivers: (state) => {
            state.extraDriverIds = [];
        },
        openAddDriverDialog: (state) => {
            state.view.addDriverDialogOpen = true;
        },
        closeAddDriverDialog: (state) => {
            state.view.addDriverDialogOpen = false;
        },
        setIsEditModeActive: (state, action) => {
            state.view.editModeData = {isActive: action.payload, selectedWorkGroup: undefined};
        },
        setSelectedEditModeWorkGroup: (state, action) => {
            state.view.editModeData.selectedWorkGroup = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateDriversOrder.fulfilled, (state, action) => {
            state.view.driverSortingPositions = action.payload;
        });

        builder.addCase(confirmWorkSchedule.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(confirmWorkSchedule.fulfilled, (state) => {
            state.loading = false;
        });

        builder.addCase(fetchWorkSchedule.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });

        builder.addCase(fetchWorkSchedule.rejected, (state, action) => {
            state.loading = false;
            state.error = mapErrorToDisplayMessage('tööajakava', action.error.message);
        });

        builder.addCase(fetchWorkSchedule.fulfilled, (state, action) => {
            state.loading = false;
            state.view.driverSortingPositions = action.payload.driverSortingPositions;
        });

        builder.addCase(removeWorkScheduleItem.fulfilled, state => {
            state.view.dialogData = undefined;
        });

        builder.addCase(addWorkScheduleItem.fulfilled, state => {
            state.view.dialogData = undefined;
        });

        builder.addCase(changeWorkScheduleItem.fulfilled, state => {
            state.view.dialogData = undefined;
        });
    }
});

export const {
    setMonth,
    setScheduleType,
    setDialogData,
    setSelectedDay,
    addExtraDriver,
    resetExtraDrivers,
    openAddDriverDialog,
    closeAddDriverDialog,
    setIsEditModeActive,
    setSelectedEditModeWorkGroup,
} = workScheduleSlice.actions;

export default workScheduleSlice.reducer;
