import {WorkGroup, WorkGroupActivity, WorkGroupItemType, WorkGroupTripDefinition} from "../../../../API/workGroup/types";
import dayjs, {Dayjs} from "dayjs";
import {Region} from "../../../../API/region/types";
import {Columns, Row, SheetData} from "write-excel-file";
import {getWorkGroupItemTypeTranslation} from "../../../../utils/enumTranslations";
import {sortByStartTime} from "../../../../utils/utils";
import {getOrderedWorkGroups, getRouteTxt, getWeekDaysTxt} from "./workGroupReportFormatter";
import {getDisplayTimeWithModifier} from "../../../../utils/dateUtils";


function getActivities(items: (WorkGroupActivity | WorkGroupTripDefinition)[]): SheetData {
    const result: Row[] = [];

    items.forEach(item => {
        const tripDefinition = item as WorkGroupTripDefinition;
        result.push([
            {value: getWorkGroupItemTypeTranslation(item.type)},
            {value: (tripDefinition.lineNumber ? tripDefinition.lineNumber : ''), fontWeight: 'bold'},
            {value: tripDefinition.route ? getRouteTxt(tripDefinition.route) : ''},
            {value: getDisplayTimeWithModifier(item.startTime, item.startTimeIsOnNextDay)},
            {value: getDisplayTimeWithModifier(item.endTime, item.endTimeIsOnNextDay)},
            {value: item.distance ? item.distance.toString() : ''},
        ]);
    });

    return result;
}

function composeReportHeader(region: Region, start: dayjs.Dayjs, end: dayjs.Dayjs): SheetData {
    return [
        [
            {
                value: `GoBus ${region.name} töögrupid ${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`,
                fontWeight: 'bold',
                fontSize: 16,
                span: 4
            },
            null, null, null, null, null
        ],
        []
    ];
}

function composeReportBody(data: WorkGroup[]) {
    const body: SheetData = [];

    data.forEach((workGroup) => {
        workGroup.versions?.forEach((version) => {
            const items: (WorkGroupActivity | WorkGroupTripDefinition)[] = [
                ...version.tripDefinitions,
                ...version.activities.filter(activity => activity.type === WorkGroupItemType.RESERVE)
            ].sort(sortByStartTime);

            if (items.length) {
                body.push(
                    [
                        {
                            value: `Bussijuhi töögrupp ${workGroup.code} (${getWeekDaysTxt(workGroup, true)})`,
                            fontWeight: 'bold',
                            span: 4
                        },
                        null, null, null, null, null
                    ],
                    ...getActivities(items)
                );
            }
        });
    });

    return body;
}

export const formatWorkGroupSimplifiedReportData = (data: WorkGroup[], start: Dayjs, end: Dayjs, region: Region): [SheetData, Columns] => {
    const reportHeader = composeReportHeader(region, start, end);
    const body = composeReportBody(getOrderedWorkGroups(data));

    return [reportHeader.concat(body), [
        { width: 10 },
        { width: 6 },
        { width: 50 },
        { width: 8 },
        { width: 8 },
        { width: 6 },
    ]];
}