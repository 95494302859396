import React, {useState} from 'react';
import {Box, Typography, useTheme} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, DateView} from "@mui/x-date-pickers";
import 'dayjs/locale/et';
import dayjs, {Dayjs, ManipulateType} from 'dayjs';
import Button from "../Buttons/Button";

dayjs.locale('et')

type Unit = Extract<ManipulateType, 'day' | 'month'>;

interface DateNavigatorProps {
    date: Dayjs;
    handleSelectedDateChange: (date: Dayjs) => void;
    unit: Unit;
    defaultDate?: Dayjs;
}

const DateNavigator = ({ date, handleSelectedDateChange, unit, defaultDate }: DateNavigatorProps) => {
    const theme = useTheme();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const selectedDate = date.startOf(unit);
    const resetDate = defaultDate ?? dayjs().startOf(unit);

    const handleMoveBackward = () => handleSelectedDateChange(selectedDate.subtract(1, unit));

    const handleMoveForward = () => handleSelectedDateChange(selectedDate.add(1, unit));

    const updateDate = (date: Dayjs | null) => {
        if (date) {
            handleSelectedDateChange(date);
        }
        setIsDatePickerOpen(false);
    };

    const handleSetDatePickerOpen = () => {
        setIsDatePickerOpen(open => !open);
    }

    const getFormat = () => {
        switch(unit) {
            case 'day': return 'dd DD.MM.YYYY';
            case 'month': return  'MMMM YYYY';
        }
    }

    const getViews = (): DateView[] => {
        switch(unit) {
            case 'day': return ['day', 'month', 'year'];
            case 'month': return ['month', 'year'];
        }
    }

    const getResetLabel = () => {
        switch(unit) {
            case 'day': return 'Täna';
            case 'month': return resetDate.format('MMMM YYYY');
        }
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', width: 'fit-content', maxHeight: '26px'}}>
            <Box sx={{
                display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center', cursor: 'pointer',
                border: `1px solid ${theme.palette.divider}`, borderRadius: '5px', backgroundColor: theme.palette.common.white
            }}>
                <ChevronLeft onClick={() => handleMoveBackward()} aria-label="Tagasi" />
                {isDatePickerOpen &&
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
                        <DatePicker
                            views={getViews()}
                            open={isDatePickerOpen}
                            onClose={() => {
                                handleSetDatePickerOpen();
                            }}
                            value={selectedDate}
                            onChange={updateDate}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    sx: {
                                        width: 0,
                                        height: 0,
                                        opacity: 0
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                }
                <Box onClick={handleSetDatePickerOpen} sx={{minWidth: '122px'}}>
                    <Typography variant="body2" align="center" sx={{
                        px: 1, py: 0.3, borderLeft: '1px solid', borderRight: '1px solid', borderColor: theme.palette.divider
                    }}>
                        {selectedDate.format(getFormat())}
                    </Typography>
                </Box>
                <ChevronRight onClick={() => handleMoveForward()} aria-label="Edasi" />
            </Box>
            <Button onClick={() => updateDate(resetDate)}
                    disabled={selectedDate.isSame(resetDate, unit)}
                    size="small"
                    color="secondary"
                    styles={{ml: 1, height: '26px', lineHeight: 'normal'}}
                    text={getResetLabel()}
                    variant="outlined"
            />
        </Box>
    )
};

export default DateNavigator;