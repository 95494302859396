import {useAuth} from "../../../../../../contexts/AuthContext";
import {useAppSelector} from "../../../../../../hooks";
import {selectWorkSheetCards} from "../../../../../../store/currentDriverWorkSheetsSlice";
import {shallowEqual} from "react-redux";
import {Box} from "@mui/material";
import WorkSheetCard from "./components/WorkSheetCard";
import React from "react";
import {renderUserGreeting} from "../../../index";

const ListView = () => {
    const {authenticatedUser} = useAuth();
    const data = useAppSelector(selectWorkSheetCards, shallowEqual);

    return (
        <>
            <Box sx={{display: {xs: 'none', sm: 'flex'}, width: '100%'}}>
                {renderUserGreeting(authenticatedUser)}
            </Box>
            {data.map(card =>
                <WorkSheetCard key={card.id} card={card}/>
            )}
        </>
    );
};

export default ListView;
