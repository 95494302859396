import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "../../../../components/Buttons/Button";
import {CalendarEntryType} from "../../../../API/types";
import {Box} from "@mui/material";
import {CalendarEntryForm} from "../types";
import {Form, Formik, FormikHelpers, FormikProps} from 'formik';
import * as Yup from 'yup';
import DatePicker from "../../../../components/Form/DatePicker";
import SubmitErrorListener from "../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {dateValidTo, validationSchema} from "../../../../utils/formValidation";
import Select from "../../../../components/Form/Select";
import {getCalendarEntryTypeTranslationFromStr} from "../../../../utils/enumTranslations";
import TextField from "../../../../components/Form/TextField";
import {Clear} from "@mui/icons-material";


const calendarEntryValidationSchema = Yup.object().shape({
    type: validationSchema('Tüüp').fields.textFieldRequired,
    validFrom: validationSchema('Kuupäev').fields.dateRequired,
    validTo: dateValidTo('Lõppkuupäev', 'validFrom'),
    comment: validationSchema('Kommentaar').fields.longTextField,
});

const defaultValues: CalendarEntryForm = {
    type: '',
    validFrom: null,
    validTo: null,
    comment: '',
};

interface CalendarEntryDialogProps {
    open: boolean;
    handleSubmit: (form: CalendarEntryForm, formHelpers: FormikHelpers<CalendarEntryForm>) => void;
    handleCloseDialog: () => void;
}

export default function CalendarDialog({open, handleSubmit, handleCloseDialog}: CalendarEntryDialogProps) {
    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Box sx={{width: '400px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{width: '300px', maxWidth: '100%'}}>Uus kalendrikirje</DialogTitle>
                <Formik initialValues={defaultValues} validationSchema={calendarEntryValidationSchema} onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<CalendarEntryForm>) =>
                        <Form>
                            <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                <Select
                                    name="type"
                                    label="Kalendrikirje tüüp*"
                                    options={Object.values(CalendarEntryType)}
                                    translationFunction={getCalendarEntryTypeTranslationFromStr}
                                    translationEnumType={CalendarEntryType}
                                />
                                <DatePicker name="validFrom" label="Kuupäev*" />
                                <DatePicker name="validTo" label="Lõppkuupäev" />
                                <TextField name="comment" label="Kommentaar" multiline minRows={2} maxRows={5} />
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                <Box maxWidth="50%">
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        text="Loobu"
                                        color="secondary"
                                        startIcon={<Clear />}
                                        onClick={handleCloseDialog}
                                    />
                                </Box>
                                <Box maxWidth="50%">
                                    <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit" />
                                </Box>
                            </DialogActions>
                            <SubmitErrorListener
                                isValid={formikProps.isValid}
                                isValidating={formikProps.isValidating}
                                isSubmitting={formikProps.isSubmitting}
                            />
                        </Form>
                    }
                </Formik>
            </Box>
        </Dialog>
    );
}
