import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityId,
    EntityState,
    PayloadAction
} from "@reduxjs/toolkit";
import {CharterTrip as ApiCharterTrip, CharterOrder, SaveCharterTrip} from "../API/types";
import {
    createCharterTrip,
    deleteCharterTrip as apiDeleteCharterTrip,
    loadCharterTrips,
    updateCharterTrip
} from "../API";
import {AppDispatch, RootState} from "../store";
import {setToast} from "./toastSlice";
import {mapErrors} from "../utils/errorMapping";
import {getCharterTripStatusTranslation} from "../utils/enumTranslations";
import {
    getDateString,
    getDisplayDateTime,
    getStrDateTimeFromDateWithModifier
} from "../utils/dateUtils";
import {decimalToDisplayStr, strToDecimal} from "../utils/utils";
import {CharterTripForm} from "../scenes/authenticated/charterTrips/detailsView/types";
import {FormikHelpers} from "formik";
import dayjs from "dayjs";


interface CharterTrip extends Omit<ApiCharterTrip, 'orderNumber' | 'client'> {
    orderNumber: EntityId;
}

const charterTripAdapter = createEntityAdapter<CharterTrip>({
    sortComparer: (a, b) => b.startDateTime.localeCompare(a.startDateTime),
});
const charterOrderAdapter = createEntityAdapter<CharterOrder>({
    selectId: (charterOrder: CharterOrder) => charterOrder.orderNumber,
    sortComparer: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
});

export const fetchCharterTrips = createAsyncThunk<
    {
        charterTrips: CharterTrip[],
        charterOrders: CharterOrder[],
    },
    void,
    { dispatch: AppDispatch }
>(
    'charterTrips/getAll',
    async (_, { dispatch }) => {
        return loadCharterTrips()
            .then((result) => {
                const charterOrders: CharterOrder[] = [];

                result.forEach(charterTrip => {
                    if (!charterOrders.find(charterOrder => charterOrder.orderNumber === charterTrip.orderNumber)) {
                        charterOrders.push({
                            orderNumber: charterTrip.orderNumber,
                            client: charterTrip.client
                        })
                    }
                });

                return ({
                    charterTrips: result.map(charterTrip => ({
                        ...charterTrip,
                        charterOrder: charterTrip.orderNumber
                    })),
                    charterOrders: charterOrders
                })
            })
            .catch(apiError => {
                dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Tellimusvedude laadimisel tekkis viga'}));
                throw Error;
            });
    }
);

export const saveCharterTrip = createAsyncThunk<
    void,
    { id: string | number | undefined, form: CharterTripForm, formHelpers: FormikHelpers<CharterTripForm>, handleCreateSuccess: (newId: number) => void },
    { dispatch: AppDispatch }
>(
    'charterTrips/save',
    async ({id, form, formHelpers, handleCreateSuccess}, { dispatch }) => {
            if (!form.startTime || !form.endTime || !form.date || !form.transportContractId) {
                dispatch(setToast({type: 'error', text: 'Vormis esineb vigu.'}));
                throw Error;
            }

            const dateStr = getDateString(form.date);
            const request: SaveCharterTrip = {
                ...form,
                orderNumber: form.orderNumber === '' ? null : form.orderNumber,
                startDateTime: getStrDateTimeFromDateWithModifier(form.startTime, form.startTimeIsOnNextDay, dateStr),
                endDateTime: getStrDateTimeFromDateWithModifier(form.endTime, form.endTimeIsOnNextDay, dateStr),
                date: dateStr,
                distance: strToDecimal(form.distance),
                numberOfPassengers: strToDecimal(form.numberOfPassengers),
                price: strToDecimal(form.price),
                transportContractId: form.transportContractId,
                comment: form.comment.length > 0 ? form.comment : undefined,
            };

            if (id) {
                return await updateCharterTrip(Number(id), request)
                    .then(result => {
                        dispatch(setToast({type: 'success', text: 'Tellimusvedu edukalt uuendatud'}));
                        dispatch(setCharterTrip({
                            charterTrip: {...result},
                            charterOrder: {orderNumber: result.orderNumber, client: result.client}
                        }));
                    })
                    .catch(error => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tellimusveo uuendamisel esines tõrge'}));
                        throw Error;
                    })
                    .finally(() => {
                        formHelpers.setSubmitting(false);
                    });
            } else {
                return await createCharterTrip(request)
                    .then((result) => {
                        dispatch(setToast({type: 'success', text: 'Tellimusvedu edukalt loodud'}));
                        dispatch(setCharterTrip({
                            charterTrip: {...result},
                            charterOrder: {orderNumber: result.orderNumber, client: result.client}
                        }));
                        handleCreateSuccess(result.id);
                    })
                    .catch(error => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tellimusveo loomisel esines tõrge'}));
                        throw Error;
                    })
                    .finally(() => {
                        formHelpers.setSubmitting(false);
                    });
            }
    }
);

export const setCharterTripAsInvoiced = createAsyncThunk<
    CharterTrip,
    { id: string | number },
    { state: RootState, dispatch: AppDispatch }
>(
    'charterTrips/setAsInvoiced',
    async ({id}, { getState, dispatch }) => {
        const state = getState();
        const charterTrip = selectCharterTripById(state, id);
        const charterOrder = selectCharterOrderById(state, charterTrip?.orderNumber ?? 0);
        if (!charterTrip || !charterOrder) throw Error;

        const request: SaveCharterTrip = {
            ...charterTrip,
            orderNumber: charterOrder.orderNumber,
            client: charterOrder.client,
            isInvoiced: true,
            transportContractId: charterTrip.transportContract.id
        };

        return await updateCharterTrip(Number(id), request)
            .then(result => {
                dispatch(setToast({type: 'success', text: 'Tellimusveo arve edukalt väljastatuks märgitud'}));
                return {...result};
            })
            .catch((apiError) => {
                throw Error(mapErrors(apiError) ?? 'Tellimusveo arve väljastatuks märkimisel esines tõrge');
            });
    }
);

export const deleteCharterTrip = createAsyncThunk<
    EntityId,
    { id: string | number },
    { dispatch: AppDispatch }
>(
    'charterTrips/delete',
    async ({id}, { dispatch }) => {
        return await apiDeleteCharterTrip(id)
            .then(() => {
                return id;
            })
            .catch(apiError => {
                dispatch(setToast({
                    type: 'error',
                    text: mapErrors(apiError) ?? 'Tellimusveo kustutamisel tekkis viga'
                }));
                throw Error;
            });
    }
);

const initialState: {
    charterTrips: EntityState<CharterTrip>;
    charterOrders: EntityState<CharterOrder>;
} = {
    charterTrips: charterTripAdapter.getInitialState(),
    charterOrders: charterOrderAdapter.getInitialState(),
};

export const charterTripsSlice = createSlice({
    name: 'charterTrips',
    initialState: initialState,
    reducers: {
        setCharterTrip: (state, action: PayloadAction<{charterTrip: CharterTrip, charterOrder: CharterOrder}>) => {
            charterTripAdapter.upsertOne(state.charterTrips, action.payload.charterTrip);
            charterOrderAdapter.upsertOne(state.charterOrders, action.payload.charterOrder);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCharterTrips.fulfilled, (state, action) => {
            charterTripAdapter.setAll(state.charterTrips, action.payload.charterTrips);
            charterOrderAdapter.setAll(state.charterOrders, action.payload.charterOrders);
        });
        builder.addCase(setCharterTripAsInvoiced.fulfilled, (state, action) => {
            charterTripAdapter.upsertOne(state.charterTrips, action.payload);
        });
        builder.addCase(deleteCharterTrip.fulfilled, (state, action) => {
            charterTripAdapter.removeOne(state.charterTrips, action.payload);
        });
    },
});

export const {
    setCharterTrip,
} = charterTripsSlice.actions;

export const {
    selectAll: selectAllCharterTrips,
    selectById: selectCharterTripById,
} = charterTripAdapter.getSelectors<RootState>(state => state.charterTrips.charterTrips);

export const {
    selectAll: selectAllCharterOrders,
    selectById: selectCharterOrderById,
} = charterOrderAdapter.getSelectors<RootState>(state => state.charterTrips.charterOrders);

export const selectCharterTripsWithSearchFields = createSelector(
    selectAllCharterTrips,
    selectAllCharterOrders,
    (charterTrips, charterOrders): ApiCharterTrip[] => {
        return charterTrips.map(charterTrip => ({
            ...charterTrip,
            orderNumber: charterTrip.orderNumber.toString(),
            client: charterOrders.find(charterOrder => charterOrder.orderNumber === charterTrip.orderNumber)?.client ?? '',
            displayDateTime: `${getDisplayDateTime(charterTrip.startDateTime)}-${dayjs(charterTrip.endDateTime).format('HH:mm')}`,
            displayDistance: decimalToDisplayStr(charterTrip.distance),
            displayNumberOfPassengers: decimalToDisplayStr(charterTrip.numberOfPassengers),
            displayPrice: decimalToDisplayStr(charterTrip.price),
            displayStatus: getCharterTripStatusTranslation(charterTrip.status),
            displayRegion: charterTrip.transportContract.regionName,
            otherSearchFields: `${charterTrip.id}, ${charterTrip.orderNumber}`
        }));
    }
);

export const selectCharterTripByIdWithOrder = createSelector(
    selectCharterTripById,
    selectAllCharterOrders,
    (charterTrip, charterOrders): ApiCharterTrip | undefined =>
        charterTrip ? {
            ...charterTrip,
            orderNumber: charterTrip.orderNumber.toString(),
            client: charterOrders.find(charterOrder => charterOrder.orderNumber === charterTrip.orderNumber)?.client ?? '',
        } : undefined
);

export const selectTripsCountWithSelectedOrderId = createSelector(
    (_: RootState, orderNumber: string) => orderNumber,
    selectAllCharterTrips,
    (orderNumber, charterTrips): number =>
        charterTrips.filter(charterTrip => charterTrip.orderNumber === orderNumber).length
);

export default charterTripsSlice.reducer;
