import {WorkGroup, WorkGroupActivity, WorkGroupTripDefinition, WorkGroupVersion} from "../../../../API/workGroup/types";
import dayjs, {Dayjs} from "dayjs";
import {Region} from "../../../../API/region/types";
import {Columns, Row, SheetData} from "write-excel-file";
import {getScheduleSettingTranslation, getWorkGroupItemTypeTranslation} from "../../../../utils/enumTranslations";
import {sortByStartTime} from "../../../../utils/utils";
import {BORDER_COLOR} from "../constants";
import {RoutePoint} from "../../../../API/types";
import {getDisplayTimeWithModifier} from "../../../../utils/dateUtils";

function getValidityTxt(workGroup: WorkGroup, workGroupVersion: WorkGroupVersion) {
    const validFromTxt = workGroupVersion.validFrom ? `alates ${dayjs(workGroupVersion.validFrom).format('DD.MM.YYYY')}` : '';
    const validToTxt = workGroupVersion.validTo ? `kuni ${dayjs(workGroupVersion.validTo).format('DD.MM.YYYY')}` : '';

    const result: string[] = [];

    if (validFromTxt !== '' || validToTxt !== '') {
        result.push('kehtib');
        if (validFromTxt !== '') {
            result.push(validFromTxt);
        }
        if (validToTxt !== '') {
            result.push(validToTxt);
        }
    }

    const startDateTxt = workGroup.startDate ? dayjs(workGroup.startDate).format('DD.MM') : '';
    const endDateTxt = workGroup.endDate ? dayjs(workGroup.endDate).format('DD.MM') : '';

    if (startDateTxt !== '' || endDateTxt !== '') {
        result.push('perioodil');
        if (startDateTxt !== '') {
            result.push(startDateTxt);
        }
        result.push('-');
        if (endDateTxt !== '') {
            result.push(endDateTxt);
        }
    }
    
    return result.join(' ');
}

export function getWeekDaysTxt(workGroup: WorkGroup, excludeExplanation = false) {
    const weekDaysActive: string[] = []
    if (workGroup.repetition.mon) {
        weekDaysActive.push('E');
    }
    if (workGroup.repetition.tue) {
        weekDaysActive.push('T');
    }
    if (workGroup.repetition.wed) {
        weekDaysActive.push('K');
    }
    if (workGroup.repetition.thu) {
        weekDaysActive.push('N');
    }
    if (workGroup.repetition.fri) {
        weekDaysActive.push('R');
    }
    if (workGroup.repetition.sat) {
        weekDaysActive.push('L');
    }
    if (workGroup.repetition.sun) {
        weekDaysActive.push('P');
    }

    return `${excludeExplanation ? '' : 'Kehtivus nädalapäeviti: '}${weekDaysActive.join(' ')}`
}

function getScheduleSettingRows(workGroup: WorkGroup): Row[] {
    const result: string[] = [];
    if (workGroup.repetition.scheduleSettings.PUBLIC_HOLIDAY) {
        result.push(`Riigipüha: ${getScheduleSettingTranslation(workGroup.repetition.scheduleSettings.PUBLIC_HOLIDAY)}`);
    }
    if (workGroup.repetition.scheduleSettings.SCHOOL_HOLIDAY) {
        result.push(`Sügisene, talvine ja kevadine koolivaheaeg: ${getScheduleSettingTranslation(workGroup.repetition.scheduleSettings.SCHOOL_HOLIDAY)}`);
    }
    if (workGroup.repetition.scheduleSettings.SUMMER_HOLIDAY) {
        result.push(`Suvine koolivaheaeg: ${getScheduleSettingTranslation(workGroup.repetition.scheduleSettings.SUMMER_HOLIDAY)}`);
    }

    return result.map(item => [{value: item, span: 4}, null, null, null]);
}

function getActivityTableHeader(): Row {
    return [
        {
            value: 'Tegevus',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Kood',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Liin',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Teekond',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Algusaeg',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Lõpuaeg',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Läbisõit',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
        {
            value: 'Bussi töögrupi kood',
            fontWeight: 'bold',
            borderColor: BORDER_COLOR,
        },
    ];
}

function getOppositeWorkGroupCodes(tripDefinition: WorkGroupTripDefinition) {
    return tripDefinition.workGroupsOfOppositeType?.map(wgoot => wgoot.code).join(', ');
}

export function getRouteTxt(routePoints: RoutePoint[]) {
    const result = routePoints.map((routePoint: RoutePoint) => {
        const additionalComponents: string[] = [];
        if (routePoint.stopName && routePoint.stopName !== '') {
            additionalComponents.push(routePoint.stopName);
        }
        if (routePoint.requestStop) {
            additionalComponents.push('nõudepeatus');
        }

        return routePoint.name + (additionalComponents.length < 1 ? '' : ` (${additionalComponents.join(', ')})`);
    });

    return result.join(' - ');
}

function getActivities(workGroupVersion: WorkGroupVersion): SheetData {
    const result: Row[] = [];
    const items: (WorkGroupActivity | WorkGroupTripDefinition)[] = [...workGroupVersion.tripDefinitions, ...workGroupVersion.activities].sort(sortByStartTime);

    items.forEach(item => {
        const tripDefinition = item as WorkGroupTripDefinition;
        result.push([
            {
                value: getWorkGroupItemTypeTranslation(item.type),
                borderColor: BORDER_COLOR,
            },
            {
                value: (tripDefinition ? tripDefinition.code : ''),
                borderColor: BORDER_COLOR,
            },
            {
                value: (tripDefinition.lineNumber ? tripDefinition.lineNumber : ''),
                borderColor: BORDER_COLOR,
            },
            {
                value: tripDefinition.route ? getRouteTxt(tripDefinition.route) : '',
                borderColor: BORDER_COLOR,
            },
            {
                value: getDisplayTimeWithModifier(item.startTime, item.startTimeIsOnNextDay),
                borderColor: BORDER_COLOR,
            },
            {
                value: getDisplayTimeWithModifier(item.endTime, item.endTimeIsOnNextDay),
                borderColor: BORDER_COLOR,
            },
            {
                value: item.distance ? item.distance.toString() : '',
                borderColor: BORDER_COLOR,
            },
            {
                value: tripDefinition ? getOppositeWorkGroupCodes(tripDefinition) : '',
                borderColor: BORDER_COLOR,
            }
        ]);
    });

    return result;
}

function composeWorkGroupVersionSection(workGroup: WorkGroup, workGroupVersion: WorkGroupVersion) {
    const workGroupSection: Row[] = [];
    workGroupSection.push([]);
    workGroupSection.push([]);
    workGroupSection.push([
        {
            value: `Bussijuhi töögrupp ${workGroup.code}`,
            fontWeight: 'bold',
            span: 4,
        },
        null,
        null,
        null,
    ]);
    workGroupSection.push([
        {
            value: getValidityTxt(workGroup, workGroupVersion),
            span: 4,
        },
        null,
        null,
        null
    ]);
    workGroupSection.push([
        {
            value: getWeekDaysTxt(workGroup),
            fontWeight: 'bold',
            span: 4,
        },
        null,
        null,
        null,
    ]);
    workGroupSection.push(...getScheduleSettingRows(workGroup));

    if (workGroup.comment) {
        workGroupSection.push([{ value: workGroup.comment, span: 4 }, null, null, null]);
    }

    workGroupSection.push([]);

    workGroupSection.push(getActivityTableHeader());

    workGroupSection.push(...getActivities(workGroupVersion));

    return workGroupSection;
}

function composeReportHeader(region: Region, start: dayjs.Dayjs, end: dayjs.Dayjs) {
    const reportHeader: SheetData = [
        [
            {
                value: `${region.name} piirkonna bussijuhi töögruppide aruanne`.toUpperCase(),
                fontWeight: 'bold',
                span: 4
            },
            null,
            null,
            null,
        ],
        [
            {
                value: `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`.toUpperCase(),
                fontWeight: 'bold',
                span: 4
            },
            null,
            null,
            null,
        ],
    ];

    return reportHeader;
}

function composeReportBody(data: WorkGroup[]) {
    const body: SheetData = [];
    data.forEach((workGroup) => {
        workGroup.versions?.forEach((version) => {
            const workGroupVersionSection = composeWorkGroupVersionSection(workGroup, version);
            body.push(...workGroupVersionSection);
        });
    });

    return body;
}

export const getOrderedWorkGroups = (data: WorkGroup[]) => {
    return data.sort((a, b) => {
        const aCode = a.code.split('_')[0];
        const bCode = b.code.split('_')[0];
        if (aCode === bCode) {
            if (a.repetition.mon !== b.repetition.mon) {
                return a.repetition.mon ? -1 : 1;
            } else if (a.repetition.tue !== b.repetition.tue) {
                return a.repetition.tue ? -1 : 1;
            } else if (a.repetition.wed !== b.repetition.wed) {
                return a.repetition.wed ? -1 : 1;
            } else if (a.repetition.thu !== b.repetition.thu) {
                return a.repetition.thu ? -1 : 1;
            } else if (a.repetition.fri !== b.repetition.fri) {
                return a.repetition.fri ? -1 : 1;
            } else if (a.repetition.sat !== b.repetition.sat) {
                return a.repetition.sat ? -1 : 1;
            } else {
                return a.repetition.sun ? -1 : 1;
            }
        } else {
            return aCode < bCode ? -1 : 1;
        }
    });
};

export const formatWorkGroupReportData = (data: WorkGroup[], start: Dayjs, end: Dayjs, region: Region): [SheetData, Columns] => {
    const reportHeader = composeReportHeader(region, start, end);
    const body = composeReportBody(getOrderedWorkGroups(data));

    return [reportHeader.concat(body), [
        { width: 18 },
        {},
        {},
        { width: 72 },
        {},
        {},
        {},
        { width: 18 },
    ]];
}