import axios from "axios";
import {handleError} from "../utils";
import {
    CoefficientsReportLine,
    CoefficientsReportRequest,
    Payslip,
    PayslipExtra,
    PayslipHours,
    SavePayslipExtra,
    SavePayslipHours,
    Selector
} from "./types";
import {Dayjs} from "dayjs";
import {getDateString, getYearMonthString} from "../../utils/dateUtils";

export const getPayslipsForMonth = (regionId: number, month: Dayjs) =>
    axios.get<Payslip[]>(`/api/regions/${regionId}/payslips?year-month=${getYearMonthString(month)}`)
        .then(result => result.data)
        .catch(handleError);

export const generateFinalPayslip = (driverId: number, date: Dayjs) =>
    axios.post<Payslip>(
        `/api/drivers/${driverId}/payslips/final`,
        { finalDate: getDateString(date) },
    )
        .then(result => result.data)
        .catch(handleError);

export const generatePayslips = (regionId: number, month: Dayjs) =>
    axios.post<Payslip[]>(`/api/regions/${regionId}/payslips?year-month=${getYearMonthString(month)}`)
        .then(result => result.data)
        .catch(handleError);

export const deletePayslip = (id: number) =>
    axios.delete(`/api/payslips/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const getDriverYearMonthCoefficients = (regionId: number, yearMonth: string) => {
    const body: CoefficientsReportRequest = {
        regionId: regionId,
        selectors: [Selector.YearMonth, Selector.Driver, Selector.ProfitCenter],
        yearMonthFrom: yearMonth,
        yearMonthTo: yearMonth,
    }
    return axios.post<CoefficientsReportLine[]>('/api/reports/coefficients', body)
        .then(result => result.data)
        .catch(handleError);
};

export const getProfitCenterCoefficients = (regionId: number, yearMonth: string) => {
    const body: CoefficientsReportRequest = {
        regionId: regionId,
        selectors: [Selector.YearMonth, Selector.ProfitCenter],
        yearMonthFrom: yearMonth,
        yearMonthTo: yearMonth,
    }
    return axios.post<CoefficientsReportLine[]>('/api/reports/coefficients', body)
        .then(result => result.data)
        .catch(handleError);
};

export const getPayslipHours = (regionId: number, month: Dayjs) => {
    return axios.get<PayslipHours[]>(`/api/regions/${regionId}/payslip-hours/${getYearMonthString(month)}`)
        .then(result => result.data)
        .catch(handleError);
};

export const getPayslipExtras = (regionId: number, month: Dayjs) => {
    return axios.get<PayslipExtra[]>(`/api/regions/${regionId}/payslip-extra/${getYearMonthString(month)}`)
        .then(result => result.data)
        .catch(handleError);
};

export const updatePayslipHours = (contractId: string | number, month: Dayjs, data: SavePayslipHours) => {
    return axios.put(`/api/driver-contracts/${contractId}/payslip-hours/${getYearMonthString(month)}`, data)
        .then(result => result.data)
        .catch(handleError);
};

export const updatePayslipExtras = (contractId: string | number, month: Dayjs, data: SavePayslipExtra) => {
    return axios.put(`/api/driver-contracts/${contractId}/payslip-extra/${getYearMonthString(month)}`, data)
        .then(result => result.data)
        .catch(handleError);
};
