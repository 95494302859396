import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import WorkScheduleTable from "../WorkScheduleTable";
import {WorkScheduleItemStatus, WorkScheduleItemType} from "../../../../API/workSchedule/types";
import {WorkScheduleItemForm} from "../types";
import WorkScheduleItemDialog from "../components/WorkScheduleItemDialog";
import {getEnumFromStr} from "../../../../utils/formUtils";
import LoaderWithChildren from "../../../../components/LoaderWithChildren";
import {addWorkScheduleItem, changeWorkScheduleItem, removeWorkScheduleItem} from "../store/actions";
import {selectWorkScheduleItemEntityId, WorkScheduleItem} from "../../../../store/workScheduleItemSlice";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {setDialogData} from "../../../../store/workScheduleSlice";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {AddDriverDialog} from "../components/AddDriverDialog";

function WorkScheduleView() {
    const loading = useAppSelector(state => state.workSchedule.loading);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const dialogData = useAppSelector(state => state.workSchedule.view.dialogData);
    const addDriverDialogOpen = useAppSelector(state => state.workSchedule.view.addDriverDialogOpen);
    const open = typeof dialogData !== 'undefined';
    const resourceType = useAppSelector(selectToggledResourceType);
    const dispatch = useAppDispatch();

    const handleSave = (resourceId: number, formData: WorkScheduleItemForm, workScheduleItem?: WorkScheduleItem) => {
        const type = getEnumFromStr(formData.type, WorkScheduleItemType);
        if (type) {
            if (formData.workGroup?.workScheduleItemId) {
                dispatch(changeWorkScheduleItem({
                    entityId: `${formData.workGroup.workScheduleItemId}-${formData.workGroup.id}-${formData.startDate.format('YYYY-MM-DD')}`,
                    workScheduleItem: {
                        comment: formData.comment,
                        regionId: selectedRegion?.id ?? 0,
                        status: WorkScheduleItemStatus.SCHEDULED,
                        type: type,
                        workGroupId: formData.workGroup?.id,
                        workItemIds: [],
                        id: formData.workGroup.workScheduleItemId,
                        resourceType: resourceType,
                        resourceId: resourceId,
                        startDate: formData.startDate.format('YYYY-MM-DD'),
                        endDate: formData.endDate.format('YYYY-MM-DD'),
                    },
                }));
            } else if (!workScheduleItem) {
                dispatch(addWorkScheduleItem({
                    comment: formData.comment,
                    regionId: selectedRegion?.id ?? 0,
                    status: WorkScheduleItemStatus.SCHEDULED,
                    type: type,
                    workGroupId: formData.workGroup?.id,
                    workItemIds: [],
                    id: undefined,
                    resourceType: resourceType,
                    resourceId: resourceId,
                    startDate: formData.startDate.format('YYYY-MM-DD'),
                    endDate: formData.endDate.format('YYYY-MM-DD'),
                }));
            } else {
                dispatch(changeWorkScheduleItem({
                    entityId: selectWorkScheduleItemEntityId(workScheduleItem),
                    workScheduleItem: {
                        ...workScheduleItem,
                        startDate: formData.startDate.format('YYYY-MM-DD'),
                        endDate: formData.endDate.format('YYYY-MM-DD'),
                        workGroupId: formData.workGroup?.id,
                        comment: formData.comment,
                    },
                }));
            }
        }
    };

    const handleCloseDialog = () => {
        dispatch(setDialogData(undefined));
    };

    const handleDelete = (workScheduleItem: WorkScheduleItem) => {
        dispatch(removeWorkScheduleItem(workScheduleItem));
    };

    return (
        <>
            <LoaderWithChildren loading={loading}>
                <WorkScheduleTable />
            </LoaderWithChildren>
            {dialogData && <WorkScheduleItemDialog
                open={open}
                data={dialogData}
                handleCloseDialog={handleCloseDialog}
                handleSave={handleSave}
                handleDelete={handleDelete} />}
            {addDriverDialogOpen && <AddDriverDialog />}
        </>
    );
}

export default React.memo(WorkScheduleView);
