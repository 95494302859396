import React from "react";
import {SxProps, TextField as MuiTextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {useField} from "formik";
import {SelectOption} from "../../../types";

interface MultiSelectProps {
    name: string;
    label: string;
    options: SelectOption[];
    disabled?: boolean;
    sx?: SxProps;
}

export default function MultiSelect({label, name, options, disabled, sx}: MultiSelectProps) {
    const [field, meta, helpers] = useField(name);

    return (
        <Autocomplete
            multiple
            size="small"
            options={options}
            getOptionLabel={(option) => option.name}
            value={field.value}
            disabled={disabled}
            onChange={(event, newValue) => {
                helpers.setValue([...newValue]);
            }}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    label={label}
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                />
            )}
            sx={{
                my: 1,
                ...sx,
            }}
        />
    );
}