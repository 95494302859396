import {BusStopUsagesReport} from "../../../../API/reports/types";
import {Columns, Row, SheetData} from "write-excel-file";
import {BORDER_COLOR} from "../constants";


export const formatBusStopUsagesReport = (data: BusStopUsagesReport): [SheetData, Columns] => {
    const headerRow: Row = [null, null];
    const subHeaderRow: Row = [null, {value: 'peatuse kood', fontWeight: 'bold', borderColor: BORDER_COLOR}];

    data.contracts.forEach(contract => {
        headerRow.push({value: contract.contractId, fontWeight: 'bold', borderColor: BORDER_COLOR, align: 'center', span: 3, leftBorderStyle: 'medium', rightBorderStyle: 'medium'});
        headerRow.push(null);
        headerRow.push(null);

        subHeaderRow.push({value: 'saabumisi', fontWeight: 'bold', borderColor: BORDER_COLOR, leftBorderStyle: 'medium'});
        subHeaderRow.push({value: 'läbimisi', fontWeight: 'bold', borderColor: BORDER_COLOR});
        subHeaderRow.push({value: 'väljumisi', fontWeight: 'bold', borderColor: BORDER_COLOR, rightBorderStyle: 'medium'});
    });

    const dataRows: Row[] = [];
    data.usages.forEach(busStopUsage => {
        const dataRow: Row = [
            {value: busStopUsage.stopName, fontWeight: 'bold', borderColor: BORDER_COLOR},
            {value: busStopUsage.stopCode, borderColor: BORDER_COLOR},
        ];

        data.contracts.forEach(transportContract => {
            const transportContractUsage = busStopUsage.contracts.find(usageContract => usageContract.id === transportContract.id);

            dataRow.push({value: transportContractUsage?.arrivals ?? '', borderColor: BORDER_COLOR, leftBorderStyle: 'medium'});
            dataRow.push({value: transportContractUsage?.passes ?? '', borderColor: BORDER_COLOR});
            dataRow.push({value: transportContractUsage?.departures ?? '', borderColor: BORDER_COLOR, rightBorderStyle: 'medium'});
        })

        dataRows.push(dataRow)
    });

    return [[headerRow, subHeaderRow, ...dataRows], [{ width: 30 }, { width: 12 }]];
};
