import axios from "axios";
import {CurrentDriverWorkSheetsResponse, Driver, DriverContract, UpdateDriverRequest} from "./types";
import {handleError} from "../utils";
import {Dayjs} from "dayjs";

const cache: {drivers: Driver[] | undefined} = {
    drivers: undefined,
};

export const loadDrivers = async () => {
    if (typeof cache.drivers === 'undefined') {
        const result = await axios.get<Driver[]>('/api/drivers');
        cache.drivers = result.data;
    }

    return cache.drivers ? [...cache.drivers] : [];
};

export const fetchDriver = (id: number | string) =>
    axios.get<Driver>(`/api/drivers/${id}`).then(result => result.data);

export const createDriver = (request: UpdateDriverRequest) => {
    cache.drivers = undefined;
    return axios.post<Driver>('/api/drivers', request)
        .then(result => result.data)
        .catch(handleError);
};

export const updateDriver = (id: number | string, request: UpdateDriverRequest) => {
    cache.drivers = undefined;
    return axios.put<void>('/api/drivers', {id: id, ...request})
        .then(result => result.data)
        .catch(handleError);
};

export const updateDriverContract = (request: DriverContract) => {
    cache.drivers = undefined;
    return axios.put<void>(`/api/driver-contracts`, request)
        .then(result => result.data)
        .catch(handleError);
};

export const createDriverContract = (driverId: number, request: DriverContract) => {
    cache.drivers = undefined;
    return axios.post<DriverContract>(`/api/drivers/${driverId}/contracts`, request)
        .then(result => result.data)
        .catch(handleError);
};

export const getCurrentDriverWorkSheets = (from: Dayjs) =>
    axios.get<CurrentDriverWorkSheetsResponse>(
        `/api/drivers/my-generated-work-sheets?from=${from.format('YYYY-MM-DD')}`
    ).then(result => result.data);
