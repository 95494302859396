import {ActivityChanges} from "../../../../../API/tripDefinition/types";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import CardBase from "./CardBase";
import {Box, Link, Typography} from "@mui/material";
import {Circle, Diversity2} from "@mui/icons-material";
import {getActivityCardColor} from "../../../../../components/Planner/utils/colorUtils";
import {getWorkGroupItemTypeTranslation} from "../../../../../utils/enumTranslations";
import React, {ReactNode} from "react";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../../../../routes";


import {getDisplayTimeRange} from "../../../../../utils/dateUtils";

const ActivityCard = ({activity}: { activity: ActivityChanges }) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);

    return (
        <CardBase>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'normal'}}>
                    <Circle fontSize="small" sx={{color: getActivityCardColor(activity.type), mr: 0.5}}/>
                    <Typography>{getWorkGroupItemTypeTranslation(activity.type)}</Typography>
                </Box>
            </Box>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                <s>{getDisplayTimeRange(activity.previousTimes)}</s> → {getDisplayTimeRange(activity.newTimes)}
            </Typography>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Diversity2 sx={{color: 'text.secondary', mr: 0.5, fontSize: '16px'}}/>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {activity.workGroupVersions.map<ReactNode>(wgv =>
                        <Link key={wgv.workGroupId + wgv.region.id}
                              component={RouterLink}
                              to={routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', wgv.workGroupId)}
                              target='_blank'
                        >
                            {`${wgv.code}${wgv.region.id !== selectedRegion?.id ? ` (${wgv.region.name})` : ''}`}
                        </Link>
                    ).reduce((prev, curr) => [prev, ', ', curr])}
                </Typography>
            </Box>
        </CardBase>
    );
};

export default ActivityCard;