import {TripReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Region} from "../../../../API/region/types";
import {Columns, Row, SheetData} from "write-excel-file";
import {BORDER_COLOR} from "../constants";
import {getStartAndEndPointLabel} from "../../../../utils/utils";
import {groupByTwoProperties} from "./utils";

export const groupByContractAndLineNumber = (allTripReports: TripReport[]): Map<string, Map<string, TripReport[]>> => {
    return groupByTwoProperties(
        allTripReports,
        (item: TripReport) => item.transportContractName,
        (item: TripReport) => item.lineNumber,
    );
};

export const groupByContractAndOrderNumber = (allTripReports: TripReport[]): Map<string, Map<string, TripReport[]>> => {
    return groupByTwoProperties(
        allTripReports,
        (item: TripReport) => item.transportContractName,
        (item: TripReport) => item.charterOrderNumber,
    );
};

export const formatMonthlyLineReportData = (data: TripReport[], startDate: Dayjs, endDate: Dayjs, selectedRegion?: Region): [SheetData, Columns] => {
    const titleRows: Row[] = [
        [{value: `${selectedRegion?.name.toUpperCase()} PIIRKONNA LIINIARUANNE`, fontWeight: 'bold', span: 4}],
        [{value: `${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`, fontWeight: 'bold', span: 3}],
        [],
        []
    ];

    const lineTrips = data.filter(item => !item.transportContractAppliesToCharterTrips);
    lineTrips.sort((a, b) => a.lineNumber > b.lineNumber ? 1 : -1);

    const lineTripColumnHeaders: Row = [
        {value: 'Liin', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Liini nimetus', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (plaaniline)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Liiniaeg (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Kohtkilomeetrid', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
    ];

    const grouped = groupByContractAndLineNumber(lineTrips);
    const lineTripDataRows: Row[] = [];
    grouped.forEach(((lineNrToTripsMap, contractName) => {
        lineTripDataRows.push([]);
        lineTripDataRows.push([{value: `[Veoleping] ${contractName}`, span: 4, fontWeight: 'bold'}]);
        let totalContractPlannedDistance = 0;
        let totalContractRealDistance = 0;
        let totalContractRealLineTime = 0;
        let totalContractPaxKm = 0;

        lineNrToTripsMap.forEach((tripReports, lineNumber) => {
            const startAndEndPoint = tripReports.length && lineNumber !== '' ? getStartAndEndPointLabel(tripReports[0].route) : '';
            const totalPlannedDistance: number = tripReports.reduce((sum, {plannedDistance}) => sum + plannedDistance, 0);
            const totalRealDistance: number = tripReports.reduce((sum, {realDistance}) => sum + realDistance, 0);
            const totalRealLineTime: number = tripReports.reduce((sum, {realLineTime}) => sum + realLineTime, 0);
            const paxKm: number = tripReports.reduce((sum, {paxKm}) => sum + paxKm, 0);

            totalContractPlannedDistance += totalPlannedDistance;
            totalContractRealDistance += totalRealDistance;
            totalContractRealLineTime += totalRealLineTime;
            totalContractPaxKm += paxKm;

            lineTripDataRows.push([
                {value: lineNumber === '' ? 'määramata' : lineNumber, type: String},
                {value: startAndEndPoint, type: String},
                {value: totalPlannedDistance, type: Number},
                {value: totalRealDistance, type: Number},
                {value: totalRealLineTime, type: Number},
                {value: paxKm, type: Number},
            ]);
        });
        lineTripDataRows.push([
            {value: 'Kokku', type: String, span: 2, align: 'right', fontWeight: 'bold'},
            null,
            {value: totalContractPlannedDistance, type: Number},
            {value: totalContractRealDistance, type: Number},
            {value: totalContractRealLineTime, type: Number},
            {value: totalContractPaxKm, type: Number},
        ]);
    }));

    const charterTripColumnHeaders: Row = [
        {value: 'Tellimuse number', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Marsruut', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (plaaniline)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Läbisõit (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Sõiduaeg (tegelik)', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Kohtkilomeetrid', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Reisijate arv', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
    ];

    const charterTrips = data.filter(item => item.transportContractAppliesToCharterTrips);
    charterTrips.sort((a, b) => a.lineNumber > b.lineNumber ? 1 : -1);
    const groupedCharterTrips = groupByContractAndOrderNumber(charterTrips);
    const charterTripDataRows: Row[] = [];
    groupedCharterTrips.forEach(((charterOrderNumberToTripsMap, contractName) => {
        charterTripDataRows.push([]);
        charterTripDataRows.push([{value: `[Tellimusveo leping] ${contractName}`, span: 4, fontWeight: 'bold'}]);
        let totalContractPlannedDistance = 0;
        let totalContractRealDistance = 0;
        let totalContractRealDriveTime = 0;
        let totalContractPaxKm = 0;
        let totalContractNumberOfPassengers = 0;

        charterOrderNumberToTripsMap.forEach((tripReports, charterOrderNumber) => {
            const totalPlannedDistance: number = tripReports.reduce((sum, {plannedDistance}) => sum + plannedDistance, 0);
            const totalRealDistance: number = tripReports.reduce((sum, {realDistance}) => sum + realDistance, 0);
            const totalRealDriverTime: number = tripReports.reduce((sum, {realLineTime}) => sum + realLineTime, 0);
            const paxKm: number = tripReports.reduce((sum, {paxKm}) => sum + paxKm, 0);
            const numberOfPassengers = tripReports.reduce((sum, {numberOfPassengers}) => sum + (numberOfPassengers ?? 0), 0);

            totalContractPlannedDistance += totalPlannedDistance;
            totalContractRealDistance += totalRealDistance;
            totalContractRealDriveTime += totalRealDriverTime;
            totalContractPaxKm += paxKm;
            totalContractNumberOfPassengers += numberOfPassengers;

            charterTripDataRows.push([
                {value: charterOrderNumber, type: String},
                {value: tripReports[0].charterTripRoute, type: String},
                {value: totalPlannedDistance, type: Number},
                {value: totalRealDistance, type: Number},
                {value: totalRealDriverTime, type: Number},
                {value: paxKm, type: Number},
                {value: numberOfPassengers, type: Number},
            ]);
        });

        charterTripDataRows.push([
            {value: 'Kokku', type: String, span: 2, align: 'right', fontWeight: 'bold'},
            null,
            {value: totalContractPlannedDistance, type: Number},
            {value: totalContractRealDistance, type: Number},
            {value: totalContractRealDriveTime, type: Number},
            {value: totalContractPaxKm, type: Number},
            {value: totalContractNumberOfPassengers, type: Number},
        ]);
    }));

    const sheetData = [...titleRows];
    if (lineTripDataRows.length > 0) {
        sheetData.push(lineTripColumnHeaders, ...lineTripDataRows);
    }
    if (charterTripDataRows.length > 0) {
        sheetData.push([], [], charterTripColumnHeaders, ...charterTripDataRows);
    }

    return [sheetData, []];
};