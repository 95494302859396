import {EntityId} from "@reduxjs/toolkit";
import {WorkGroupItemType} from "../../../API/workGroup/types";
import {AppDispatch, RootState} from "../../../store";
import {PlannerType} from "../types";
import {
    setDialogData,
    updateUnassignedCharterTripsStateWithMovedTrip,
    updateUnassignedTripsStateWithMovedTrip
} from "../../../store/plannerSlice";
import {
    addCharterTripWorkItem, addTripDefinitionWorkItem,
    CharterTripWorkItem as WorkScheduleCharterTripWorkItem,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem
} from "../../../store/workScheduleItemSlice";
import {selectSelectedRegion} from "../../../store/regionSlice";

export const openAddOppositeGroupDialog = (
    currentGroupId: EntityId,
    itemId: EntityId,
    type: WorkGroupItemType,
) => (dispatch: AppDispatch, getState: () => RootState) => {
    const plannerType = getState().planner.plannerType;
    if (plannerType === PlannerType.WORK_GROUP) {
        if (type === WorkGroupItemType.TRIP_DEFINITION) {
            dispatch(setDialogData({
                addOppositeWorkGroupToTrip: {workGroupId: currentGroupId, tripId: itemId}
            }));
        } else {
            dispatch(setDialogData({
                addOppositeWorkGroupToActivity: {workGroupId: currentGroupId, activityId: itemId}
            }));
        }
    }
    if (plannerType === PlannerType.WORK_SHEET) {
        dispatch(setDialogData({
            addOppositeWorkSheetToWorkItem: {workSheetId: currentGroupId, workItemId: itemId}
        }));
    }
};

export const addOtherRegionUnassignedWorkItem = (
    workItem: WorkScheduleTripDefinitionWorkItem | WorkScheduleCharterTripWorkItem,
) => (dispatch: AppDispatch, getState: () => RootState) => {
    const selectedRegion = selectSelectedRegion(getState());
    if (!selectedRegion) { return }

    if ('charterTripId' in workItem) {
        dispatch(addCharterTripWorkItem(workItem));
        dispatch(updateUnassignedCharterTripsStateWithMovedTrip({
            movedCharterTrip: {
                id: workItem.id,
                regionId: workItem.regionId,
            },
            selectedRegionId: selectedRegion.id,
        }));
    } else {
        dispatch(addTripDefinitionWorkItem(workItem));
        dispatch(updateUnassignedTripsStateWithMovedTrip({
            movedTrip: {
                id: workItem.id,
                regionId: workItem.regionId,
            },
            selectedRegionId: selectedRegion.id,
        }));
    }
};