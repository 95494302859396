import React, {ReactNode} from "react";
import {Box, SxProps, useTheme} from "@mui/material";

interface CardBaseProps {
    children: ReactNode;
    isActive?: boolean;
    onClick?: () => void;
    sx?: SxProps;
}

const CardBase = ({children, onClick, isActive, sx}: CardBaseProps) => {
    const theme = useTheme();

    return (
        <Box onClick={onClick} sx={{
            minWidth: '100%',
            maxWidth: '100%',
            borderRadius: '10px',
            border: isActive ? '3px solid' : '2px solid',
            p: 1,
            my: 1,
            mx: {xs: 0, s: 1},
            borderColor: isActive ? theme.palette.secondary.main : theme.palette.divider,
            cursor: onClick ? 'pointer' : 'initial',
            boxSizing: 'border-box',
            ...sx,
        }}>
            {children}
        </Box>
    );
};

export default CardBase;
