import {TripReport} from "../../../../API/reports/types";

export const groupByTwoProperties = (
    items: TripReport[],
    firstGroupByPropSelector: (item: TripReport) => string,
    secondGroupByPropSelector: (item: TripReport) => string
): Map<string, Map<string, TripReport[]>> => {
    const level1Groups = new Map<string, Map<string, TripReport[]>>();
    items.forEach((item) => {
        const firstGroupByProp = firstGroupByPropSelector(item);
        let level1Group = level1Groups.get(firstGroupByProp);
        if (!level1Group) {
            level1Group = new Map<string, TripReport[]>();
            level1Groups.set(firstGroupByProp, level1Group);
        }
        const secondGroupByProp = secondGroupByPropSelector(item);
        const level2Group = level1Group.get(secondGroupByProp);
        if (!level2Group) {
            level1Group.set(secondGroupByProp, [item]);
        } else {
            level2Group.push(item);
        }
    });

    return level1Groups;
};