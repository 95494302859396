import React, {useEffect, useState} from "react";
import {Box, Tooltip} from "@mui/material";


interface PlannerTooltipProps {
    isDragging: boolean;
    isRowDisabled: boolean;
    actions?: React.ReactNode;
    content: React.ReactNode;
    children: React.ReactElement;
}

const PlannerTooltip = ({
    isDragging,
    isRowDisabled,
    actions,
    content,
    children,
}: PlannerTooltipProps) => {
    const [enableTooltip, setEnableTooltip] = useState(true);

    useEffect(() => {
        if (!enableTooltip && !isDragging) {
            setTimeout(() => {
                setEnableTooltip(true);
            }, 300);
        }
        if (isDragging && enableTooltip) {
            setEnableTooltip(false);
        }
    }, [isDragging]);

    if (!enableTooltip) {
        return <Box>{children}</Box>
    }

    return (
        <Tooltip
            arrow
            placement="top"
            PopperProps={{
                modifiers: [
                    {name: 'offset', options: {offset: [0, -3]}}
                ],
            }}
            title={
                <Box px={0.5} py={1}>
                    {!isRowDisabled && actions &&
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', pb: 1}}>{actions}</Box>
                    }
                    {content}
                </Box>
            }
        >{children}</Tooltip>
    );
};

export default PlannerTooltip;