import dayjs, {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {getItemBgColor, shortenWorkGroupCode} from "../utils";
import CustomCell from "./CustomCell";
import React, {useMemo} from "react";
import {isActiveOnDay, restTimeLessThan9Hours} from "../../utils";
import {DEFAULT_CELL_WIDTH, disabledBackground} from "../index";
import {shallowEqual} from "react-redux";
import {selectActivityByDayAndId, selectRequestByDayAndId} from "../../store/selectors";
import {darken} from "@mui/material";
import {WorkScheduleItemType} from "../../../../../API/workSchedule/types";
import {EntityId} from "@reduxjs/toolkit";
import {selectDriverContractById} from "../../../../../store/driverContractSlice";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {Region, ResourceType} from "../../../../../API/types";
import {WorkScheduleItemContraction} from "../../types";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {DefectSimple} from "../../../../../API/defects/types";
import {updateWorkScheduleItemOnDay} from "../../../../../store/workScheduleSlice";
import {ItemCellTooltip} from "./ItemCellTooltip";

export const getCellLabel = (item: any, selectedRegion?: Region): string =>
    item?.workGroupCode
        ? shortenWorkGroupCode(item.workGroupCode)+ (item?.regionId !== selectedRegion?.id ? '*' : '')
        : WorkScheduleItemContraction.get(item?.type) ?? '';

const ItemCell = ({rowId, day, defects}: {
    rowId: EntityId;
    day: Dayjs;
    defects?: DefectSimple[];
}) => {
    const dispatch = useAppDispatch();
    const activity = useAppSelector(selectActivityByDayAndId(rowId, day), shallowEqual);
    const prevDayActivity = useAppSelector(selectActivityByDayAndId(rowId, day.subtract(1, 'day')), shallowEqual);
    const request = useAppSelector(selectRequestByDayAndId(rowId, day), shallowEqual);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const confirmed = useAppSelector(state => state.workSchedule.view.scheduleType === 'KINNITATUD');
    const driverContract = useAppSelector((state) => selectDriverContractById(state, rowId.toString()));
    const resourceType = useAppSelector(selectToggledResourceType);
    const selectedEditModeWorkGroup = useAppSelector(state => state.workSchedule.view.editModeData.selectedWorkGroup);

    const warning = resourceType === ResourceType.DRIVER && restTimeLessThan9Hours(activity, prevDayActivity);
    const cellLabel = getCellLabel(activity ?? request, selectedRegion);

    const handleCellClick = (rowId: EntityId, day: Dayjs) => {
        dispatch(updateWorkScheduleItemOnDay(rowId, day));
    };

    const sx = activity && request ? {
        background: `repeating-linear-gradient(
            -45deg,
            ${getItemBgColor(activity.type)},
            ${getItemBgColor(activity.type)} 10px,
            ${getItemBgColor(request.type)} 10px,
            ${getItemBgColor(request.type)} 15px
        )`,
    } : {};

    if (activity && !request && activity.type === WorkScheduleItemType.WORK_GROUP && activity?.regionId !== selectedRegion?.id) {
        sx.background = `repeating-linear-gradient(
            -45deg,
            ${getItemBgColor(activity.type)},
            ${getItemBgColor(activity.type)} 10px,
            ${darken(getItemBgColor(activity.type) ?? '#FFF',.1)} 10px,
            ${darken(getItemBgColor(activity.type) ?? '#FFF',.1)} 15px
        )`;
    }

    const driverIsActiveOnDay = useMemo(() => {
        if (!driverContract) {
            return false;
        }
        return isActiveOnDay({
            contractStartDate: dayjs(driverContract.startDate),
            contractEndDate: driverContract.endDate ? dayjs(driverContract.endDate) : null,
            nominalWeeklyWorkingHours: driverContract.nominalWeeklyWorkingHours,
        }, day);
    }, [driverContract, day]);

    if (resourceType === ResourceType.DRIVER && !driverIsActiveOnDay) {
        return <CustomCell
            content={cellLabel}
            sx={{backgroundColor: disabledBackground, minWidth: DEFAULT_CELL_WIDTH}}
        />
    }

    return (
        <CustomCell
            hasWarning={warning}
            bgColor={getItemBgColor((activity ?? request)?.type)}
            content={cellLabel}
            onClick={() => handleCellClick(rowId, day)}
            defects={defects}
            tooltip={(activity || request || (defects && defects.length > 0)) && !selectedEditModeWorkGroup ? (
                <ItemCellTooltip activity={activity} request={request} warning={warning} defects={defects} />
            ) : undefined}
            sx={{
                ...sx,
                minWidth: DEFAULT_CELL_WIDTH,
                opacity: confirmed ? 0.7 : undefined,
            }}
        />
    );
};

export default React.memo(ItemCell);
