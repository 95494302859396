import {roundToDecimals} from "../../../utils/utils";
import {HOUR_DECIMALS, MIN_WORK_GROUP_ITEM_DURATION_HOURS} from "../constants";
import {getTimeInHours} from "./utils";
import {PlannerItemStartAndEndHourWithTypeAndId, StartAndEndTimeWithModifierAndId} from "../types";


export const canItemDrop = (movedItem: StartAndEndTimeWithModifierAndId, allItems: PlannerItemStartAndEndHourWithTypeAndId[]) => {
    if (allItems.length > 0){
        const tripStartHh = getTimeInHours(movedItem.startTime, movedItem.startTimeIsOnNextDay);
        const tripEndHh = getTimeInHours(movedItem.endTime, movedItem.endTimeIsOnNextDay);

        let previousClosestItem: PlannerItemStartAndEndHourWithTypeAndId | undefined = undefined;
        let nextClosestItem: PlannerItemStartAndEndHourWithTypeAndId | undefined = undefined;

        for (const item of allItems) {
            if (item.startHh <= tripStartHh) {
                previousClosestItem = item;
            }
            if (item.startHh > tripStartHh) {
                nextClosestItem = item;
                break;
            }
        }

        let hasCollisions = false;
        if (previousClosestItem !== undefined && tripStartHh < previousClosestItem.endHh) {
            if (previousClosestItem.id !== movedItem.id) hasCollisions = true;
        }
        if (nextClosestItem !== undefined && tripEndHh > nextClosestItem.startHh) {
            hasCollisions = true;
        }
        return !hasCollisions;
    } else {
        return true;
    }
};

export const getFirstAvailableTimeWithoutCollisions = (
    timeslotStartHh: number,
    allItems: PlannerItemStartAndEndHourWithTypeAndId[]
): { startHh: number, endHh: number } | undefined => {
    const timeslotEndHh = timeslotStartHh + 1;

    if (allItems.length > 0) {
        let previousClosestItem: PlannerItemStartAndEndHourWithTypeAndId | undefined = undefined;
        let nextClosestItem: PlannerItemStartAndEndHourWithTypeAndId | undefined = undefined;

        for (const item of allItems) {
            if (item.startHh < timeslotStartHh + MIN_WORK_GROUP_ITEM_DURATION_HOURS) {
                previousClosestItem = item;
            }
            if (item.startHh > timeslotStartHh) {
                // when there is less than 3min before the next trip then overwrite the previous closest trip
                if (previousClosestItem
                    && roundToDecimals(item.startHh, HOUR_DECIMALS)
                    < roundToDecimals(previousClosestItem.endHh + MIN_WORK_GROUP_ITEM_DURATION_HOURS, HOUR_DECIMALS)
                ) {
                    previousClosestItem = item;
                } else {
                    nextClosestItem = item;
                    break;
                }
            }
        }

        let firstCollisionFreeStartHh: number | undefined = undefined;
        let collisionFreeEndHh: number | undefined = undefined;

        if (previousClosestItem) {
            if (previousClosestItem.endHh >= timeslotEndHh) { // min 3min does not exist inside timeslot
                return undefined;
            } else if (timeslotStartHh < previousClosestItem.endHh) { // activity collides with timeslot but can add an activity
                firstCollisionFreeStartHh = previousClosestItem.endHh;
            } else { // no collisions with previous item in timeslot
                firstCollisionFreeStartHh = timeslotStartHh;
            }
        } else {
            firstCollisionFreeStartHh = timeslotStartHh;
        }

        // allow 1h period for automatic end time suggestions for nearby items
        if (nextClosestItem !== undefined && nextClosestItem.startHh < firstCollisionFreeStartHh + 1) {
            collisionFreeEndHh = nextClosestItem.startHh;
        } else {
            collisionFreeEndHh = timeslotEndHh;
        }

        return roundToDecimals(collisionFreeEndHh - firstCollisionFreeStartHh, HOUR_DECIMALS) < MIN_WORK_GROUP_ITEM_DURATION_HOURS
            ? undefined
            : {startHh: firstCollisionFreeStartHh, endHh: collisionFreeEndHh};
    } else {
        return {
            startHh: timeslotStartHh,
            endHh: timeslotEndHh,
        }
    }
};
