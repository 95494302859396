import React from "react";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {
    clearDialogData,
    fetchWorkGroupPlannerData,
    selectDialogData,
    selectWorkGroupById
} from "../../../../../store/plannerSlice";
import {RootState} from "../../../../../store";
import {shallowEqual} from "react-redux";
import {Form, Formik} from "formik";
import {Check, Clear} from "@mui/icons-material";
import {selectDate} from "../../../../../store/viewSlice";
import DatePicker from "../../../../Form/DatePicker";
import RepetitionRulesInput from "../../../../Form/RepetiotionRulesInput";
import {Box, Dialog, DialogContent, Typography, useMediaQuery} from "@mui/material";
import Divider from "@mui/material/Divider";
import {ApiError} from "../../../../../API/types";
import TextField from "../../../../Form/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "../../../../Buttons/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {splitWorkGroup} from "../../../../../API/workGroup/api";
import {getDateString, getDisplayDate, getDisplayValidDatesRange} from "../../../../../utils/dateUtils";
import {NewWorkGroup} from "../../../../../API/workGroup/types";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import theme from "../../../../../theme";
import {validationSchema} from "../../../../../utils/formValidation";
import * as Yup from "yup";

const selectWorkGroupToSplit = (state: RootState) => {
    const dialogData = selectDialogData(state);
    const workGroupId = dialogData.splitWorkGroup?.workGroupId;

    return selectWorkGroupById(state, workGroupId ?? 0);
};

interface FormData {
    date: Date | null;
    first: NewWorkGroup;
    second: NewWorkGroup;
}

const newWorkGroupsValidationSchema = Yup.object({
    date: validationSchema("Kuupäev").fields.dateRequired,
    first: Yup.object().shape({
        code: validationSchema("Kood").fields.shortTextFieldRequired,
    }),
    second: Yup.object().shape({
        code: validationSchema("Kood").fields.shortTextFieldRequired,
    }),
});

const NewWorkGroupForm = ({prefix}: { prefix: 'first' | 'second' }) => (
    <Box sx={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
        <TextField name={`${prefix}.code`} label="Kood"/>
        <RepetitionRulesInput name={`${prefix}.repetitionRules`}/>
    </Box>
);

const SplitWorkGroupDialog = () => {
    const workGroup = useAppSelector(selectWorkGroupToSplit, shallowEqual);
    const date = useAppSelector(selectDate);
    const dispatch = useAppDispatch();
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

    if (!workGroup) return null;

    const handleSubmit = (values: FormData) => {
        const date = values.date;
        if (date) {
            splitWorkGroup(workGroup.id, getDateString(date), {
                newWorkGroup1: values.first,
                newWorkGroup2: values.second,
            }).then(() => {
                dispatch(fetchWorkGroupPlannerData());
                dispatch(setToast({
                    type: 'success',
                    text: `Töögrupp ${workGroup.code} poolitatud töögruppideks ${values.first.code} ja ${values.second.code} alates kuupäevast ${getDisplayDate(date)}`
                }));
                dispatch(clearDialogData());
            }).catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Töögrupi poolitamisel tekkis viga'}));
            });
        }
        return;
    };

    const handleClose = () => {
        dispatch(clearDialogData());
    };

    const initialValues: FormData = {
        date: date.toDate(),
        first: {
            code: workGroup.code,
            repetitionRules: workGroup.repetition,
        },
        second: {
            code: workGroup.code,
            repetitionRules: workGroup.repetition,
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={newWorkGroupsValidationSchema}>
            <Dialog open={true} onClose={handleClose} maxWidth="md">
                <Form>
                    <Box sx={{
                        minWidth: 'min(800px, 80vw)',
                        maxWidth: '100%',
                        mt: 1, p: {xs: 0, sm: 3},
                    }}>
                        <DialogTitle>
                            <Typography variant="h5" component="div">Töögrupi poolitamine</Typography>
                            <Typography variant="body2" component="div" color="text.secondary">
                                {workGroup.code} {getDisplayValidDatesRange({validFrom: workGroup.validFrom, validTo: workGroup.validTo, useEllipsisFallback: true})}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{mb: 2}}>
                                <DatePicker name="date" label="Kuupäev*" />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: {md: 'row', xs: 'column'},
                                justifyItems: 'space-between',
                                gap: 2
                            }}>
                                <NewWorkGroupForm prefix="first" />
                                <Divider orientation={isScreenSmall ? 'horizontal' : 'vertical'} flexItem sx={{my: 1}} />
                                <NewWorkGroupForm prefix="second" />
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2, mx: 2}}>
                            <Button text="Loobu" color="secondary" onClick={handleClose} startIcon={<Clear/>} />
                            <Button text="Salvesta" color="primary" type="submit" startIcon={<Check/>} />
                        </DialogActions>
                    </Box>
                </Form>
            </Dialog>
        </Formik>
    );
};

export default SplitWorkGroupDialog;
