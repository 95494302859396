import axios from "axios";
import {AddCalendarEntryRequest, CalendarEntry} from "./types";

const cache: {calendarEntries: CalendarEntry[] | undefined} = {
    calendarEntries: undefined,
};

export const loadCalendarEntries = async () => {
    if (typeof cache.calendarEntries === 'undefined') {
        const result = await axios.get<CalendarEntry[]>('/api/calendar-entries');
        cache.calendarEntries = result.data;
    }

    return cache.calendarEntries ? [...cache.calendarEntries] : [];
};

export const createCalendarEntry = async (request: AddCalendarEntryRequest) => {
    cache.calendarEntries = undefined;

    const result = await axios.post<CalendarEntry>('/api/calendar-entries', request);

    return result.data;
};

export const deleteCalendarEntry = (id: number) => {
    cache.calendarEntries = undefined;

    return axios.delete(`/api/calendar-entries/${id}`);
};
