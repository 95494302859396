import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import logo from "../../assets/logo.png";
import logoViiger from "../../assets/logo_viiger.png";
import axios from "axios";


interface LoaderProps {
    isTimeoutIgnored?: boolean;
}

const Loader = ({isTimeoutIgnored = false}: LoaderProps) => {
    const [showLoader, setShowLoader] = useState(isTimeoutIgnored);
    const [showLongLoader, setShowLongLoader] = useState(false);
    const style = {position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto'};

    useEffect(() => {
        setTimeout(() => setShowLoader(true), 200);
        setTimeout(async () => {
            axios.get('/actuator/health', {timeout: 3000})
                .catch(() => setShowLongLoader(true))
        }, 1000);
    }, []);

    return (showLoader ?
        <Box sx={{height: '100%', width: '100%'}}>
            <CircularProgress
                variant="indeterminate"
                color="primary"
                size="60px"
                thickness={2}
                value={70}
                sx={{...style, scale: '-1 1'}}
            />
            <CircularProgress
                color="secondary"
                size="50px"
                thickness={2.5}
                value={40}
                sx={style}
            />
            <img
                src={showLongLoader ? String(logoViiger) : String(logo)}
                alt="logo"
                style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', maxWidth: '50px'}}
            />
            {showLongLoader &&
                <Typography align="center" color="text.secondary" sx={{pt: 'calc(50vh + 40px)', width: 'fit-content', maxWidth: '280px', ...style}}>
                    Viiger ärkas just uinakust. Ta on mõne hetke pärast tööks valmis.
                </Typography>
            }
        </Box>
        :
        <></>
    );
};

export default Loader;