import {StartAndEndTimeWithModifier} from "../../../../../types";
import {getDisplayTimeWithModifier} from "../../../../../../../utils/dateUtils";
import React from "react";
import TooltipRow from "./TooltipRow";

const StartAndEndTime = (
    {startTime, startTimeIsOnNextDay, endTime, endTimeIsOnNextDay}: StartAndEndTimeWithModifier
) => {
    return (
        <TooltipRow>
            {getDisplayTimeWithModifier(startTime, startTimeIsOnNextDay)}
            {' - '}
            {getDisplayTimeWithModifier(endTime, endTimeIsOnNextDay)}
        </TooltipRow>
    );
};

export default StartAndEndTime;