import React from 'react';
import {DirectionsBus, PersonRounded} from "@mui/icons-material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material";


const CompositeIcon = ({PrimaryIcon, SecondaryIcon, name}: {
    PrimaryIcon: OverridableComponent<SvgIconTypeMap>;
    SecondaryIcon: OverridableComponent<SvgIconTypeMap>;
    name: string;
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <mask id={`${name}-mask`}>
                    <rect width="24" height="24" fill="white" />
                    <g transform="translate(8, 5) scale(0.8)">
                        <SecondaryIcon sx={{color: "black", stroke: "black", strokeWidth: 3}} />
                    </g>
                </mask>
            </defs>

            <g mask={`url(#${name}-mask)`}>
                <PrimaryIcon sx={{color: "text.secondary"}} />
            </g>
            <g transform="translate(8, 5) scale(0.8)">
                <SecondaryIcon sx={{color: "text.secondary"}} />
            </g>
        </svg>
    );
};

const DriverAndVehicleIcon = () => (
    <CompositeIcon
        PrimaryIcon={DirectionsBus}
        SecondaryIcon={PersonRounded}
        name="driver-and-vehicle"
    />
);

export default DriverAndVehicleIcon;
