import React from "react";
import {Navigate} from "react-router-dom";
import routes from "../../../routes";
import {useWorkGroupsPlanningView} from "../../../hooks";

const WorkGroups = () => {
    const workGroupsPlanningView = useWorkGroupsPlanningView();

    return (
        <Navigate
            to={workGroupsPlanningView
                ? routes.AUTHENTICATED.WORK_GROUPS.PLANNER
                : routes.AUTHENTICATED.WORK_GROUPS.LIST
            }
            replace
        />
    )
};

export default WorkGroups;