import React, {createContext, ReactElement, useCallback} from 'react';
import Toast, {ToastProps} from "../components/Toast";
import {useAppDispatch, useAppSelector} from "../hooks";
import {selectToast, setToast} from "../store/toastSlice";

interface ToastContextType {
    addToast: (toast: ToastProps) => void;
}

interface Props {
    children: ReactElement;
}

export const ToastContext = createContext<ToastContextType>({} as ToastContextType);

export default function ToastProvider({children}: Props) {
    const toast = useAppSelector(selectToast);
    const dispatch = useAppDispatch();

    const addToast = useCallback((toast: ToastProps) => {
        dispatch(setToast(toast));
    },[dispatch]);

    const closeToast = useCallback(() => {
        dispatch(setToast(null));
    }, [dispatch]);

    return (
        <ToastContext.Provider value={{addToast}}>
            {toast && <Toast open={true} type={toast.type} text={toast.text} closeToast={closeToast} />}
            {children}
        </ToastContext.Provider>
    );
}
