import {PayslipBody, PayslipLine} from "../../../API/payslip/types";
import {Driver, Region, TransportContract} from "../../../API/types";
import dayjs, {Dayjs} from "dayjs";
import {directoCodeToPayTypes} from "./types";
import {isInMonth} from "../../../utils/dateUtils";

export const createDirectoExportString = (payslipBody: PayslipBody, driver: Driver): string => {
    const driverPersonalId = driver?.personalId;
    const driverName = `"${driver?.lastName} ${driver?.firstName}"`;

    return payslipBody.periods.map((period) => {
        const formattedMonth = dayjs(period.yearMonth).format('YYYYMM');
        return directoCodeToPayTypes.map(([directoCode, lineTypes]) => {
            const items: PayslipLine[] = [];
            lineTypes.forEach(type => {
                period.lines
                    .filter(item => item.type === type)
                    .forEach(item => items.push(item));
            });
            const hours = items.reduce((total, {hours}) => total + hours, 0) ?? 0;
            const amount = items.reduce((total, {amount}) => total + amount, 0) ?? 0;

            return [
                formattedMonth,
                driverPersonalId,
                driverName,
                period.numberOfWorkDays,
                Math.round(hours * 100) / 100,
                Math.round(amount * 100) / 100,
                directoCode,
            ].join("\t");
        }).join("\n");
    }).join("\n");
};

export const getTransportContractOptionIds = (transportContracts: TransportContract[], month: Dayjs, region?: Region): number[] => {
    const contractsInMonth = transportContracts.filter(transportContract => isInMonth(month, transportContract.validFrom, transportContract.validTo))
    const orderedContracts = [...contractsInMonth]
        .sort((a, b) => {
            if (a.regionName === region?.name && b.regionName === region?.name) a.contractId.localeCompare(b.contractId);
            if (a.regionName === region?.name && b.regionName !== region?.name) return -1;
            if (a.regionName !== region?.name && b.regionName === region?.name) return 1;

            return a.contractId.localeCompare(b.contractId);
        });

    return [0, ...orderedContracts.map(transportContract => transportContract.id)];
};

export const getTransportContractLabels = (transportContracts: TransportContract[]) => {
    const labels: Map<number, string> = new Map();

    labels.set(0, '-');
    transportContracts.forEach(transportContract =>
        labels.set(transportContract.id, `${transportContract.contractId} (${transportContract.regionName})`)
    );

    return labels;
};
