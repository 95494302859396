import {useContext, useEffect} from 'react'
import {ToastContext} from "../../../contexts/ToastContext";


interface SubmitErrorListenerProps {
    isValid: boolean;
    isValidating: boolean;
    isSubmitting: boolean;
}

const SubmitErrorListener = ({isValid, isValidating, isSubmitting}: SubmitErrorListenerProps) => {
    const { addToast } = useContext(ToastContext);

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
          addToast({type: 'error', text: 'Vormis esineb vigu'});
        }
    }, [isSubmitting, isValid, isValidating])

    return null;
};

export default SubmitErrorListener;
