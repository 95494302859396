import {createEntityAdapter, createSlice, EntityId, EntityState} from "@reduxjs/toolkit";
import {WorkGroupItemType} from "../API/workGroup/types";
import {RootState} from "../store";
import {fetchWorkSchedule} from "./workScheduleSlice";
import {fetchWorkSheets} from "../scenes/authenticated/workSheets/store/actions";
import {fetchWorkSheetPlannerData} from "./plannerSlice";


export interface WorkGroup { // Same as work sheet
    id: number;
    versionId: number;
    code: string;
    validFrom: string;
    validTo: string | null;
    workGroupItemIds: EntityId[];
}

export interface WorkGroupItem {
    id: number;
    type: WorkGroupItemType;
    startTime: string;
    startTimeIsOnNextDay?: boolean;
    endTime: string;
    endTimeIsOnNextDay?: boolean;
    distance: number | null;
}

const workGroupAdapter = createEntityAdapter<WorkGroup>({
    selectId: (workGroup: WorkGroup) => workGroup.versionId,
});
export const selectWorkGroupItemId = (workGroupItem: WorkGroupItem) =>
    `${workGroupItem.type === WorkGroupItemType.TRIP_DEFINITION ? 'trip-definition' : 'activity'}-${workGroupItem.id}`;
const workGroupItemAdapter = createEntityAdapter<WorkGroupItem>({
    selectId: selectWorkGroupItemId,
});

interface WorkGroupSliceState {
    workGroups: EntityState<WorkGroup>;
    workGroupItems: EntityState<WorkGroupItem>;
}

const initialState: WorkGroupSliceState = {
    workGroups: workGroupAdapter.getInitialState(),
    workGroupItems: workGroupItemAdapter.getInitialState(),
};

export const workGroupSlice = createSlice({
    name: 'workGroups',
    initialState: initialState,
    reducers: {
        setWorkGroups: (state, action) => {
            workGroupAdapter.setAll(state.workGroups, action.payload);
        },
        setWorkGroupItems: (state, action) => {
            workGroupItemAdapter.setAll(state.workGroupItems, action.payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchWorkSchedule.pending, (state) => {
            workGroupAdapter.removeAll(state.workGroups);
            workGroupItemAdapter.removeAll(state.workGroupItems);
        });
        builder.addCase(fetchWorkSchedule.fulfilled, (state, action) => {
            workGroupAdapter.setAll(state.workGroups, action.payload.workGroups);
            workGroupItemAdapter.setAll(state.workGroupItems, action.payload.workGroupItems);
        });

        builder.addCase(fetchWorkSheets.fulfilled, (state, action) => {
            workGroupAdapter.setAll(state.workGroups, action.payload.workGroups);
            workGroupItemAdapter.setAll(state.workGroupItems, action.payload.workGroupItems);
        });

        builder.addCase(fetchWorkSheetPlannerData.fulfilled, (state, action) => {
            workGroupAdapter.setAll(state.workGroups, action.payload.workGroups);
        });
    }
});

export interface WorkGroupWithItems extends WorkGroup {
    items: WorkGroupItem[];
}

export const selectWorkGroupWithItems = (state: RootState, id: EntityId, date: string): WorkGroupWithItems | undefined => {
    const workGroup = selectWorkGroupByIdAndDate(state, id, date);
    if (!workGroup) {
        return undefined;
    }
    const items: WorkGroupItem[] = [];
    workGroup.workGroupItemIds.forEach(itemId => {
        const item = selectWorkGroupItemById(state, itemId);
        if (item) {
            items.push(item);
        }
    });

    return {
        ...workGroup,
        items: items,
    };
};

export const {
    selectAll: selectAllWorkGroups,
} = workGroupAdapter.getSelectors<RootState>(state => state.workGroups.workGroups);

export const {
    selectById: selectWorkGroupItemById,
} = workGroupItemAdapter.getSelectors<RootState>(state => state.workGroups.workGroupItems);

export const selectWorkGroupByIdAndDate = (state: RootState, id: EntityId, date: string) => {
    const workGroups = selectAllWorkGroups(state);
    return workGroups.find(workGroup =>
        workGroup.id === id &&
        workGroup.validFrom <= date &&
        (workGroup.validTo === null || workGroup.validTo >= date)
    );
};

export const {
    setWorkGroups,
    setWorkGroupItems,
} = workGroupSlice.actions;

export default workGroupSlice.reducer;
