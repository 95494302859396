import {DisplayWorkGroupItemDetails, PlannerTimeline, StartAndEndTimeWithModifier,} from "../types";
import {getTimeInHours} from "./utils";


export const getItemXPos = (startHh: number, plannerTimeline: PlannerTimeline, contentWidth: number) =>
    ((startHh - plannerTimeline.startTime) / plannerTimeline.duration) * contentWidth;

export const getItemWidth = (startHh: number, endHh: number, plannerTimeline: PlannerTimeline, contentWidth: number) =>
    ((endHh - startHh) / plannerTimeline.duration) * contentWidth;

export const getDisplayWorkGroupItemDetails = (
    item: StartAndEndTimeWithModifier, plannerTimeline: PlannerTimeline, contentWidth: number
): DisplayWorkGroupItemDetails => {
    const startHh = getTimeInHours(item.startTime, item.startTimeIsOnNextDay);
    const endHh = getTimeInHours(item.endTime, item.endTimeIsOnNextDay);

    return {
        xPos: getItemXPos(startHh, plannerTimeline, contentWidth),
        width: getItemWidth(startHh, endHh, plannerTimeline, contentWidth),
    };
};
