import React from "react";
import {Box, Dialog as MuiDialog, DialogProps as MuiDialogProps, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button, {ButtonProps} from "../Buttons/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Form } from "formik";

interface DialogProps extends MuiDialogProps {
    title?: string;
    subtitle?: string;
    buttons?: ButtonProps[];
    hasFormikForm?: boolean;
    children?: React.ReactNode;
}

const Dialog = ({title, subtitle, open, onClose, buttons, hasFormikForm, children}: DialogProps) => {
    return (
        <MuiDialog open={open} onClose={onClose} PaperProps={{component: hasFormikForm ? Form : undefined}}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                {(title || subtitle) && <DialogTitle>
                    {title && <Typography variant="h5" component="div">{title}</Typography>}
                    {subtitle && <Typography component="div" color="text.secondary">{subtitle}</Typography>}
                </DialogTitle>}
                <DialogContent>{children}</DialogContent>
                {buttons &&
                    <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                        {buttons.map(buttonProps => <Button key={buttonProps.text.toString()} {...buttonProps} />)}
                    </DialogActions>
                }
            </Box>
        </MuiDialog>
    )
};

export default Dialog;