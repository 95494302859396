import React, {ReactElement, useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {AuthenticatedUser, Permission} from "../../types";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {Region} from "../../API/region/types";


type VisibleProps = {
	permission?: Permission;
	allPermissions?: Permission[];
	region?: Region;
	requireAllRegions?: boolean;
	children: ReactElement;
	fallback?: ReactJSXElement
};

export const doesUserHavePermission = (
	user: AuthenticatedUser | null,
	permission?: Permission,
): boolean => {
	return !permission || (user?.permissions.includes(permission) ?? false);
};

export const doesUserHaveAllRegionsAccess = (
	user: AuthenticatedUser | null,
	requireAllRegions: boolean = false
): boolean => {
	return !requireAllRegions || (user?.regionalFilteringApplies !== true);
};

const Visible = ({permission, allPermissions, requireAllRegions, children, fallback}: VisibleProps): ReactElement => {
	const {authenticatedUser} = useContext(AuthContext);

	const hasPermission = doesUserHavePermission(authenticatedUser, permission);
	const hasAllPermissions =
		authenticatedUser !== null && allPermissions !== undefined &&
		allPermissions.every((p) => authenticatedUser.permissions.includes(p));

	const hasAllRegionsAccess = doesUserHaveAllRegionsAccess(authenticatedUser, requireAllRegions);

	if ((hasPermission || hasAllPermissions) && hasAllRegionsAccess) {
		return children;
	} else {
		return fallback ?? <></>;
	}
};

export default Visible;
