import React from 'react';
import {MenuItem, TextField as MuiTextField, TextFieldProps} from '@mui/material';
import {Region} from "../../API/types";


interface RegionSelectProps extends Omit<TextFieldProps, 'label'> {
    regions: Region[];
    selectedRegion: Region;
    updateSelectedRegion: (region: Region) => void;
}

const RegionSelect = ({ regions, selectedRegion, updateSelectedRegion, ...otherProps }: RegionSelectProps) => {
    return (
        <MuiTextField
            value={selectedRegion.name}
            onChange={(event) => {
                const newRegion = regions.find(region => event.target.value === region.name);
                if (newRegion) updateSelectedRegion(newRegion);
            }}
            select
            size="small"
            label="Piirkond"
            {...otherProps}
            disabled={regions.length === 1}
        >
            {regions.map(region =>
                <MenuItem key={region.id} value={region.name}>
                    {region.name}
                </MenuItem>
            )}
        </MuiTextField>
    );
};

export default RegionSelect;
