import {ResourceType} from "../../../API/workGroup/types";
import {
    CharterTripWorkItem as WorkScheduleCharterTripWorkItem,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem
} from "../../../store/workScheduleItemSlice";
import {WorkSheetWorkItem} from "../../../API/workSheets/types";

const getOppositeWorkSheets = (workItem: WorkSheetWorkItem, resourceType: ResourceType) =>
    resourceType === ResourceType.DRIVER
    ? (workItem.busWorkSheetId ? [workItem.busWorkSheetId] : [])
    : (workItem.driverWorkSheetId ? [workItem.driverWorkSheetId] : []);

export const workItemToTripWorkItem = (regionId: number, resourceType: ResourceType) => (
    (workItem: WorkSheetWorkItem): WorkScheduleTripDefinitionWorkItem => ({
        id: workItem.tripSegmentId as number,
        tripId: workItem.trip?.id as number,
        workItemId: workItem.id,
        startDateTime: workItem.startDateTime,
        endDateTime: workItem.endDateTime,
        distance: workItem.distance ?? 0,
        comment: workItem.comment,
        code: workItem.trip?.code,
        regionId: regionId,
        route: workItem.route,
        driverWorkSheetId: workItem.driverWorkSheetId,
        busWorkSheetId: workItem.busWorkSheetId,
        workSheetsOfOppositeType: getOppositeWorkSheets(workItem, resourceType),
    })
);

export const workItemToCharterTripWorkItem = (regionId: number, resourceType: ResourceType) => (
    (workItem: WorkSheetWorkItem): WorkScheduleCharterTripWorkItem => ({
        id: workItem.charterTrip?.id as number,
        charterTripId: workItem.charterTrip?.id as number,
        workItemId: workItem.id,
        startDateTime: workItem.startDateTime,
        endDateTime: workItem.endDateTime,
        distance: workItem.distance ?? 0,
        comment: workItem.comment,
        departurePoint: workItem.charterTrip?.departurePoint as string,
        destination: workItem.charterTrip?.destination as string,
        regionId: regionId,
        route: workItem.charterTrip?.route ?? null,
        workSheetsOfOppositeType: getOppositeWorkSheets(workItem, resourceType),
        code: workItem.charterTrip?.tripNumber ?? '',
    })
);