import {useAuth} from "../../../../contexts/AuthContext";
import React, {useEffect, useRef, useState} from "react";
import {ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber} from "firebase/auth";
import {mapFirebaseAuthError} from "../utils";
import {Alert, Box} from "@mui/material";
import {FormikHelpers} from "formik";
import SendConfirmationForm, {SendConfirmationCodeFormData} from "./SendConfirmationForm";
import ConfirmCodeForm, {ConfirmFormData} from "./ConfirmCodeForm";


const PhoneLoginForm = ({handleCancel}: { handleCancel: () => void }) => {
    const {firebaseAuth} = useAuth();
    const recaptchaRef = useRef(null);
    const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | undefined>(undefined);
    const [numberWithPrefix, setNumberWithPrefix] = useState<string>('');
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (recaptchaRef.current && !recaptcha) {
            const reCaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaRef.current, {
                size: 'invisible',
            });
            setRecaptcha(reCaptchaVerifier);
        }
    }, [recaptchaRef, recaptcha]);

    const handleSendConfirmationCode = (values: SendConfirmationCodeFormData, helpers: FormikHelpers<SendConfirmationCodeFormData>) => {
        setNumberWithPrefix('');
        const phonePrefix = values.phonePrefix;
        const phoneNumber = values.phoneNumber;
        if (recaptcha) {
            const numberWithPrefix = `${phonePrefix}${phoneNumber.replace(/\s/g, '')}`;
            setError(undefined);
            signInWithPhoneNumber(firebaseAuth, numberWithPrefix, recaptcha).then(result => {
                setNumberWithPrefix(numberWithPrefix);
                setConfirmationResult(result);
            }).catch(error => {
                setError(mapFirebaseAuthError(error.code));
            }).finally(() => {
                helpers.setSubmitting(false);
            });
        }
    };

    const handleConfirmCode = (values: ConfirmFormData, helpers: FormikHelpers<ConfirmFormData>) => {
        if (confirmationResult) {
            confirmationResult.confirm(values.confirmationCode)
                .catch((error) => {
                    setError(mapFirebaseAuthError(error.code));
                })
                .finally(() => {
                    helpers.setSubmitting(false);
                });
        }
    };

    return (
        <Box>
            {error && <Alert severity="error" sx={{mb: 1}}>{error}</Alert>}
            {confirmationResult
                ? <ConfirmCodeForm
                    handleSubmit={handleConfirmCode}
                    handleCancel={handleCancel}
                    phoneNumber={numberWithPrefix}
                />
                : <SendConfirmationForm handleSubmit={handleSendConfirmationCode} handleCancel={handleCancel} />
            }
            <Box ref={recaptchaRef} />
        </Box>
    );
};

export default PhoneLoginForm;