import {createEntityAdapter, createSlice, EntityId, EntityState} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {fetchDrivers} from "./driverSlice";
import {Contract} from "../API/driver/types";

export interface DriverContract {
    id: number;
    type: Contract;
    startDate: string;
    endDate: string | null;
    nominalWeeklyWorkingHours: number;
    active: boolean;
    driverId: EntityId;
    hourlyRate: number | null;
    monthlyPay: number | null;
}

export const driverContractAdapter = createEntityAdapter<DriverContract>();

const initialState: EntityState<DriverContract> = driverContractAdapter.getInitialState();

export const driverContractSlice = createSlice({
    name: 'driverContracts',
    initialState: initialState,
    reducers: {
        setDriverContracts: driverContractAdapter.setAll,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDrivers.fulfilled, (state, action) => {
            if (action.payload) {
                driverContractAdapter.setAll(
                    state,
                    action.payload.flatMap(driver => driver.contracts.map((contract): DriverContract => ({
                        id: contract.id ?? 0,
                        type: contract.type,
                        startDate: contract.startDate,
                        endDate: contract.endDate,
                        nominalWeeklyWorkingHours: contract.nominalWeeklyWorkingHours,
                        active: driver.active,
                        driverId: driver.id.toString(),
                        hourlyRate: contract.hourlyRate,
                        monthlyPay: contract.monthlyPay,
                    }))));
            }
        });
    }
});

export const {
    selectById: selectDriverContractById,
    selectAll: selectAllDriverContracts,
} = driverContractAdapter.getSelectors<RootState>((state) => state.driverContracts);

export const { setDriverContracts } = driverContractSlice.actions;

export default driverContractSlice.reducer;