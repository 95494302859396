export interface CharterOrder {
    orderNumber: string;
    client: string;
}

export interface CharterTrip {
    id: number;
    tripNumber: string;
    orderNumber: string;
    route: string;
    departurePoint: string;
    destination: string;
    client: string;
    startDateTime: string;
    endDateTime: string;
    date: string;
    distance: number;
    numberOfPassengers: number;
    status: CharterTripStatus;
    isInvoiced: boolean;
    isCancelled: boolean;
    price: number;
    comment?: string;
    transportContract: TransportContract;
    zone: CharterTripZone;
    driver?: Driver;
    bus?: Bus;
}

export interface SaveCharterTrip {
    orderNumber: string | null;
    route: string;
    departurePoint: string;
    destination: string;
    client: string;
    startDateTime: string;
    endDateTime: string;
    date: string;
    distance: number;
    numberOfPassengers: number;
    transportContractId: number;
    isInvoiced: boolean;
    isCancelled: boolean;
    price: number;
    comment?: string;
    zone: CharterTripZone;
}

export enum CharterTripStatus {
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
    PLANNED = 'PLANNED',
    DONE = 'DONE',
}

export enum CharterTripZone {
    DOMESTIC = 'DOMESTIC',
    EU = 'EU',
    NON_EU = 'NON_EU',
}

export interface TransportContract {
    id: number;
    contractId: string;
    partnerName: string;
    regionName: string;
    validFrom: string;
    validTo: string | null;
}

export interface Bus {
    id: number,
    licencePlateNumber: string,
}

export interface Driver {
    id: number,
    firstName: string,
    lastName: string,
    phoneNumber: string,
}
