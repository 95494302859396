import React from 'react';
import {FormikProps} from "formik";
import {isEqual} from "lodash";
import {Box, useTheme} from "@mui/material";
import Button from "../Button";
import {Check, Clear} from "@mui/icons-material";
import {useNavigate} from "react-router";
import {useAuth} from "../../../contexts/AuthContext";
import {Permission} from "../../../types";


interface DetailViewButtonsProps {
    id: string | number | undefined;
    defaultFormValues: any;
    formikProps: FormikProps<any>;
    listViewPath: string;
    useDialogVariant?: boolean;
    handleCancelButtonPress?: () => void;
    allowSaveWithoutChanges?: boolean;
    savePermission?: Permission;
}

const DetailViewButtons = ({
    id,
    defaultFormValues,
    formikProps,
    listViewPath,
    useDialogVariant,
    handleCancelButtonPress,
    allowSaveWithoutChanges,
    savePermission
}: DetailViewButtonsProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { authenticatedUser } = useAuth();

    const areInitialValuesEqualToValues = isEqual(formikProps.initialValues, formikProps.values)
        || (id !== undefined && isEqual(formikProps.initialValues, defaultFormValues));

    return (
        <Box sx={{
            width: {xs: '100%', sm: useDialogVariant ? '100%' : 'fit-content'},
            display: 'flex',
            justifyContent: useDialogVariant ? 'center' : 'flex-end',
            height: 'fit-content',
            p: useDialogVariant ? 2 : 0,
            boxSizing: 'border-box',
            [theme.breakpoints.down('sm')]: useDialogVariant ? {} : {justifyContent: 'center', pt: 1}
        }}>
            <Button
                text="Loobu"
                color="secondary"
                onClick={() => handleCancelButtonPress ? handleCancelButtonPress() : navigate(listViewPath)}
                styles={{width: 'fit-content'}}
                startIcon={<Clear />}
            />
            {(!savePermission || authenticatedUser?.permissions.includes(savePermission)) &&
                <Button
                    text="Salvesta"
                    type="submit"
                    disabled={(!allowSaveWithoutChanges && areInitialValuesEqualToValues) || formikProps.isSubmitting}
                    styles={{marginLeft: 1, width: 'fit-content'}}
                    startIcon={<Check />}
                />
            }
        </Box>
    )
}

export default DetailViewButtons;