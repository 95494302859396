import {BusReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Region} from "../../../../API/region/types";
import {Cell, Columns, Row, SheetData} from "write-excel-file";
import {Fuel} from "../../../../API/bus/types";
import {getFuelTranslation, getFuelUnit} from "../../../../utils/enumTranslations";
import {BORDER_COLOR} from "../constants";

const EMPTY_CELL_WITH_BOTTOM_BORDER = {value: '', bottomBorderColor: BORDER_COLOR};

const groupByVehicleType = (data: BusReport[]): Map<string, BusReport[]> => {
    const result = new Map<string, BusReport[]>();
    data.forEach(row => {
            if (!result.has(row.type)) {
                result.set(row.type, []);
            }
            result.get(row.type)?.push(row);
        });
    return result;
};

export const formatBusReportData = (data: BusReport[], start: Dayjs, end: Dayjs, region: Region): [SheetData, Columns] => {
    const decimalNumberFormat = '0.0';

    const grouped = groupByVehicleType(data);
    const profitCenters = data.reduce((names: string[], {lines}) => {
        lines.forEach(line => {
            if (line.profitCenter !== null && names.indexOf(line.profitCenter) < 0) {
                names.push(line.profitCenter);
            }
        });
        return names;
    }, []);
    const emptyProfitCenterCols = (profitCenters.length > 1 ? Array(profitCenters.length - 1) : []).map(() => null);
    const fuelTypes = data.reduce((fuelTypes: Fuel[], {fuels}) => {
        fuels.forEach(fuel => {
            if (!fuelTypes.find(fuelType => fuelType === fuel.fuelType)) {
                fuelTypes.push(fuel.fuelType);
            }
        });
        return fuelTypes;
    }, []);
    const emptyFuelTypesCols = (fuelTypes.length > 1 ? Array(fuelTypes.length - 1) : []).map(() => null);
    const commonHeaders: Row = [
        {value: 'Masinmark', fontWeight: 'bold'},
        {value: 'Liinikilomeetrid (kokku)', fontWeight: 'bold'},
        {
            value: 'Tulemusüksuse kaupa liinikilomeetrid',
            fontWeight: 'bold',
            align: 'center',
            span: emptyProfitCenterCols.length + 1
        },
        ...emptyProfitCenterCols,
        {value: 'Üldkilomeetrid', fontWeight: 'bold'},
        {value: 'Tühisõidu osakaal', fontWeight: 'bold'},
        {value: 'Liiniaeg (kokku)', fontWeight: 'bold'},
        {
            value: 'Tulemusüksuse kaupa liiniaeg',
            fontWeight: 'bold',
            align: 'center',
            span: emptyProfitCenterCols.length + 1
        },
        ...emptyProfitCenterCols,
    ];
    const busHeaders: Row = [
        {value: 'Reg. nr', fontWeight: 'bold'},
        ...commonHeaders,
        {value: 'Töötatud päevi', fontWeight: 'bold'},
        ...(fuelTypes.length ? [{value: 'Kütusekogus', fontWeight: 'bold', align: 'center', span: emptyFuelTypesCols.length + 1} as Cell] : []),
        ...emptyFuelTypesCols,
        {value: 'Kütuse erikulu', fontWeight: 'bold', align: 'center', span: 2},
        null,
        {value: 'Odomeetri näidud', fontWeight: 'bold', align: 'center', span: 4},
        null,
        null,
        null
    ];
    const commonSubHeaders: Row = [
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        ...profitCenters.map((name): Cell => ({value: name, fontWeight: 'bold', bottomBorderColor: BORDER_COLOR})),
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        ...profitCenters.map((name): Cell => ({value: name, fontWeight: 'bold', bottomBorderColor: BORDER_COLOR})),
    ];
    const busSubHeaders: Row = [
        ...commonSubHeaders,
        EMPTY_CELL_WITH_BOTTOM_BORDER,
        ...fuelTypes.map((fuel): Cell => ({
            value: `${getFuelTranslation(fuel)} (${getFuelUnit(fuel)})`,
            fontWeight: 'bold',
            bottomBorderColor: BORDER_COLOR
        })),
        {value: '/100liinikm', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: '/100üldkm', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Algnäit', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Lõppnäit', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Üldläbisõit', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        {value: 'Tühisõidu osakaal', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
    ];
    const totalHeaders: Row = [null, ...commonHeaders];
    const totalSubHeaders: Row = [...commonSubHeaders];
    const busRows: Row[] = [];
    const totalRows: Row[] = [];
    let summedTotals = {
        lineDistance: 0,
        deadHeadingDistance: 0,
        lineDistanceByProfitCenter: Array(profitCenters.length).fill(0),
        lineTime: 0,
        lineTimeByProfitCenter: Array(profitCenters.length).fill(0),
    };

    function getOptionalDecimalNumberCell(value: number | undefined) {
        if (value === undefined) {
            return {};
        } else {
            return {value: value, format: decimalNumberFormat};
        }
    }

    grouped.forEach((value, type) => {
        const dataRows: Row[] = [];
        const totals = {
            lineDistance: 0,
            deadHeadingDistance: 0,
            lineTime: 0,
        };

        value.forEach(data => {
            const lineDistance = data.lines.reduce((sum, {lineDistance}) => sum + lineDistance, 0);
            totals.lineDistance += lineDistance;
            const deadHeadingDistance = data.lines.reduce((sum, {deadHeadingDistance}) => sum + deadHeadingDistance, 0);
            totals.deadHeadingDistance += deadHeadingDistance;
            const totalDistance = lineDistance + deadHeadingDistance;
            const lineTime = data.lines.reduce((sum, {lineTime}) => sum + lineTime, 0);
            totals.lineTime += lineTime;
            const totalFuelAmount = data.fuels.reduce((sum, {fuelAmount}) => sum + fuelAmount, 0);
            const totalDistanceByOdometer = data.endingOdometerReading - data.startingOdometerReading;

            dataRows.push([
                {value: data.licencePlateNumber, type: String},
                {value: data.type, type: String},
                {value: lineDistance, format: decimalNumberFormat},
                ...profitCenters.map((name): Cell => {
                    return getOptionalDecimalNumberCell(data.lines.find(item => item.profitCenter === name)?.lineDistance);
                }),
                {value: totalDistance, format: decimalNumberFormat},
                {value: totalDistance !== 0 ? deadHeadingDistance / totalDistance : 0, format: '0.00%'},
                {value: lineTime, format: decimalNumberFormat},
                ...profitCenters.map((name): Cell => {
                    return getOptionalDecimalNumberCell(data.lines.find(item => item.profitCenter === name)?.lineTime);
                }),
                {value: data.workedDays},
                ...fuelTypes.map((fuel): Cell => {
                    return getOptionalDecimalNumberCell(data.fuels.find(item => item.fuelType === fuel)?.fuelAmount);
                }),
                {value: (lineDistance !== 0 ? totalFuelAmount / lineDistance : 0) * 100, format: decimalNumberFormat},
                {value: (totalDistance !== 0 ? totalFuelAmount / totalDistance : 0) * 100, format: decimalNumberFormat},
                {value: data.startingOdometerReading},
                {value: data.endingOdometerReading},
                {value: totalDistanceByOdometer},
                {value: totalDistanceByOdometer !== 0 ? deadHeadingDistance / totalDistanceByOdometer : 0, format: '0.00%'},
            ]);
        });
        const totalDistance = totals.lineDistance + totals.deadHeadingDistance;
        const profitCenterDistances = profitCenters.map(name => value.reduce((sum, {lines}) =>
            sum + (lines.find(item => item.profitCenter === name)?.lineDistance ?? 0), 0));
        const profitCenterTimes = profitCenters.map(name => value.reduce((sum, {lines}) =>
            sum + (lines.find(item => item.profitCenter === name)?.lineTime ?? 0), 0));
        const distanceCoefficients = profitCenterDistances.map(value => totals.lineDistance !== 0 ? value / totals.lineDistance : 0);
        const timesCoefficients = profitCenterTimes.map(value => totals.lineTime !== 0 ? value / totals.lineTime : 0);
        totalRows.push([
            {value: 'kokku', fontWeight: 'bold'},
            {value: type, fontWeight: 'bold'},
            {value: totals.lineDistance, fontWeight: 'bold', format: decimalNumberFormat},
            ...profitCenterDistances.map((value): Cell => ({value: value, fontWeight: 'bold', format: decimalNumberFormat})),
            {value: totalDistance, fontWeight: 'bold', format: decimalNumberFormat},
            {value: totalDistance !== 0 ? totals.deadHeadingDistance / totalDistance : 0, format: '0.00%', fontWeight: 'bold'},
            {value: totals.lineTime, fontWeight: 'bold', format: decimalNumberFormat},
            ...profitCenterTimes.map((value): Cell => ({value: value, fontWeight: 'bold', format: decimalNumberFormat})),
        ]);
        summedTotals = {
            lineDistance: summedTotals.lineDistance + totals.lineDistance,
            deadHeadingDistance: summedTotals.deadHeadingDistance + totals.deadHeadingDistance,
            lineDistanceByProfitCenter: summedTotals.lineDistanceByProfitCenter.map((value, index) => value + profitCenterDistances[index]),
            lineTime: summedTotals.lineTime + totals.lineTime,
            lineTimeByProfitCenter: summedTotals.lineTimeByProfitCenter.map((value, index) => value + profitCenterTimes[index]),
        };
        totalRows.push([
            {value: 'koefitsendid'},
            null,
            {
                value: distanceCoefficients.reduce((sum, value) => sum + value, 0),
                format: '0.000',
            },
            ...distanceCoefficients.map((value): Cell => ({
                value: value,
                format: '0.000',
            })),
            null,
            null,
            {
                value: timesCoefficients.reduce((sum, value) => sum + value, 0),
                format: '0.000',
            },
            ...timesCoefficients.map((value): Cell => ({
                value: value,
                format: '0.000',
            })),
        ]);
        busRows.push(...dataRows);
    });
    const summedTotalRow: Row = [
        {
            value: 'masinmargid kokku',
            fontWeight: 'bold',
            align: 'right',
            span: 2,
            topBorderColor: BORDER_COLOR
        },
        null,
        {value: summedTotals.lineDistance, fontWeight: 'bold', topBorderColor: BORDER_COLOR},
        ...summedTotals.lineDistanceByProfitCenter.map((value): Cell => ({value: value, fontWeight: 'bold', topBorderColor: BORDER_COLOR})),
        {value: summedTotals.lineDistance, fontWeight: 'bold', topBorderColor: BORDER_COLOR},
        {
            value: summedTotals.lineDistance !== 0 ? summedTotals.deadHeadingDistance / (summedTotals.lineDistance + summedTotals.deadHeadingDistance) : 0,
            format: '0.00%',
            fontWeight: 'bold',
            topBorderColor: BORDER_COLOR
        },
        {value: summedTotals.lineTime, fontWeight: 'bold', topBorderColor: BORDER_COLOR},
        ...summedTotals.lineTimeByProfitCenter.map((value): Cell => ({value: value, fontWeight: 'bold', topBorderColor: BORDER_COLOR})),
    ];
    return [[
        [
            {
                value: `${region.name} piirkonna busside tööaruanne`.toUpperCase(),
                fontWeight: 'bold',
            }
        ],
        [
            {
                value: `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`.toUpperCase(),
                fontWeight: 'bold',
            }
        ],
        [],
        busHeaders,
        busSubHeaders,
        ...busRows.sort((a,b) => {
            if (!a || !b || !a.length || !b.length) return -1;
            const aLicencePlate = a[0]?.value?.toString();
            const bLicencePlate = b[0]?.value?.toString();
            if (!aLicencePlate || !bLicencePlate) return -1;
            return (aLicencePlate).localeCompare(bLicencePlate);
        }),
        [],
        totalHeaders,
        totalSubHeaders,
        ...totalRows,
        summedTotalRow
    ], []];
};