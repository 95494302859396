import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers';
import {useField} from 'formik';
import dayjs, {Dayjs} from 'dayjs';
import 'dayjs/locale/et';
import {SxProps, useTheme} from '@mui/material';


dayjs.locale('et');

interface TimePickerProps extends Omit<MuiTimePickerProps<Date>, 'name' | 'value' | 'onChange' | 'views'> {
	name: string;
	label: string;
	sx?: SxProps;
	disabled?: boolean;
}

const TimePicker = ({ name, label, sx, disabled }: TimePickerProps) => {
	const [field, meta, helpers] = useField<Dayjs | null>(name);
	const theme = useTheme();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
			<MuiTimePicker
				label={label}
				value={field.value}
				onChange={(newValue: Dayjs | null) => helpers.setValue(newValue)}
				slotProps={{
					textField: {
						size: 'small',
						error: meta.touched && !!meta.error,
						helperText: meta.touched && meta.error,
						onBlur: () => helpers.setTouched(true),
						inputProps: {
							sx: {
								color: field.value?.isValid() ? '' : theme.palette.text.secondary,
							},
						},
						FormHelperTextProps: {
							sx: {
								color: theme.palette.error.main,
							},
						},
					},
					popper: {
						placement: 'bottom-end',
					}
				}}
				sx={{
					my: 1,
					'& .MuiInputLabel-root': {
						color: meta.touched && !!meta.error
							? theme.palette.error.main
							: disabled ? 'text.disabled' : theme.palette.text.secondary
					},
					...sx,
				}}
				disabled={disabled}
			/>
		</LocalizationProvider>
	);
};

export default TimePicker;
