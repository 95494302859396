import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {Comment, CommentFormData} from "./types";
import {FormikHelpers} from "formik";
import {getDisplayDateTime} from "../../utils/dateUtils";
import ActionIcon from "../Icons/ActionIcon";
import CommentForm from "./components/CommentForm";
import {useAuth} from "../../contexts/AuthContext";


interface CommentsProps {
    comments: Comment[];
    handleSave: (commentForm: CommentFormData, formHelpers: FormikHelpers<CommentFormData>, id?: number) => void;
    handleDelete: (id: number) => void;
    isLoading?: boolean;
    showTableView?: boolean;
}

const Comments = ({comments, handleSave, handleDelete, isLoading = false, showTableView = false}: CommentsProps) => {
    const { authenticatedUser } = useAuth();
    const [commentFormData, setCommentFormData] = useState<Comment | null | undefined>(undefined);

    useEffect(() => {
        if (commentFormData !== undefined) setCommentFormData(undefined);
    }, [comments]);

    const handleAddCommentClick = () => setCommentFormData(prevState => prevState === undefined ? null : undefined);

    const handleCommentSave = (commentForm: CommentFormData, formHelpers: FormikHelpers<CommentFormData>) => {
        handleSave(commentForm, formHelpers, commentFormData?.id);
    };

    const handleEditComment = (id: number) => {
        if (commentFormData?.id === id) {
            setCommentFormData(undefined);
        } else {
            setCommentFormData(comments.find(comment => comment.id === id));
        }
    };

    const handleDeleteComment = (id: number) => handleDelete(id);

    return (
        <Box sx={{width: {xs: '100%', sm: '400px'}, maxWidth: '100%'}}>
            {!showTableView &&
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, mb: -1}}>
                    <Typography variant="h6" sx={{my: 1}}>Kommentaarid</Typography>
                    <ActionIcon type="ADD_COMMENT" id={0} handleClick={handleAddCommentClick} disabled={isLoading} />
                </Box>
            }
            {commentFormData === null && <CommentForm handleSubmit={handleCommentSave} />}
            {!comments.length && commentFormData !== null &&
                <Typography variant="body1" color="text.secondary" pt={1}>Kommentaarid puuduvad</Typography>
            }
            {comments.map(comment =>
                <Box key={comment.id} sx={{'&:not(:last-of-type)': {borderBottom: '1px solid', borderColor: 'divider'}, py: showTableView ? 0.2 : 1}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',  minHeight: showTableView ?  '' : '30px'}}>
                        <Box sx={{display: 'flex'}}>
                            <Typography variant="body2" fontWeight="bold" pr={1}>{comment.creatorUserName}</Typography>
                            <Typography variant="body2" color="text.secondary">{getDisplayDateTime(comment.createdAt)}</Typography>
                        </Box>
                        {!showTableView && comment.creatorUserId === authenticatedUser?.id &&
                            <Box>
                                <ActionIcon type="EDIT" id={comment.id} handleClick={() => handleEditComment(comment.id)} disabled={isLoading} />
                                <ActionIcon type="DELETE" id={comment.id} handleClick={() => handleDeleteComment(comment.id)} disabled={isLoading} />
                            </Box>
                        }
                    </Box>
                    {commentFormData?.id === comment.id && commentFormData !== null ?
                        <CommentForm comment={commentFormData} handleSubmit={handleCommentSave} />
                        :
                        <Typography variant={showTableView ? 'body2' : 'body1'} sx={{whiteSpace: showTableView ? '' : 'pre-line'}}>
                            {comment.text}
                        </Typography>
                    }
                    {!showTableView && comment.lastEditedAt &&
                        <Typography variant="body2" color="text.secondary" pt={1} sx={{fontSize: '12px'}}>
                            Muudetud {getDisplayDateTime(comment.lastEditedAt)}
                        </Typography>
                    }
                </Box>
            )}
        </Box>
    );
};

export default Comments;
