import {Payslip, PayslipPeriod, PayType} from "../../../../API/payslip/types";
import {PayslipBlockData} from "./PayslipBlock";
import dayjs from "dayjs";
import 'dayjs/locale/et';

dayjs.locale('et')

function getNonContractualBlocks(period: PayslipPeriod, addYearMonthPrefix: boolean): PayslipBlockData[] {
    const linesWithoutContract = period.lines.filter(line => line.contractId === null);
    if (linesWithoutContract.length === 0) {
        return [];
    }
    let label: string;
    if (addYearMonthPrefix) {
        label = `${dayjs(period.yearMonth).format('MMMM YYYY')} veolepinguta`;
    } else {
        label = `Veolepinguta`;
    }
    return [{
        name: label,
        lines: new Map<PayType, { hours: number; amount: number }>(
            linesWithoutContract.map(line => [
                line.type,
                {
                    hours: line.hours,
                    amount: line.amount,
                },
            ])
        )
    }];
}

function getTotalBlock(blocks: PayslipBlockData[], label: string) {
    return blocks.reduce((total, block) => {
        for (const [type, line] of block.lines) {
            const totalLine = total.lines.get(type) ?? {hours: 0, amount: 0};
            totalLine.hours += line.hours;
            totalLine.amount += line.amount;
            total.lines.set(type, totalLine);
        }
        return total;
    }, {
        name: label,
        lines: new Map<PayType, { hours: number, amount: number }>(),
    });
}

function getContractualBlocks(period: PayslipPeriod, addYearMonthPrefix: boolean) {
    return period.contracts.map((contract): PayslipBlockData => {
        let label: string;
        if (addYearMonthPrefix) {
            label = `${dayjs(period.yearMonth).format('MMMM YYYY')} veoleping ${contract.contractId}`;
        } else {
            label = `Veoleping ${contract.contractId}`;
        }
        return {
            name: label,
            lines: new Map<PayType, { hours: number; amount: number }>(
                period.lines.filter(line => line.contractId === contract.id).map(line => [
                    line.type,
                    {
                        hours: line.hours,
                        amount: line.amount,
                    },
                ])
            )
        };
    });
}

function getFinalPayslipLabel(startDate: string, endDate: string) {
    return `Lõpparve ${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(endDate).format('DD.MM.YYYY')}`;
}

function getOverallTotal(periodTotals: [string, PayslipBlockData][], label: string): PayslipBlockData[] {
    if (periodTotals.length <= 1) {
        return [];
    }
    const result = getTotalBlock(periodTotals.map(item => item[1]), label);
    return [result];
}

function composePeriodsWithTotals(periodTotals: [string, PayslipBlockData][], periods: Map<string, PayslipBlockData[]>) {
    return periodTotals.flatMap((entry) => {
        const yearMonth = entry[0];
        const currentTotalBlock = entry[1];
        const currentPeriodBlocks = periods.get(yearMonth) || [];
        return [currentTotalBlock].concat(currentPeriodBlocks);
    });
}

function getTotalBlockLabel(payslip: Payslip, hasMultiplePeriods: boolean, yearMonth: string) {
    if (payslip.body.finalPayslip && !hasMultiplePeriods) {
        return getFinalPayslipLabel(payslip.startDate, payslip.endDate);
    } else {
        return dayjs(yearMonth).format('MMMM YYYY');
    }
}

export const composePayslipBlockData = (payslip: Payslip): PayslipBlockData[] => {
    const hasMultiplePeriods = payslip.body.periods.length > 1;

    const periods = new Map(payslip.body.periods.map((period): [string, PayslipBlockData[]] => {
        return [period.yearMonth, getContractualBlocks(period, hasMultiplePeriods).concat(getNonContractualBlocks(period, hasMultiplePeriods))];
    }));

    const periodTotals = Array.from(periods.entries()).map((entry): [string, PayslipBlockData] => {
        const yearMonth = entry[0];
        return [yearMonth, getTotalBlock(entry[1], getTotalBlockLabel(payslip, hasMultiplePeriods, yearMonth))];
    });

    const periodsWithTotals = composePeriodsWithTotals(periodTotals, periods);

    return getOverallTotal(periodTotals, getFinalPayslipLabel(payslip.startDate, payslip.endDate)).concat(periodsWithTotals);
}