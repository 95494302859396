import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {CalendarEntry} from "../API/types";
import {loadCalendarEntries} from "../API";
import {RootState} from "../store";

export const calendarEntryAdapter = createEntityAdapter<CalendarEntry>();

export const fetchCalendarEntries = createAsyncThunk(
    'calendarEntries/getAll',
    () => {
        return loadCalendarEntries();
    }
);

const initialState: EntityState<CalendarEntry> = calendarEntryAdapter.getInitialState();

export const calendarEntrySlice = createSlice({
    name: 'calendarEntries',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCalendarEntries.fulfilled, (state, action) => {
            calendarEntryAdapter.setAll(state, action.payload);
        });
    }
});

export const {
    selectAll: selectAllCalendarEntries,
} = calendarEntryAdapter.getSelectors<RootState>((state) => state.calendarEntries);

export default calendarEntrySlice.reducer;