import {useAppSelector} from "../../../../../../../hooks";
import {selectOppositeToggledResourceType} from "../../../../../../../store/viewSlice";
import TooltipRowWithLink from "./TooltipRowWithLink";
import routes from "../../../../../../../routes";
import React from "react";
import {WorkGroup} from "../../../../../../../API/workGroup/types";

const OppositeWorkGroup = ({workGroup}: {workGroup?: WorkGroup}) => {
    const oppositeResourceType = useAppSelector(selectOppositeToggledResourceType);

    if (!workGroup) return null;

    return (
        <TooltipRowWithLink
            text={workGroup.code}
            route={routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', workGroup.id.toString())}
            resourceType={oppositeResourceType}
            padTop
        />
    );
};

export default OppositeWorkGroup;