import {ChangedWorkGroupVersion} from "../../../../../API/tripDefinition/types";
import {useAppSelector} from "../../../../../hooks";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import CardBase from "./CardBase";
import {Box, Link, Typography} from "@mui/material";
import {Diversity2} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../../../../routes";
import React from "react";

import {getDisplayValidDatesRange} from "../../../../../utils/dateUtils";

const WorkGroupCard = ({workGroup}: { workGroup: ChangedWorkGroupVersion }) => {
    const selectedRegion = useAppSelector(selectSelectedRegion);

    return (
        <CardBase>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{display: 'flex', alignItems: 'normal'}}>
                    <Diversity2 fontSize="small" sx={{color: 'text.secondary', mr: 0.5}}/>
                    <Typography>
                        <Link component={RouterLink}
                              to={routes.AUTHENTICATED.WORK_GROUPS.EDIT.replace(':id', workGroup.workGroupId)}
                              target='_blank'
                        >{workGroup.code}</Link>
                    </Typography>
                </Box>
                <Typography>{workGroup.region.id !== selectedRegion?.id ? workGroup.region.name : ''}</Typography>
            </Box>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>{getDisplayValidDatesRange(workGroup)}</Typography>
        </CardBase>
    )
};

export default WorkGroupCard;