import React, {useEffect, useMemo, useState} from "react";
import {BusDetails, FuelCard} from "../../../../../../API/bus/types";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import dayjs from "dayjs";
import {AirlineSeatReclineNormal, LocalGasStation, Man, Speed, Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {grey} from "@mui/material/colors";
import {getFuelTranslation} from "../../../../../../utils/enumTranslations";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {selectCurrentDriverBusByLicencePlateNumber} from "../../../../../../store/currentDriverWorkSheetsSlice";
import routes from "../../../../../../routes";
import BackButton from "../../components/BackButton";
import DefectsTab from "./components/DefectsTab";
import OdometerReadingsTab from "./components/OdometerReadingsTab";
import Divider from "@mui/material/Divider";
import {loadDefectsByBusId} from "../../../../../../store/defectsSlice";


const FuelCardView = ({card}: {card: FuelCard}) => {
    const [isFuelCardPinVisible, setIsFuelCardPinVisible] = useState(false);

    return (
        <Box>
            <Typography component="span">Kütusekaardi {card.name} PIN:</Typography>
            <Box component="code" sx={{backgroundColor: grey[100], px: 1, mx: 1}}>
                {isFuelCardPinVisible ? card.pin : '•'.repeat(card.pin.length)}
            </Box>
            <IconButton size="small" onClick={() => setIsFuelCardPinVisible(prevState => !prevState)}>
                {isFuelCardPinVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
};

const BusView = () => {
    const dispatch = useAppDispatch();
    const {licencePlateNumber} = useParams();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = React.useState(1);
    const bus = useAppSelector(state => selectCurrentDriverBusByLicencePlateNumber(state, licencePlateNumber ?? ''));

    useEffect(() => {
        if (bus?.id) dispatch(loadDefectsByBusId({busId: bus.id}));
    }, [bus]);

    const handleClose = () => {
        navigate(routes.AUTHENTICATED.AGENDA.ROOT);
    };

    const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const latestOdometerReading = useMemo(() => {
        if (!bus || bus.odometerReadings.length === 0) {
            return null;
        }

        const sorted = [...bus.odometerReadings]
            .sort((a,b) => b.dateTime.localeCompare(a.dateTime));

        return sorted[0].reading.toLocaleString('et-EE');
    }, [bus]);

    const renderHeader = (bus: BusDetails) => {
        return (
            <>
                <Box sx={{display: 'flex', alignItems: 'flex-start', pb: 1}}>
                    <BackButton onClick={handleClose} />
                    <Box pl={1} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h6">{bus.licencePlateNumber}</Typography>
                        <Typography variant="body2" color="text.secondary" mt={-0.5}>
                            {bus.make} {bus.model}, {dayjs(bus.registrationDate).format('YYYY')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', my: 1}}>
                    <>
                        <Typography sx={{display: 'flex', alignItems: 'center'}}>
                            <LocalGasStation sx={{color: 'text.secondary', pr: 0.5}} />{bus.fuelTypes.map(getFuelTranslation).join(', ')}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{mx: 1}} />
                    </>
                    <>
                        <Typography sx={{display: 'flex', alignItems: 'center', minWidth: 'fit-content'}} noWrap>
                            <Speed sx={{color: 'text.secondary', pr: 0.5}} /> {latestOdometerReading ? `${latestOdometerReading} km` : 'puudub'}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{mx: 1}} />
                    </>
                    <Box display="flex">
                        <Typography component="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <AirlineSeatReclineNormal sx={{color: 'text.secondary'}} /> {bus.seatingCapacity}
                        </Typography>
                        <Divider orientation="vertical" flexItem sx={{mx: 1}} />
                        <Typography component="span" sx={{display: 'flex', alignItems: 'center'}}>
                            <Man sx={{color: 'text.secondary', ml: -0.5}} /> {bus.standingCapacity}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {bus.fuelCards.map(card => <FuelCardView card={card} key={card.name} />)}
                </Box>
            </>
        );
    };

    const renderTabHeader = (label: string, value: number, width: string) => (
        <Tab label={label} value={value} sx={{width: width}} />
    );

    function CustomTabPanel({index, children}: {index: number, children?: React.ReactNode}) {
        return (
            <Box
                role="tabpanel"
                hidden={tabValue !== index}
                id={`simple-tab-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                aria-controls={`simple-tabpanel-${index}`}
            >
                {tabValue === index && <Box>{children}</Box>}
            </Box>
        );
    }

    return bus ?
        <Box>
            {renderHeader(bus)}
            <Tabs value={tabValue} onChange={handleTabChange} sx={{mt: 1}}>
                {renderTabHeader('Odomeetri näidud', 1, '60%')}
                {renderTabHeader('Rikked', 2, '40%')}
            </Tabs>
            <CustomTabPanel index={1}>
                <OdometerReadingsTab bus={bus} />
            </CustomTabPanel>
            <CustomTabPanel index={2}>
                <DefectsTab bus={bus} />
            </CustomTabPanel>
        </Box>
        :
        <Box sx={{display: 'flex'}}>
            <BackButton onClick={handleClose} />
            <Typography variant="h6" pl={1}>Bussi ei leitud</Typography>
        </Box>;
};

export default BusView;
