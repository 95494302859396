import {EntityId} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../../../../../hooks";
import {selectOppositeToggledResourceType} from "../../../../../../../store/viewSlice";
import {selectResourceNameByWorkSheetId} from "../../../../../store/selectors";
import TooltipRowWithLink from "./TooltipRowWithLink";
import routes from "../../../../../../../routes";
import React from "react";

const OppositeWorkSheet = ({workSheetIds}: { workSheetIds: EntityId[] }) => {
    const oppositeResourceType = useAppSelector(selectOppositeToggledResourceType);
    const oppositeTypeWorkSheetId = workSheetIds.length > 0 ? workSheetIds[0] : undefined;
    const resourceName = useAppSelector(state => selectResourceNameByWorkSheetId(state, oppositeTypeWorkSheetId ?? 0));
    if (!oppositeTypeWorkSheetId || !resourceName) return null;

    return (
        <TooltipRowWithLink
            text={resourceName}
            route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', oppositeTypeWorkSheetId.toString())}
            resourceType={oppositeResourceType}
            padTop
        />
    );
};

export default OppositeWorkSheet;