import React, {useMemo, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "../../../../components/Buttons/Button";
import {Role} from "../../../../types";
import {Box, Typography} from "@mui/material";
import {Clear} from '@mui/icons-material';
import {User, UserPermissions} from "../../../../API/types";
import {Form, Formik} from "formik";
import UserPermissionsForm, {UserPermissionsFormData} from "./UserPermissionsForm";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {Region} from "../../../../API/region/types";
import {getRoleTranslation} from "../../../../utils/enumTranslations";
import {getEnumFromStr} from "../../../../utils/formUtils";


interface EditUserDialogProps {
    open: boolean;
    user: User;
    updateUser: (id: string, userPermissions: UserPermissions) => void;
    handleCloseDialog: () => void;
}

export default function EditUserDialog({open, user, updateUser, handleCloseDialog}: EditUserDialogProps) {
    const [loading, setLoading] = useState(false);
    const regions = useAppSelector(selectAllRegions);

    const userRegions: Region[] = useMemo(() => {
        return user.regionIds
            ?.map(id => regions.find(region => region.id === id))
            ?.filter((region): region is Region => !!region) ?? []
    }, [user, regions]);

    const handleUpdateUserPermissions = (data: UserPermissionsFormData) => {
        setLoading(true);
        updateUser(user.id, {
            roles: data.roles.map(role => getEnumFromStr(role.id, Role)) as Role[],
            allRegions: data.allRegions,
            regionIds: !data.allRegions ? data.regions.map(region => region.id) : [],
        });
        setLoading(false);
    };

    const initialValues: UserPermissionsFormData = {
        roles: user.roles?.map(role => ({
            id: role.toString(),
            name: getRoleTranslation(role),
        })) ?? [],
        allRegions: user.allRegions,
        regions: userRegions,
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Box p={2} sx={{width: '400px', maxWidth: '100%'}}>
                <Formik initialValues={initialValues} onSubmit={handleUpdateUserPermissions}>
                    <Form>
                        <DialogTitle sx={{width: '300px', maxWidth: '100%'}}>
                            {user.displayName}
                            <Typography variant="body2" color="textSecondary">{user.userName}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <UserPermissionsForm />
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                            <Box maxWidth="50%">
                                <Button disabled={loading}
                                        text="Loobu"
                                        color="secondary"
                                        startIcon={<Clear />}
                                        onClick={handleCloseDialog} />
                            </Box>
                            <Box maxWidth="50%">
                                <Button disabled={loading} text="Salvesta" type="submit" />
                            </Box>
                        </DialogActions>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
    );
}