import React from 'react';
import {useAppSelector} from "../../../../../../../../../hooks";
import {selectDisplayWorkGroupByIdMemoized} from "../../../../../../../store/selectors";
import {EntityId} from "@reduxjs/toolkit";
import {shallowEqual} from "react-redux";
import {isDriverWorkDurationOverLimit} from "../../../../../../../utils/utils";
import PlannerItemGroupCard from "../../../Card";


interface WorkGroupWrapperProps {
    groupId: EntityId;
    index: number;
}

const WorkGroupCardWrapper = ({groupId, index}: WorkGroupWrapperProps) => {
    const workGroup = useAppSelector(state => selectDisplayWorkGroupByIdMemoized(state, Number(groupId)), shallowEqual);
    if (!workGroup) return <></>;
    const isDurationOverLimit = isDriverWorkDurationOverLimit(
        workGroup.type,
        workGroup.firstItemStartHh ?? 0,
        workGroup.lastItemEndHh ?? 0,
        [...workGroup.activities, ...workGroup.tripDefinitions]
    );

    return (
        <PlannerItemGroupCard
            groupId={groupId}
            workGroupId={Number(groupId)}
            workGroupCode={workGroup.code}
            index={index}
            isDurationOverLimit={isDurationOverLimit}
            repetition={workGroup.repetition}
        />
    )
};

export default WorkGroupCardWrapper;
