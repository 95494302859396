import axios from "axios";
import {handleError} from "../utils";
import {User, Me, UserPermissions} from "./types";

export const getMe = () =>
    axios.get<Me>('/api/users/me')
        .then(result => result.data)
        .catch(handleError);

export const getUsers = () =>
    axios.get<User[]>('/api/users')
        .then(result => result.data)
        .catch(handleError);

export const updateUserPermissions = (userId: string, userPermissions: UserPermissions) =>
    axios.put(`/api/users/${userId}/permissions`, userPermissions)
        .catch(handleError);