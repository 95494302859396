import React, {FC, ReactNode, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {Box, styled} from '@mui/material';
import {useAuth} from "../../contexts/AuthContext";
import {useLocation} from "react-router";


const PageContainer = styled(Box)(({ theme }) => ({
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
}));

interface BaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    const { authenticatedUser } = useAuth();
    const prevLocation = useLocation().state?.from?.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticatedUser) {
            navigate(prevLocation ?? '/');
        }
    }, [authenticatedUser]);

    return (
        <PageContainer>
            {children || <Outlet />}
        </PageContainer>
    );
};

export default BaseLayout;
