import React, {useContext, useEffect, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {ApiError, TripDefinition} from "../../../../API/types";
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid';
import {filterByActive, filterByValue} from "../../../../utils/utils";
import theme from "../../../../theme";
import {ActiveToggleButtonType, activeToggleButtonValues, Permission} from "../../../../types";
import Visible from '../../../../components/Visible';
import DataGrid from "../../../../components/DataGrid";
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import routes from "../../../../routes";
import {useAppSelector} from "../../../../hooks";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {endTrip, loadTripDefinitions} from "../../../../API";
import {ToastContext} from "../../../../contexts/ToastContext";
import {mapErrors} from "../../../../utils/errorMapping";
import {
    getDateString,
    getDisplayDate,
    getDisplayDateWithoutYear,
    getDisplayTimeWithModifier
} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink";
import NavIcon from "../../../../components/Icons/NavIcon";
import StaticIcon from "../../../../components/Icons/StaticIcon";
import Repetition from "../../../../components/RepetitionRules";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import ActionIcon from "../../../../components/Icons/ActionIcon";
import EndTripDialog, {EndTripDialogData} from "./components/EndTripDialog";


export default function Trips() {
    const { addToast } = useContext(ToastContext);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const navigate = useNavigate();
    const location = useLocation();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [rows, setRows] = useState<TripDefinition[] | undefined>(undefined);
    const [toggleButtonValue, setToggleButtonValue] = useState<ActiveToggleButtonType>('AKTIIVSED');
    const [endTripDialogData, setEndTripDialogData] = useState<EndTripDialogData | undefined>(undefined);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('xl'));

    useEffect(() => {
        if (selectedRegion) {
            loadTripDefinitions(selectedRegion.id)
                .then(result => setRows(result))
                .catch((error: ApiError) => {
                    setRows([]);
                    addToast({type: 'error', text: mapErrors(error) ?? 'Reiside pärimisel ilmnes viga'})
                })
            ;
        }
    }, [selectedRegion]);

    const handleAddTripDefinition = () => {
        navigate(routes.AUTHENTICATED.TRIPS.ADD);
    };

    const handleToggleButtonChange = (value: string) => {
        if (activeToggleButtonValues.includes(value as ActiveToggleButtonType)) setToggleButtonValue(value as ActiveToggleButtonType);
    };

    const getFilteredRows = (rows: TripDefinition[]) => {
        return filterByActive(filterByValue(rows, searchInput), toggleButtonValue)
            .sort((a,b) =>
                a.code.localeCompare(b.code) || (a.validFrom ?? '').localeCompare(b.validFrom ?? '')
            );
    };

    const handleClose = () => setEndTripDialogData(undefined);

    const handleEndTrip = async (trip: TripDefinition, date: Date) => {
        const dateString = getDateString(date);
        return endTrip(trip.tripId, {
                date: dateString,
                confirmed: false,
            }).then((result) => {
                setEndTripDialogData(prevState =>
                    prevState && { ...prevState, date: date, plannedChanges: result }
                );
            });
    };

    const handleConfirmEndTrip = async (trip: TripDefinition, date: Date) => {
        const dateString = getDateString(date);
        await endTrip(trip.tripId, {
            date: dateString,
            confirmed: true,
        });
        setRows((prevState) =>
            prevState && prevState.map(row => (row.id === trip.id) ? {...row, validTo: dateString} : row)
        );
        setEndTripDialogData(undefined);
        navigate(location.pathname, {
            replace: true,
            state: {highlightId: trip.id}
        });
        addToast({
            type: 'success',
            text: `Reisi ${trip.code} lõpukuupäevaks määratud ${getDisplayDate(date)}`,
        });
    };

    const columns: GridColDef[] = [
        {
            field: 'code',
            headerName: 'Kood',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            renderCell: (params) =>
                <NavLink id={params.row.id} value={params.row.code} route={routes.AUTHENTICATED.TRIPS.EDIT} navPermission={Permission.UpdateTripDefinitions} />
        },
        {
            field: 'lineNumber',
            headerName: 'Liini nr',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
        },
        {
            field: 'startTimeWithModifier',
            headerName: 'Algusaeg',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 90,
            valueGetter: (params: GridValueGetterParams) =>
                getDisplayTimeWithModifier(params.row.startTime, params.row.startTimeIsOnNextDay),
        },
        {
            field: 'endTimeWithModifier',
            headerName: 'Lõpuaeg',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 90,
            valueGetter: (params: GridValueGetterParams) =>
                getDisplayTimeWithModifier(params.row.endTime, params.row.endTimeIsOnNextDay),
        },
        {
            field: 'startingPoint',
            headerName: 'Algpunkt',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            valueGetter: (params) => params.row.route && params.row.route.length > 0
                ? params.row.route[0].name
                : undefined,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{params.value}</Box> : <></>
        },
        {
            field: 'endingPoint',
            headerName: 'Lõpp-punkt',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            valueGetter: (params) => params.row.route && params.row.route.length > 1
                ? params.row.route[params.row.route.length-1].name
                : undefined,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{params.value}</Box> : <></>
        },
        {
            field: 'validFrom',
            headerName: 'Algus',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'validTo',
            headerName: 'Lõpp',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 100 : 120,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            field: 'periodStartAndEndDate',
            headerName: 'Kehtivusperiood',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 110 : 140,
            valueGetter: (params: GridValueGetterParams) => params.row.startDate && params.row.endDate ?
                getDisplayDateWithoutYear(params.row.startDate) + ' - ' + getDisplayDateWithoutYear(params.row.endDate) : ''
        },
        {
            field: 'repetition',
            headerName: 'Korduvusreeglid',
            sortable: false,
            filterable: false,
            minWidth: 160,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}><Repetition repetition={params.value} /></Box> : <></>
        },
        {
            field: 'active',
            headerName: 'Aktiivne',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 80 : 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        },
        {
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: true,
            filterable: false,
            width: isScreenSmall ? 140 : 300,
        },
        {
            field: 'Menüü',
            headerName: '',
            sortable: false,
            filterable: false,
            align: 'right',
            flex: 1,
            minWidth: 160,
            renderCell: (params) => {
                return (
                    <Visible permission={Permission.UpdateTripDefinitions}>
                        <Box>
                            <NavIcon type="COPY" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.ADD}
                                     copyState={{copiedTripDefinition: rows?.find(row => row.id === params.id)}} />
                            <ActionIcon type={"END_TRIP"} id={params.row.id}
                                        handleClick={() => setEndTripDialogData({trip: params.row, date: null})} />
                            <NavIcon type="START_NEW" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.ADD}
                                     copyState={{previousTripDefinitionVersion: rows?.find(row => row.id === params.id)}} />
                            <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.EDIT} />
                        </Box>
                    </Visible>
                );
            }
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Reisid',
                searchProps: {input: searchInput, setInput: setSearchInput},
                buttonProps: {
                    title: 'Lisa reis',
                    onClick: handleAddTripDefinition,
                    permission: Permission.UpdateTripDefinitions
                },
                toggleProps: {
                    options: activeToggleButtonValues.map(value => value.toString()),
                    value: toggleButtonValue,
                    handleChange: handleToggleButtonChange
                },
                showRegionSelect: true,
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
            />
            {endTripDialogData &&
                <EndTripDialog
                    dialogData={endTripDialogData}
                    handleCloseDialog={handleClose}
                    handleSave={handleEndTrip}
                    handleConfirm={handleConfirmEndTrip}
                />
            }
        </ListView>
    );
}
