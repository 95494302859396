import React, {useState} from "react";
import ActionIcon from "../../../../../components/Icons/ActionIcon";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {setToast} from "../../../../../store/toastSlice";
import {selectCharterTripById} from "../../../../../store/charterTripsSlice";
import {getDisplayDateTime} from "../../../../../utils/dateUtils";
import {formatName} from "../../../workSchedule/utils";
import dayjs from "dayjs";


const CopyInfoButton = ({charterTripId}: {charterTripId: string | number}) => {
    const dispatch = useAppDispatch();
    const charterTrip = useAppSelector(state => selectCharterTripById(state, charterTripId));
    const [copySuccess, setCopySuccess] = useState(false);
    if (!charterTrip) return null;

    const handleCopy = () => {
        if (!charterTrip.bus || !charterTrip.driver) return;

        const text =
            `${getDisplayDateTime(charterTrip.startDateTime)}-${dayjs(charterTrip.endDateTime).format('HH:mm')}`
            + `, ${charterTrip.route}`
            + ` (${charterTrip.bus.licencePlateNumber}, ${formatName(charterTrip.driver)} ${charterTrip.driver.phoneNumber})`;

        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 3000);
            })
            .catch(() => {
                dispatch(setToast({ type: 'error', text: 'Sõidu info kopeerimisel lõikelauale esines tõrge' }));
            });
    };

    return (
        <ActionIcon
            type="COPY_EMAIL_INFO"
            id={Number(charterTripId)}
            tooltipTitle={!charterTrip.driver || !charterTrip.bus
                ? 'Sõidu info kopeerimiseks peab olema määratud buss ja juht'
                : copySuccess ? 'Kopeeritud!' : 'Kopeeri sõidu info lõikelauale'
            }
            disabled={!charterTrip.driver || !charterTrip.bus}
            handleClick={handleCopy}
        />
    );
};

export default CopyInfoButton;
