import {Form, FormikProps} from "formik";
import {Box, Typography} from "@mui/material";
import TextField from "../../../../../components/Form/TextField";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {CharterTripForm} from "../types";
import DetailViewButtons from "../../../../../components/Buttons/DetailViewButtons";
import NumberTextField from "../../../../../components/Form/NumberTextField";
import routes from "../../../../../routes";
import {useAppSelector} from "../../../../../hooks";
import SelectWithId from "../../../../../components/Form/SelectWithId";
import {CharterTrip} from "../../../../../API/types";
import SelectFreeInput from "../../../../../components/Form/SelectFreeInput";
import {selectAllCharterOrders, selectTripsCountWithSelectedOrderId} from "../../../../../store/charterTripsSlice";
import {Permission, SelectOptionWithIdAndPermission} from "../../../../../types";
import ModifierStartAndEndTime from "../../../../../components/Form/ModifierStartAndEndTime";
import Switch from "../../../../../components/Form/Switch";
import DatePicker from "../../../../../components/Form/DatePicker";
import {loadTransportContracts} from "../../../../../API";
import {mapErrors} from "../../../../../utils/errorMapping";
import {ToastContext} from "../../../../../contexts/ToastContext";
import {CharterTripZone, TransportContract} from "../../../../../API/charterTrip/types";
import {getDisplayDate, getDisplayNullableDate} from "../../../../../utils/dateUtils";
import StatusWithProgress from "../../listView/components/StatusWithProgress";
import {getCharterTripZoneTranslationFromStr} from "../../../../../utils/enumTranslations";
import Select from "../../../../../components/Form/Select";
import {formatName} from "../../../workSchedule/utils";


interface CharterTripEditFormProps {
    id: string | undefined;
    formikProps: FormikProps<CharterTripForm>;
    defaultFormValues: CharterTripForm;
    hasCopiedContent?: boolean;
    existingCharterTrip?: CharterTrip;
}

export default function CharterTripEditForm({id, formikProps, defaultFormValues, hasCopiedContent, existingCharterTrip}: CharterTripEditFormProps) {
    const { addToast } = useContext(ToastContext);
    const charterOrderOptions = useAppSelector(selectAllCharterOrders);
    const charterOrderTripsCount = useAppSelector(state => selectTripsCountWithSelectedOrderId(state, formikProps.values.orderNumber));
    const selectedCharterOrder = useMemo(() =>
        charterOrderOptions.find(order => order.orderNumber === formikProps.values.orderNumber)
        , [formikProps.values.orderNumber, charterOrderOptions]
    );
    const [transportContracts, setTransportContracts] = useState<TransportContract[] | undefined>(undefined);

    useEffect(() => {
        loadTransportContracts()
            .then(result => setTransportContracts(result.filter(c => c.appliesToCharterTrip && c.active)))
            .catch(error => {
                addToast({
                    type: 'error',
                    text: mapErrors(error) ?? 'Veolepingute pärimisel ilmnes viga',
                });
            });
    }, []);

    useEffect(() => {
        if (formikProps.isSubmitting) {
            formikProps.setSubmitting(false);
        }
    }, [formikProps.initialValues]);

    const renderDetailViewButtons = () => {
        return (
            <DetailViewButtons
                id={id}
                defaultFormValues={defaultFormValues}
                formikProps={formikProps}
                listViewPath={routes.AUTHENTICATED.CHARTER_TRIPS.ROOT}
                allowSaveWithoutChanges={hasCopiedContent}
                savePermission={Permission.UpdateCharterTrips}
            />
        );
    };

    const transportContractToOption = (contract: TransportContract): SelectOptionWithIdAndPermission => {
        const validFrom = getDisplayDate(contract.validFrom);
        const validTo = getDisplayNullableDate(contract.validTo);
        return {
            id: contract.id,
            name: contract.contractId,
            description: `${contract.regionName}, ${validFrom} - ${validTo}`,
        };
    };

    const getTransportContractOptions = () => {
        if (transportContracts === undefined) {
            if (existingCharterTrip !== undefined) {
                return [transportContractToOption(existingCharterTrip.transportContract)];
            } else {
                return [];
            }
        } else {
            const options = transportContracts.map(tc => transportContractToOption(tc));
            if (existingCharterTrip !== undefined && !options.find(o => o.id === existingCharterTrip.transportContract.id)) {
                return [transportContractToOption(existingCharterTrip.transportContract), ...options];
            } else {
                return options;
            }
        }
    };

    const newOptionValidation = (value: string) => !(value.length > 0 && value.substring(0,1) === 'T');

    return (
        <Form>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2}}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h5" pt={{xs: 1, sm: 0}} pr={1}>
                            {existingCharterTrip?.tripNumber ?? 'Uus sõit'}
                        </Typography>
                        { existingCharterTrip &&
							<StatusWithProgress status={existingCharterTrip.status} showCompactVersion customSize={28} tooltipPlacement="right" />
                        }
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        {existingCharterTrip?.driver && `${formatName(existingCharterTrip.driver)}`}
                        {existingCharterTrip?.driver && existingCharterTrip.bus && ' | '}
                        {existingCharterTrip?.bus && `${existingCharterTrip.bus.licencePlateNumber}`}
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                        {renderDetailViewButtons()}
                    </Box>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', maxWidth: '100%', width: {xs: '100%', sm: '450px'}}}>
                <SelectFreeInput
                    name="orderNumber"
                    label="Tellimuse number"
                    options={charterOrderOptions.map(charterOrderOption => charterOrderOption.orderNumber)}
                    newOptionValidation={newOptionValidation}
                    emptyInputHelperText="Tühjaks jätmisel genereerib süsteem tellimuse numbri automaatselt"
                />
                <TextField name="client" label="Kliendi nimi*" />
                {charterOrderTripsCount > 1 && selectedCharterOrder && formikProps.values.client && selectedCharterOrder.client !== formikProps.values.client &&
                    <Typography variant="body2" fontSize="12px" color="warning.main" mt={-0.5} mb={0.5} ml={1.5}>
                        {`Kliendi nimi erineb varasemalt tellimusele #${formikProps.values.orderNumber} lisatud 
                        nimest "${selectedCharterOrder.client}". Salvestamisel uueneb see kõigil tellimuse sõitudel.`}
                    </Typography>
                }
                <Select name="zone"
                        label="Tsoon*"
                        options={Object.values(CharterTripZone)}
                        translationFunction={getCharterTripZoneTranslationFromStr}
                        translationEnumType={CharterTripZone}
                />
                <TextField name="route" label="Marsruut*" />
                <TextField name="departurePoint" label="Lähtekoht*" />
                <TextField name="destination" label="Sihtkoht*" />
                <ModifierStartAndEndTime />
                <DatePicker name="date" label="Kuupäev*" />
                <NumberTextField name="distance" label="Pikkus* (km)" decimals={2} />
                <NumberTextField name="numberOfPassengers" label="Reisijate arv*" />
                <SelectWithId name="transportContractId" label="Veoleping*" options={getTransportContractOptions()} />
                <NumberTextField name="price" label="Hind* (ilma KM-ta)" decimals={2} />
                <Switch name="isInvoiced" label="Arve väljastatud" />
                <TextField name="comment" label="Lisainfo" multiline minRows={2} maxRows={5} />
                <Switch name="isCancelled" label="Tühistatud" />
                <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                    {renderDetailViewButtons()}
                </Box>
            </Box>
        </Form>
    );
}
