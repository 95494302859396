import {Box} from "@mui/material";
import React from "react";

const StartAndEndPoint = ({start = '', end = ''}: {start?: string, end?: string}) => {
    return (
        <Box sx={{display: 'flex'}} component="span">
            {start.length > 0 &&
                <Box component="span" sx={{flex: 1, maxWidth: 'fit-content', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{start.toUpperCase()}</Box>
            }
            {start.length > 0 && end.length > 0 &&
                <Box component="span" sx={{px: 0.3}}>-</Box>
            }
            {end.length > 0 &&
                <Box component="span" sx={{flex: 1, maxWidth: 'fit-content', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{end.toUpperCase()}</Box>
            }
        </Box>
    );
};

export default StartAndEndPoint;