import React, {useEffect, useMemo, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Dayjs} from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {PayslipViewType, payslipViewTypeValues} from "../../types";
import {formatName} from "../../../workSchedule/utils";
import EditableDataGrid, {defaultColDef, numberColDef} from "../../../../../components/EditableDataGrid";
import {selectSelectedRegion} from "../../../../../store/regionSlice";
import {Region} from "../../../../../API/types";
import {getPayslipExtras, updatePayslipExtras} from "../../../../../API";
import {decimalToStr, strToDecimal} from "../../../../../utils/utils";
import {getTransportContractLabels, getTransportContractOptionIds} from "../../utils";
import {fetchTransportContracts, selectAllTransportContracts} from "../../../../../store/transportContractsSlice";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import {getYearMonthString, startOfPreviousMonth} from "../../../../../utils/dateUtils";
import ListView from "../../../../../layouts/ListViewWrapper";


interface DriverExtraPayRow {
    id: string;
    driverContractId: number;
    transportContractId: number;
    driverName: string;
    parcelDeliveryFee: string;
    extraFee: string;
    disabled: boolean;
}

interface DriverExtraPayProps {
    month: Dayjs;
    handleChangeMonth: (month: Dayjs) => void;
    payslipViewType: PayslipViewType,
    handleChangePayslipViewType: (value: string) => void;
}

const ExtraPay = ({month, handleChangeMonth, payslipViewType, handleChangePayslipViewType}: DriverExtraPayProps) => {
    const dispatch = useAppDispatch();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const transportContracts = useAppSelector(selectAllTransportContracts);
    const [initialRows, setInitialRows] = useState<DriverExtraPayRow[] | undefined>(undefined);
    const transportContractLabels = useMemo(() => getTransportContractLabels(transportContracts),[transportContracts]);

    useEffect(() => {
        dispatch(fetchTransportContracts());
    }, []);

    useEffect(() => {
        loadPayslipExtras(month, selectedRegion);
    }, [month, selectedRegion]);

    const loadPayslipExtras = (month: Dayjs, selectedRegion?: Region) => {
        setInitialRows(undefined);
        if (selectedRegion) {
            getPayslipExtras(selectedRegion.id, month)
                .then(result => {
                    setInitialRows(result
                        .map(payslipExtrasRow => {
                            const driverContract = payslipExtrasRow.driverContract;

                            return {
                                id: `${driverContract.id}-${getYearMonthString(month)}`,
                                driverContractId: driverContract.id,
                                transportContractId: payslipExtrasRow.transportContractId ?? 0,
                                driverName: `${formatName(driverContract.driver, 'lastNameFirst')} (${driverContract.number})`,
                                parcelDeliveryFee: decimalToStr(payslipExtrasRow.parcelDeliveryFee),
                                extraFee: decimalToStr(payslipExtrasRow.extraFee),
                                disabled: payslipExtrasRow.payslipGenerated,
                            };
                        })
                        .sort((a,b) => a.driverName.localeCompare(b.driverName))
                    )
                })
                .catch(apiError =>
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Lisatasude pärimisel esines viga'}))
                );
        } else {
            setInitialRows([]);
        }
    };

    const handleSave = (row: DriverExtraPayRow): Promise<void> => {
        return updatePayslipExtras(row.driverContractId, month, {
            parcelDeliveryFee: strToDecimal(row.parcelDeliveryFee),
            extraFee: strToDecimal(row.extraFee),
            transportContractId: row.transportContractId
        });
    };

    const columns: GridColDef[] = [
        {
            ...defaultColDef,
            field: 'driverName',
            headerName: 'Juht ja leping',
            editable: false,
            width: 250
        },
        {
            field: 'transportContractId',
            headerName: 'Veoleping',
            width: 250,
            editable: true,
            type: 'singleSelect',
            valueOptions: getTransportContractOptionIds(transportContracts, month, selectedRegion),
            getOptionLabel: (value) => transportContractLabels.get(value as number) ?? '-',
            renderCell: (params) => transportContractLabels.get(params.value as number) ?? '-',
        },
        {
            ...numberColDef,
            field: 'parcelDeliveryFee',
            headerName: 'Pakiveo tasu',
        },
        {
            ...numberColDef,
            field: 'extraFee',
            headerName: 'Preemia',
            width: 100,
        },
    ];

    const getErrors = (row: DriverExtraPayRow) => (row.transportContractId === 0) ? 'Veoleping on kohustuslik väli' : undefined;

    return (
        <ListView
            headerProps={{
                title: 'Palgalehed',
                toggleProps: {
                    options: payslipViewTypeValues.map(value => value.toString()),
                    value: payslipViewType,
                    handleChange: handleChangePayslipViewType,
                },
                showRegionSelect: true,
            }}
            isLoading={!initialRows}
        >
            <EditableDataGrid
                initialRows={initialRows ?? []}
                columns={columns}
                monthSelect={{month: month, handleChangeMonth: handleChangeMonth, defaultMonth: startOfPreviousMonth()}}
                handleSave={handleSave}
                getErrors={getErrors}
                disabledRowText="Juhile on juba palgaleht genereeritud. Lisatasude muutmiseks kustuta palgaleht."
            />
        </ListView>
    );
};

export default ExtraPay;
