import React from 'react';
import {Box, TextField, Typography} from '@mui/material';
import theme from '../../theme';
import Button, {ButtonProps} from "../Buttons/Button";
import {Add} from '@mui/icons-material';
import {Permission} from "../../types";
import Visible from "../Visible";
import ToggleButton from "../Buttons/ToggleButton";
import PageHeaderRegionSelect from "./RegionSelect";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";


export const PAGE_HEADER_ITEM_HEIGHT = '36.5px';

interface SearchProps {
    input: string;
    setInput: (term: string) => void;
}

interface PageHeaderButtonProps {
    title: string;
    onClick: () => void;
    permission: Permission;
    startIcon?: ReactJSXElement;
}

interface ToggleProps {
    options: string[];
    value: string;
    handleChange: (value: string) => void;
}

export interface PageHeaderProps {
    title: string;
    searchProps?: SearchProps;
    buttonProps?: PageHeaderButtonProps;
    secondaryButton?: JSX.Element;
    toggleProps?: ToggleProps;
    showRegionSelect?: boolean;
    buttons?: ButtonProps[];
}

export default function PageHeader({title, searchProps, buttonProps, secondaryButton, toggleProps, showRegionSelect, buttons}: PageHeaderProps) {
    const hasSearchAndButtonProps = searchProps && buttonProps;
    const hasManyToggleOptions = toggleProps?.options && toggleProps.options.length > 3;

    return (
        <Box sx={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 0.5,
            [theme.breakpoints.down('md')]:
                hasSearchAndButtonProps || hasManyToggleOptions ? {flexDirection: 'column', alignItems: 'flex-start'} : {},
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
            displayPrint: 'none',
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                my: 0.5,
                [theme.breakpoints.down('sm')]: {width: '100%', flexDirection: 'column'},
                [theme.breakpoints.down('md')]: hasManyToggleOptions ? {width: '100%', flexDirection: 'column'} : {}
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        justifyContent: toggleProps ? 'space-between' : 'flex-start'
                    },
                    [theme.breakpoints.down('md')]: hasManyToggleOptions ? {
                        width: '100%',
                        flexDirection: 'column',
                        alignItems: toggleProps ? 'flex-start' : 'space-between'
                    } : {}
                }}>
                    <Typography component="h1" variant="h5" mr={{xs: 1, sm: 2}}>{title}</Typography>
                    {toggleProps &&
                        <ToggleButton options={toggleProps.options} value={toggleProps.value} handleChange={toggleProps.handleChange} />
                    }
                </Box>
                {secondaryButton}
            </Box>
            <Box sx={{
                display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', ml: 2, my: 0.5,
                [theme.breakpoints.down('md')]:
                    hasSearchAndButtonProps || hasManyToggleOptions ? {width: '100%', ml: 0} : {},
                [theme.breakpoints.down('sm')]: {
                    width: '100%', flexDirection: 'column-reverse', justifyContent: 'space-between', ml: 0
                },
            }}>
                {buttonProps &&
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', my: 0.5,
                        [theme.breakpoints.down('sm')]: {
                            width: '100%', justifyContent: 'space-between', alignItems: 'flex-end'
                        }
                    }}>
                        <Visible permission={buttonProps.permission}>
                            <Box sx={{ml: {xs: 0, sm: 2}, width: {xs: '100%', sm: 'fit-content'}}}>
                                <Button
                                    text={buttonProps.title}
                                    onClick={buttonProps.onClick}
                                    startIcon={buttonProps.startIcon ?? <Add />}
                                />
                            </Box>
                        </Visible>
                    </Box>
                }
                {buttons && buttons.map((buttonProps, index) =>
                    <Box key={index}
                         sx={{
                            ml: {xs: 0, sm: 1},
                            mb: {xs: 0.5, sm: 0},
                            width: {xs: '100%', sm: 'fit-content'}
                        }}
                    >
                        <Button {...buttonProps} />
                    </Box>
                )}
                <Box sx={{
                    display: 'flex', flexDirection: 'row', width: '100%', [theme.breakpoints.down('sm')]: {flexDirection: 'column'}
                }}>
                    {showRegionSelect &&
                        <PageHeaderRegionSelect styles={{
                            pr: {xs: 0, sm: searchProps ? 2 : 0},
                            [theme.breakpoints.down('sm')]: {mb: 1},
                            [theme.breakpoints.down('md')]: hasManyToggleOptions ? {width: '100%'} : {}
                        }} />
                    }
                    {searchProps &&
                        <TextField
                            value={searchProps.input}
                            label="Otsing"
                            size="small"
                            onChange={(event) => searchProps.setInput(event.target.value)}
                            InputLabelProps={{shrink: true}}
                            InputProps={{sx: {height: PAGE_HEADER_ITEM_HEIGHT}}}
                            sx={{my: 0.5, width: {xs: '100%', sm: hasSearchAndButtonProps ? '100%' : '200px', md: '200px'}}}
                        />
                    }
                </Box>
            </Box>
        </Box>
    );
}
