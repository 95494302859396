import React from 'react';
import {useField} from "formik";
import {MenuItem, SxProps, TextField as MuiTextField} from '@mui/material';


interface SelectProps<T extends { [key: string]: any }> {
    name: string;
    label: string;
    options: string[];
    translationFunction?: (value: string, enumObject: T) => string;
    translationEnumType?: T;
    disabled?: boolean;
    onChange?: (value: T) => void;
    sx?: SxProps;
}

const Select = ({name, label, options, translationFunction, translationEnumType, disabled, onChange, sx}: SelectProps<any>) => {
    const [field, meta, helpers] = useField(name);

    return (
        <MuiTextField
            value={field.value ?? ''}
            onChange={(event) => {
                helpers.setValue(event.target.value);
                if (onChange) {
                    onChange(event.target.value);
                }
            }}
            select
            size="small"
            label={label}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            SelectProps={translationFunction && translationEnumType ?
                {renderValue: option => translationFunction(option as string, translationEnumType)} : {}}
            sx={{my: 1, ...sx}}
            disabled={disabled}
        >
            {options.map(option =>
                <MenuItem key={option} value={option}>
                    {translationFunction && translationEnumType ? translationFunction(option, translationEnumType) : option}
                </MenuItem>
            )}
        </MuiTextField>
    );
};

export default Select;