import {useAuth} from "../../../../contexts/AuthContext";
import React, {useState} from "react";
import {signInWithEmailAndPassword} from "firebase/auth";
import {mapFirebaseAuthError} from "../utils";
import {Alert, Box} from "@mui/material";
import Button from "../../../../components/Buttons/Button";
import {Clear} from "@mui/icons-material";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import TextField from "../../../../components/Form/TextField";
import * as Yup from "yup";
import {validationSchema} from "../../../../utils/formValidation";


interface LoginFormData {
    email: string;
    password: string;
}

const loginValidationSchema = Yup.object().shape({
    email: validationSchema('E-mail').fields.emailRequired,
    password: validationSchema('Parool').fields.textFieldRequired,
});

const PasswordLoginForm = ({handleCancel}: { handleCancel: () => void }) => {
    const {firebaseAuth} = useAuth();
    const [error, setError] = useState<string | undefined>(undefined);

    const handleSubmit = (values: LoginFormData, helpers: FormikHelpers<LoginFormData>) => {
        setError(undefined);
        signInWithEmailAndPassword(
            firebaseAuth,
            values.email,
            values.password,
        ).catch((error) => {
            setError(mapFirebaseAuthError(error.code));
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1.5}}>
            {error && <Alert severity="error">{error}</Alert>}
            <Formik
                initialValues={{email: '', password: ''}}
                onSubmit={handleSubmit}
                validationSchema={loginValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {(formikProps: FormikProps<LoginFormData>) =>
                    <Form noValidate>
                        <TextField
                            fullWidth
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            type="email"
                            autoFocus
                        />
                        <TextField
                            fullWidth
                            name="password"
                            label="Parool"
                            type="password"
                            autoComplete="current-password"
                        />
                        <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', mt: .5}}>
                            <Button variant="outlined" color="secondary" startIcon={<Clear/>} text="Loobu" onClick={handleCancel} style={{width:'100%'}}/>
                            <Button type="submit" color="primary" variant="contained" disabled={formikProps.isSubmitting} text="Logi sisse" style={{width:'100%'}}/>
                        </Box>
                    </Form>
                }
            </Formik>
        </Box>
    );
};

export default PasswordLoginForm;