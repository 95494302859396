import React, {useMemo} from 'react';
import {useField} from "formik";
import {Autocomplete, SxProps, TextField as MuiTextField} from '@mui/material';


interface SelectProps {
    name: string;
    label: string;
    options: string[];
    newOptionValidation?: (value: string) => boolean;
    emptyInputHelperText?: string;
    sx?: SxProps;
}

const SelectFreeInput = ({name, label, options, emptyInputHelperText, newOptionValidation = () => true, sx}: SelectProps) => {
    const [field, meta, helpers] = useField(name);

    const helperText = useMemo(() =>
        meta.touched && meta.error
        ? meta.error
        : (field.value.length > 0 ? undefined : emptyInputHelperText)
    , [meta.touched, meta.error, field.value]);

    return (
        <Autocomplete
            options={field.value !== '' && !options.includes(field.value) && newOptionValidation(field.value) ? [field.value, ...options] : options}
            value={field.value}
            freeSolo
            size="small"
            onChange={(_, value) => void helpers.setValue(value ?? '')}
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    label={label}
                    onBlur={() => helpers.setTouched(true)}
                    error={meta.touched && !!meta.error}
                    helperText={helperText}
                    onChange={(event) => void helpers.setValue(event.target.value ?? '')}
                />
            )}
            sx={{my: helperText ? 0 : 1, ...sx}}
        />
    );
};

export default SelectFreeInput;
