import React, {useMemo} from "react";
import {Alert} from "@mui/material";
import {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} from "../../../../../../constants";
import {ResourceType, WorkSheetDetails} from "../../../../../../API/types";
import dayjs from "dayjs";


interface DayLengthWarningProps {
    workSheet: WorkSheetDetails;
}

const DayLengthWarning = ({workSheet}: DayLengthWarningProps) => {
    const dayLengthWarning = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) {
            return false;
        }

        const {dayStart, dayEnd} = workSheet.workItems.reduce(
            ({dayStart, dayEnd}: {dayStart: string | null, dayEnd: string | null}, item) => {
                return {
                    dayStart: dayStart && dayStart < item.startDateTime ? dayStart : item.startDateTime,
                    dayEnd: dayEnd && dayEnd > item.endDateTime ? dayEnd : item.endDateTime,
                }
            },
            {dayStart: null, dayEnd: null}
        );
        const total = dayStart && dayEnd ? dayjs(dayEnd).diff(dayStart, 'hours', true) : 0;

        return total > MAX_DRIVER_WORK_GROUP_DURATION_HOURS;
    }, [workSheet]);

    return (dayLengthWarning &&
        <Alert
            severity="warning"
            variant="outlined"
            sx={{mb: 1, minWidth: '200px', '&:not(:last-of-type)': {mr: {sm: 1}}}}
        >
            Tööpäeva pikkus ületab lubatud {MAX_DRIVER_WORK_GROUP_DURATION_HOURS} tunni piiri
        </Alert>
    );
};

export default DayLengthWarning;