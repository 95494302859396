import {FieldArrayRenderProps} from "formik";
import React, {useState} from "react";
import {Box, ListItemIcon, Menu, MenuItem} from "@mui/material";
import Divider from "@mui/material/Divider";
import {Add, ArrowDownward, ArrowUpward, Delete, MoreVert} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";


interface ArrayAnchorMenuProps<T> {
    index: number;
    maxIndex: number;
    arrayHelpers: FieldArrayRenderProps;
    createEmptyPoint: (newPointIndex?: number, arrayHelpers?: FieldArrayRenderProps) => T;
}

const ArrayAnchorMenu = <T,>({ index, maxIndex, arrayHelpers, createEmptyPoint }: ArrayAnchorMenuProps<T>) => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const handleOpenMenu = (anchor: HTMLElement) => setMenuAnchor(anchor);

    const handleCloseMenu = () => setMenuAnchor(null);

    const add = () => {
        arrayHelpers.insert(index, createEmptyPoint(index, arrayHelpers));
        handleCloseMenu();
    };

    const moveUp = () => {
        arrayHelpers.move(index, index-1);
        handleCloseMenu();
    };

    const moveDown = () => {
        arrayHelpers.move(index, index+1);
        handleCloseMenu();
    };

    const remove = () => {
        arrayHelpers.remove(index);
        handleCloseMenu();
    };

    return (
        <Box sx={{display: 'flex'}}>
            <IconButton onClick={(event) => handleOpenMenu(event.currentTarget)}>
                <MoreVert fontSize="small" />
            </IconButton>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={add}>
                    <ListItemIcon>
                        <Add fontSize="small" />
                    </ListItemIcon>
                    Lisa uus
                </MenuItem>
                <Divider/>
                {index > 0 &&
                    <MenuItem onClick={moveUp}>
                        <ListItemIcon>
                            <ArrowUpward fontSize="small" />
                        </ListItemIcon>
                        Liiguta ettepoole
                    </MenuItem>
                }
                {index < maxIndex &&
                    <MenuItem onClick={moveDown}>
                        <ListItemIcon>
                            <ArrowDownward fontSize="small" />
                        </ListItemIcon>
                        Liiguta tahapoole
                    </MenuItem>
                }
                <Divider/>
                <MenuItem onClick={remove}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Kustuta
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default ArrayAnchorMenu;
