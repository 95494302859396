import React from "react";
import TooltipRow from "./TooltipRow";
import {getDisplayDateWithoutYear} from "../../../../../../../utils/dateUtils";

const SeasonalValidity = ({seasonalStartDate, seasonalEndDate}: { seasonalStartDate?: string | null, seasonalEndDate?: string | null }) => {
    if (!seasonalStartDate || !seasonalEndDate) return null;

    return (
        <TooltipRow>
            Sesonaalsus: {getDisplayDateWithoutYear(seasonalStartDate)}-{getDisplayDateWithoutYear(seasonalEndDate)}
        </TooltipRow>
    )
};

export default SeasonalValidity;
