import {IconButton} from "@mui/material";
import {grey} from "@mui/material/colors";
import {ChevronRight} from "@mui/icons-material";
import React from "react";

export const CollapseButton = ({onClick, text, isOpen}: {
    onClick: () => void,
    text: string,
    isOpen: boolean,
}) => (
    <IconButton
        onClick={onClick}
        aria-label={text}
        size="small"
        sx={{
            height: '20px',
            width: '20px',
            backgroundColor: grey[100],
            position: 'absolute',
            top: 14,
            right: -10,
            zIndex: 10,
        }}
    ><ChevronRight sx={{rotate: isOpen ? '180deg' : undefined}}/></IconButton>
);