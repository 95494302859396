import {ApiError} from "../API/types";

export const mapErrors = (error: ApiError): string | undefined => {

    const workGroupCode = error.context?.WorkGroupCode;
    const workGroupItemCode = error.context?.WorkGroupItemCode;
    const tripDefinitionCode = error.context?.TripDefinitionCode;
    const workScheduleItemId = error.context?.WorkScheduleItemId;
    const name = error.context?.Name;
    const date = error.context?.Date;

    switch (error.errorCode) {
        case 'parallel_items_found':
            return `Töögrupis${workGroupCode ? workGroupCode + ' ' : ''} leiduvad samaaegsed tööd`;
        case 'day_of_week_rules_conflict':
            return `Töögrupi${workGroupCode ? ' ' + workGroupCode : ''} ja reisi${workGroupItemCode ? ' ' + workGroupItemCode: ''} vahel on nädalapäevade konflikt`;
        case 'group_validity_is_wider':
            return `Töögrupi${workGroupCode ? ' ' + workGroupCode : ''} kehtivus on laiem kui reisi${workGroupItemCode ? ' ' + workGroupItemCode: ''} kehtivus`;
        case 'group_schedule_settings_are_wider':
            return `Töögrupi${workGroupCode ? ' ' + workGroupCode : ''} kordumisreeglid on laiemad kui reisi${workGroupItemCode ? ' ' + workGroupItemCode: ''} kordumisreeglid${date ? ' kuupäeval ' + date : ''}`;
        case 'repetition_not_defined':
            return 'Töögrupi või reisi kordumisreeglid määramata';
        case 'cannot_associate_inactive':
            return 'Töögruppi ei saa lisada mitteaktiivset reisi';
        case 'work_schedule_item_work_group_already_assigned':
            return 'Töögrupp on sel päeval juba planeeritud';
        case 'overlapping_coverage':
            return `Muudatus tekitab reisi${workGroupItemCode ? ' ' + workGroupItemCode: ''} mitmekordse kaetuse töögrupi${workGroupCode ? ' ' + workGroupCode : ''} poolt${date ? ' kuupäeval ' + date : ''}`;
        case 'work_schedule_item_without_coverage':
            return `Muudatus tekitab katteta tööajakava rea${date ? ' kuupäeval ' + date : ''}`;
        case 'work_schedule_item_outside_validity':
            return `Muudatus tekitab kehtivusajast väljaspool oleva tööajakava rea${date ? ' kuupäeval ' + date : ''}`;
        case 'work_schedule_item_outside_validity_due_to_contract':
            return `Lepingu muudatus tekitab kehtivusajast väljaspool oleva tööajakava rea${date ? ' kuupäeval ' + date : ''}`;
        case 'work_group_not_scheduled':
            return `Töögrupp ${workGroupCode} on planeerimata`;
        case 'work_item_with_trip_already_exists':
            return `Reis ${tripDefinitionCode} on juba planeeritud sõidulehel ${workScheduleItemId}`;
        case 'unconfirmed_work_sheet_exists':
            return `Juhil ${name} on tagastamata sõidulehti kuupäeval ${date}`;
        case 'payslip_already_generated':
            return `Juhil ${name} on genereeritud palgaleht kuupäeval ${date}`;
        case 'no_driver_contract_for_date':
            return `Juhil ${name} pole kehtivat lepingut kuupäeval ${date}`;
        default:
            if (error.message) {
                return error.message;
            }
            if (error.errorCode) {
                return ErrorMessages.get(error.errorCode);
            }
    }
    // TODO Map "work_item_has_invalid_duration"

    return undefined;
};

export const ErrorMessages = new Map<string, string>([
    ['end_time_before_start_time', 'Reisi lõpp ei tohi olla enne algust'],
    ['duration_too_short', 'Reisi pikkus ei tohi olla alla 3 minuti'],
    ['code_in_use_by_another_active_trip_in_region', 'Sellise koodiga reis on juba piirkonnas olemas'],
    ['code_in_use_by_another_work_group_in_region', 'Sellise koodiga töögrupp on juba piirkonnas olemas'],
    ['overlapping_contract_found', 'Bussijuhil ei tohi olla mitut samal ajal kehtivat töölepingut'],
    ['end_date_before_start_date', 'Töölepingu lõppkuupäev ei tohi olla enne alguskuupäeva'],
    ['work_schedule_item_out_of_contract_date_range', 'Tööajakava tegevuse kuupäevad on väljaspool töölepingu kuupäevi'],
    ['overlapping_with_other_work_schedule_item', 'Tööajakava tegevus kattub teise tegevusega'],
    ['start_and_end_date_must_be_defined_together', 'Perioodi algus ja -lõpp peavad olema määratud koos'],
    ['work_sheet_is_missing_resource', 'Seotud sõidulehel puudub buss/juht'],
    ['work_item_start_time_after_end_time', 'Sõidulehe tegevuse lõpuaeg on enne algusaega'],
    ['trip_definition_already_has_work_item_on_date', 'Reis on antud kuupäeval juba planeeritud'],
    ['driver_has_no_region', 'Juhil puudub piirkond'],
    ['driver_has_no_contract_ending_on_date', 'Juhil puudub sel kuupäeval lõppev leping'],
    ['hourly_rate_missing', 'Tunnitasu määramata'],
    ['monthly_pay_missing', 'Kuutasu määramata'],
    ['accounting_region_not_matching_driver_regions', 'Juhi piirkonnad peavad sisaldama valitud arvestuspiirkonda'],
]);

export const mapErrorToDisplayMessage = (pageName: string, error?: string): string => {
    switch(error) {
        case 'Request failed with status code 403':
            return `Puuduvad õigused ${pageName} vaatamiseks selles piirkonnas`;
        default:
            return `${pageName} laadimine ebaõnnestus`;
    }
};