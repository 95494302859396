import React from "react";
import {FormControlLabel, Switch as MuiSwitch, Typography} from "@mui/material";

interface SwitchProps {
    label: string;
    onChange: () => void;
    checked?: boolean;
}

const Switch = ({label, onChange, checked}: SwitchProps) => {
    return (
        <FormControlLabel
            control={<MuiSwitch
                checked={checked}
                onChange={onChange}
            />}
            label={<Typography color={'text.secondary'}>{label}</Typography>}
        />
    );
};

export default Switch;
