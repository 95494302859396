import React from 'react';
import {InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@mui/material';
import { useField } from 'formik';
import StaticIcon from "../../Icons/StaticIcon";


interface TextFieldProps extends Omit<MuiTextFieldProps, 'name' | 'label'> {
    name: string;
    label: string;
    description?: string;
}

const TextField = ({name, label, description, ...otherProps}: TextFieldProps) => {
    const [field, meta, helpers] = useField(name);

    return (
        <MuiTextField
            value={field.value}
            label={label}
            size={otherProps.size ?? 'small'}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            InputProps={
                description ? {
                    endAdornment:
                        <InputAdornment position="end" sx={{mr: -1.5, cursor: 'default'}}>
                            <StaticIcon type="INFO" tooltipTitle={description} size="medium" disabled={otherProps.disabled} />
                        </InputAdornment>
                } : undefined
            }
            onBlur={(event) => {
                void helpers.setTouched(true);
                void helpers.setValue(event.target.value.trim());
            }}
            onChange={(event) => helpers.setValue(event.target.value)}
            sx={{my: 1}}
            {...otherProps}
        />
    );
};

export default TextField;