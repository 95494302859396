import {Box} from '@mui/material';
import React from 'react';
import Cell from "./Cell";
import {useAppSelector} from "../../../../../hooks";
import { EntityId } from '@reduxjs/toolkit';
import {selectAllItemTimesInPlannerItemGroupMemoized} from "../../../store/selectors";
import {selectPlannerTimelineMemoized} from '../../../../../store/plannerSlice';
import {selectIsPlannerRowDisabled} from "../../../../../store/workScheduleItemSlice";


interface TimelineBackgroundProps {
    groupId?: EntityId;
}

const TimelineBackground = ({ groupId = 0 }: TimelineBackgroundProps) => {
    const plannerTimeline = useAppSelector(selectPlannerTimelineMemoized);
    const allItemTimesInPlannerGroup = useAppSelector(state => selectAllItemTimesInPlannerItemGroupMemoized(state, groupId));
    const isRowDisabled = useAppSelector(state => selectIsPlannerRowDisabled(state, groupId));

    return (
        <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', boxSizing: 'border-box'}}>
            {plannerTimeline.displayedHours.map((hour, index) =>
                <Cell
                    key={index}
                    hour={hour}
                    hourIndex={index}
                    groupId={isRowDisabled ? 0 : groupId}
                    itemTimesInGroup={allItemTimesInPlannerGroup}
                />
            )}
        </Box>
    );
};

export default TimelineBackground;
