import React from "react";
import {GeoPoint, WorkGroupActivity, WorkGroupTripDefinition} from "../../../../../API/types";
import DataGrid from "../../../../../components/DataGrid";
import {Box, Typography, useMediaQuery} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {getWorkGroupItemTypeTranslation} from "../../../../../utils/enumTranslations";
import theme from "../../../../../theme";
import NavLink from "../../../../../components/NavLink";
import routes from "../../../../../routes";
import {Permission} from "../../../../../types";
import {getDisplayTimeWithModifier} from "../../../../../utils/dateUtils";

interface WorkGroupItemsProps {
    items: (WorkGroupActivity | WorkGroupTripDefinition)[] | undefined;
}

export default function WorkGroupItems({items}: WorkGroupItemsProps) {
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'));
    if (typeof items === 'undefined' || items.length === 0) {
        return null;
    }

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tegevus',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 130 : 150,
        },
        {
            field: 'code',
            headerName: 'Kood',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 90 : 100,
            renderCell: (params) => params.value
                ? <NavLink id={params.row.tripId} value={params.row.code} route={routes.AUTHENTICATED.TRIPS.EDIT} navPermission={Permission.UpdateTripDefinitions} openInNewWindow={true} />
                : <></>
        },
        {
            field: 'lineNumber',
            headerName: 'Liin',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 60 : 70,
        },
        {
            field: 'route',
            headerName: 'Teekond',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 150 : 300,
            valueFormatter: params => params.value
                ? params.value.map((item: GeoPoint) => item.name).join(' - ')
                : null,
        },
        {
            field: 'startTimeWithModifier',
            headerName: 'Algusaeg',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 90 : 100,
            valueGetter: (params) =>
                getDisplayTimeWithModifier(params.row.startTime, params.row.startTimeIsOnNextDay),
        },
        {
            field: 'endTimeWithModifier',
            headerName: 'Lõpuaeg',
            sortable: false,
            disableColumnMenu: true,
            width: isScreenSmall ? 90 : 100,
            valueGetter: (params) =>
                getDisplayTimeWithModifier(params.row.endTime, params.row.endTimeIsOnNextDay),
        },
        {
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            minWidth: 80,
        },
    ];

    return (
        <Box my={2}>
            <Typography variant="h5" my={1}>Reisid ja tegevused</Typography>
            <DataGrid
                rows={items.map((item, key) => ({
                    ...item,
                    type: getWorkGroupItemTypeTranslation(item.type),
                    id: item.id ?? key,
                }))}
                columns={columns}
            />
        </Box>
    );
}