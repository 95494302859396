import React from 'react';
import {CircularProgress, TextField} from '@mui/material';

const LoadingField = () => {
    return (
        <TextField
            disabled
            InputProps={{startAdornment: <CircularProgress size={20} />}}
            size="small"  sx={{width: '100%', my: 1}}
        />
    );
};

export default LoadingField;
