import React, {useEffect, useState} from "react";
import {ApiError, WorkGroup} from "../../../../../API/types";
import Dialog from "../../../../../components/Dialog";
import {Check, Clear} from "@mui/icons-material";
import {
    findClosestItemByValidRange,
    getDateString,
    getDisplayDate,
    getDisplayValidDatesRangeEllipsis
} from "../../../../../utils/dateUtils";
import {Formik, FormikHelpers, FormikProps} from "formik";
import DatePicker from "../../../../../components/Form/DatePicker";
import * as Yup from "yup";
import {useAppDispatch} from "../../../../../hooks";
import {setToast} from "../../../../../store/toastSlice";
import {mapErrors} from "../../../../../utils/errorMapping";
import dayjs from "dayjs";
import {divideWorkGroup, loadWorkGroupDetails} from "../../../../../API/workGroup/api";
import Loader from "../../../../../components/Loader";
import {Box, List, ListItem, Typography} from "@mui/material";


export interface NewWorkGroupVersionDialogData {
    originalWorkGroup: WorkGroup;
}

interface NewVersionFormData {
    date: Date | null;
}

interface TimedVersion {
    id: number;
    validFrom: string;
    validTo: string | null;
    isUpdated: boolean;
}

const getNewVersionValidationSchema = (workGroup: WorkGroup) => {
    let dateValidationSchema = Yup.date()
        .typeError('Vigane kuupäev')
        .required('Alguskuupäev on kohustuslik väli');

    if (workGroup.validTo) {
        dateValidationSchema = dateValidationSchema.max(
            dayjs(workGroup.validTo).subtract(1, 'day').toDate(),
            `Uue versiooni alguskuupäev peab olema enne töögrupi lõpukuupäeva ${getDisplayDate(workGroup.validTo)}`,
        );
    }

    if (workGroup.validFrom) {
        dateValidationSchema = dateValidationSchema.min(
            workGroup.validFrom,
            `Uue versiooni alguskuupäev peab olema peale töögrupi alguskuupäeva ${getDisplayDate(workGroup.validFrom)}`,
        );
    }

    return Yup.object().shape({
        date: dateValidationSchema,
    });
};

interface NewWorkGroupVersionDialogProps {
    dialogData: NewWorkGroupVersionDialogData;
    handleCloseDialog: () => void;
    handleSuccess: (workGroup: WorkGroup) => void;
}

const NewWorkGroupVersionDialog = ({dialogData, handleCloseDialog, handleSuccess}: NewWorkGroupVersionDialogProps) => {
    const initialValues: NewVersionFormData = { date: null };
    const {originalWorkGroup} = dialogData;
    const workGroupId = originalWorkGroup.id;
    const dispatch = useAppDispatch();
    const [workGroup, setWorkGroup] = useState<WorkGroup | undefined>();

    useEffect(() => {
        loadWorkGroup()
            .then(result => {
                if (result) setWorkGroup(result);
            });
    }, []);

    const loadWorkGroup = async () => {
        return loadWorkGroupDetails(workGroupId)
            .then(result => {
                return result;
            })
            .catch((error) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Töögrupi pärimisel tekkis viga'}));
            });
    };

    const handleSubmit = (values: NewVersionFormData, helpers: FormikHelpers<NewVersionFormData>) => {
        if (workGroup && values.date) {
            const dateString = getDateString(values.date);

            divideWorkGroup(workGroup.id, dateString)
                .then(() => {
                    loadWorkGroup()
                        .then(result => {
                            if (result) {
                                if (workGroup.versions?.length === result.versions?.length) {
                                    dispatch(setToast({type: 'info', text: 'Kuupäeval algav versioon on juba olemas. Uut versiooni ei lisatud.'}));
                                } else {
                                    dispatch(setToast({type: 'success', text: `Lisatud uus versioon alguskuupäevaga ${getDisplayDate(dateString)}`}));
                                    handleSuccess(result);
                                }
                            }
                        });
                })
                .catch((error: ApiError) => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? 'Töögrupi uue versiooni alustamisel tekkis viga'
                    }));
                })
                .finally(() => {
                    helpers.setSubmitting(false);
                });
        }
    };

    const getHelperText = (selectedDate: Date | null) => {
        const selectedDateDayjs = dayjs(selectedDate);
        if (!selectedDate || !selectedDateDayjs.isValid() || !workGroup?.versions) return undefined;
        const closestVersion = findClosestItemByValidRange(workGroup.versions, selectedDate);

        return (closestVersion && dayjs(closestVersion.validFrom).diff(selectedDateDayjs, 'day') === 0)
            ? "Kuupäeval algav versioon on juba olemas"
            : undefined;
    };

    const renderDates = (version: TimedVersion) => (
        <Typography variant="body2" sx={{color: version.isUpdated ? 'secondary.main' : '', fontWeight: version.isUpdated ? 'bold' : '', width: 'fit-content', whiteSpace: 'nowrap', pr: 1}}>
            {getDisplayValidDatesRangeEllipsis(version)}
        </Typography>
    );

    const Versions = ({selectedDate}: {selectedDate: Date | null}) => {
        if (!workGroup?.versions || !workGroup.versions.length) return null;
        const versions: TimedVersion[] = [...workGroup.versions]
            .map(version => ({
                id: version.id,
                validFrom: version.validFrom,
                validTo: version.validTo,
                isUpdated: false,
            }));

        const selectedDateDayjs = dayjs(selectedDate);
        const closestVersion = findClosestItemByValidRange(workGroup.versions, selectedDate);

        let updatedVersion: TimedVersion | undefined;
        const alreadyHasVersionOnDate = closestVersion && dayjs(closestVersion.validFrom).diff(selectedDateDayjs, 'day') === 0;
        const isWithinWorkGroupValidity = !selectedDateDayjs.isBefore(workGroup.validFrom) && !selectedDateDayjs.isAfter(workGroup.validTo);
        if (selectedDate && selectedDateDayjs.isValid() && !alreadyHasVersionOnDate && isWithinWorkGroupValidity) {
            versions.push({id: 0, validFrom: getDateString(selectedDateDayjs), validTo: closestVersion.validTo, isUpdated: true});
            updatedVersion = {id: closestVersion.id, validFrom: closestVersion.validFrom, validTo: getDateString(selectedDateDayjs.subtract(1, 'day')), isUpdated: true};
        }

        return (
            <Box mb={2}>
                <Typography variant="body2">Versioonid:</Typography>
                <List sx={{ listStyleType: 'disc', pl: 3, py: 0}}>
                    {versions
                        .sort((a, b) => a.validFrom.localeCompare(b.validFrom))
                        .map(version =>
                            <ListItem key={version.id} sx={{ display: 'list-item', p: 0, pt: 0.5 }}>
                                {updatedVersion?.id === version.id ?
                                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                                        <s>{renderDates(version)}</s>{renderDates(updatedVersion)}
                                    </Box>
                                    : renderDates(version)
                                }
                            </ListItem>
                        )
                    }
                </List>
            </Box>
        );
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={getNewVersionValidationSchema(workGroup ?? originalWorkGroup)}>
            {(formikProps: FormikProps<NewVersionFormData>) =>
                <Dialog
                    open={true}
                    buttons={[
                        {text: 'Loobu', onClick: handleCloseDialog, color: 'secondary', startIcon: <Clear/>, disabled: formikProps.isSubmitting},
                        {text: 'Salvesta', type: 'submit', startIcon: <Check/>, disabled: formikProps.isSubmitting},
                    ]}
                    title="Alusta uut töögrupi versiooni"
                    subtitle={`${originalWorkGroup.code} ${workGroup ? `(${getDisplayValidDatesRangeEllipsis(workGroup)})` : ''}`}
                    hasFormikForm={true}
                    onClose={handleCloseDialog}
                >
                    {workGroup ?
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Versions selectedDate={formikProps.values.date} />
                            <DatePicker
                                name="date"
                                label="Uue versiooni alguskuupäev*"
                                helperText={getHelperText(formikProps.values.date)}
                            />
                        </Box>
                        :
                        <Loader />
                    }
                </Dialog>
            }
        </Formik>
    );
};

export default NewWorkGroupVersionDialog;
