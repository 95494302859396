import React, {createContext, ReactElement, useEffect} from 'react';
import {Region} from "../API/types";
import {useAuth} from "./AuthContext";
import {doesUserHavePermission} from "../components/Visible";
import {Permission} from "../types";
import {useAppDispatch, useAppSelector} from "../hooks";
import {fetchAllRegions, selectAllRegions, selectSelectedRegion, setSelectedRegion} from "../store/regionSlice";


interface SidebarContextType {
    regions: Region[] | undefined;
    selectedRegion: Region | undefined;
    updateSelectedRegion: (newRegion: Region) => void;
}

interface Props {
    children: ReactElement;
}

export const RegionContext = createContext<SidebarContextType>({} as SidebarContextType);

export const RegionProvider = ({ children }: Props) => {
    const { authenticatedUser} = useAuth();

    const regions = useAppSelector(selectAllRegions);
    const selectedRegion = useAppSelector(selectSelectedRegion);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (authenticatedUser && doesUserHavePermission(authenticatedUser, Permission.ReadRegions)) {
            dispatch(fetchAllRegions());
        }
    }, []);

    const updateSelectedRegion = (newRegion: Region) => {
        dispatch(setSelectedRegion(newRegion));
    };

    return (
        <RegionContext.Provider value={{ regions, selectedRegion, updateSelectedRegion: updateSelectedRegion }}>
            {children}
        </RegionContext.Provider>
    );
};
