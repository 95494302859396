import React, {useEffect, useState} from 'react';
import {Box, useTheme} from "@mui/material";
import PlannerItemGroupRow from "./PlannerItemGroup/Row";
import {PLANNER_ROW_HEIGHT, PLANNER_SIDEBAR_WIDTH} from "../../../constants";
import {useAppSelector, useCustomRef} from "../../../../../hooks";
import {ContentScroll, PlannerType, ScrollOrigin} from "../../../types";
import {selectPlannerItemGroupIds, selectPlannerType} from "../../../../../store/plannerSlice";
import WorkGroupCardWrapper from "./PlannerItemGroup/Wrappers/WorkGroup/CardWrapper";
import WorkSheetCardWrapper from "./PlannerItemGroup/Wrappers/WorkSheet/CardWrapper";
import {Virtuoso} from "react-virtuoso";


interface AssignedItemsContainerProps {
    contentScrollX: ContentScroll | undefined;
    handleContentScroll: (ref: React.RefObject<HTMLDivElement>, origin: ScrollOrigin) => void;
}

const AssignedItemsContainer = ({contentScrollX, handleContentScroll}: AssignedItemsContainerProps) => {
    const theme = useTheme();
    const plannerType = useAppSelector(selectPlannerType);
    const groupIds = useAppSelector(selectPlannerItemGroupIds);
    const [workGroupRowsRef] = useCustomRef();
    const [parentRef, setParentRef] = useState<HTMLDivElement | undefined>(undefined);

    useEffect(() => {
        updateWorkGroupRowsScroll();
    }, [workGroupRowsRef.current, contentScrollX]);

    const updateWorkGroupRowsScroll = () => {
        if (workGroupRowsRef.current && contentScrollX && contentScrollX.origin !== 'assigned') {
            workGroupRowsRef.current.scrollLeft = contentScrollX.value;
        }
    };

    const handleWorkGroupRowsScroll = () => {
        if (workGroupRowsRef.current?.scrollLeft !== contentScrollX?.value) {
            handleContentScroll(workGroupRowsRef, 'assigned');
        }
    };

    return (
        <Box ref={setParentRef} sx={{
            minWidth: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarGutter: 'stable',
            minHeight: groupIds.length > 0 ? PLANNER_ROW_HEIGHT : undefined,
        }}>
            <Box sx={{display: 'flex', flexDirection: 'column', minWidth: `${PLANNER_SIDEBAR_WIDTH}px`, position: 'static'}}>
                {parentRef && <Virtuoso
                    data={groupIds}
                    customScrollParent={parentRef}
                    itemContent={(index, groupId) =>
                        plannerType === PlannerType.WORK_GROUP
                            ? <WorkGroupCardWrapper key={groupId} groupId={groupId} index={index} />
                            : <WorkSheetCardWrapper key={groupId} groupId={groupId} index={index} />
                    }
                />}
            </Box>
            <Box ref={workGroupRowsRef} onScroll={() => handleWorkGroupRowsScroll()} sx={{
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'scroll',
                minWidth: `calc(100% - ${PLANNER_SIDEBAR_WIDTH}px)`,
                height: 'fit-content',
                borderLeft: `2px solid ${theme.palette.divider}`,
                boxSizing: 'border-box',
                scrollbarWidth: 'none'
            }}>
                {parentRef && <Virtuoso
                    data={groupIds}
                    customScrollParent={parentRef}
                    itemContent={(index, groupId) =>
                        <PlannerItemGroupRow
                            groupId={groupId}
                            index={index}
                        />
                    }
                />}
            </Box>
        </Box>
    );
};

export default AssignedItemsContainer;