import React, {useEffect} from 'react';
import {Permission} from "../../../../types";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {fetchDefects, selectAllDefectsWithSearchFields, selectIsLoading} from "../../../../store/defectsSlice";
import routes from "../../../../routes";
import {useNavigate} from "react-router-dom";
import {fetchBuses} from "../../../../store/busSlice";
import DefectsTable from "./components/DefectsTable";
import ListView from "../../../../layouts/ListViewWrapper";


export default function Defects() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isLoading = useAppSelector(selectIsLoading);
    const defects = useAppSelector(selectAllDefectsWithSearchFields);

    useEffect(() => {
        dispatch(fetchDefects());
        dispatch(fetchBuses());
    }, []);

    return (
        <ListView
            headerProps={{
                title: 'Rikked',
                buttonProps: {
                    title: 'Lisa rike',
                    onClick: () => navigate(routes.AUTHENTICATED.DEFECTS.ADD),
                    permission: Permission.UpdateDefects
                }
            }}
            isLoading={isLoading}
        >
            <DefectsTable defects={defects} />
        </ListView>
    );
}
