import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {ToastProps} from "../components/Toast";

const initialState: { toast: ToastProps | null } = {
    toast: null
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState: initialState,
    reducers: {
        setToast: (state, action: PayloadAction<ToastProps | null>) => {
            state.toast = action.payload;
        },
    },
});

export const selectToast = (state: RootState) => state.toast.toast;

export const { setToast } = toastSlice.actions;

export default toastSlice.reducer;
