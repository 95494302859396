import React from 'react';
import {SidebarProvider} from "./contexts/SidebarContext";
import {ThemeProvider} from "@mui/material/styles";
import ToastProvider from "./contexts/ToastContext";
import theme from "./theme";
import Router from "./router";
import AuthProvider from "./contexts/AuthContext";
import axios from "axios";
import {Provider} from "react-redux";
import {setupStore} from "./store";
import * as Sentry from "@sentry/react";
import {getEnvironmentFromUrl} from "./utils/utils";
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";

const store = setupStore({});

function App() {
    const firebaseApiKey = import.meta.env.VITE_FIREBASE_API_KEY;
    const firebaseAuthDomain = import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
    const firebaseProjectId = import.meta.env.VITE_FIREBASE_PROJECT_ID;
    const firebaseAppId = import.meta.env.VITE_FIREBASE_APP_ID;
    const firebaseConfig = {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        appId: firebaseAppId,
    };
    initializeApp(firebaseConfig);
    const auth = getAuth();
    auth.languageCode = 'et';

    axios.defaults.baseURL =  import.meta.env.VITE_BACKEND_URL ?? 'https://test.api.viiger.gobus.ee';

    axios.interceptors.request.use(async config => {
        const jwtToken = await auth.currentUser?.getIdToken();
        if (jwtToken) {
            config.headers.Authorization = "Bearer " + jwtToken;
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    });

    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN ?? '',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserApiErrorsIntegration(),
        ],
        environment: getEnvironmentFromUrl(),
        // Performance Monitoring
        tracesSampleRate: 0.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <ToastProvider>
                    <AuthProvider firebaseAuth={auth}>
                        <SidebarProvider>
                            <Router />
                        </SidebarProvider>
                    </AuthProvider>
                </ToastProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default App;