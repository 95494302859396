import {OverUnderTimeReport} from "../../../../API/reports/types";
import {Dayjs} from "dayjs";
import {Cell, Columns, Row, SheetData} from "write-excel-file";
import {BORDER_COLOR} from "../constants";
import {Contract} from "../../../../API/driver/types";


const groupByProfitCenter = (data: OverUnderTimeReport[]): Map<string, OverUnderTimeReport[]> => [...data]
    .sort((a, b) => a.profitCenter > b.profitCenter ? 1 : -1)
    .reduce((acc, report) => {
        if (!acc.has(report.profitCenter)) {
            acc.set(report.profitCenter, []);
        }
        acc.get(report.profitCenter)!.push(report);
        return acc;
    }, new Map<string, OverUnderTimeReport[]>());

const getHeaderCell = (value: string): Cell => ({value: value, fontWeight: 'bold', bottomBorderColor: BORDER_COLOR, wrap: true});
const getNumberCell = (value: number | undefined): Cell => ({value: value ?? 0, type: Number, format: '0.00'});

export const formatOverUnderTimeReportData = (data: OverUnderTimeReport[], startDate: Dayjs, endDate: Dayjs): [SheetData, Columns] => {
    const titleRow: Row = [{value: `Periood ${startDate.format('DD.MM.YYYY')} - ${endDate.format('DD.MM.YYYY')}`, span: 3}];

    const columnHeaders: Row = [
        {value: 'Tulemusüksus', fontWeight: 'bold', bottomBorderColor: BORDER_COLOR},
        getHeaderCell('Töötundide arv kokku'),
        getHeaderCell('sh. töölepinguga'),
        getHeaderCell('sh. käsundus\nlepinguga'),
        getHeaderCell('sh. tööjõu rendilepinguga'),
        getHeaderCell('Palgasumma (tööleping)'),
        getHeaderCell('Palgasumma (käsundus)'),
        getHeaderCell('Palgasumma (tööjõurent)'),
        getHeaderCell('Ületundide arv (tööleping)'),
        getHeaderCell('Ületundide lisa\ntasu (tööleping)'),
        getHeaderCell('Ületundide protsent'),
        getHeaderCell('Alatundide arv (tööleping)'),
        getHeaderCell('Alatundide tasu (tööleping)'),
        getHeaderCell('Alatundide protsent'),
    ];

    const grouped = groupByProfitCenter(data);
    const dataRows: Row[] = [];

    grouped.forEach(((overUnderTimeReportLines, profitCenter) => {
        const totalActualWorkHours = overUnderTimeReportLines.reduce((sum, report) => sum + report.actualWorkHours, 0);
        const employmentContractReport = overUnderTimeReportLines.find(report => report.driverContractType === Contract.EMPLOYMENT_CONTRACT)
        const serviceContractReport = overUnderTimeReportLines.find(report => report.driverContractType === Contract.SERVICE_CONTRACT)
        const laborLeasingAgreementReport = overUnderTimeReportLines.find(report => report.driverContractType === Contract.LABOR_LEASING_AGREEMENT)

        dataRows.push([
            {value: profitCenter, type: String},
            getNumberCell(totalActualWorkHours),
            getNumberCell(employmentContractReport?.actualWorkHours),
            getNumberCell(serviceContractReport?.actualWorkHours),
            getNumberCell(laborLeasingAgreementReport?.actualWorkHours),
            getNumberCell(employmentContractReport?.totalPay),
            getNumberCell(serviceContractReport?.totalPay),
            getNumberCell(laborLeasingAgreementReport?.totalPay),
            getNumberCell(employmentContractReport?.overTimeHours),
            getNumberCell(employmentContractReport?.overTimePay),
            {value: totalActualWorkHours ? (employmentContractReport?.overTimeHours ?? 0) / totalActualWorkHours : 0, format: '0.00%'},
            getNumberCell(employmentContractReport?.underTimeHours),
            getNumberCell(employmentContractReport?.underTimePay),
            {value: totalActualWorkHours ? (employmentContractReport?.underTimeHours ?? 0) / totalActualWorkHours : 0, format: '0.00%'},
        ]);
    }));

    return [[titleRow, columnHeaders, ...dataRows], [
        {width: 14}, {width: 10},
        {width: 12}, {width: 12}, {width: 14},
        {width: 12}, {width: 12}, {width: 12},
        {width: 14}, {width: 15}, {width: 10},
        {width: 14}, {width: 14}, {width: 10}
    ]];
};
