import {BusDetails} from "../bus/types";
import {WorkGroup} from "../workGroup/types";
import {WorkSheetWorkItem} from "../workSheets/types";
import {DriverWorkSheet} from "../workSchedule/types";

export enum Contract {
    SERVICE_CONTRACT = 'SERVICE_CONTRACT',
    LABOR_LEASING_AGREEMENT = 'LABOR_LEASING_AGREEMENT',
    EMPLOYMENT_CONTRACT = 'EMPLOYMENT_CONTRACT',
}

export interface Driver {
    id: number;
    firstName: string;
    lastName: string;
    personalId: string;
    phonePrefix: string;
    phone: string;
    email: string | null;
    active: boolean;
    contracts: DriverContract[];
    regionIds: number[];
    accountingRegionId: number;
    externalId: string | null;
}

export interface UpdateDriverRequest {
    firstName: string;
    lastName: string;
    personalId: string;
    phonePrefix: string;
    phone: string;
    email: string | null;
    active: boolean;
    regionIds: number[];
    accountingRegionId: number;
    externalId: string | null;
}

export interface DriverContract {
    id: number | null;
    type: Contract;
    number: string;
    startDate: string;
    endDate: string | null;
    nominalWeeklyWorkingHours: number;
    hourlyRate: number | null;
    monthlyPay: number | null;
}

export interface UpdateDriverContractRequest {
    type: Contract;
    number: string;
    startDate: string ;
    endDate: string | null;
    nominalWeeklyWorkingHours: number;
    hourlyRate: number | null;
    monthlyPay: number | null;
}

export interface CurrentDriverWorkSheetsResponse {
    buses: BusDetails[];
    workGroups: WorkGroup[];
    workItems: WorkSheetWorkItem[];
    workSheets: DriverWorkSheet[];
}
