import {createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState} from "@reduxjs/toolkit";
import {Defect as ApiDefect, DefectStatus, Region} from "../API/types";
import {getDefect, loadDefects} from "../API";
import {AppDispatch, RootState} from "../store";
import {Comment, CommentFormData} from "../components/Comments/types";
import {setToast} from "./toastSlice";
import {FormikHelpers} from "formik";
import {
    createDefect as apiCreateDefect,
    createDefectComment as apiCreateDefectComment,
    deleteDefectComment as apiDeleteDefectComment,
    updateDefect as apiUpdateDefect,
    updateDefectComment as apiUpdateDefectComment,
} from "../API/defects/api";
import {SaveDefectComment, SaveDefectRequest} from "../API/defects/types";
import {getDateTimeString, getDisplayDateTime} from "../utils/dateUtils";
import {DefectFormData} from "../scenes/authenticated/defects/detailsView/types";
import {selectAllRegions} from "./regionSlice";
import {getDefectPriorityTranslationFromStr, getDefectStatusTranslationFromStr} from "../utils/enumTranslations";
import {getDefectsByBusId} from "../API/bus/api";


interface DefectState {
    isLoading: boolean;
    defects: EntityState<Defect>;
    defectComments: EntityState<Comment>;
}

export interface Defect extends Omit<ApiDefect, 'comments'> {
    commentIds: number[];
}

export interface DefectWithSearchFields extends Defect {
    busLicencePlateNr: string;
    priorityDisplayStr: string;
    statusDisplayStr: string;
    createdAtDisplayStr: string;
    lastEditedAtDisplayStr: string;
    repairDateDisplayStr: string;
    otherSearchFields: string;
}

export const defectAdapter = createEntityAdapter<Defect>({
    selectId: (a) => a.id,
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

export const defectCommentAdapter = createEntityAdapter<Comment>({
    sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

export const fetchDefects = createAsyncThunk<
    { defects: Defect[], defectComments: Comment[]},
    void,
    { dispatch: AppDispatch }
>(
    'defects/getAll',
    async (_, { dispatch }) => {
        return await loadDefects()
            .then(result => {
                const defectComments: Comment[] = [];
                const defects = result.map(apiDefect => {
                    defectComments.push(...apiDefect.comments);

                    return {
                        ...apiDefect,
                        commentIds: apiDefect.comments.map(comment => comment.id)
                    };
                });

                return {defects, defectComments};
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikete laadimisel ilmnes viga'}))
                throw error;
            });
    }
);

export const fetchDefectById = createAsyncThunk<
    { defect: Defect, defectComments: Comment[]},
    { id: string | number, handleDefectNotFound?: () => void },
    { dispatch: AppDispatch }
>(
    'defects/fetchDefectById',
    async ({id, handleDefectNotFound}, { dispatch }) => {
        return await getDefect(id)
            .then(result => {
                return {
                    defect: {...result, commentIds: result.comments.map(comment => comment.id)},
                    defectComments: result.comments
                };
            })
            .catch(error => {
                if (handleDefectNotFound) handleDefectNotFound();
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikke laadimisel ilmnes viga'}))
                throw error;
            });
    }
);

export const loadDefectsByBusId = createAsyncThunk<
    { defects: Defect[], defectComments: Comment[]},
    { busId: number },
    { dispatch: AppDispatch }
>(
    'defects/loadDefectsByBusId',
    async ({busId}, { dispatch }) => {
        return await getDefectsByBusId(busId)
            .then(result => {
                const defectComments: Comment[] = [];
                const defects = result.map(apiDefect => {
                    defectComments.push(...apiDefect.comments);

                    return {
                        ...apiDefect,
                        commentIds: apiDefect.comments.map(comment => comment.id)
                    };
                });

                return {defects, defectComments};
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikete laadimisel ilmnes viga'}))
                throw error;
            });
    }
);

export const createDefect = createAsyncThunk<
    { defect: Defect },
    { form: DefectFormData, formHelpers: FormikHelpers<DefectFormData>, handleSuccess?: (id: number) => void },
    { dispatch: AppDispatch }
>(
    'defects/createDefect',
    async ({form, formHelpers, handleSuccess }, { dispatch }) => {
        if (!form.bus) throw Error;

        const request: SaveDefectRequest = {
            ...form,
            busId: form.bus.id,
            expectedRepairDateTime: form.expectedRepairDateTime ? getDateTimeString(form.expectedRepairDateTime) : undefined,
            expectedRepairFinishedDateTime: form.expectedRepairFinishedDateTime ? getDateTimeString(form.expectedRepairFinishedDateTime) : undefined,
        };

        return await apiCreateDefect(request)
            .then(result => {
                if (handleSuccess) handleSuccess(result.id);
                return {defect: {...result, commentIds: result.comments.map(comment => comment.id)}};
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikke salvestamisel ilmnes viga'}));
                throw error;
            })
            .finally(() => formHelpers.setSubmitting(false));
    }
);

export const updateDefect = createAsyncThunk<
    void,
    { form: DefectFormData, formHelpers: FormikHelpers<DefectFormData>, id: string | number, handleSuccess?: () => void },
    { state: RootState, dispatch: AppDispatch }
>(
    'defects/updateDefect',
    async ({form, formHelpers, id}, { getState, dispatch }) => {
        const state = getState();
        const defect = selectDefectById(state, id);
        if (!defect || !form.bus) throw Error;

        const request: SaveDefectRequest = {
            ...form,
            busId: form.bus.id,
            expectedRepairDateTime: form.expectedRepairDateTime ? getDateTimeString(form.expectedRepairDateTime) : undefined,
            expectedRepairFinishedDateTime: form.expectedRepairFinishedDateTime ? getDateTimeString(form.expectedRepairFinishedDateTime) : undefined,
            comment: form.comment !== '' ? form.comment : undefined,
        };

        return await saveDefect(id, request, dispatch, formHelpers);
    }
);

export const updateDefectStatus = createAsyncThunk<
    void,
    { id: string | number, status: DefectStatus },
    { state: RootState, dispatch: AppDispatch }
>(
    'defects/updateDefect',
    async ({id, status}, { getState, dispatch }) => {
        const state = getState();
        const defect = selectDefectById(state, id);
        if (!defect) throw Error;

        const request: SaveDefectRequest = {...defect, status: status, busId: defect.bus.id};

        return await saveDefect(id, request, dispatch);
    }
);

const saveDefect = async (id: string | number, request: SaveDefectRequest, dispatch: AppDispatch, formHelpers?: FormikHelpers<DefectFormData>) => {
    return apiUpdateDefect(id, request)
        .then(() => {
            dispatch(setToast({type: 'success', text: 'Rikke andmed edukalt uuendatud'}));
            dispatch(fetchDefectById({id}));
        })
        .catch(error => {
            dispatch(setToast({type: 'error', text: error.message ?? 'Rikke salvestamisel ilmnes viga'}));
            throw error;
        })
        .finally(() => {
            if (formHelpers) formHelpers.setSubmitting(false);
        });
};

export const createDefectComment = createAsyncThunk<
    { defectComment: Comment, defect: Defect },
    { form: CommentFormData, formHelpers: FormikHelpers<CommentFormData>, defectId: number },
    { state: RootState, dispatch: AppDispatch }
>(
    'defects/createDefectComment',
    async ({form, formHelpers, defectId }, { getState, dispatch }) => {
        const state = getState();
        const defect = selectDefectById(state, defectId);
        if (!defect) throw Error;

        return await apiCreateDefectComment({defectId: defectId, text: form.comment})
            .then(result => {
                return {defectComment: result, defect: defect};
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikke kommentaari loomisel esines tõrge'}));
                throw error;
            })
            .finally(() => formHelpers.setSubmitting(false));
    }
);

export const updateDefectComment = createAsyncThunk<
    { defect: Defect, defectComment: Comment},
    { form: CommentFormData, formHelpers: FormikHelpers<CommentFormData>, defectId: number, id: number },
    { state: RootState, dispatch: AppDispatch }
>(
    'defects/updateDefectComment',
    async ({form, formHelpers, defectId, id}, { getState, dispatch }) => {
        const state = getState();
        const defect = selectDefectById(state, defectId);
        const defectComment = selectDefectCommentById(state, id);
        if (!defect || !defectComment) throw Error;

        const request: SaveDefectComment = {
            defectId: defectId,
            text: form.comment
        };

        return await apiUpdateDefectComment(id, request)
            .then(() => {
                return {
                    defect: defect,
                    defectComment: {
                        ...defectComment,
                        text: form.comment,
                        lastEditedAt: getDateTimeString(new Date())
                    }
                };
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikke kommentaari uuendamisel esines tõrge'}));
                throw error;
            })
            .finally(() => formHelpers.setSubmitting(false));
    }
);

export const deleteDefectComment = createAsyncThunk<
    { id: number, defect: Defect },
    { id: number, defectId: number },
    { state: RootState, dispatch: AppDispatch }
>(
    'defects/deleteDefectComment',
    async ({ id, defectId }, { getState, dispatch }) => {
        const state = getState();
        const defect = selectDefectById(state, defectId);
        const existingDefectComment = selectDefectCommentById(state, id);
        if (!defect || !existingDefectComment) throw Error;

        return await apiDeleteDefectComment(id)
            .then(() => {
                return {id, defect};
            })
            .catch(error => {
                dispatch(setToast({type: 'error', text: error.message ?? 'Rikke kommentaari kustutamisel esines tõrge'}));
                throw error;
            });
    }
);

const initialState: DefectState = {
    isLoading: true,
    defects: defectAdapter.getInitialState(),
    defectComments: defectCommentAdapter.getInitialState(),
};

export const defectSlice = createSlice({
    name: 'defects',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDefects.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDefects.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(fetchDefects.fulfilled, (state, action) => {
            defectAdapter.upsertMany(state.defects, action.payload.defects);
            defectCommentAdapter.upsertMany(state.defectComments, action.payload.defectComments);
            state.isLoading = false;
        });
        builder.addCase(loadDefectsByBusId.fulfilled, (state, action) => {
            defectAdapter.upsertMany(state.defects, action.payload.defects);
            defectCommentAdapter.upsertMany(state.defectComments, action.payload.defectComments);
        });
        builder.addCase(fetchDefectById.fulfilled, (state, action) => {
            defectAdapter.upsertOne(state.defects, action.payload.defect);
            defectCommentAdapter.upsertMany(state.defectComments, action.payload.defectComments);
            state.isLoading = false;
        });
        builder.addCase(createDefect.fulfilled, (state, action) => {
            defectAdapter.addOne(state.defects, action.payload.defect);
        });
        builder.addCase(updateDefect.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createDefectComment.fulfilled, (state, action) => {
            defectAdapter.updateOne(state.defects, {
                id: action.payload.defect.id,
                changes: {
                    commentIds: [...action.payload.defect.commentIds, action.payload.defectComment.id]
                }
            });
            defectCommentAdapter.upsertOne(state.defectComments, action.payload.defectComment);
        });
        builder.addCase(updateDefectComment.fulfilled, (state, action) => {
            defectAdapter.updateOne(state.defects, {
                id: action.payload.defect.id,
                changes: {
                    commentIds: [
                        ...[...action.payload.defect.commentIds].filter(commentId =>  commentId !== action.payload.defectComment.id),
                        action.payload.defectComment.id
                    ]
                }
            });
            defectCommentAdapter.updateOne(state.defectComments, {
                id: action.payload.defectComment.id,
                changes: {
                    text: action.payload.defectComment.text,
                    lastEditedAt: action.payload.defectComment.lastEditedAt
                }
            });
        });
        builder.addCase(deleteDefectComment.fulfilled, (state, action) => {
            defectAdapter.updateOne(state.defects, {
                id: action.payload.defect.id,
                changes: {
                    commentIds: [...action.payload.defect.commentIds].filter(commentId =>  commentId !== action.payload.id)
                }
            });
            defectCommentAdapter.removeOne(state.defectComments, action.payload.id);
        });
    }
});

export const {
    selectAll: selectAllDefects,
    selectById: selectDefectById,
} = defectAdapter.getSelectors<RootState>((state) => state.defects.defects);

export const {
    selectAll: selectAllDefectComments,
    selectById: selectDefectCommentById,
} = defectCommentAdapter.getSelectors<RootState>((state) => state.defects.defectComments);

export const selectAllDefectsWithSearchFields = createSelector(
    selectAllRegions,
    selectAllDefects,
    (regions, defects): DefectWithSearchFields[] => {
        return defects.map(defect => getDefectWithSearchFields(defect, regions));
    }
);

export const selectDefectsWithSearchFieldsByBusId = createSelector(
    (_: RootState, busId: number) => busId,
    selectAllRegions,
    selectAllDefects,
    (busId, regions, defects): DefectWithSearchFields[] => {
        return defects.filter(defect => defect.bus.id === busId).map(defect => getDefectWithSearchFields(defect, regions));
    }
);

export const selectCommentsByDefectId = (defectId: number) => createSelector(
    (state: RootState) => selectDefectById(state, defectId),
    selectAllDefectComments,
    (defect, defectComments): Comment[] => {
        if (!defect) return [];
        return defectComments.filter(comment => defect?.commentIds.includes(comment.id));
    }
);

const getDefectWithSearchFields = (defect: Defect, regions: Region[]): DefectWithSearchFields => {
    const busRegionIds = [...defect.bus.regionIds];
    if (!defect.bus.regionIds.includes(defect.bus.accountingRegionId)) {
        busRegionIds.push(defect.bus.accountingRegionId);
    }
    const busRegionNames = busRegionIds
        .map(regionId => regions.find(region => region.id === regionId)?.name)
        .filter(region => !!region);

    const repairDate = defect.repairedAt ?? defect.expectedRepairDateTime;

    return {
        ...defect,
        busLicencePlateNr: defect.bus.licencePlateNumber,
        priorityDisplayStr: getDefectPriorityTranslationFromStr(defect.priority),
        statusDisplayStr: getDefectStatusTranslationFromStr(defect.status),
        createdAtDisplayStr: defect.createdAt ? getDisplayDateTime(defect.createdAt) : '',
        lastEditedAtDisplayStr: defect.lastEditedAt ? getDisplayDateTime(defect.lastEditedAt) : '',
        repairDateDisplayStr: repairDate ? getDisplayDateTime(repairDate) : '',
        otherSearchFields: busRegionNames.join(', ')
    }
}

export const selectIsLoading = (state: RootState) => state.defects.isLoading;

export default defectSlice.reducer;
