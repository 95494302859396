import React from "react";
import {FieldArrayRenderProps} from "formik";
import {Box} from "@mui/material";
import SelectGeoPoint from "../../SelectGeoPoint";
import Divider from "@mui/material/Divider";
import Checkbox from "../../Checkbox";
import {Hail} from "@mui/icons-material";
import TimePickerWithModifier from "../../TimePickerWithModifier";
import TextField from "../../TextField";
import { createEmptyPoint } from "../utils";
import ArrayAnchorMenu from "../../ArrayAnchorMenu";
import {RoutePointForm} from "../../../../scenes/authenticated/trips/detailsView/types";

interface RoutePointProps {
    index: number;
    maxIndex: number;
    name: string;
    arrayHelpers: FieldArrayRenderProps;
}

const RoutePoint = ({index, maxIndex, name, arrayHelpers}: RoutePointProps) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            mb: 2
        }}>
            <SelectGeoPoint
                name={`${name}[${index}].geoPoint`}
                label="Punkti nimi"
                sx={{'&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {right: 95}}}
            />
            <Box sx={{display: 'flex', position: 'absolute', right: 0, top: 8}}>
                <Divider orientation="vertical" variant="middle" flexItem sx={{mx: 0.5}}/>
                <Checkbox
                    name={`${name}[${index}].requestStop`}
                    labelIcon={<Hail/>}
                    label="Nõudepeatus"
                    sx={{mr: -1}}
                />
                <Box sx={{mt: 0.2, ml: -2.5}}>
                    <ArrayAnchorMenu<RoutePointForm>
                        index={index}
                        maxIndex={maxIndex}
                        arrayHelpers={arrayHelpers}
                        createEmptyPoint={createEmptyPoint}
                    />
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 2
            }}>
                <Box sx={{flex: 1}}>
                    <TimePickerWithModifier
                        timeName={`${name}[${index}].time`}
                        modifierName={`${name}[${index}].timeIsOnNextDay`}
                        label="Aeg"
                    />
                </Box>
                <Box sx={{flex: 1}}>
                    <TextField name={`${name}[${index}].stopName`}
                               label="Peatus/platvorm"
                               fullWidth sx={{minWidth: 160}} />
                </Box>
            </Box>
        </Box>
    );
};

export default RoutePoint;