import React, {ReactElement} from "react";
import {useAppSelector} from "../../../../../hooks";
import {Tooltip} from "@mui/material";


const EditModeTooltipWrapper = ({children}: {children: ReactElement}) => {
    const isEditModeActive = useAppSelector(state => state.workSchedule.view.editModeData.isActive);
    const selectedEditModeWorkGroup = useAppSelector(state => state.workSchedule.view.editModeData.selectedWorkGroup);
    return (
        <Tooltip title={isEditModeActive && selectedEditModeWorkGroup ? selectedEditModeWorkGroup.code : undefined} followCursor>
            {children}
        </Tooltip>
    );
};

export default EditModeTooltipWrapper;