import axios from "axios";
import {CharterTrip, SaveCharterTrip} from "./types";
import {handleError} from "../utils";


export const loadCharterTrips = () =>
    axios.get<CharterTrip[]>(`/api/charter-trips`)
        .then(result => result.data)
        .catch(handleError);

export const loadCharterTrip = (id: number | string) =>
    axios.get<CharterTrip>(`/api/charter-trips/${id}`)
        .then(result => result.data)
        .catch(handleError);

export const createCharterTrip = (request: SaveCharterTrip) =>
    axios.post<CharterTrip>('/api/charter-trips', request)
        .then(result => result.data)
        .catch(handleError);

export const updateCharterTrip = (id: number | string, request: SaveCharterTrip) =>
    axios.put<CharterTrip>(`/api/charter-trips/${id}`, request)
        .then(result => result.data)
        .catch(handleError);

export const deleteCharterTrip = (id: number | string) =>
    axios.delete(`/api/charter-trips/${id}`)
        .then(result => result.data)
        .catch(handleError);
