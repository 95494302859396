import React, {useMemo} from "react";
import Dialog from "@mui/material/Dialog";
import {Box} from "@mui/material";
import {FormikHelpers} from "formik";
import {WorkSheetDetails} from "../../../../../../API/workSheets/types";
import {useAppSelector} from "../../../../../../hooks";
import {selectAllBuses} from "../../../../../../store/busSlice";
import SelectWithId from "../../../../../../components/Form/SelectWithId";
import {getDisplayDateTimeRange} from "../../../../../../utils/dateUtils";
import {BusUsageWithDistance} from "../../../../dashboard/DriverDashboard/types";
import {isReadingWithinBusUsage, validateReadingWithPrevious} from "../../../../../../components/FormTemplates/ReadingForm/utils";
import AddReadingForm, {AddReadingFormData} from "../../../../../../components/FormTemplates/ReadingForm/AddReadingForm";

export interface AddReadingDialogData {
    workSheet: WorkSheetDetails;
    busUsages: BusUsageWithDistance[];
    driverId: number;
}

interface AddReadingDialogProps {
    dialogData: AddReadingDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => void;
}

const AddReadingDialog = ({dialogData, handleCloseDialog, handleSave}: AddReadingDialogProps) => {
    const buses = useAppSelector(selectAllBuses);

    const handleSubmit = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => {
        const readingType = formData.fuelType ? 'Tankimine' : 'Odomeetri näit';
        const busUsages = dialogData.busUsages.filter(busUsage => busUsage.busId === formData.busId);
        if (!busUsages.some(busUsage => isReadingWithinBusUsage(formData.dateTime, busUsage))) {
            const dateTimeRange = busUsages.map(busUsage => getDisplayDateTimeRange(busUsage)).join(', ');
            if (!confirm(`${readingType} on väljaspool bussiga seotud tööde toimumisaega ${dateTimeRange}. Kas soovid selle siiski salvestada?`)) {
                helpers.setSubmitting(false);
                return;
            }
        }

        const readingsOfTheSelectedBus = dialogData.workSheet.odometerReadings.filter(reading => reading.busId === formData.busId);
        if (!validateReadingWithPrevious(readingsOfTheSelectedBus, formData)) {
            helpers.setSubmitting(false);
            return;
        }

        handleSave(formData, helpers);
    };

    const firstBusUsageStartDateTime: string = dialogData.busUsages
        .reduce((min: BusUsageWithDistance, busUsage: BusUsageWithDistance) =>
            min.startDateTime.localeCompare(busUsage.startDateTime) > 0 ? busUsage : min
        )?.startDateTime ?? dialogData.workSheet.startDate;

    const usedBuses = useMemo(() => {
        const usedBusIds = dialogData.busUsages.map(busUsage => busUsage.busId);
        return buses.filter(bus => usedBusIds.indexOf(bus.id) > -1);
    }, [dialogData.busUsages, buses]);

    return (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
            <Box p={{xs: 2, sm: 3}}>
                {dialogData &&
                    <AddReadingForm
                        bus={usedBuses.length > 0 ? usedBuses[0] : undefined}
                        handleCloseDialog={handleCloseDialog}
                        handleSubmit={handleSubmit}
                        suggestedDateTime={firstBusUsageStartDateTime}
                        driverId={dialogData.driverId}
                    >
                        <SelectWithId
                            name="busId"
                            label="Buss"
                            options={usedBuses.map(bus => ({id: bus.id, name: bus.licencePlateNumber}))}
                        />
					</AddReadingForm>
                }
            </Box>
        </Dialog>
    );
};

export default AddReadingDialog;
