import React, {useState} from "react";
import ActionIcon from "../../../../../components/Icons/ActionIcon";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {setToast} from "../../../../../store/toastSlice";
import {selectCharterTripById, setCharterTripAsInvoiced} from "../../../../../store/charterTripsSlice";
import dayjs from "dayjs";
import {decimalToDisplayStr} from "../../../../../utils/utils";


const CopyInvoiceButton = ({charterTripId}: {charterTripId: string | number}) => {
    const dispatch = useAppDispatch();
    const charterTrip = useAppSelector(state => selectCharterTripById(state, charterTripId));
    const [copySuccess, setCopySuccess] = useState(false);
    if (!charterTrip) return null;

    const handleCopy = () => {
        const text = [
            charterTrip.orderNumber + '/' +  charterTrip.tripNumber,
            dayjs(charterTrip.date).format('DD.MM.YYYY'),
            charterTrip.route,
            decimalToDisplayStr(charterTrip.price)
        ].join(' ');

        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess(true);
                if (!charterTrip?.isInvoiced) dispatch(setCharterTripAsInvoiced({id: charterTripId}));
                setTimeout(() => setCopySuccess(false), 3000);
            })
            .catch(() => {
                dispatch(setToast({ type: 'error', text: 'Arve info kopeerimisel lõikelauale esines tõrge' }));
            });
    };

    return (
        <ActionIcon
            type="COPY_TO_CLIPBOARD"
            id={Number(charterTripId)}
            tooltipTitle={copySuccess ? 'Kopeeritud!' : 'Kopeeri arve info lõikelauale'}
            handleClick={handleCopy}
        />
    );
};

export default CopyInvoiceButton;
