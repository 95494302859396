import {createSlice} from "@reduxjs/toolkit";
import {fetchWorkSheets} from "./actions";
import {mapErrorToDisplayMessage} from "../../../../utils/errorMapping";


interface WorkSheetsState {
    loading: boolean;
    error: string | undefined;
}

const initialState: WorkSheetsState = {
    loading: true,
    error: undefined,
};

export const workSheetsSlice = createSlice({
    name: 'workSheets',
    initialState: initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchWorkSheets.pending, (state) => {
            state.loading = true;
            state.error = undefined;
        });
        builder.addCase(fetchWorkSheets.rejected, (state, action) => {
            state.loading = false;
            state.error = mapErrorToDisplayMessage('päevaplaani', action.error.message)
        });
        builder.addCase(fetchWorkSheets.fulfilled, (state) => {
            state.loading = false;
            state.error = undefined;
        })
    }
});

export const {
    setLoading,
} = workSheetsSlice.actions;

export default workSheetsSlice.reducer;
