export interface Bus {
    id: number;
    licencePlateNumber: string;
    make: string;
    model: string;
    type: string;
    seatingCapacity: number;
    standingCapacity: number;
    registrationDate: string;
    regionIds: number[];
    accountingRegionId: number;
    fuelTypes: Fuel[];
    active: boolean;
}

export interface BusDetails extends Bus {
    fuelCards: FuelCard[];
    odometerReadings: OdometerReading[];
}

export interface UpdateBusRequest {
    licencePlateNumber: string;
    make: string;
    model: string;
    type: string;
    seatingCapacity: number;
    standingCapacity: number;
    registrationDate: string;
    regionIds: number[];
    accountingRegionId: number;
    fuelTypes: Fuel[];
    active: boolean;
    fuelCards: FuelCard[];
}

export interface FuelCard {
    name: string;
    pin: string;
}

export interface OdometerReading {
    id: number;
    driverId: number;
    dateTime: string;
    reading: number;
    fuelType?: Fuel;
    fuelAmount?: number;
}

export interface OdometerReadingWithBusId extends OdometerReading {
    busId: number;
}

export interface OdometerReadingRequest {
    driverId?: number;
    dateTime: string;
    reading: number;
    fuelType?: Fuel;
    fuelAmount?: number;
}

export enum Fuel {
    D = 'D',
    B = 'B',
    XTL = 'XTL',
    CNG = 'CNG',
    CBG = 'CBG',
}
