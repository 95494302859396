import {createSelector, EntityId} from "@reduxjs/toolkit";
import {RootState} from "../../../store";
import {WorkGroupActivity, WorkGroupTripDefinition} from "../../../API/workGroup/types";
import {
    selectActivityById,
    selectPlannerItemGroupById,
    selectPlannerTimelineMemoized,
    selectTripDefinitionById,
    selectWorkGroupById,
} from "../../../store/plannerSlice";
import {getDisplayWorkGroupItemDetails} from "../utils/displayItemUtils";
import {getSortedWorkGroupItemTimes, getSortedWorkGroupItemTimesWithIdAndType} from "../utils/utils";
import {
    DisplayCharterTripWorkItem,
    DisplayTripDefinitionWorkItem,
    DisplayWorkGroup,
    DisplayWorkGroupActivity,
    DisplayWorkGroupTripDefinition,
    DisplayWorkItem,
    PlannerItemStartAndEndHourWithType,
    PlannerItemStartAndEndHourWithTypeAndId,
    WorkItem
} from "../types";
import {selectResourceNameByType} from "../../../scenes/authenticated/workSheets/store/selectors";
import {
    selectAllWorkScheduleItems,
    selectCharterTripWorkItemById,
    selectPlannerTripWorkItemById,
    selectPlannerWorkItemById,
} from "../../../store/workScheduleItemSlice";
import dayjs from "dayjs";
import {getStartAndEndTimeWithModifierFromDateTimeStr} from "../../../utils/dateUtils";


export const getFirstItemStartAndLastItemEndHh = (sortedItems: PlannerItemStartAndEndHourWithType[]) => {
    return {
        firstItemStartHh: sortedItems.length > 0
            ? sortedItems[0].startHh
            : null,
        lastItemEndHh: sortedItems.length > 0
            ? sortedItems[sortedItems.length - 1].endHh
            : null
    }
};

export const selectDisplayWorkGroupById = (state: RootState, groupId: EntityId): DisplayWorkGroup | undefined => {
    const workGroup = selectPlannerItemGroupById(state, groupId);
    const workGroupWithDetails = selectWorkGroupById(state, groupId);
    if (!workGroup || !workGroupWithDetails) return;

    const tripDefinitions = workGroup.tripDefinitionIds
        .map(tripId => selectTripDefinitionById(state, tripId))
        .filter((trip): trip is WorkGroupTripDefinition => !!trip);
    const activities = workGroup.activityIds
        .map(activityId => selectActivityById(state, activityId))
        .filter((activity): activity is WorkGroupActivity => !!activity);

    const sortedItems = getSortedWorkGroupItemTimes([...tripDefinitions, ...activities]);
    const {firstItemStartHh, lastItemEndHh} = getFirstItemStartAndLastItemEndHh(sortedItems);

    return {
        ...workGroupWithDetails,
        tripDefinitions: tripDefinitions,
        activities: activities,
        firstItemStartHh: firstItemStartHh,
        lastItemEndHh: lastItemEndHh
    };
};

export const selectDisplayWorkGroupByIdMemoized = createSelector(
    selectDisplayWorkGroupById,
    (result) => result
);

export const selectAllItemTimesInPlannerItemGroup = (state: RootState, groupId: EntityId): PlannerItemStartAndEndHourWithTypeAndId[] => {
    const group = selectPlannerItemGroupById(state, groupId);
    if (!group) return [];

    const tripDefinitions = group.tripDefinitionIds
        .map(tripId => selectTripDefinitionById(state, tripId))
        .filter((trip): trip is WorkGroupTripDefinition => !!trip);
    const activities = group.activityIds
        .map(activityId => selectActivityById(state, activityId))
        .filter((activity): activity is WorkGroupActivity => !!activity);
    const workItems = group.workItemIds
        .map(workItemId => selectPlannerWorkItemById(state, workItemId))
        .filter((workItem): workItem is WorkItem => !!workItem);

    return getSortedWorkGroupItemTimesWithIdAndType([...tripDefinitions, ...activities, ...workItems]);
};

export const selectAllItemTimesInPlannerItemGroupMemoized = createSelector(
    selectAllItemTimesInPlannerItemGroup,
    (result) => result
);

export const selectDisplayWorkGroupTripDefinitionById = (state: RootState, id: EntityId): DisplayWorkGroupTripDefinition | undefined => {
    const tripDefinition = selectTripDefinitionById(state, id);
    const plannerTimeline = selectPlannerTimelineMemoized(state);
    const contentWidth = state.planner.contentWidth;

    if (!tripDefinition) return undefined;

    return {
        ...tripDefinition,
        ...getDisplayWorkGroupItemDetails(tripDefinition, plannerTimeline, contentWidth)
    };
};

export const selectDisplayWorkGroupActivityById = (state: RootState, id: EntityId): DisplayWorkGroupActivity | undefined => {
    const activity = selectActivityById(state, id);
    const plannerTimeline = selectPlannerTimelineMemoized(state);
    const contentWidth = state.planner.contentWidth;

    if (!activity || !activity.id) return undefined;

    return {
        ...activity,
        ...getDisplayWorkGroupItemDetails(activity, plannerTimeline, contentWidth),
        id: activity.id,
        comment: null,
    };
};

export const selectDisplayWorkItemById = (state: RootState, id: EntityId): DisplayWorkItem | undefined => {
    const workItem = selectPlannerWorkItemById(state, id);
    const plannerTimeline = selectPlannerTimelineMemoized(state);
    const contentWidth = state.planner.contentWidth;

    if (!workItem) return undefined;

    return {
        ...workItem,
        ...getDisplayWorkGroupItemDetails(workItem, plannerTimeline, contentWidth)
    };
};

export const selectDisplayTripWorkItemById = (state: RootState, id: EntityId): DisplayTripDefinitionWorkItem | undefined => {
    const workItem = selectPlannerTripWorkItemById(state, id);
    const plannerTimeline = selectPlannerTimelineMemoized(state);
    const contentWidth = state.planner.contentWidth;

    if (!workItem) return undefined;

    return {
        ...workItem,
        ...getDisplayWorkGroupItemDetails(workItem, plannerTimeline, contentWidth)
    };
};

export const selectWorkScheduleItemByEntityId = (state: RootState, id: number) => {
    const workScheduleItems = selectAllWorkScheduleItems(state);

    return workScheduleItems.find(item => item.id === id);
};

export const selectResourceNameByWorkSheetId = (state: RootState, workSheetId: EntityId) => {
    const workSheet = selectWorkScheduleItemByEntityId(state, workSheetId as number);
    return workSheet
        ? selectResourceNameByType(state, workSheet.resourceId as number, workSheet.resourceType)
        : undefined;
};

export const selectDisplayCharterTripWorkItemById = (state: RootState, id: EntityId): DisplayCharterTripWorkItem | undefined => {
    const workItem = selectCharterTripWorkItemById(state, id);
    const plannerTimeline = selectPlannerTimelineMemoized(state);
    const contentWidth = state.planner.contentWidth;

    if (!workItem) return undefined;

    const workItemWithStartAndEndTimeModifiers = {
        ...workItem,
        ...getStartAndEndTimeWithModifierFromDateTimeStr(workItem.startDateTime, workItem.endDateTime, dayjs(workItem.startDateTime)),
    };

    return {
        ...workItemWithStartAndEndTimeModifiers,
        ...getDisplayWorkGroupItemDetails(workItemWithStartAndEndTimeModifiers, plannerTimeline, contentWidth)
    };
};
