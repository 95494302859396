import React, {ReactElement, useState} from "react";
import {Box, Pagination} from "@mui/material";
import {OdometerReading} from "../../API/bus/types";

const DEFAULT_PAGE_SIZE = 20;

interface PaginatedItemsProps {
    items: OdometerReading[];
    itemsPerPage?: number;
    renderItem: (item: OdometerReading, index: number, array: OdometerReading[]) => ReactElement;
}

export function PaginatedItems({items, itemsPerPage, renderItem}: PaginatedItemsProps) {
    const [page, setPage] = useState(1);

    const pageSize = itemsPerPage ?? DEFAULT_PAGE_SIZE;
    const numberOfPages = Math.ceil(items.length / pageSize);

    return (
        <Box>
            {items.slice((page - 1) * pageSize, page * pageSize).map(renderItem)}
            {items.length > pageSize &&
                <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                    <Pagination
                        count={numberOfPages}
                        onChange={(_, page) => setPage(page)}
                    />
                </Box>
            }
        </Box>
    );
}