import React from "react";
import {Payslip} from "../../../../API/payslip/types";
import {useAppSelector} from "../../../../hooks";
import {selectDriverById} from "../../../../store/driverSlice";
import {Box, Typography} from "@mui/material";
import PayslipBlock from "./PayslipBlock";
import {ArrowBack} from "@mui/icons-material";
import Button from "../../../../components/Buttons/Button";
import {formatName} from "../../workSchedule/utils";
import {composePayslipBlockData} from "./utils";

interface PayslipDetailsProps {
    payslip: Payslip;
    handleNavigateBack: () => void;
}

const PayslipDetails = ({payslip, handleNavigateBack}: PayslipDetailsProps) => {
    const driver = useAppSelector(state => selectDriverById(state, payslip.driverId));

    const blocks = composePayslipBlockData(payslip);

    return (
        <>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                <Box>
                    <Typography component="h1" variant="h5" mr={{xs: 1, sm: 2}}>
                        {driver && formatName(driver)}
                    </Typography>
                    <Typography component="span" color="text.secondary">{driver?.personalId}</Typography>
                </Box>
                <Box displayPrint="none">
                    <Button text="Tagasi" onClick={handleNavigateBack} startIcon={<ArrowBack />} color="secondary" />
                </Box>
            </Box>
            <Box sx={{maxWidth: 'md'}}>
                {blocks.map(block => <PayslipBlock key={block.name} data={block} />)}
            </Box>
        </>
    );
};

export default PayslipDetails;