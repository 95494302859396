import {PayType} from "../../../API/payslip/types";

export enum DirectoCode {
    HOURLY = '199',
    OVERTIME = '30',
    NIGHT_TIME = '213',
    HOLIDAY = '222',
    DISRUPTION = '229',
    UNDERTIME = '346',
    PARCEL_DELIVERY = '185',
    BONUS = '164',
}

export const directoCodeToPayTypes: [DirectoCode, PayType[]][] = [
    [DirectoCode.HOURLY, [PayType.LINE_TIME, PayType.EXTRA_TIME, PayType.RESERVE, PayType.MAINTENANCE]],
    [DirectoCode.OVERTIME, [PayType.OVERTIME]],
    [DirectoCode.NIGHT_TIME, [PayType.NIGHT_TIME]],
    [DirectoCode.HOLIDAY, [PayType.HOLIDAY]],
    [DirectoCode.DISRUPTION, [PayType.DISRUPTION]],
    [DirectoCode.UNDERTIME, [PayType.UNDERTIME]],
    [DirectoCode.PARCEL_DELIVERY, [PayType.PARCEL_DELIVERY_FEE]],
    [DirectoCode.BONUS, [PayType.EXTRA_FEE]],
];

export const payslipViewTypeValues = ['KUUPALGAD', 'LÕPPARVED', 'LISATASUD', 'SÜSTEEMIVÄLISED TÖÖD'] as const;
export type PayslipViewType = typeof payslipViewTypeValues[number];

export const payTypeIsCountedForTotal = (payType: PayType): boolean => {
    switch (payType) {
        case PayType.OVERTIME:
        case PayType.NIGHT_TIME:
        case PayType.HOLIDAY:
        case PayType.DISRUPTION:
        case PayType.UNDERTIME:
        case PayType.PARCEL_DELIVERY_FEE:
        case PayType.EXTRA_FEE:
            return false;
        case PayType.LINE_TIME:
        case PayType.EXTRA_TIME:
        case PayType.RESERVE:
        case PayType.MAINTENANCE:
            return true;
    }
};

export const numberFormat: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};
