import React, {ReactNode} from "react";
import {Box, useTheme} from "@mui/material";

const CardBase = ({children}: { children: ReactNode }) => {
    const theme = useTheme();

    return (
        <Box sx={{
            p: 1,
            my: {xs: 1, s: 1},
            mx: {xs: 0, s: 1},
            borderRadius: '10px',
            border: '2px solid',
            borderColor: theme.palette.divider,
        }}>
            {children}
        </Box>
    );
};

export default CardBase;