import React, {useEffect, useMemo, useState} from "react";
import {Payslip} from "../../../API/payslip/types";
import {deletePayslip, generateFinalPayslip, generatePayslips, getPayslipsForMonth} from "../../../API/payslip/api";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectSelectedRegion} from "../../../store/regionSlice";
import {Dayjs} from "dayjs";
import {setToast} from "../../../store/toastSlice";
import {mapErrors} from "../../../utils/errorMapping";
import {fetchDrivers, selectDriversWithSelectedRegionAsAccountingRegion} from "../../../store/driverSlice";
import PayslipDetails from "./details";
import FinalPayslipDialog from "./list/Payslips/components/FinalPayslipDialog";
import PayslipList from "./list/Payslips";
import {PayslipViewType, payslipViewTypeValues} from "./types";
import {Driver} from "../../../API/driver/types";
import {contractIsActiveOnMonth} from "../workSchedule/utils";
import {AxiosError} from "axios";
import CharterTripHours from "./list/CharterTripHours";
import ExtraPay from "./list/ExtraPay";
import {startOfPreviousMonth} from "../../../utils/dateUtils";


const getDriversWithoutGeneratedPayslip = (driversWithSelectedAccountingRegion: Driver[], payslips: Payslip[], month: Dayjs): Driver[] => {
    const driversWithoutPayslips: Driver[] = [];
    driversWithSelectedAccountingRegion.forEach(driver => {
        if (driver.active) {
            const driverPayslips = payslips.filter(payslip => payslip.driverId === driver.id);
            const activeContractsOnMonthAndYear = driver.contracts.filter(contract => contractIsActiveOnMonth(contract, month));
            if (driverPayslips.length !== activeContractsOnMonthAndYear.length) {
                driversWithoutPayslips.push(driver)
            }
        }
    });
    return driversWithoutPayslips;
};

const Payslips =  () => {
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const driversWithSelectedAccountingRegion = useAppSelector(selectDriversWithSelectedRegionAsAccountingRegion);
    const [month, setMonth] = useState(startOfPreviousMonth());
    const [payslips, setPayslips] = useState<Payslip[]>([]);
    const [selectedPayslip, setSelectedPayslip] = useState<Payslip|undefined>(undefined);
    const [finalPayslipDialogOpen, setFinalPayslipDialogOpen] = useState(false);
    const [payslipViewType, setPayslipViewType] = useState<PayslipViewType>('KUUPALGAD');
    const [driversWithoutGeneratedPayslip, setDriversWithoutGeneratedPayslip] = useState<Driver[]>([]);
    const [error, setError] = useState<string|undefined>(undefined);
    const dispatch= useAppDispatch();

    useEffect(() => {
        dispatch(fetchDrivers())
    }, []);

    useEffect(() => {
        setError(undefined);
        if (selectedRegion) {
            getPayslipsForMonth(selectedRegion.id, month)
                .then(result => setPayslips(result))
                .catch((error: AxiosError) => {
                    if (error.request.status === 403) {
                        setError('Puuduvad õigused vaadata palgalehti selles piirkonnas');
                    }
                    else {
                        dispatch(setToast({
                            type: 'error',
                            text: mapErrors(error) ?? 'Palgalehtede pärimisel tekkis viga'
                        }));
                    }
                })
        }
    }, [month, selectedRegion]);

    useEffect(() => {
        setDriversWithoutGeneratedPayslip(getDriversWithoutGeneratedPayslip(driversWithSelectedAccountingRegion, payslips, month));
    }, [payslips]);

    const handleSelectPayslip = (id: number) => {
        const payslip = payslips.find(payslip => payslip.id === id);
        setSelectedPayslip(payslip);
    };

    const handleChangeMonth = (month: Dayjs) => setMonth(month);

    const handleGenerateFinalPayslip = (driverId: number, date: Dayjs) => {
        generateFinalPayslip(driverId, date)
            .then(result => {
                setPayslips(prevState => [...prevState, result]);
                dispatch(setToast({type: 'success', text: 'Lõpparve koostatud'}));
                setFinalPayslipDialogOpen(false);
            })
            .catch(error => {
                dispatch(setToast({
                    type: 'error',
                    text: mapErrors(error) ?? 'Lõpparve koostamisel tekkis viga'
                }));
            });
    };

    const handleGeneratePayslips = () => {
        if (selectedRegion) {
            generatePayslips(selectedRegion.id, month)
                .then(result => {
                    setPayslips(prevState => [...prevState, ...result]);
                    if (result.length) {
                        dispatch(setToast({type: 'success', text: `${result.length} palgaleht${result.length === 1 ? '' : 'e'} koostatud`}));
                    } else {
                        dispatch(setToast({type: 'info', text: 'Kõigi juhtide palgalehed on juba koostatud'}));
                    }
                })
                .catch(error => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? 'Palgalehtede koostamisel tekkis viga'
                    }));
                });
        }
    };

    const handleDeletePayslip = (id: number) => {
        if (confirm('Kas soovid palgalehe kustutada?')) {
            deletePayslip(id)
                .then(() => {
                    setPayslips(prevState => prevState.filter(payslip => payslip.id !== id));
                    dispatch(setToast({type: 'success', text: 'Palgaleht kustutatud'}));
                })
                .catch(error => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? 'Palgalehe kustutamisel tekkis viga'
                    }));
                });
        }
    };

    const handleChangePayslipViewType = (value: string) => {
        if (payslipViewTypeValues.includes(value as PayslipViewType)) setPayslipViewType(value as PayslipViewType);
    };

    const filteredPayslips = useMemo(() => {
        if (payslipViewType === 'KUUPALGAD') {
            return payslips.filter(payslip => !payslip.body.finalPayslip)
        } else {
            return payslips.filter(payslips => payslips.body.finalPayslip);
        }
    }, [payslips, payslipViewType]);

    const renderPayslipDetails = (payslip: Payslip) => {
        return (
            <PayslipDetails
                payslip={payslip}
                handleNavigateBack={() => setSelectedPayslip(undefined)}
            />
        );
    };

    const renderPayslipsView = () => {
        switch (payslipViewType) {
            case 'LISATASUD':
                return <ExtraPay
                    month={month}
                    handleChangeMonth={handleChangeMonth}
                    payslipViewType={payslipViewType}
                    handleChangePayslipViewType={handleChangePayslipViewType}
                />;
            case 'SÜSTEEMIVÄLISED TÖÖD':
                return <CharterTripHours
                    month={month}
                    handleChangeMonth={handleChangeMonth}
                    payslipViewType={payslipViewType}
                    handleChangePayslipViewType={handleChangePayslipViewType}
                />
            default:
                return (
                    <PayslipList
                        payslips={filteredPayslips}
                        handleOpenPayslip={handleSelectPayslip}
                        handleDelete={handleDeletePayslip}
                        handleChangePayslipViewType={handleChangePayslipViewType}
                        month={month}
                        handleChangeMonth={handleChangeMonth}
                        handleGeneratePayslips={handleGeneratePayslips}
                        payslipViewType={payslipViewType}
                        driversWithoutGeneratedPayslip={driversWithoutGeneratedPayslip}
                        handleOpenFinalPayslipDialog={() => setFinalPayslipDialogOpen(true)}
                        error={error}
                    />
                );
        }
    };

    return (
        <>
            {selectedPayslip ? renderPayslipDetails(selectedPayslip) : renderPayslipsView()}
            {finalPayslipDialogOpen &&
                <FinalPayslipDialog
                    handleClose={() => setFinalPayslipDialogOpen(false)}
                    handleSave={handleGenerateFinalPayslip}
                />
            }
        </>
    );
};

export default Payslips;
