import {Fuel} from "../API/types";
import {FormikContextType} from "formik";
import {DriverForm} from "../scenes/authenticated/drivers/detailsView/types";
import {BusForm} from "../scenes/authenticated/buses/detailsView/types";

export const getEnumFromStr = <T extends { [key: string]: any }>(value: string, enumObject: T): T[keyof T] | undefined => {
	const keys = Object.keys(enumObject) as (keyof T)[];
	for (const key of keys) {
		if (enumObject[key].toString() === value) {
			return enumObject[key];
		}
	}
	return undefined;
};

export const getFuelsFromStr = (values: string[]): Fuel[] => {
	return values.map(fuel => getEnumFromStr(fuel, Fuel)).filter((fuel): fuel is Fuel => fuel !== undefined);
};

export const strToNr = (stringValue: string): number => Number(stringValue);

export const updateAccountingRegionOnRegionsChange = (formikProps: FormikContextType<DriverForm> | FormikContextType<BusForm>) => {
	// if no accounting region is selected when a region is added, then set it as the accounting region by default
	if (!formikProps.values.accountingRegionId && formikProps.values.regions.length > 0 && formikProps.values.regions[0].id) {
		void formikProps.setFieldValue('accountingRegionId', formikProps.values.regions[0].id);
	}
};