import React, {ReactElement} from "react";
import dayjs from "dayjs";

interface TimeLabelProps {
    startDateTime?: string | null;
    endDateTime?: string | null;
}

const TimeLabel = ({startDateTime, endDateTime}: TimeLabelProps): ReactElement => {
    if (startDateTime && endDateTime) {
        return <>{dayjs(startDateTime).format('HH:mm')} - {dayjs(endDateTime).format('HH:mm')}</>;
    }
    if (startDateTime) {
        return <>{dayjs(startDateTime).format('HH:mm')}</>;
    }
    if (endDateTime) {
        return <>{dayjs(endDateTime).format('HH:mm')}</>;
    }

    return <></>;
};

export default TimeLabel;
