import React, {useContext} from 'react';
import {
    Box,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography, useMediaQuery,
    useTheme,
} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {Logout} from '@mui/icons-material';
import {useAuth} from '../../../contexts/AuthContext';
import {SidebarContext} from '../../../contexts/SidebarContext';


export default function Profile() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const auth = useAuth();
    const theme = useTheme();
    const { isSidebarOpen } = useContext(SidebarContext);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const signOut = () => auth.signOut();

    const handleClick = (event: any) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const renderProfile = () => {
        return (
            <Box>
                <Tooltip title="Kasutaja" arrow>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{width: '100%', py: {xs: 1, sm: 2}, borderRadius: {xs: '', sm: 0}}}
                    >
                        <AccountCircle sx={{fontSize: '30px', color: theme.palette.text.secondary}} />
                        {isSidebarOpen && !isScreenSmall && <Typography sx={{ ml: 1 }}>{auth.authenticatedUser && auth.authenticatedUser.firstName}</Typography>}
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            borderRadius: {xs: '0 0 5px 5px', sm: '5px'},
                            filter: {sm: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))'},
                            border: {xs: `1px solid ${theme.palette.divider}`}
                        },
                    }}
                    transformOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                    sx={{position: {xs: 'absolute', sm: 'fixed'}, top: {xs: '33px', sm: ''}}}
                >
                    <ListItemIcon sx={{display: 'flex', alignItems: 'center', py: 1, px: 2}}>
                        <AccountCircle sx={{fontSize: '30px', pr: 1}} />
                        <Typography>{auth.authenticatedUser?.firstName}</Typography>
                    </ListItemIcon>
                    <Divider />
                    <Box sx={{
                        marginLeft: '4px',
                        marginRight: '4px',
                        marginTop: '4px',
                        borderRadius: '5px'
                    }}>
                    <MenuItem onClick={() => signOut()} sx={{py: 1}}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                        <Typography>Logi välja</Typography>
                    </MenuItem>
                    </Box>
                </Menu>
            </Box>
        );
    }

    return (isScreenSmall ? renderProfile() :
        <Box sx={{display: 'flex', height: '90px', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', bottom: 0}}>
            {renderProfile()}
        </Box>
    );
}
