import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box, DialogActions, DialogContent, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import {BusOption, DriverOption, useAppDispatch, useAppSelector, useResourceOptions} from "../../../../../hooks";
import {selectToggledResourceType} from "../../../../../store/viewSlice";
import {Form, Formik, FormikHelpers} from "formik";
import Autocomplete from "../../../../Form/Autocomplete";
import Button from "../../../../Buttons/Button";
import {Add, Clear} from "@mui/icons-material";
import * as Yup from "yup";
import {validationSchema} from "../../../../../utils/formValidation";
import {addWorkSheet, clearDialogData} from "../../../../../store/plannerSlice";
import {ResourceType} from "../../../../../API/types";

interface AddWorkSheetForm {
    resource: DriverOption | BusOption | null;
}

const addWorkSheetValidationSchema = (label: string) => Yup.object().shape({
    resource: validationSchema(label).fields.objectRequired,
});

const defaultValues: AddWorkSheetForm = {
    resource: null,
};

export default function AddWorkSheetDialog() {
    const resourceType = useAppSelector(selectToggledResourceType);
    const {availableDrivers, availableBuses} = useResourceOptions();
    const dispatch = useAppDispatch();

    const options = resourceType === ResourceType.DRIVER ? availableDrivers : availableBuses;

    const handleClose = () => {
        dispatch(clearDialogData());
    };

    const handleFormSubmitClick = (form: AddWorkSheetForm, formHelpers: FormikHelpers<AddWorkSheetForm>) => {
        formHelpers.setSubmitting(true);
        if (form.resource) {
            dispatch(addWorkSheet({resourceId: form.resource.id}));
        }
        handleClose();
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <Box sx={{width: '400px', maxWidth: '100%', mt: 1, p: {xs: 0, sm: 3}}}>
                <DialogTitle>
                    <Typography variant="h5" component="div">
                        {getResourceTypeTranslation(resourceType, 'genitive')} sõidulehe lisamine
                    </Typography>
                </DialogTitle>
                <Formik
                    initialValues={defaultValues}
                    validationSchema={addWorkSheetValidationSchema(getResourceTypeTranslation(resourceType))}
                    onSubmit={handleFormSubmitClick}
                >
                    <Form>
                        <DialogContent sx={{pt: 0}}>
                            <Autocomplete
                                options={options}
                                name="resource"
                                label={getResourceTypeTranslation(resourceType)}
                                getOptionLabel={option => option.name}
                                noOptionsText={'Ühtegi '
                                    + getResourceTypeTranslation(resourceType, 'partitive').toLowerCase()
                                    + ' ei leitud'}
                            />
                        </DialogContent>
                        <DialogActions sx={{justifyContent: 'center', maxWidth: '100%'}}>
                            <Box maxWidth="50%">
                                <Button
                                    text="Loobu"
                                    variant="outlined"
                                    onClick={handleClose}
                                    startIcon={<Clear />}
                                />
                            </Box>
                            <Box maxWidth="50%">
                                <Button text="Lisa" type="submit" startIcon={<Add />} />
                            </Box>
                        </DialogActions>
                    </Form>
                </Formik>
            </Box>
        </Dialog>
    );
}