import React from "react";
import {FormControlLabel, Switch as MuiSwitch, Typography} from "@mui/material";
import {useField} from "formik";

interface SwitchProps {
    name: string;
    label: string;
}

const Switch = ({name, label}: SwitchProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, _, helpers] = useField(name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => void helpers.setValue(event.target.checked);

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    {...field}
                    checked={field.value}
                    onChange={handleChange}
                />
            }
            label={
                <Typography color={field.value ? 'text.primary' : 'text.secondary'}>{label}</Typography>
            }
        />
    );
};

export default Switch;
