import React, {useEffect} from 'react';
import {Box} from "@mui/material";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import DetailView from "./views/detailView";
import {
    fetchCurrentDriverWorkSheets,
    selectCurrentDriverWorkSheetLoading
} from "../../../../store/currentDriverWorkSheetsSlice";
import ListView from "./views/listView";
import {Route, Routes} from "react-router-dom";
import BusView from "./views/busView";
import routes from "../../../../routes";
import LoaderWithChildren from "../../../../components/LoaderWithChildren";

const DriverDashboard = () => {
    const loading = useAppSelector(selectCurrentDriverWorkSheetLoading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchCurrentDriverWorkSheets(dayjs().subtract(5, 'days')));
    }, []);

    return (
        <LoaderWithChildren loading={loading}>
            <Box sx={{maxWidth: {'xs': '100%', 'sm': '400px'}}}>
                <Routes>
                    <Route index element={<ListView />} />
                    <Route path={routes.AUTHENTICATED.AGENDA.BUS.replace(routes.AUTHENTICATED.AGENDA.ROOT+'/', '')} element={<BusView />} />
                    <Route path={routes.AUTHENTICATED.AGENDA.WORK_SHEET.replace(routes.AUTHENTICATED.AGENDA.ROOT+'/', '')} element={<DetailView />} />
                </Routes>
            </Box>
        </LoaderWithChildren>
    );
};

export default DriverDashboard;
